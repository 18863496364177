import { batch } from '@preact/signals-core';
import { createDataList, type List } from '../cache/DataList';
import type { Node } from '../cache/DataNode';
import type { NodeFactory } from '../cache/nodeFactory';
import type { StatusFragment } from '../schema';
import { meta } from './meta';
import { updateFieldStatus } from './updateFieldStatus';

interface ProcessValuesOptions {
  values: any;
  // fieldStatus?: StatusFragment;
  source?: string;

  factory: NodeFactory;
}

export function processNodeData(options: ProcessValuesOptions): any {
  // const { id, __typename: typename } = options.values;

  return batch(() => {
    if (Array.isArray(options.values)) {
      return options.values.map((value) =>
        processNodeData({
          ...options,
          values: value,
        }),
      );
    }

    if (
      options.values === null ||
      typeof options.values !== 'object' ||
      !options.values.__typename ||
      !options.values.id
    ) {
      return options.values;
    }

    const node = options.factory.ensureNode(
      {
        __typename: options.values.__typename,
        id: options.values.id,
      },
      {
        fieldStatus: { id: 'ready' },
        source: options.source,
      },
    );
    const fieldStatus = meta(node).fieldStatus;

    if (options.source && !meta(node).sources.includes(options.source)) {
      meta(node).sources.push(options.source);
    }

    let statusChanged = false;
    if (fieldStatus) {
      for (const key in options.values) {
        if (key !== '__typename' && key !== 'id') {
          const changed = processField(node, fieldStatus, key, options);
          statusChanged = statusChanged || changed;
        }
      }
    }

    if (statusChanged) {
      // Trigger reactivity
      meta(node).fieldStatus = { ...fieldStatus };
    }

    return node;
  });
}

function processField(
  node: Node,
  fieldStatus: StatusFragment,
  key: string,
  options: ProcessValuesOptions,
): boolean {
  const nodeSchema = meta(node).schema;

  const fieldName = key.split('__')[0];
  const fieldSchema =
    nodeSchema?.fields[fieldName] || nodeSchema?.reverseFields[fieldName];
  let fieldValue = (options.values as any)[key];

  const statusChanged = updateFieldStatus(
    fieldStatus,
    key,
    fieldSchema,
    'ready',
  );

  if (fieldSchema?.type === 'date' && typeof fieldValue === 'string') {
    fieldValue = fieldValue.includes('T')
      ? new Date(fieldValue)
      : new Date(Number.parseInt(fieldValue as string));
  }

  if (!fieldSchema || fieldSchema.scalar || fieldSchema.enum) {
    updateField(node, key, fieldValue);
    return statusChanged;
  }

  if (fieldSchema.array) {
    if (!fieldSchema.reverse) return statusChanged;

    if (fieldValue && Array.isArray(fieldValue)) {
      for (const value of fieldValue) {
        processNodeData({
          ...options,
          values: {
            ...value,
            [fieldSchema.reverse]: { __typename: node.__typename, id: node.id },
          },
        });
      }
    }

    const existingList = meta(node).store.$.peek(key);
    if (existingList) {
      meta(existingList as List).isLoading = false;
    } else {
      updateField(
        node,
        key,
        createDataList({
          typename: fieldSchema.type,
          match: { where: { [fieldSchema.reverse]: { eq: node.id } } },
          sort: fieldSchema.sort?.[0]?.order,
          schema: meta(node).appSchema,
          factory: options.factory,
          placeholderCount: 3,
        }),
      );
    }
    //     utils.processOrigins(list[1], list[3], !!local, list[2]);
    //     const nested = nestedRootInstances[list[0]];
    //     if (nested) {
    //       meta(nested).isLoading = false;
    //       invalidate(list[0]);
    //     }

    //   scheduled.lists.push({
    //     key,
    //     value: fieldValue || [],
    //     defaultValues: { [fieldSchema.reverse]: node },
    //     status: options.fieldStatus?.[key as string] as
    //       | StatusFragment
    //       | undefined,
    //   });
    return statusChanged;
  }

  if (!fieldValue) {
    updateField(node, key, null);
    return statusChanged;
  }

  // const nestedStatus =
  //   options.fieldStatus &&
  //   ((options.fieldStatus[key] ??= { id: 'ready' }) as
  //     | StatusFragment
  //     | undefined);

  updateField(
    node,
    key,
    processNodeData({
      ...options,
      source: undefined,
      values: fieldValue,
      // fieldStatus: { id: 'ready' },
    }),
  );

  return statusChanged;
}

function updateField(node: Node, key: string, value: any) {
  if (meta(node).store.$.peek(key) !== value)
    (meta(node).store as any)[key] = value;
}
