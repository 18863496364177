import { store, type Store } from '@donkeyjs/proxy';
import { activateElement } from './dom-utils/createElement';
import { registerInfo } from './info';
import { componentContext } from './mount/mount';

export interface JSXVirtualNode {
  __type: 'node';
  currentValue: any;
  update(value: any): true | string;
  testUpdate(value: any): true | string;
  dispose(): void;
  nodes: DomNode[];
}

export interface DomStyle extends DomElement {
  nodeType: 1;
  id: string;
}

export interface DomText {
  parentNode: DomElement | null | undefined;
  nodeType: 3;
  textContent: string | number | object;
}

export interface DomComment {
  parentNode: DomElement | null | undefined;
  nodeType: 8;
  data: string;
}

export interface DomElement {
  parentNode: DomElement | null | undefined;
  nodeType: 1;
  readonly tagName: string;
  attributes?: Record<string, any>;
  childNodes: DomNodeList;
  setAttribute(name: string, value: string): void;
  removeAttribute(name: string): void;
  innerHTML?: string;
  append(...nodes: DomNode[]): void;
  insertBefore(newNode: DomNode, referenceNode: DomNode | null): void;
  removeChild(child: DomNode): void;
  addEventListener: (
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ) => void;
  removeEventListener: (
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ) => void;
}

export type DomNodeList = NodeListOf<ChildNode>;

export type DomNode = DomText | DomElement | DomComment;

export interface JSXNode {
  __type: 'jsx-node';
  tag: any;
  props: any;
  children: JSX.Element[];
}

export function isVirtualNode(value: any): value is JSXVirtualNode {
  return (
    typeof value === 'object' && '__type' in value && value.__type === 'node'
  );
}

export function isJSXNode(value: any): value is JSXNode {
  return (
    value != null &&
    typeof value === 'object' &&
    '__type' in value &&
    value.__type === 'jsx-node'
  );
}

export interface DomUtils {
  ssr: boolean;
  createElement(
    tagName: string,
    attr?: Record<string, any>,
    namespace?: string,
  ): DomElement;
  createTextNode(text: string): DomText;
  createComment(text: string): DomComment;
  // createFragment(
  //   children: JSX.Element,
  //   context: RenderContext | undefined,
  // ): DomFragment;
  // createDummy(
  //   onmount?: () => (() => void) | undefined,
  //   data?: string,
  // ): DomDummy;
}

export interface Dom extends DomUtils {
  crawler: boolean;
  ssrCursor?: Map<Node, Node | null>;
  ssrMismatches?: any[][];
  head: DomElement;
  headAttributes: Store<JSX.IntrinsicElements['head']>;
  body: DomElement;
  bodyAttributes: Store<JSX.IntrinsicElements['body']>;
  window: {
    addEventListener: typeof window.addEventListener;
    removeEventListener: typeof window.removeEventListener;
  };
  removeExtraSsrNodes(): void;
}

// const ssrCursor = new Map<DomNode, DomNode | null>();
// const ssrMismatches: any[][] = [];

// function matchSsrElement(creating: Creating): DomElement | DomText | null {
//   const parent = creating.area.parentNode;

//   if (!parent) throw new Error('Insert before or after not supported in SSR');

//   let cursor = ssrCursor.get(parent);
//   if (cursor === undefined) cursor = parent.firstChild;

//   while (
//     cursor &&
//     (cursor as DomElement).nodeType === 1 &&
//     (cursor as DomElement).tagName === 'STYLE'
//   ) {
//     cursor = cursor.nextSibling;
//   }

//   ssrCursor.set(parent, cursor || null);

//   if (!cursor) {
//     ssrMismatches.push([
//       `Expected "${creating.describe()}" but found no more elements in`,
//       parent,
//     ]);
//     return null;
//   }

//   const isMismatch = (creating: Creating, ssrElement: DomElement | DomText) =>
//     (creating.type === 'text' && ssrElement.nodeType !== 3) ||
//     (creating.type === 'element' &&
//       (ssrElement.nodeType !== 1 ||
//         ssrElement.tagName?.toUpperCase() !==
//           creating.tagNameOrText.toUpperCase()));

//   let ssrElement = cursor as DomElement | DomText | null;
//   if (isMismatch(creating, ssrElement!)) {
//     ssrMismatches.push([
//       `Expected "${creating.describe()}" but got`,
//       ssrElement,
//     ]);
//     while (ssrElement && isMismatch(creating, ssrElement)) {
//       const next = ssrElement.nextSibling;
//       if ((ssrElement as HTMLElement).tagName !== 'STYLE') {
//         (ssrElement.parentNode as Node)?.removeChild(ssrElement as Node);
//       }
//       ssrElement = next as DomElement | DomText | null;
//     }
//     ssrCursor.set(parent, ssrElement || null);
//     if (!ssrElement) return null;
//   }

//   if (
//     creating.type === 'text' &&
//     (ssrElement as DomText).textContent !==
//       creating.tagNameOrText?.replace(/\r/g, '\n')
//   ) {
//     ssrMismatches.push([
//       `Expected "${creating.describe()}" but got "${
//         (ssrElement as DomText).textContent
//       }"`,
//     ]);
//     (ssrElement as DomText).textContent = creating.tagNameOrText;
//   }

//   if (ssrElement?.firstChild) {
//     ssrCursor.set(ssrElement, ssrElement.firstChild);
//   }

//   ssrCursor.set(parent, ssrElement!.nextSibling || null);
//   creating.foundSsr = true;
//   return ssrElement;
// }

let bodyAttributes: Store<JSX.IntrinsicElements['body']> | undefined;
let headAttributes: Store<JSX.IntrinsicElements['head']> | undefined;

function activateBody() {
  if (!bodyAttributes) {
    bodyAttributes = store({ class: 'app-root' });
    activateElement(document.body as DomElement, bodyAttributes);
  }
}

function activateHead() {
  if (!headAttributes) {
    headAttributes = store({});
    activateElement(document.head as DomElement, headAttributes);
  }
}

export const dom: Dom = {
  ssr: false,
  crawler: false,

  get body() {
    activateBody();
    return document.body as DomElement;
  },

  get head() {
    activateHead();
    return document.head as DomElement;
  },

  get bodyAttributes() {
    activateBody();
    return bodyAttributes!;
  },

  get headAttributes() {
    activateHead();
    return headAttributes!;
  },

  window: {
    addEventListener: (
      type: string,
      listener: EventListenerOrEventListenerObject,
      options?: boolean | AddEventListenerOptions,
    ) => {
      window.addEventListener(type, listener, options);
    },
    removeEventListener: (
      type: string,
      listener: EventListenerOrEventListenerObject,
      options?: boolean | EventListenerOptions,
    ) => {
      window.removeEventListener(type, listener, options);
    },
  },

  createElement(tagName, _attr, namespace) {
    const result = namespace
      ? (document.createElementNS(namespace, tagName) as DomElement)
      : (document.createElement(tagName) as DomElement);
    (result as any).__context = componentContext.current;
    return result;
  },

  createTextNode(text) {
    return document.createTextNode(text) as DomText;
  },

  createComment(text) {
    return document.createComment(text) as DomComment;
  },

  removeExtraSsrNodes() {
    if (dom.ssrCursor) {
      for (let cursor of dom.ssrCursor.values()) {
        while (
          cursor &&
          !(
            cursor.nodeType === 1 &&
            (cursor as HTMLElement).tagName === 'SCRIPT' &&
            (cursor as HTMLElement).getAttribute('type') === 'application/ssr'
          )
        ) {
          const next = cursor.nextSibling;
          (dom.ssrMismatches ??= []).push(['Extra SSR node', cursor]);
          (cursor.parentNode as Node)?.removeChild(cursor as Node);
          cursor = next;
        }
      }
    }
    if (dom.ssrMismatches?.length) {
      console.warn(`${dom.ssrMismatches.length} SSR mismatches.`);
      for (const [message, element] of dom.ssrMismatches) {
        registerInfo('ssr', message, element);
      }
    }
  },
};
