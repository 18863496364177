import {
  type NodeFieldsFromSchema,
  type SchemaMeta,
  createSchema,
} from '@donkeyjs/proxy';
import { baseSchema } from '../data/baseSchema';
import { baseSchemaMeta } from '../data/baseSchemaMeta';
import type { LocaleSet } from '../i18n';
import {
  type Permissions,
  type PermissionsInput,
  defaultPermissions,
} from '../permissions';

export interface CreateAppInput {
  key: string;
  hostname: string;
  schema: DataSchema;
  schemaMeta?: SchemaMeta<DataSchema>;
  locales: LocaleSet;
  permissions?: Permissions<DataSchema>;
  roles?: {
    value: string;
    label: string;
  }[];
  userCreation?: {
    role?: string;
    fields?: (keyof NodeFieldsFromSchema<DataSchema, 'User'>)[];
  };
  routerFollowsCultures?: boolean;
}

export interface AppModule {
  schemaMeta?: SchemaMeta<DataSchema>;
  permissions?: PermissionsInput<DataSchema>;
}

export type AppBase = {
  key: string;
  hostname: string;
  schema: ApplicationSchema;
  schemaMeta: SchemaMeta<DataSchema>;
  locales: LocaleSet;
  permissions: Permissions<DataSchema>;
  routerFollowsCultures: boolean;
  roles: { value: string; label: string }[];
  userCreation?: CreateAppInput['userCreation'];
};

export const createApp = (
  input: CreateAppInput,
  modules?: AppModule[],
): AppBase => {
  const permissions = (modules || []).reduce(
    (acc, module) => (module?.permissions ? acc.with(module.permissions) : acc),
    input.permissions || defaultPermissions(),
  );

  return {
    key: input.key,
    hostname: input.hostname,
    locales: input.locales || {},
    permissions,
    routerFollowsCultures: input.routerFollowsCultures || false,
    userCreation: input.userCreation || undefined,
    roles: input.roles || [],
    schema:
      (input.schema as ApplicationSchema | undefined) ||
      (createSchema(baseSchema) as unknown as ApplicationSchema),
    schemaMeta: {
      ...(baseSchemaMeta as any),
      ...(modules?.reduce((acc, module) => {
        for (const k in module.schemaMeta) {
          const key = k as keyof SchemaMeta<DataSchema>;
          if (acc[key]) {
            acc[key] = {
              ...acc[key],
              ...module.schemaMeta[key],
            };
          } else {
            acc[key] = module.schemaMeta[key];
          }
        }
        return acc;
      }, {} as any) as SchemaMeta<DataSchema>),
      ...(input.schemaMeta as any),
    },
  };
};
