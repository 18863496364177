import type { LocaleInput } from '@donkeyjs/core';
import { formatLocale } from 'd3-format';
import { format } from 'date-fns';
import dateLocale from 'date-fns/locale/nl';

export const I18nSystemNl = () =>
  import('./System').then((m) => m.I18nSystemNl);

export const I18nBlocksNl = () =>
  import('./Blocks').then((m) => m.I18nBlocksNl);

export const I18nImportNl = () =>
  import('./Import').then((m) => m.I18nImportNl);

export const schemaNlNL = () => import('./Schema').then((m) => m.BaseSchemaNl);

const d3Locale = {
  decimal: ',',
  thousands: '.',
  grouping: [3],
  currency: ['€', ''],
};

const formatNumber = formatLocale(d3Locale as any).format;

export const nlNL: LocaleInput = {
  formatDate: (date, specifier) =>
    format(date, specifier, { locale: dateLocale }),
  formatNumber: (number, specifier) => formatNumber(specifier)(number),
  dateLocale,
};
