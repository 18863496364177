import { store } from '@donkeyjs/proxy';
import type { MenuProps } from './Menu';
import { getMenu } from './getMenu';
import { session } from '../session';
import { getUserContext } from '../authentication';

export function useMenu(props: MenuProps = {}) {
  const user = getUserContext();

  return store({
    get level() {
      return (
        props.routes ||
        getMenu(
          store.clone(store(props), {
            activeRoute: props.activeRoute || session.router.route,
            user,
          }),
        )
      );
    },
  });
}
