import { bind } from '@donkeyjs/jsx-runtime';
import { meta, type DataNode, type NodeTypename } from '@donkeyjs/proxy';
import { CheckboxList } from '../../components/CheckboxList';
import { LoadingSingleLine } from '../../loaders';
import { session } from '../../session';

interface NodeSelectCheckboxListProps<
  Typename extends NodeTypename<DataSchema>,
> {
  typename: Typename;
  values: DataNode<DataSchema, Typename>[];
  select(value: DataNode<DataSchema, Typename>): void;
  deselect(value: DataNode<DataSchema, Typename>): void;
}

export function NodeSelectCheckboxList<
  Typename extends NodeTypename<DataSchema>,
>(props: NodeSelectCheckboxListProps<Typename>) {
  const options = session.data.useNodes<Typename>({
    typename: props.typename,
    drafts: false,
  });

  return () =>
    meta(options).isLoading ? (
      <LoadingSingleLine />
    ) : (
      <CheckboxList
        values={bind(() => props.values)}
        toggle={(value) =>
          props.values.includes(value)
            ? props.deselect(value)
            : props.select(value)
        }
        options={bind(() =>
          options.map((node) => ({
            label: node.toString(),
            value: node,
          })),
        )}
      />
    );
}
