import { batch, store } from '@donkeyjs/proxy';

type Position = [Node | undefined, number];

const internal = store({
  v: 0,
  startNode: undefined as Node | undefined,
  startOffset: 0,
  endNode: undefined as Node | undefined,
  endOffset: 0,
  collapsed: true,
  // enqueued: undefined as
  //   | undefined
  //   | {
  //       element: Node;
  //       from: number;
  //       to: number;
  //     },
});

export interface DocumentSelection {
  readonly start: Position;
  readonly end: Position;
  readonly collapsed: boolean;
  readonly commonAncestor: Node | null;
  relateTo(element: Element | null | undefined): ScopedDocumentSelection | null;
  set(where: { element: Element; from: number; to: number }): void;
  set(start: Position, end: Position): void;
  // store(element: Element): ScopedDocumentSelection | null;
  // restore(replacedElement?: Element): void;
  forceUpdate(): void;
  clear(): void;
}

export interface ScopedDocumentSelection {
  element: Element;
  from: number;
  to: number;
  reversed: boolean;
  collapsed: boolean;
  readonly outdated: boolean;
  set(from: number, to: number): void;
}

// let currentRange: Range | null = null;
// let stored: ScopedDocumentSelection | null = null;
// const storedFor = new Map<Element, ScopedDocumentSelection>();
// let storedCounter = 0;

export const documentSelection: DocumentSelection = {
  get start() {
    ensureSelection();
    return [internal.startNode, internal.startOffset] as Position;
  },

  get end() {
    ensureSelection();
    return [internal.endNode, internal.endOffset] as Position;
  },

  get collapsed() {
    ensureSelection();
    return internal.collapsed;
  },

  get commonAncestor() {
    ensureSelection();
    if (!internal.startNode || !internal.endNode) return null;
    return getCommonAncestor(internal.startNode, internal.endNode);
  },

  relateTo: (element) => {
    if (!element) return null;

    ensureSelection();

    let from = 0;
    let to = 0;

    const v = internal.v;
    const startNode = internal.startNode as Element | undefined;
    const endNode = internal.endNode as Element | undefined;

    if (!startNode || !endNode) return null;

    if (element === startNode) {
      const childAtIndex = element.childNodes[internal.startOffset];
      from =
        internal.startOffset === 0
          ? 0
          : childAtIndex
            ? getOffsetIn(childAtIndex, element)
            : getOffsetIn(
                element.childNodes[internal.startOffset - 1],
                element,
              ) + 1;
    } else {
      const fromOffset = getOffsetIn(startNode, element);
      if (fromOffset === -1) return null;
      from = fromOffset + internal.startOffset;
    }

    if (element === endNode) {
      if (internal.endOffset > 0) {
        const childAtIndex = element.childNodes[internal.endOffset];
        to =
          internal.endOffset === 0
            ? 0
            : childAtIndex
              ? getOffsetIn(childAtIndex, element)
              : getOffsetIn(
                  element.childNodes[internal.endOffset - 1],
                  element,
                ) + 1;
      }
    } else {
      const toOffset = getOffsetIn(endNode, element);
      if (toOffset === -1) return null;
      to = toOffset + internal.endOffset;
    }

    const set = (from: number, to: number) =>
      documentSelection.set({ element: result.element, from, to });

    const outdated = () => internal.v !== v;

    const result =
      from > to
        ? {
            element,
            from: to,
            to: from,
            reversed: true,
            collapsed: false,
            set,
            get outdated() {
              return outdated();
            },
          }
        : {
            element,
            from,
            to,
            reversed: false,
            collapsed: from === to,
            set,
            get outdated() {
              return outdated();
            },
          };

    return result;
  },

  set: (
    whereOrStart: { element: Element; from: number; to: number } | Position,
    end?: Position,
  ) => {
    ensureSelection();

    if (Array.isArray(whereOrStart)) applySelection(whereOrStart, end!);
    else {
      const { element, from, to } = whereOrStart;
      if (!element.isConnected) return;
      // internal.enqueued = { element, from, to };

      const start = getChildOffset(element, from!);
      const end = getChildOffset(element, to!);

      if (start && end) applySelection(start, end);
    }
  },

  // store: (element) => {
  //   return null;
  //   if (!isWatching) return null;

  //   return dontWatch(() => {
  //     if (storedFor.has(element)) {
  //       return storedFor.get(element)!;
  //     }

  //     const forElement = documentSelection.relateTo(element);
  //     if (forElement) {
  //       storedFor.set(element, forElement);
  //       return forElement;
  //     }

  //     if (stored) {
  //       storedCounter++;
  //       return stored;
  //     }

  //     const result = currentRange?.intersectsNode(element)
  //       ? documentSelection.relateTo(
  //           getCommonElement(internal.startNode!, internal.endNode!),
  //         )
  //       : null;

  //     stored = result;
  //     storedCounter++;

  //     return result;
  //   });
  // },

  // restore(replacedElement) {
  //   return null;
  //   if (replacedElement && storedFor.has(replacedElement)) {
  //     const stored = storedFor.get(replacedElement)!;
  //     documentSelection.set({
  //       element: stored.element,
  //       from: stored.from,
  //       to: stored.to,
  //     });
  //     storedFor.delete(replacedElement);
  //   }

  //   if (storedCounter > 0) {
  //     storedCounter--;
  //     if (stored && storedCounter === 0) {
  //       documentSelection.set({
  //         element: stored.element,
  //         from: stored.from,
  //         to: stored.to,
  //       });
  //       stored = null;
  //     }
  //   }
  // },

  forceUpdate: () => {
    updateSelection();
  },

  clear: () => {
    const selection = window.getSelection();
    selection?.removeAllRanges();
  },
};

let isWatching = false;

const ensureSelection = () => {
  if (!isWatching) {
    isWatching = true;
    watchSelection();
  }
};

// const inChildren = (
//   element: Element,
//   from: number,
//   to: number,
// ): [Position, Position] => {
//   const start = getChildOffset(element, from!);
//   const end = getChildOffset(element, to!);

//   if (start && end) return [start, end];

//   return [
//     [undefined, 0],
//     [undefined, 0],
//   ];
// };

export const afterNodeRemoval = () => {
  // updateSelection();
};

// export const textNodeChanged = (node: DomText) => {
//   return null;
//   if (!isWatching) return null;

//   const [start, end] = /*internal.enqueued
//     ? inChildren(
//         internal.enqueued.element as Element,
//         internal.enqueued.from,
//         internal.enqueued.to,
//       )
//     :*/ [
//     [internal.startNode, internal.startOffset] as const,
//     [internal.endNode, internal.endOffset] as const,
//   ];

//   if (
//     isWatching &&
//     start[0] &&
//     end[0] &&
//     (node === start[0] || node === end[0])
//   )
//     applySelection(
//       [
//         start[0],
//         start[0].nodeType === Node.TEXT_NODE
//           ? Math.min(start[1], (start[0] as Text).length)
//           : start[1],
//       ],
//       [
//         end[0],
//         end[0].nodeType === Node.TEXT_NODE
//           ? Math.min(end[1], (end[0] as Text).length)
//           : end[1],
//       ],
//     );

//   updateSelection();
// };

const applySelection = (start: Position, end: Position) => {
  const selection = window.getSelection();
  const range = document.createRange();
  range.setStart(start[0]!, start[1]);
  range.setEnd(end[0]!, end[1]);
  selection?.removeAllRanges();
  selection?.addRange(range);
  // stored = null;
  // storedCounter = 0;
  // storedFor.clear();
  batch(() => {
    // internal.enqueued = undefined;
    internal.v = internal.$.peek('v') + 1;
    internal.startNode = start[0];
    internal.startOffset = start[1];
    internal.endNode = end[0];
    internal.endOffset = end[1];
    internal.collapsed = end.join() === start.join();
    // currentRange = range;
  });
};

const watchSelection = () => {
  updateSelection();
  document.addEventListener('selectionchange', updateSelection);
};

const getSelection = () => {
  const selection = window.getSelection();
  const range = selection?.rangeCount ? selection.getRangeAt(0) : undefined;
  const v = internal.$.peek('v') + 1;

  if (!range)
    return {
      v,
      startNode: undefined,
      startOffset: 0,
      endNode: undefined,
      endOffset: 0,
      collapsed: true,
      range: null,
    };

  return {
    v,
    startNode: range.startContainer,
    startOffset:
      range.startOffset === 1 && range.startContainer.textContent === '\u200B'
        ? 0
        : range.startOffset,
    endNode: range.endContainer,
    endOffset:
      range.endOffset === 1 && range.endContainer.textContent === '\u200B'
        ? 0
        : range.endOffset,
    collapsed: range.collapsed,
    range,
  };
};

const updateSelection = () => {
  const selection = getSelection();

  batch(() => {
    Object.assign(internal, selection);
    // currentRange = range;
  });
};

const getOffsetIn = (element: Node, parentElement: Node) => {
  if (element === parentElement) return 0;
  let offset = getParentOffset(element);
  let current = element;
  while (current.parentNode && current.parentNode !== parentElement) {
    current = current.parentNode as Element;
    offset += getParentOffset(current);
  }
  return current.parentNode === parentElement ? offset : -1;
};

const getParentOffset = (element: Node) => {
  const walkChildren = (current: Node) => {
    const childNodes = current.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
      const childNode = childNodes[i];
      if (childNode.nodeType === Node.TEXT_NODE) {
        const text = (childNode as Text).textContent;
        if (text) {
          offset += text.length;
        }
      } else if (childNode.nodeType === Node.COMMENT_NODE) {
        // Ignore comment nodes
      } else if (
        (childNode as Element).getAttribute?.('contenteditable') === 'false'
      ) {
        offset += 1;
      } else {
        walkChildren(childNode);
      }
    }
  };

  let offset = 0;
  let current = element.previousSibling;
  while (current) {
    if (current.nodeType === Node.TEXT_NODE) {
      const text = (current as Text).textContent;
      if (text) {
        offset += text.length;
      }
    } else if (current.nodeType === Node.COMMENT_NODE) {
      // Ignore comment nodes
    } else if (
      (current as Element).getAttribute?.('contenteditable') === 'false'
    ) {
      offset += 1;
    } else {
      walkChildren(current);
    }
    current = current.previousSibling;
  }
  return offset;
};

const getChildOffset = (
  element: Element,
  index: number,
): [container: Node, offset: number] | null => {
  // if (index === 0 && !(element.firstChild as HTMLElement)?.isContentEditable)
  //   return [element, 0];

  let previous: Text | undefined;
  let current: Element | Text = element;
  let offset = 0;

  while (current) {
    if ((current as Element).getAttribute?.('contenteditable') === 'false') {
      if (index === offset) {
        if (previous) return [previous, previous.length];

        const index = Array.from(element.childNodes).indexOf(current);
        return [element, index];
      }
      offset += 1;
      previous = undefined;
    } else {
      while (current.firstChild) {
        current = current.firstChild as Element | Text;
      }

      if (current.nodeType === Node.TEXT_NODE) {
        const containerEnd = offset + (current as Text).length;

        if (index <= containerEnd) {
          return [current, index - offset];
        }

        offset = containerEnd;
        previous = current as Text;
      } else {
        previous = undefined;
      }
    }

    if (current === element) return [element, element.childNodes.length];
    while (!current.nextSibling) {
      current = current.parentNode as Element;
      if (current === element) return [element, element.childNodes.length];
    }

    current = current.nextSibling as Element;
  }
  return [element, element.childNodes.length];
};

const getCommonAncestor = (node1: Node, node2: Node): Node | null => {
  if (node1 === node2) {
    return node1;
  }

  // Create a set to store the ancestors of one of the nodes
  const ancestors = new Set<Node>();
  let currentNode: Node | null = node1;
  while (currentNode) {
    ancestors.add(currentNode);
    currentNode = currentNode.parentNode;
    if (currentNode === node2) {
      return currentNode;
    }
  }

  // Iterate over the ancestors of the other node and return the first ancestor
  // that is present in the set
  currentNode = node2;
  while (currentNode) {
    if (ancestors.has(currentNode)) return currentNode;
    currentNode = currentNode.parentNode;
  }

  // If no common ancestor is found, return null
  return null;
};

// const getCommonElement = (node1: Node, node2: Node): Element | null => {
//   let commonAncestor = getCommonAncestor(node1, node2);
//   while (commonAncestor && commonAncestor.nodeType !== Node.ELEMENT_NODE) {
//     commonAncestor = commonAncestor.parentNode;
//   }
//   return commonAncestor as Element | null;
// };
