export const formatName = (
  input:
    | {
        firstName?: string | null;
        lastName?: string | null;
        middleName?: string | null;
        title?: string | null;
      }
    | null
    | undefined,
  options?: { lastNameFirst?: boolean },
) => {
  if (!input) return '';
  const { firstName, lastName, middleName, title } = input;
  const first = [title?.trim(), firstName?.trim(), middleName?.trim()]
    .filter((t) => !!t)
    .join(' ');
  return options?.lastNameFirst
    ? [lastName?.trim(), first].filter((t) => !!t).join(', ')
    : [first, lastName?.trim()].filter((t) => !!t).join(' ');
};
