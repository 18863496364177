import { SpinnerBackground } from './SpinnerBackground';

interface SpinnerProps {
  delay?: number | false;
  floating?: boolean;
  small?: boolean;
}

export function Spinner(props: SpinnerProps) {
  return (
    <SpinnerBackground
      enabled={!!props.floating}
      delay={props.delay === false ? 0 : props.delay ?? 0}
    >
      O
    </SpinnerBackground>
  );
}
