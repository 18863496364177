import {
  bind,
  getSyncState,
  jsxx,
  updateComponent,
} from '@donkeyjs/jsx-runtime';
import {
  isDataNode,
  type DataNode,
  type NodeRef,
  type NodeTypename,
  type Schema,
} from '@donkeyjs/proxy';
import { ChildBlocks, type BlockProps } from '../../blocks';
import type { ButtonsProps } from '../../components/Buttons';
import { isNodeRef, isNodeType, session } from '../../session';
import { getTheme } from '../../styles';
import {
  getNodeContextProviders,
  setNodeContext,
  type NodeContext,
} from '../nodeContext';
import { DataFormButtons } from './DataFormButtons';
import { Errors } from './Errors';
import { Form, type FormProps } from './Form';

// Note: in use as BlockProps
export interface DataFormProps<
  S extends Schema = DataSchema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
> extends FormProps,
    Partial<ButtonsProps> {
  action?: 'create' | 'update' | 'send-message';
  node?: Typename | 'user' | NodeRef<S, Typename> | DataNode<S, Typename>;
}

type DataFormState = Pick<
  NodeContext,
  'typename' | 'schema' | 'node' | 'steps' | 'step'
>;

export function DataForm<S extends Schema, Typename extends NodeTypename<S>>(
  props: BlockProps<DataFormProps<S, Typename>>,
) {
  const theme = getTheme();

  const state = useDataFormState(props);
  setNodeContext(state);

  return (
    <Form layout={bind(() => props.layout)} onmount={props.onmount}>
      <Errors node={state.node} />
      {() =>
        props.children ?? (
          <ChildBlocks
            parentProps={props}
            class={bind(() =>
              props.layout?.endsWith(':flow') ? theme.class.flow : undefined,
            )}
          />
        )
      }
      {jsxx(DataFormButtons, props)}
    </Form>
  );
}

export function useDataFormState<
  S extends Schema,
  Typename extends NodeTypename<S>,
>(props: BlockProps<DataFormProps<S, Typename>>) {
  const providers = getNodeContextProviders();

  const state: DataFormState = getSyncState(props.block || props, () => ({
    get typename() {
      return state.node?.__typename;
    },
    get schema() {
      return (state.node as any)?.__meta?.schema;
    },
    get node() {
      if (isDataNode<S, Typename>(props.node)) return props.node;

      if (props.action === 'create') {
        if (isNodeType(props.node)) {
          return session.data.createLocalNode({
            __typename: props.node as NodeTypename<DataSchema>,
          }) as any;
        }
        return null;
      }

      if (props.action === 'update') {
        if (typeof props.node === 'string' && providers.providers[props.node]) {
          return providers.providers[props.node].get();
        }

        if (isNodeRef(props.node)) {
          const [type, id] = props.node;
          return session.data.ensureNode({
            __typename: type,
            id,
          });
        }
      }

      return null;
    },

    get steps() {
      return props.context?.filter(
        (c) => c.parent?.id === props.block?.id && c.type === 'data-form-step',
      );
    },

    get step() {
      const id = session.router.query.step?.[0];
      return state.steps?.find((p) => p.id === id) ?? state.steps?.[0];
    },

    set step(value) {
      session.router.updateQuery({ step: value?.id ? [value.id] : undefined });
    },
  }));
  return state;
}

if (import.meta.hot) {
  import.meta.hot.accept((hot) => {
    updateComponent(DataForm as any, hot?.DataForm);
  });
}
