import { addDays, differenceInMinutes } from 'date-fns';
import type { Node } from '../cache/DataNode';
import type {
  NodeTypename,
  ResolverManyArgsFromSchema,
  Schema,
} from '../schema';
import { checkInMatch } from './isMatch';
import { InvalidOperationError, type ValidationError } from './validate';
import { meta } from './meta';

export const changeMatch = <S extends Schema, Typename extends NodeTypename<S>>(
  action: 'apply' | 'remove',
  args: ResolverManyArgsFromSchema<S, Typename>,
  node: Node,
  test?: boolean,
): [errors: ValidationError[], reset: (() => void) | void] => {
  const updates: Record<any, any> = {};
  const errors: ValidationError[] = [];

  if (args.where)
    for (const key in args.where) {
      const checks = args.where[key];
      if (checks) {
        const value = meta(node).getValue(key);
        if (action === 'apply') {
          if (checks.eq != null && checks.eq !== value)
            updates[key] = checks.eq;
          if (
            checks.gt != null &&
            (value == null || matchType(value, checks.gt) <= checks.gt)
          ) {
            const bigger = makeBigger(checks.gt, false);
            if (bigger != null) updates[key] = bigger;
            else {
              errors.push(
                new InvalidOperationError(
                  `Can not automatically apply condition to node: ${key} > ${checks.gt}`,
                ),
              );
            }
          }
          if (
            checks.gte != null &&
            (value == null || matchType(value, checks.gte) < checks.gte)
          ) {
            const bigger = makeBigger(checks.gte, true);
            if (bigger != null) updates[key] = bigger;
            else {
              errors.push(
                new InvalidOperationError(
                  `Can not automatically apply condition to node: ${key} >= ${checks.gte}`,
                ),
              );
            }
          }
          if (
            checks.lt != null &&
            (value == null || matchType(value, checks.lt) >= checks.lt)
          ) {
            const smaller = makeSmaller(checks.lt, false);
            if (smaller != null) updates[key] = smaller;
            else {
              errors.push(
                new InvalidOperationError(
                  `Can not automatically apply condition to node: ${key} < ${checks.lt}`,
                ),
              );
            }
          }
          if (
            checks.lte != null &&
            (value == null || matchType(value, checks.lte) > checks.lte)
          ) {
            const smaller = makeSmaller(checks.lte, true);
            if (smaller != null) updates[key] = smaller;
            else {
              errors.push(
                new InvalidOperationError(
                  `Can not automatically apply condition to node: ${key} <= ${checks.lte}`,
                ),
              );
            }
          }
          if (
            checks.in != null &&
            (value == null || !checkInMatch(value, checks.in))
          ) {
            const [matchedKey, matched] = makeIn(key, value, checks.in);
            if (matched != null && (matchedKey !== key || matched !== value))
              updates[matchedKey] = matched;
            else {
              errors.push(
                new InvalidOperationError(
                  `Can not automatically apply condition to node: ${key} in ${checks.in}`,
                ),
              );
            }
          }
          if (checks.notIn?.includes(value as any)) updates[key] = undefined;
        } else {
          if (checks.eq != null && checks.eq === value)
            updates[key] = undefined;
          if (
            checks.gt != null &&
            (value == null || (value as any) > checks.gt)
          )
            updates[key] = undefined;
          if (
            checks.gte != null &&
            (value == null || (value as any) >= checks.gte)
          )
            updates[key] = undefined;
          if (
            checks.lt != null &&
            (value == null || (value as any) < checks.lt)
          )
            updates[key] = undefined;
          if (
            checks.lte != null &&
            (value == null || (value as any) <= checks.lte)
          )
            updates[key] = undefined;
          if (
            checks.in != null &&
            (value == null || checks.in.includes(value as any))
          )
            updates[key] = undefined;
          if (checks.notIn != null && !checks.notIn.includes(value as any))
            errors.push(
              new InvalidOperationError(
                `Can not automatically remove condition from node: ${key} not in [${checks.notIn.join(
                  ', ',
                )}]`,
              ),
            );
        }
      }
    }

  if (!errors.length) {
    if (test) {
      return [errors, meta(node).testValues(updates as any)];
    }

    for (const key in updates) {
      meta(node).setValue(key, updates[key]);
    }
  }

  return [errors, undefined];
};

const matchType = (value: any, sample: any) => {
  if (typeof value === typeof sample) return value;
  if (typeof sample === 'number') return Number(value);
  if (
    typeof sample === 'string' &&
    Date.prototype.isPrototypeOf.call(Date, value)
  )
    return value.toISOString();
  if (typeof sample === 'string') return String(value);
  if (typeof sample === 'boolean') return Boolean(value);
  return value;
};

const makeBigger = (value: any, orEqual: boolean) => {
  if (typeof value === 'number') return value + (orEqual ? 0 : 1);
  if (
    typeof value === 'object' &&
    Date.prototype.isPrototypeOf.call(Date, value)
  ) {
    let date = new Date(value);
    date.setHours(20, 0, 0, 0);
    if (differenceInMinutes(date, value) < 10) date = addDays(date, 1);
    return date;
  }
  return null;
};

const makeSmaller = (value: any, orEqual: boolean) => {
  if (typeof value === 'number') return value - (orEqual ? 0 : 1);
  if (
    typeof value === 'object' &&
    Date.prototype.isPrototypeOf.call(Date, value)
  ) {
    let date = new Date(value);
    date.setHours(20, 0, 0, 0);
    if (differenceInMinutes(date, value) > 10) date = addDays(date, -1);
    return date;
  }
  return null;
};

const makeIn = (
  key: string,
  value: any,
  match: any[] | any[][],
): [string, any] => {
  if (!match.length) return [key, null];

  const keys = key.split('.');

  if (Array.isArray(match[0])) {
    let result = value;
    for (const item of match) {
      result = makeIn(key, result, item)[1];
    }
    return [Array.isArray(value) ? keys[0] : key, result];
  }

  if (checkInMatch(value, match)) return value;

  if (Array.isArray(value)) {
    return [
      keys.length === 2 ? keys[0] : key,
      [...value, keys.length === 2 ? { [keys[1]]: match[0] } : match[0]],
    ];
  }

  return [key, match[0]];
};
