import { bind } from '@donkeyjs/jsx-runtime';
import {
  Buttons,
  type ButtonsPreset,
  type ButtonsProps,
} from '../../components/Buttons';

export function DataFormButtons(
  props: Partial<ButtonsProps> & { onClick?: (isDefault: boolean) => void },
) {
  return () =>
    !!props.buttons && (
      <Buttons
        buttons={bind(() =>
          typeof props.buttons === 'string'
            ? ({
                preset: props.buttons,
                onClick: props.onClick,
              } as ButtonsPreset)
            : props.buttons,
        )}
        alignButtons={bind(() => props.alignButtons)}
      />
    );
}
