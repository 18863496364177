import { bind, mount } from '@donkeyjs/jsx-runtime';
import { map, store } from '@donkeyjs/proxy';
import type { UserContext } from '../../authentication';
import { applyDonkeyTheme } from '../../donkey';
import { session } from '../../session';
import { getTheme } from '../../styles';
import { Dialog } from './Dialog';

interface AlertAction {
  key: string;
  label: JSX.Children;
  primary?: boolean;
}

export const showAlert = (
  message: JSX.Children | string,
  options: {
    actions?: AlertAction[];
    user?: UserContext;
  } = {},
) => {
  const { actions = [{ key: 'ok', label: 'Ok', primary: true }], user } =
    options;

  return new Promise<string | undefined>((resolve) => {
    const [dispose] = mount(
      session.dom,
      AlertComponent,
      {
        message,
        actions,
        user,
        onClose(key?: string) {
          dispose();
          resolve(key);
        },
      },
      [],
      document.body,
    );
  });
};

export const confirm = async (
  message: JSX.Children | string,
  options: {
    confirmButtonText: JSX.Children;
    cancelButtonText: JSX.Children;
    user?: UserContext;
  },
) =>
  (await showAlert(message, {
    user: options.user,
    actions: [
      {
        key: 'confirm',
        label: options.confirmButtonText,
        primary: true,
      },
      {
        key: 'cancel',
        label: options.cancelButtonText,
      },
    ],
  })) === 'confirm';

export const confirmInput = async (
  value: string,
  options: {
    confirmButtonText: JSX.Children;
    cancelButtonText: JSX.Children;
    user?: UserContext;
    placeholder?: string;
    prompt?: JSX.Children | string;
  },
) => {
  return new Promise<string | undefined>((resolve) => {
    const [dispose] = mount(
      session.dom,
      ConfirmComponent,
      {
        prompt: options.prompt,
        confirmButtonText: options.confirmButtonText,
        cancelButtonText: options.cancelButtonText,
        user: options.user,
        placeholder: options.placeholder,
        value,
        onClose(value?: string) {
          dispose();
          resolve(value);
        },
      },
      [],
      document.body,
    );
  });
};

function AlertComponent(props: {
  message: JSX.Children | string;
  actions: AlertAction[];
  user?: UserContext;
  onClose: (action?: string) => void;
}) {
  const theme = applyDonkeyTheme(props.user);

  return (
    <Dialog
      class={bind(() => theme().themeClass)}
      size="sm"
      buttons={map(
        () => props.actions,
        (action) => (
          <button
            type="button"
            class={bind(() => [
              theme().class.button,
              { default: action.primary },
            ])}
            autofocus={action.primary}
            onclick={() => {
              props.onClose(action.key);
            }}
          >
            {() => action.label}
          </button>
        ),
      )}
      onClose={() => {
        props.onClose();
      }}
    >
      {props.message}
    </Dialog>
  );
}

function ConfirmComponent(props: {
  prompt: JSX.Children | undefined;
  confirmButtonText: JSX.Children;
  cancelButtonText: JSX.Children;
  user?: UserContext;
  value?: string;
  placeholder?: string;
  onClose: (value: string | undefined) => void;
}) {
  const themeClass = () =>
    props.user && applyDonkeyTheme(props.user)().themeClass;
  const theme = getTheme();

  const state = store({
    value: props.value || '',
  });

  return (
    <Dialog
      class={bind(() => themeClass())}
      size="sm"
      buttons={
        <>
          <button
            type="button"
            class={[theme.class.button, 'default']}
            onclick={() => {
              props.onClose(state.value);
            }}
          >
            {() => props.confirmButtonText}
          </button>
          <button
            type="button"
            class={theme.class.button}
            onclick={() => {
              props.onClose(undefined);
            }}
          >
            {() => props.cancelButtonText}
          </button>
        </>
      }
      onClose={(confirm) => {
        props.onClose(confirm ? state.value : undefined);
      }}
    >
      <p class={theme.class.paragraph}>{props.prompt}</p>
      <input
        type="text"
        class={bind(() => theme.class.input)}
        value={bind(
          () => state.value,
          (value) => {
            state.value = value as string;
          },
        )}
        placeholder={bind(() => props.placeholder)}
        onmount={(el: HTMLInputElement) => {
          setTimeout(() => el.focus(), 0);
        }}
      />
    </Dialog>
  );
}
