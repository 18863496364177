import { bind } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import styles from './SlideOut.module.css';

interface SlideOutProps {
  readonly open: boolean;
  readonly class?: JSX.ClassNames;
  readonly childrenClass?: JSX.ClassNames;
  readonly children?: JSX.Children;
}

export function SlideOut(props: SlideOutProps) {
  const state = store({
    height: 0,
  });

  return (
    <div
      class={bind(() => [styles.slideout, props.class, { open: props.open }])}
      style={bind(() => ({
        'max-height': `${props.open ? state.height : 0}px`,
      }))}
    >
      <div
        class={bind(() => ['children', props.childrenClass])}
        onsize={(size) => {
          state.height =
            size.borderBoxSize?.[0]?.blockSize ?? size.contentRect.height;
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
