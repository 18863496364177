import { createView } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { Quantity, useCart } from '@donkeyjs/module-shopping';
import styles from './ViewOrderItem.module.css';

export const ViewOrderItem = createView<DataSchema, 'OrderItem'>(
  { name: () => 'Default order item view' },

  function ViewOrderItem(props) {
    const cart = useCart();

    return !props.node.course ? null : (
      <div
        class={bind(() => [styles.item, props.class])}
        onmount={props.onmount}
      >
        <div class={styles.side}>
          <props.node.course.offering.$.images ratio={1} />
        </div>
        <div class={styles.main}>
          <div class={styles.info}>
            <props.node.course.$.name />
          </div>
          <Quantity
            number={bind(() => props.node.quantity)}
            decrement={() => {
              if (!props.node.order) return;
              cart.remove('course', props.node.course!);
            }}
            increment={() => {
              if (!props.node.order) return;
              cart.add('course', props.node.course!);
            }}
          />
        </div>
      </div>
    );
  },
);
