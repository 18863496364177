import { bind, documentSelection, jsxx, live } from '@donkeyjs/jsx-runtime';
import {
  isEmptyMarkupTree,
  store,
  type MarkupString,
  type MarkupStringProcessor,
} from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { LoadingSingleLine } from '../../../loaders';
import {
  InlineSelect,
  type InlineSelectProps,
} from '../../components/InlineSelect';
import { usePopup } from '../../components/usePopup';
import inlineStyles from '../../inlineLayout.module.css';
import { Markup, type MarkupProps } from '../../markup';
import { attachMarkup } from '../../markup/attachMarkup';
import styles from './InlineString.module.css';
import { OptionButtons } from './OptionButtons';
import { useStringEditor, type StringEditor } from './useStringEditor';

export function InlineString(props: FieldRenderProps<'string'>) {
  return () => {
    if (props.field.loading) return <LoadingSingleLine size="medium" />;

    if (props.readonly)
      return props.class && props.field.value ? (
        <span class={props.class}>{props.field.value}</span>
      ) : (
        props.field.value
      );

    return jsxx(InlineStringEditor, props);
  };
}

function InlineStringEditor(props: FieldRenderProps<'string'>) {
  const editor = useStringEditor(props);

  return () =>
    editor.options?.length && props.optionButtons ? (
      <OptionButtons
        value={bind(editor, 'value')}
        options={bind(() => editor.options!)}
        optionButtons={props.optionButtons}
      />
    ) : editor.options?.length ? (
      <OptionsEditor p={props} editor={editor} />
    ) : (
      <MarkupEditor p={props} editor={editor} />
    );
}

interface EditorOptions {
  readonly p: FieldRenderProps<'string'>;
  readonly editor: StringEditor;
}

function OptionsEditor(props: EditorOptions) {
  const selectProps = store<InlineSelectProps<string>>({
    search: '',
    get value() {
      return props.editor.value;
    },
    set value(value) {
      props.editor.value = value;
    },
    onClose() {
      popup.hide();
      state.el?.focus();
    },
    options() {
      return {
        get value(): string[] {
          const values = props.editor.options!.map((o) => o.value);
          return selectProps.search
            ? values.filter((v) =>
                v.toLowerCase().includes(selectProps.search.toLowerCase()),
              )
            : values;
        },
      };
    },
  });

  const state = store({
    el: undefined as undefined | HTMLElement,
  });

  const popup = usePopup({
    children: jsxx(InlineSelect, selectProps),
    onClose() {
      popup.hide();
      state.el?.focus();
    },
  });

  return (
    <span
      class={bind(() => [
        inlineStyles.editableLink,
        props.p.class,
        {
          [inlineStyles.active]:
            props.p.parentOutline == null || props.p.parentOutline?.active,
        },
      ])}
      onmount={[
        (el: HTMLElement) => {
          state.el = el;
        },
      ]}
      role="button"
      onclick={(ev: MouseEvent) => popup.toggle(ev.target as HTMLElement)}
      onkeydown={(ev: KeyboardEvent) => {
        if (ev.key === 'Enter') popup.toggle(ev.target as HTMLElement);
      }}
      onfocus={(ev) => props.p.onfocus?.(ev.target as HTMLElement)}
      onblur={(ev) => {
        props.p.onblur?.(ev.target as HTMLElement);
        popup.hide();
      }}
    >
      {() => props.p.field.value}
    </span>
  );
}

function MarkupEditor(props: EditorOptions) {
  const state = store({
    processor: undefined as undefined | MarkupStringProcessor,
  });

  const markupProps = store<MarkupProps<MarkupString | string>>({
    get value() {
      return props.p.field.value || '';
    },
    set value(value) {
      props.p.field.value = value;
    },
    get processor() {
      return state.processor;
    },
    set processor(processor) {
      state.processor = processor;
    },
    readonly: false,
    get autofocus() {
      return props.p.autofocus;
    },
    onkeydown: props.editor.handleInput,
    onblur: (ev) => props.p.onblur?.(ev),
    onfocus: (ev) => props.p.onfocus?.(ev),
  });

  live(() => {
    if (isEmptyMarkupTree(state.processor?.tree) && markupProps.hasFocus) {
      setTimeout(() => documentSelection.forceUpdate(), 0);
    }
  });

  return (
    <span
      role="textbox"
      class={bind(() => [
        styles.markupEditor,
        props.p.class,
        { [styles.active]: props.p.parentOutline?.active },
      ])}
      onmount={attachMarkup(markupProps)}
    >
      {jsxx(Markup, markupProps)}
      {() =>
        isEmptyMarkupTree(state.processor?.tree) &&
        !markupProps.hasFocus && (
          <span class={styles.placeholder}>{props.p.label}</span>
        )
      }
    </span>
  );
}
