import { bind } from '@donkeyjs/jsx-runtime';
import { meta, type DataList } from '@donkeyjs/proxy';
import type { ListProps } from '../../components/List';
import { getNodeIcon } from '../../helpers';
import type { ViewProps } from '../../views';
import { Label } from '../components/Label';
import { LayoutListItem } from '../components/LayoutListItem';
import { NodeSelect } from '../components/NodeSelect';

export function ViewFileRefPropertiesList(
  props: ViewProps<DataSchema, 'FileRef'>,
) {
  return (
    <LayoutListItem
      title={bind(() => props.node.file.name)}
      icon={bind(() => getNodeIcon(props.node.file)({ weight: 'fill' }))}
      onDelete={() => meta(props.node).delete()}
    >
      <props.node.$.name />
    </LayoutListItem>
  );
}

export function AddFileRefPropertiesList(
  props: ListProps<DataSchema, 'FileRef'>,
) {
  return (
    <div class="list-item">
      <Label class="input" label="Add file">
        <NodeSelect<'File', 'File'>
          typename="File"
          value={null}
          onchange={(value) => {
            if (value && !Array.isArray(value)) {
              (props.data as DataList<DataSchema, 'FileRef'>).push({
                file: value,
                name: value.name,
              });
              return false;
            }
          }}
        />
      </Label>
    </div>
  );
}
