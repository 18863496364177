import { bind } from '@donkeyjs/jsx-runtime';
import { store, type DataNode } from '@donkeyjs/proxy';
import type { BlockProps } from '.';
import { Image } from '../components/Image';
import { getTheme } from '../styles';
import styles from './FileBlock.module.css';

export function FileBlock(props: BlockProps) {
  const theme = getTheme();

  const state = store({
    get fileRef() {
      return props.block?.files[0] as
        | DataNode<DataSchema, 'FileRef'>
        | undefined;
    },

    get file() {
      return props.block?.files[0]?.file;
    },
  });

  return () =>
    !state.file ? null : (
      <>
        <div
          class={[
            props.class,
            theme.class.block,
            theme.class.paragraph,
            { [styles.editing]: !props.readonly },
          ]}
          style={bind(
            () =>
              state.fileRef && {
                width: state.fileRef.maxWidth
                  ? `${state.fileRef.maxWidth}px`
                  : undefined,
                'max-width': state.fileRef.maxWidth ? '100%' : undefined,
                'margin-left':
                  state.fileRef.textWrap === 'WRAP'
                    ? state.fileRef.align === 'RIGHT'
                      ? 'var(--spacing)'
                      : undefined
                    : state.fileRef.align === 'RIGHT' ||
                        state.fileRef.align === 'CENTER'
                      ? 'auto'
                      : undefined,
                'margin-right':
                  state.fileRef.textWrap === 'WRAP'
                    ? !state.fileRef.align || state.fileRef.align === 'LEFT'
                      ? 'var(--spacing)'
                      : undefined
                    : state.fileRef.align === 'CENTER'
                      ? 'auto'
                      : undefined,
                float:
                  state.fileRef.textWrap !== 'WRAP'
                    ? undefined
                    : state.fileRef.align === 'RIGHT'
                      ? 'right'
                      : 'left',
              },
          )}
          onmount={props.onmount}
          contenteditable="false"
        >
          <Image
            file={bind(() => state.file)}
            maxWidth={bind(() => state.fileRef?.maxWidth || undefined)}
          />
        </div>
        {() =>
          state.fileRef?.textWrap === 'WRAP' && (
            <div
              class={bind(() => [
                styles.break,
                { [styles.left]: state.fileRef?.align === 'RIGHT' },
              ])}
            />
          )
        }
      </>
    );
}
