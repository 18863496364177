import { addMinutes, differenceInMinutes } from 'date-fns';
import type { OrderPaymentPlanFields } from './order/getOrderOverview';

export interface Installment {
  date: Date;
  amount: number;
  isToday: boolean;
}

export interface AvailableInstallments {
  scheme: OrderPaymentPlanFields | null;
  installments: Installment[];
}

export function getAvailableInstallments(
  schemes: OrderPaymentPlanFields[] | null | undefined,
  reducedPrice: number,
  quantity = 1,
  minimumDistanceInDays = 7,
  earliestPaymentAt: Date | null = null,
): AvailableInstallments[] {
  return (
    (schemes?.length
      ? schemes
      : earliestPaymentAt
        ? [null]
        : undefined
    )?.reduce<
      {
        scheme: OrderPaymentPlanFields | null;
        installments: Installment[];
      }[]
    >((result, scheme) => {
      const installments = getInstallmentsForScheme(
        scheme,
        reducedPrice,
        quantity,
        earliestPaymentAt,
        minimumDistanceInDays,
      );

      if (installments?.length) {
        result.push({
          scheme,
          installments,
        });
      }

      return result;
    }, []) || []
  );
}

export function getInstallmentsForScheme(
  scheme: OrderPaymentPlanFields | null,
  reducedPrice: number,
  quantity = 1,
  earliestPaymentAt: Date | null = null,
  minimumDistanceInDays = 7,
) {
  // Client-side: make sure to fetch these fields
  const { startDate, endDate, numberOfInstallments } = scheme ?? {};

  // Use earliestPaymentAt if it's provided and later than both 'now' and 'starts'
  const now = new Date();

  const { postponeTo, ends } =
    earliestPaymentAt && earliestPaymentAt > now
      ? { postponeTo: earliestPaymentAt, ends: earliestPaymentAt }
      : { postponeTo: undefined, ends: endDate };

  if (!postponeTo && scheme?.scheme !== 'INSTALLMENTS') return null;

  const starts = postponeTo ?? startDate ?? now;

  return ends
    ? getRangeInstallments(
        starts,
        ends,
        numberOfInstallments ?? 1,
        reducedPrice * quantity,
        minimumDistanceInDays,
      )
    : [];
}

export function getRangeInstallments(
  starts: Date,
  ends: Date,
  count: number,
  amount: number,
  minimumDistanceInDays = 7,
): Installment[] {
  const result: Installment[] = [];

  // Case 1: Single installment
  if (count <= 1) {
    return [
      {
        date: starts,
        amount,
        isToday: isToday(starts),
      },
    ];
  }

  // Case 2: Multiple installments
  const totalMinutes = differenceInMinutes(ends, starts);
  const minutesPerInstallment = Math.floor(totalMinutes / (count - 1));

  // If the total duration is less than the minimum distance, return a single installment
  if (totalMinutes < minimumDistanceInDays * 24 * 60) {
    return [
      {
        date: starts,
        amount,
        isToday: isToday(starts),
      },
    ];
  }

  let currentDate = starts;

  // Calculate installment amounts
  const baseInstallmentAmount = Number.parseFloat((amount / count).toFixed(2));
  const totalDistributedBase = baseInstallmentAmount * count;
  const discrepancy = amount - totalDistributedBase;

  // Generate installments
  for (let i = 0; i < count - 1; i++) {
    result.push({
      date: currentDate,
      amount: baseInstallmentAmount,
      isToday: isToday(currentDate),
    });
    currentDate = addMinutes(currentDate, minutesPerInstallment);
  }

  // Add the final installment with the discrepancy
  result.push({
    date: ends,
    amount: Math.round((baseInstallmentAmount + discrepancy) * 100) / 100,
    isToday: isToday(ends),
  });

  return result;
}

function isToday(date: Date) {
  return differenceInMinutes(date, new Date()) < 60 * 24;
}
