import { getTheme, session } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { PhShoppingCart } from '@donkeyjs/phosphor-icons';
import { useCart } from '../useCart';
import styles from './CartLink.module.css';

interface CartLinkProps {
  class?: JSX.ClassNames;
}

export function CartLink(props: CartLinkProps) {
  const theme = getTheme();
  const cart = useCart();

  return () => {
    if (cart.count === 0) return null;
    return (
      <a
        href={bind(() => session.router.getPath({ routeKey: 'cart' }))}
        class={bind(() => [theme.class.link, styles.cartLink, props.class])}
      >
        <PhShoppingCart weight="duotone" />
        <span>{cart.count}</span>
      </a>
    );
  };
}
