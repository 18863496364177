import styles from './Dialog.module.css';

export function DialogSidebars(props: {
  left?: JSX.Children;
  right?: JSX.Children;
  children?: JSX.Children;
}) {
  return () =>
    props.left || props.right ? (
      <div class={styles.sidebars}>
        {props.left}
        {props.children}
        {props.right}
      </div>
    ) : (
      props.children
    );
}
