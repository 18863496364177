import {
  htmlToMarkupInBrowser,
  markupToHtml,
  type HtmlToMarkupBlock,
  type MarkupString,
} from '@donkeyjs/proxy';
import type { BlockEditorInfo } from '../../fields/blocks/BlocksEditor';

export const clipboard = {
  async copy(markup: MarkupString) {
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([markupToHtml(markup)], { type: 'text/html' }),
        'text/plain': new Blob([markup.toString()], { type: 'text/plain' }),
      }),
    ]);
  },

  async paste({
    onText,
    onMarkup,
  }: {
    onText: (
      text: string,
    ) => [textEndsAtBlock: BlockEditorInfo, textEndsAtIndex: number] | null;
    onMarkup?: (
      markup: HtmlToMarkupBlock[],
    ) => [textEndsAtBlock: BlockEditorInfo, textEndsAtIndex: number] | null;
  }): Promise<
    [textEndsAtBlock: BlockEditorInfo, textEndsAtIndex: number] | null
  > {
    const clipboardItems = await navigator.clipboard.read();

    for (const item of clipboardItems) {
      if (onMarkup && item.types.includes('text/html')) {
        const blob = await item.getType('text/html');
        const customDataString = await blob.text();
        return onMarkup(htmlToMarkupInBrowser(customDataString));
      }

      // if (item.types.includes('image/png') || item.types.includes('image/jpeg') || item.types.includes('image/gif')) {
      //   const blob = await item.getType('image/png') || await item.getType('image/jpeg') || await item.getType('image/gif');
      //   return this.insertImage(processor, from, to, blob);
      // }
    }

    const text = (await navigator.clipboard.readText()).replace(/\r/g, '');
    const paragraphs = text.split('\n');
    return onText(paragraphs.join('\n'));
  },
};
