import { bind } from '@donkeyjs/jsx-runtime';
import { getTheme } from '../applyTheme';
import { getHeadingLevel } from './HeadingContext';

export type HeadingStyle = 'title' | 'subtitle' | 'heading1' | 'heading2';

interface HeadingProps {
  readonly addIncrement?: number;
  readonly styleAs?: HeadingStyle;
  readonly class?: JSX.ClassNames;
  readonly onmount?: JSX.OnMount<HTMLElement>;
  readonly children?: JSX.Children;
}

export function Heading(props: HeadingProps) {
  const level = getHeadingLevel() + (props.addIncrement ?? 0);
  const theme = getTheme();

  return (
    <h1
      $element={bind(
        () => `h${Math.min(level, 6) as 1 | 2 | 3 | 4 | 5 | 6}` as const,
      )}
      onmount={props.onmount}
      class={bind(() => [
        props.styleAs && theme.class[props.styleAs],
        'heading',
        props.styleAs && `heading-${props.styleAs}`,
        props.class,
      ])}
    >
      {() =>
        Array.isArray(props.children) ? props.children : [props.children]
      }
    </h1>
  );
}
