import { bind } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import type { BlockProps } from '.';
import styles from './LayoutBlock.module.css';
import { ChildBlocks } from './helpers/ChildBlocks';

export interface CellProps {
  css?: Partial<JSX.CSSProperties>;
  width?: string;
}

export function CellBlock(props: BlockProps<CellProps>) {
  const state = store({
    get width() {
      return props.width ? `width-${props.width}` : undefined;
    },
    // get parentKind() {
    //   return props.block?.parent?.type === 'layout'
    //     ? (props.block.parent.settings as LayoutSettings)?.kind ?? 'flex'
    //     : undefined;
    // },
  });

  return (
    <ChildBlocks
      class={bind(() => [props.class, styles.flexCell, state.width])}
      style={bind(() => props.css)}
      onmount={props.onmount}
      parentProps={props}
    />
  );
}
