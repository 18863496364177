import { bind } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { getUserContext } from '../../../authentication';
import { List } from '../../../components/List';
import { useSortableList } from '../../../dragdrop/useSortable';
import { getViewContext } from '../../../views';
import { View } from '../../../views/View';
import { getView } from '../node/getView';

export function InlineNodeList(props: FieldRenderProps<'nodeList'>) {
  const user = getUserContext();
  const viewContext = getViewContext();

  const state = store({
    get view() {
      return props.field.schema
        ? !props.view || typeof props.view === 'string'
          ? getView(props.field.schema.type, props.view, viewContext)
          : props.view
        : undefined;
    },

    get canInsert() {
      return (
        !!props.field.schema && user?.can('insert', props.field.schema.type)
      );
    },

    get canUpdate() {
      return (
        !!props.field.schema && user?.can('update', props.field.schema.type)
      );
    },
  });

  const sortable = props.field.value && useSortableList(props.field.value);

  return () => {
    if (state.view)
      return props.field.value?.length || state.canInsert ? (
        <div class={bind(() => props.class)} onmount={props.onmount}>
          <List
            data={props.field.value}
            render={(node) => {
              const sort = sortable?.(node as any);
              return (
                <View
                  node={node}
                  context={props.field.value}
                  view={state.view!}
                  readonly={bind(() => !state.canUpdate)}
                  draggable={sort?.draggable}
                  accept={sort?.accept as any}
                />
              );
            }}
            // adding={bind(() => (props.readonly ? false : 'top'))}
            // addAsDraft={false}
            renderAdd={bind(() => state.view?.renderAdd)}
            renderContainer={state.view?.renderContainer}
            renderDataContainer={state.view?.renderDataContainer}
            dataContainerClass={state.view?.dataContainerClass}
            class={state.view?.containerClass}
          />
        </div>
      ) : null;

    return (
      <div class="view" onmount={props.onmount}>
        {() =>
          `No view available for ${props.field.schema?.type} with key ${
            (props.view ?? viewContext?.preferredListView)
              ? `'${props.view ?? viewContext?.preferredListView}' or 'default'`
              : "'default'"
          }`
        }
      </div>
    );
  };
}
