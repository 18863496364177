import {
  LayoutListItem,
  createView,
  draggable,
  dropZone,
} from '@donkeyjs/client';
import { meta } from '@donkeyjs/proxy';

export const ViewCourseOptionPropertiesList = createView<
  DataSchema,
  'CourseOption'
>(
  { name: () => 'Properties list' },

  function ViewCourseOptionPropertiesList(props) {
    const sort = props.sortable?.(props.node as any, {
      handle: '.handle',
    });

    return (
      <LayoutListItem
        title="Course Option"
        onDelete={() => meta(props.node).delete()}
        class={props.class}
        withDragHandle={!!sort?.draggable}
        onmount={[
          sort?.draggable ? draggable(sort.draggable) : undefined,
          sort?.accept ? dropZone(sort.accept) : undefined,
        ]}
      >
        <props.node.$.name />
        <props.node.$.currency />
        <props.node.$.price />
        <props.node.$.earliestPaymentAt />
        <props.node.$.coupons />
        <props.node.$.discounts />
        <props.node.$.paymentSchemes />
      </LayoutListItem>
    );
  },
);
