import { jsxx, type Component } from '@donkeyjs/jsx-runtime';
import { batch, store } from '@donkeyjs/proxy';
import { Errors } from '../layout/components/Errors';
import { session } from '../session';
import { Loading, type LoaderType } from './loader';

const cache = new Map<() => Promise<Function>, Component>();

export interface LazyResource<T> {
  loading: boolean;
  error: unknown | null;
  data: T | null;
  trigger(): Promise<void>;
}

export function lazyResource<T extends Function>(
  key: string,
  fn: () => Promise<T>,
): LazyResource<T> {
  let promise: Promise<T> | null = null;
  const state = store<LazyResource<T>>({
    loading: true,
    error: null,
    data: null,
    trigger() {
      promise = fn();
      this.trigger = async () => {
        await promise;
      };

      if (session.resources.has(key))
        throw new Error(`Lazy resource key "${key}" already exists`);
      session.resources.set(key, state);

      return promise.then(
        (data) => {
          batch(() => {
            this.data = data;
            this.loading = false;
          });
        },
        (error) => {
          batch(() => {
            this.error = error;
            this.loading = false;
          });
        },
      );
    },
  });

  return state;
}

export function lazyComponent<T extends Function>(
  key: string,
  lazyComponent: () => Promise<T>,
  loader: LoaderType = 'multi-line',
): T {
  const state = lazyResource(key, lazyComponent);

  return ((props: any) => {
    const cached = cache.get(lazyComponent);
    if (cached) return jsxx(cached, props);

    state.trigger();

    return () => {
      if (state.loading) return <Loading type={loader} />;
      if (state.error)
        return (
          <Errors
            additionalErrors={[
              state.error instanceof Error
                ? state.error
                : new Error('Unexpected error'),
            ]}
          />
        );

      cache.set(lazyComponent, state.data as any);
      return jsxx(state.data! as any, props);
    };
  }) as any;
}
