import type { Theme } from '../../../../styles/Theme';

export type TextBlockType =
  | 'P'
  | 'T1'
  | 'T2'
  | 'H1'
  | 'H2'
  | 'UL'
  | 'OL'
  | 'QUOTE';

export const textBlockTypes: TextBlockType[] = [
  'P',
  'T1',
  'T2',
  'H1',
  'H2',
  'UL',
  'OL',
  'QUOTE',
];

export const getTextBlockElement = (
  theme: Theme,
  blockType: string | null | undefined,
  isMailRender?: boolean,
): {
  element: keyof JSX.IntrinsicElements;
  class: string;
} => {
  switch (blockType as TextBlockType) {
    case 'T1':
      return { element: 'h1', class: theme.class.title };
    case 'T2':
      return { element: 'h2', class: theme.class.subtitle };
    case 'H1':
      return { element: 'h3', class: theme.class.heading1 };
    case 'H2':
      return { element: 'h4', class: theme.class.heading2 };
    case 'UL':
      return { element: 'div', class: theme.class.ul };
    case 'OL':
      return { element: 'div', class: theme.class.ol };
    case 'QUOTE':
      return {
        element: isMailRender ? 'p' : 'blockquote',
        class: theme.class.blockQuote,
      };
    default:
      return { element: 'p', class: theme.class.paragraph };
  }
};
