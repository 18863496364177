import type { PasswordStrength } from '@donkeyjs/core';
import { bind } from '@donkeyjs/jsx-runtime';
import { I18nSystem } from '../i18n/I18nSystem';
import { text } from '../i18n/i18n';
import styles from './PasswordStrengthBar.module.css';

interface PasswordStrengthBarProps {
  strength: PasswordStrength;
}

export function PasswordStrengthBar(props: PasswordStrengthBarProps) {
  return (
    <>
      <div
        class={bind(() => [
          styles.bar,
          props.strength.isStrongEnough
            ? undefined
            : props.strength.score >= 0.5
              ? styles.medium
              : styles.weak,
        ])}
      >
        <span
          style={bind(() => ({
            width: `${props.strength.score * 100}%`,
          }))}
        />
      </div>
      {() =>
        props.strength.score < 1 && (
          <div class={styles.helper}>
            {text(I18nSystem, 'Account.PasswordStrengthBar.Suggestions')}
            <ul>
              {() =>
                !props.strength.hasUpperCase && (
                  <li>
                    {text(
                      I18nSystem,
                      'Account.PasswordStrengthBar.UseUpperCase',
                    )}
                  </li>
                )
              }
              {() =>
                !props.strength.hasLowerCase && (
                  <li>
                    {text(
                      I18nSystem,
                      'Account.PasswordStrengthBar.UseLowerCase',
                    )}
                  </li>
                )
              }
              {() =>
                !props.strength.hasSymbol && (
                  <li>
                    {text(I18nSystem, 'Account.PasswordStrengthBar.UseSymbols')}
                  </li>
                )
              }
              {() =>
                !props.strength.hasNumber && (
                  <li>
                    {text(I18nSystem, 'Account.PasswordStrengthBar.UseNumbers')}
                  </li>
                )
              }
              {() =>
                !props.strength.isMediumLong ? (
                  <li>
                    {text(
                      I18nSystem,
                      'Account.PasswordStrengthBar.UseAtLeast6Characters',
                    )}
                  </li>
                ) : (
                  !props.strength.isLong && (
                    <li>
                      {text(
                        I18nSystem,
                        'Account.PasswordStrengthBar.UseAtLeast12Characters',
                      )}
                    </li>
                  )
                )
              }
            </ul>
          </div>
        )
      }
    </>
  );
}
