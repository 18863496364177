import { PhX } from '@donkeyjs/phosphor-icons';
import { bind, map } from '@donkeyjs/proxy';
import { getTheme } from '../../styles';
import styles from './SelectChips.module.css';
import type { SelectState } from './useSelect';

export function SelectChips<T, Mapped = T>(props: {
  select: SelectState<T, Mapped>;
  readonly?: boolean;
}) {
  const theme = getTheme();

  return () =>
    props.select.isArray &&
    map(
      () => props.select.values,
      ({ value, label }) => (
        <span
          class={bind(() => [
            theme.class.button,
            styles.chip,
            {
              [styles.readonly]: props.readonly,
            },
          ])}
        >
          <span class={styles.label}>{label}</span>
          {() =>
            !props.readonly && (
              <span
                role="button"
                class={styles.remove}
                onclick={(ev) => {
                  if (!value || ev.button !== 0) return;
                  props.select.remove(value);
                }}
              >
                <PhX weight="fill" />
              </span>
            )
          }
        </span>
      ),
    );
}
