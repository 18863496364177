import { getContext, setContext } from '@donkeyjs/jsx-runtime';
import type { ChangelogScopedMessage } from './donkey';

export interface ChangelogContext {
  readonly changelog: ChangelogScopedMessage[];
  readonly unread: ChangelogScopedMessage[];
  readonly groupedByElement: Map<string, ChangelogScopedMessage[]>;
  scheduled: ChangelogScopedMessage[];
  schedule(node: HTMLElement, messages: ChangelogScopedMessage[]): void;
  markAsRead(item: ChangelogScopedMessage): void;
}

const key = Symbol('changelog');

export function useChangelog() {
  return getContext(key) as ChangelogContext | undefined;
}

export function setChangelogContext(value: ChangelogContext) {
  setContext(key, value);
}
