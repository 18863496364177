import { type MarkupFieldType, bind, meta } from '@donkeyjs/proxy';
import { useDataContext } from '../../data/DataContext';
import { getI18n } from '../../i18n/getI18n';
import styles from './MarkupField.module.css';

interface MarkupFieldProps {
  entity: MarkupFieldType;
  readonly?: boolean;
  selected?: boolean;
}

export function MarkupField(props: MarkupFieldProps) {
  const context = useDataContext();
  const i18n = getI18n(true);

  return () => {
    const field = props.entity.fld.split(':')[1] ?? props.entity.fld;
    const [typename, fieldName] = field?.split('.') ?? [];
    if (!typename || !fieldName) return null;

    const node = context?.findTypename(typename);
    const Field =
      props.readonly || !meta(node?.node)?.isLoading
        ? (node?.node?.$ as any)?.[fieldName]
        : undefined;

    if (Field)
      return props.readonly ? (
        <Field readonly />
      ) : (
        <span contenteditable="false" class={styles.preview}>
          <Field readonly />
        </span>
      );

    if (props.readonly) return null;

    return (
      <span class={bind(() => [styles.template])} contenteditable="false">
        {i18n.getFieldName(typename as any, fieldName)}
      </span>
    );
  };
}
