export class DataError extends Error {
  public code: 'fetch' | string;
  public original: unknown;

  constructor(code: 'fetch' | string, original: unknown) {
    super();
    this.code = code;
    this.original = original;
    this.message = 'Data error';
  }
}
