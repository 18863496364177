import { setHeadingLevel } from './HeadingContext';

interface IncreaseHeadingLevelProps {
  readonly increment?: number;
  readonly children?: JSX.Children;
}

export function IncreaseHeadingLevel(props: IncreaseHeadingLevelProps) {
  setHeadingLevel((current) => current + (props.increment ?? 1));
  return props.children;
}
