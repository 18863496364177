import { Images, session } from '@donkeyjs/client';
import { formatName } from '@donkeyjs/core';
import { bind } from '@donkeyjs/jsx-runtime';
import type { DataNode } from '@donkeyjs/proxy';
import styles from './CourseTeacher.module.css';

interface CourseTeacherProps {
  teacher: DataNode<DataSchema, 'Teacher'>;
}

export function CourseTeacher(props: CourseTeacherProps) {
  return (
    <a
      class={styles.teacher}
      href={session.router.getPath({ node: props.teacher })}
    >
      <Images files={bind(() => props.teacher.images)} ratio={1} />
      {formatName(props.teacher)}
    </a>
  );
}
