import {
  type SchemaStore,
  type SchemaStoreInput,
  schemaStore,
} from '../../data/schemaStore';
import type { UrlQuery } from '../types';

export function getSchemaQuery<Input extends SchemaStoreInput>(
  schema: ApplicationSchema,
  query: UrlQuery,
  input: Input,
): SchemaStore<Input> {
  const value = {} as any;
  for (const key in input) {
    Object.defineProperty(value, key, {
      get: () => query[key],
      set: (v: unknown) =>
        (query[key] = (Array.isArray(v) ? v : [v]).map((s) =>
          typeof s !== 'string' ? JSON.stringify(s) : s,
        )),
    });
  }

  return schemaStore<Input>(input, value, schema, { autoFix: true });
}
