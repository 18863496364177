import type { CreateAppInput } from '@donkeyjs/core';
import { enGB } from '@donkeyjs/i18n-en-gb';
import { nlNL } from '@donkeyjs/i18n-nl-nl';
import { permissions } from './permissions';
import { schema } from './schema';
import { schemaMeta } from './schemaMeta';

export const settings: CreateAppInput = {
  hostname: 'www.internationallectures.com',
  key: 'intlect',
  locales: { 'en-GB': enGB, nl: nlNL },
  permissions,
  schema,
  schemaMeta,
  userCreation: {
    role: 'student',
    fields: ['firstName', 'lastName', 'email', 'password'],
  },
  routerFollowsCultures: true,
};
