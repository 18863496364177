import type { DatabaseRouter, RouterPlugin, UrlQuery } from '@donkeyjs/core';
import type { NodeTypename } from '@donkeyjs/proxy';
import slug from 'slug';
import { session } from '../session';

export const NodeRoutingPlugin: RouterPlugin<DatabaseRouter> = (() => {
  const lastSeenName: Record<string, string> = {};

  return {
    decode: (input, router) => {
      const { route, segments, query } = input;
      if (segments.length !== 2) return input;

      const routeTypename = router.getRouteTypename(route);
      if (!routeTypename) return input;

      if (segments[0]) lastSeenName[segments[1]] = segments[0];

      return {
        route,
        segments: [],
        query: {
          ...query,
          'node-typename': [routeTypename],
          'node-id': [segments[1]],
        },
        anchor: segments[1],
      };
    },

    encode: (input, router) => {
      const { route, query } = input;
      const {
        'node-typename': typenameArray,
        'node-id': idArray,
        ...restQuery
      } = query as {
        'node-typename'?: [NodeTypename<DataSchema>];
        'node-id'?: [string];
      };

      const typename = typenameArray?.[0];
      const id = idArray?.[0];

      if (!typename || !id) return input;

      const routeTypename = router.getRouteTypename(route);
      if (routeTypename !== typename) return input;

      const node = session.data.getNode(typename, id);
      const name = node ? slug(node.toString()) : lastSeenName[id] || '';

      return {
        route,
        segments: [...input.segments, name, id],
        query: restQuery as UrlQuery,
      };
    },
  };
})();

export const routerNode = () =>
  session.router.getSchemaQuery({
    'node-typename': 'string?',
    'node-id': 'string?',
  });
