import styles from './Logo.module.css';
import { LogoSvg } from './LogoSvg';

export function Logo() {
  return (
    <div class={styles.logo}>
      <LogoSvg />
      <div class={styles.name}>International Lectures</div>
      <div class={styles.footer}>east – west</div>
      <div class={styles.footer}>medicine – philosophy – management</div>
    </div>
  );
}
