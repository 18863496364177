import { bind } from '@donkeyjs/jsx-runtime';
import {
  isDataList,
  map,
  type DataList,
  type DataNode,
  type InsertableNode,
  type NodeTypename,
  type Schema,
} from '@donkeyjs/proxy';
import { WithHeading, type HeadingStyle } from '../styles';
import type {
  RenderGroupContainerFunction,
  RenderViewAddFunction,
  RenderViewContainerFunction,
  ViewMode,
} from '../views';
import { ListControls } from './ListControls';
import { ListDrafts } from './ListDrafts';
import { useList, type UseListProps } from './useList';

export interface ListProps<S extends Schema, Typename extends NodeTypename<S>>
  extends UseListProps<S, Typename> {
  class?: JSX.ClassNames;
  controls?: 'top' | 'bottom' | 'both' | 'hidden';
  modes?: ViewMode[];
  mode?: ViewMode;
  showModeButtons?: boolean;
  adding?: 'button' | 'button-bottom' | 'top' | 'bottom' | false;
  addAsDraft?: boolean;
  addButtonLabel?: JSX.Children;
  defaultValues?: InsertableNode<DataNode<S, Typename>>;
  onAdd?: (node: DataNode<S, Typename>) => void;
  isInIsolation?: boolean;
  onContainerMount?: JSX.OnMount<HTMLElement>;
  onDataContainerMount?: JSX.OnMount<HTMLElement>;
  heading?: string;
  groupHeadingStyle?: HeadingStyle;
  groupContainerClass?: JSX.ClassNames;
  dataContainerClass?: JSX.ClassNames;
  render: (item: DataNode<S, Typename>, index: () => number) => JSX.Children;
  renderAdd?: RenderViewAddFunction<S, Typename>;
  renderContainer?: RenderViewContainerFunction<S, Typename>;
  renderDataContainer?: RenderViewContainerFunction<S, Typename>;
  renderGroupContainer?: RenderGroupContainerFunction<S, Typename>;
}

export function List<S extends Schema, Typename extends NodeTypename<S>>(
  props: ListProps<S, Typename>,
) {
  const state = useList(props);

  const controls = () =>
    isDataList<S, Typename>(props.data) && (
      <ListControls
        isInIsolation={bind(() => props.isInIsolation)}
        drafts={bind(() => state.nodes.drafts)}
        draftsOpen={bind(state, 'draftsOpen')}
        addButton={bind(
          () =>
            props.adding !== false &&
            ((!props.renderAdd && props.adding == null) ||
              props.adding === 'button'),
        )}
        addButtonLabel={bind(() => props.addButtonLabel)}
        addAsDraft={bind(() => props.addAsDraft)}
        modeButtons={bind(() => props.showModeButtons)}
        list={bind(() => props.data as DataList<S, Typename>)}
        modes={bind(() => props.modes)}
        mode={bind(props, 'mode')}
        defaultValues={bind(() => props.defaultValues)}
        onAdd={(node) => {
          state.draftsOpen = true;
          props.onAdd?.(node);
        }}
      />
    );

  const rendered = map(
    () => props.data,
    (item: DataNode<S, Typename>, index) => props.render(item, index),
  );

  const renderDataContainer = (children: JSX.Children | undefined) => {
    if (props.renderDataContainer) {
      return props.renderDataContainer!(props, children);
    }
    if (props.onDataContainerMount || props.dataContainerClass) {
      return (
        <div
          onmount={props.onDataContainerMount}
          class={bind(() => props.dataContainerClass)}
        >
          {children}
        </div>
      );
    }
    return children || null;
  };

  const renderItems = () => {
    let isFirst = true;
    function renderList(list: () => DataNode<S, Typename>[]) {
      return () => list().map((item) => rendered?.().get(item));
    }

    return map(
      () => state.groups,
      (group) => {
        const useHeading = isFirst && props.heading;
        isFirst = false;

        const value = useHeading ? props.heading : group.name;

        return value || props.renderGroupContainer ? (
          props.renderGroupContainer ? (
            props.renderGroupContainer(
              group,
              props,
              renderList(() => group.items),
            )
          ) : (
            <WithHeading
              heading={value}
              styleAs={props.groupHeadingStyle || 'subtitle'}
            >
              {props.groupContainerClass ? (
                <div class={bind(() => props.groupContainerClass)}>
                  {renderList(() => group.items)}
                </div>
              ) : (
                renderList(() => group.items)
              )}
            </WithHeading>
          )
        ) : (
          <>{renderList(() => group.items)}</>
        );
      },
    );
  };

  const withControls = (children: JSX.Children) => (
    <>
      {() =>
        props.controls !== 'bottom' && props.controls !== 'hidden' && controls()
      }
      {() =>
        !!state.canInsert && (
          <ListDrafts
            drafts={bind(() => state.nodes.drafts)}
            class={bind(() => props.class)}
            modes={bind(() => props.modes)}
            mode={bind(() => props.mode)}
            loading={bind(() => state.isLoading)}
            render={bind(() => props.render)}
            renderContainer={renderDataContainer}
            open={bind(state, 'draftsOpen')}
          />
        )
      }
      {() =>
        props.renderAdd && state.canInsert ? (
          props.adding === 'top' ? (
            <>
              {props.renderAdd(props)}
              {children}
            </>
          ) : (
            <>
              {children}
              {props.renderAdd(props)}
            </>
          )
        ) : (
          children
        )
      }
      {() =>
        !!props.controls &&
        props.controls !== 'hidden' &&
        props.controls !== 'top' &&
        controls()
      }
    </>
  );

  const items = renderDataContainer(renderItems());

  return props.renderContainer ? (
    props.renderContainer(props, withControls(items))
  ) : props.class || props.onContainerMount ? (
    <div class={bind(() => props.class)} onmount={props.onContainerMount}>
      {withControls(items)}
    </div>
  ) : (
    withControls(items)
  );
}
