import { dontWatch, isStore, store } from '@donkeyjs/proxy';
import { ensureComponentInstrumented, type Component } from '../component';
import type { JSXNode, JSXVirtualNode } from '../dom';
import { Fragment } from '../index';
import { componentContext, type RenderContext } from '../mount/mount';
import { canUpdateArray, canUpdateFromProps } from './createElement';

export function createDomComponent(
  values: JSXNode,
  nextSsrCandidate?: Node | null,
): JSXVirtualNode {
  const childNodes = values.children;
  const component = values.tag as Component;
  const props = isStore<{}>(values.props)
    ? values.props
    : store(values.props ?? {});
  if (childNodes.length)
    props.children = {
      __type: 'jsx-node',
      tag: Fragment,
      props: {},
      children: childNodes,
    };

  ensureComponentInstrumented(component);
  let context: RenderContext | undefined;
  const current = {
    node: dontWatch(() => {
      const previousContext = componentContext.current;
      if (!previousContext)
        throw new Error('Cannot render a component without context');

      context = {
        component,
        context: Object.create(previousContext.context),
        dom: previousContext.dom,
        global: previousContext.global,
        parent: previousContext,
        props,
        onMount: previousContext.onMount,
        onUnmount: [],
        disposed: false,
      };
      return component.component!(context, props, nextSsrCandidate);
    }),
  };

  const result: JSXVirtualNode = {
    __type: 'node',
    currentValue: values,
    testUpdate(values: JSXNode) {
      if (
        values == null ||
        typeof values !== 'object' ||
        !values.tag ||
        !values.props
      ) {
        return 'invalid JSX node';
      }
      if (component !== values.tag) {
        return 'component changed unexpectedly';
      }
      if (!canUpdateArray(childNodes, values.children)) {
        return 'children changed unexpectedly';
      }
      return canUpdateFromProps(result.currentValue.props, values.props);
    },
    update(values: JSXNode) {
      const test = result.testUpdate(values);
      if (test !== true) return test;
      if (result.currentValue.props !== values.props) {
        result.currentValue = values;
        store.assign(props, values.props);
      }
      return true;
    },
    dispose() {
      if (context) {
        context.disposed = true;
        for (const onUnmount of context.onUnmount) onUnmount();
        context.onUnmount.length = 0;
        context = undefined;
      }
      current.node.dispose();
    },
    get nodes() {
      return current.node.nodes || [];
    },
  };

  return result;
}
