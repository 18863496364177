import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import type { DataNode, Node, NodeTypename, Schema } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { admin } from '../../../admin';
import { getUserContext } from '../../../authentication';
import { Label } from '../../components/Label';
import { NodeSelect } from '../../components/NodeSelect';
import { InlineNode } from './InlineNode';

export function HtmlNodeField(props: FieldRenderProps<'node'>) {
  // const user = getUserContext();

  return (
    <HtmlNode
      typename={bind(
        () => props.field.schema?.type as NodeTypename<DataSchema>,
      )}
      value={bind(props.field, 'value')}
      mode={bind(() => props.mode)}
      view={bind(() => props.view)}
      optional={bind(() => props.field.schema?.optional)}
      readonly={bind(() => props.readonly)}
      label={bind(() => props.label)}
      helper={bind(() => props.helper)}
      class={bind(() => props.class)}
      onmount={props.onmount}
    />
  );
}

export interface HtmlNodeProps<S extends Schema = Schema> {
  typename: NodeTypename<S> | null | undefined;
  value: Node | null | undefined;
  mode?: 'view' | 'select';
  view?: string;
  optional?: boolean;
  readonly?: boolean;
  label?: JSX.Children;
  helper?: JSX.Children;
  class?: JSX.ClassNames;
  onmount?: JSX.OnMount<HTMLElement>;
}

export function HtmlNode<S extends Schema = Schema>(props: HtmlNodeProps<S>) {
  const user = getUserContext();

  return (
    <Label
      class={bind(() => ['input', props.class])}
      label={bind(() => props.label)}
      helper={bind(() => props.helper)}
      required={bind(() => !props.optional && !props.readonly)}
      onmount={props.onmount}
    >
      {() =>
        props.mode === 'view' ? (
          jsxx(InlineNode, props)
        ) : (
          <NodeSelect
            typename={bind(() => props.typename as NodeTypename<DataSchema>)}
            allowEmpty={props.optional}
            value={bind(
              () => props.value as DataNode<DataSchema> | null | undefined,
              (value) => {
                props.value = value as DataNode<DataSchema> | null | undefined;
              },
            )}
            readonly={bind(() => props.readonly)}
            onRequestAdd={bind(() =>
              props.typename && user.isSysAdmin // user.can('insert', props.typename)
                ? async () =>
                    admin.showCreateOrEditNodeDialog?.({
                      typename: props.typename as NodeTypename<DataSchema>,
                    })
                : undefined,
            )}
          />
        )
      }
    </Label>
  );
}
