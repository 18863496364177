import { bind } from '@donkeyjs/jsx-runtime';
import { meta, type SupportedCulture } from '@donkeyjs/proxy';
import { Flag } from '../i18n/Flag';
import { getI18n } from '../i18n/getI18n';
import { session } from '../session';
import { getTheme } from '../styles';
import styles from './CultureMenu.module.css';

export type CultureMenuProps = {
  includeActive?: boolean;
  showText?: boolean;
};

export function CultureMenu(props: CultureMenuProps) {
  const i18n = getI18n();

  return () =>
    session.app.schema.cultures.map((culture) => {
      if (!props.includeActive && culture === i18n.culture) return null;

      const theme = getTheme();

      const path = session.router.getPath(
        {
          route:
            meta(session.router.route.node)?.getCulture(culture) ||
            session.router.route,
        },
        { updateQuery: {} },
      );

      return (
        <a
          href={path}
          class={bind(() => [
            theme.class.link,
            {
              [styles.active]: culture === i18n.culture,
              [styles.hasActive]: props.includeActive,
              [styles.hasText]: props.showText,
            },
          ])}
        >
          <Flag culture={culture} showTextIfNotFound />
          {() => (props.showText ? Language[culture] : null)}
        </a>
      );
    });
}

const Language: Partial<Record<SupportedCulture, string>> = {
  'en-GB': 'English',
  nl: 'Nederlands',
};
