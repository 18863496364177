import type { RenderContext } from '@donkeyjs/jsx-runtime';
import {
  type AppSchema,
  type CustomResolverArgs,
  type CustomResolverArgsFromSchema,
  type CustomResolverSchema,
  type CustomResolverSetSchema,
  DataError,
  type DataList,
  type DataNode,
  DataNodeApply,
  type FieldSchemaInput,
  type Fragment,
  type ManyFragment,
  type Mutations,
  type NodeFactory,
  type NodeSchema,
  type NodeTypename,
  type QuerySortOrder,
  type ResolverArgsFromSchema,
  type ResolverArgsSearch,
  type ResolverFromSchema,
  type ResolverManyArgsFromSchema,
  type ResolverSchema,
  type Scalar,
  type ScalarType,
  type Schema,
  type Simplified,
  type StatusFragment,
  type TimeZoneConfig,
  batch,
  createDataList,
  createDataListAggregation,
  extendStatusFragment,
  getMutations,
  getSourceFromSearch,
  nodeFactory,
  store,
} from '@donkeyjs/proxy';
import { useAsyncProvider } from '../helpers';
import { Value } from '../layout';
import type { ClientSchemaMeta } from '../schema/clientSchemaMetaUtils';
import { createDataCache } from './dataCache';
import {
  type FetchDataFunction,
  fetchGqlCustomQuery,
  fetchGqlMutation,
  fetchGqlQuery,
  fetchLazyNodes,
  fetchRawGql,
} from './fetch';

export type DataClientFromSchema<
  S extends Schema,
  A extends AppSchema,
> = Simplified<
  {
    readonly schema: A;
    query: CustomResolverClientFunctions<S, A['customResolvers']['queries']>;
    mutation: {
      mutate: (mutations: Mutations<S>) => Promise<DataError[]>;
    } & CustomResolverClientFunctions<S, A['customResolvers']['mutations']>;
    get: <Resolver extends keyof A['resolvers']>(
      resolver: Resolver,
      args: ResolverArgsFromSchema<S, A, Resolver>,
    ) => ResolverResponse<S, A, Resolver>;
    useNodes: <T extends NodeTypename<S>>(
      options: NodesOptions<S, T>,
    ) => DataList<S, T>;
    getNode: <T extends NodeTypename<S>>(
      typename: T,
      id: string,
    ) => DataNode<S, T>;
    createList: <T extends NodeTypename<S>>(options: {
      typename: T;
      match: ResolverManyArgsFromSchema<S, T>;
      sort?: QuerySortOrder<S, S['nodes'][T]>;
    }) => DataList<S, T>;

    serialize(): any;
    deserialize(data: any): void;
  } & NodeFactory<S> & {
      [Resolver in keyof A['resolvers'] as `get${Capitalize<
        Extract<Resolver, string>
      >}`]: keyof ResolverArgsFromSchema<S, A, Resolver> extends never
        ? (args?: {
            skipExecution?: boolean;
            permanent?: boolean;
          }) => ResolverResponse<S, A, Resolver>
        : (
            args?: Simplified<
              ResolverArgsFromSchema<S, A, Resolver> & {
                skipExecution?: boolean;
                permanent?: boolean;
              }
            >,
          ) => ResolverResponse<S, A, Resolver>;
    }
>;

type NodesOptions<S extends Schema, T extends NodeTypename<S>> = Simplified<{
  readonly typename: T;
  readonly ids?: string[];
  readonly search?: ResolverArgsSearch;
  readonly sort?: QuerySortOrder<S, S['nodes'][T]>;
  readonly placeholderCount?: number;
  readonly drafts?: boolean;
  readonly skipExecution?: boolean;
}>;

type ResolverResponse<
  S extends Schema,
  A extends AppSchema,
  Resolver extends keyof A['resolvers'],
> = ResolverFromSchema<S, A, Resolver> extends {
  typename: infer Typename;
}
  ? Typename extends NodeTypename<S>
    ? DataList<S, Typename>
    : DataList<S, NodeTypename<S>>
  : DataList<S, NodeTypename<S>>;

export type CustomResolverClientFunction<
  S extends Schema,
  T extends CustomResolverSchema,
> = T['returns'] extends NodeTypename<S>
  ? CustomResolverClientFunctionWithSelection<S, T, T['returns']>
  : T['returns'] extends `${infer U extends NodeTypename<S>}?`
    ? CustomResolverClientFunctionWithSelection<S, T, U>
    : T['args'] extends CustomResolverArgs
      ? (args: Simplified<CustomResolverArgsFromSchema<T['args']>>) => Promise<{
          data: CustomResolverClientFunctionReturnType<S, T['returns']>;
          errors?: DataError[];
        }>
      : () => Promise<{
          data: CustomResolverClientFunctionReturnType<S, T['returns']>;
          errors?: DataError[];
        }>;

type CustomResolverClientFunctionWithSelection<
  S extends Schema,
  T extends CustomResolverSchema,
  Returns extends NodeTypename<S>,
> = T['args'] extends CustomResolverArgs
  ? (
      args: Simplified<CustomResolverArgsFromSchema<T['args']>>,
      fragment: Fragment<S, Returns>,
      options?: {
        source?: string;
      },
    ) => Promise<{
      data: CustomResolverClientFunctionReturnType<S, Returns>;
      errors?: DataError[];
    }>
  : (fragment: Fragment<S, Returns>) => Promise<{
      data: CustomResolverClientFunctionReturnType<S, Returns>;
      errors?: DataError[];
    }>;

type CustomResolverClientFunctionReturnType<
  S extends Schema,
  T extends FieldSchemaInput<
    NodeTypename<S>,
    Extract<keyof S['enums'], string>
  >,
> = T extends `${infer U}?`
  ? U extends Scalar
    ? ScalarType<Scalar> | undefined
    : U extends NodeTypename<S>
      ? DataNode<S, U> | undefined
      : never
  : T extends Scalar
    ? ScalarType<T>
    : T extends NodeTypename<S>
      ? DataNode<S, T>
      : never;

export type CustomResolverClientFunctions<
  S extends Schema,
  T extends CustomResolverSetSchema,
> = {
  [name in keyof T]: T[name] extends CustomResolverSchema
    ? CustomResolverClientFunction<S, T[name]>
    : never;
};

export interface QueryVariables {
  [key: string]: QueryVariable;
}

export type QueryVariable =
  | string
  | number
  | boolean
  | QueryVariables
  | { __literal: string }
  | (string | number | boolean | QueryVariables | { __literal: string })[];

export interface FetchDataOptions<
  S extends Schema,
  Typename extends NodeTypename<S>,
> {
  resolverName: string;
  resolver?: ResolverSchema;
  schema?: Schema;
  nodeSchema?: NodeSchema;
  returnsAggregation?: boolean;
  forUnion?: string;
  options: QueryVariables;
  fragment: Fragment<S, Typename> | ManyFragment<S, Typename>;
}

export interface FetchDataResult {
  data?: any;
  errors?: DataError[];
}

export const createDataClient = <S extends Schema, A extends AppSchema>(
  context: RenderContext,
  schema: A,
  schemaMeta?: ClientSchemaMeta<S>,
  tz?: TimeZoneConfig,
  fetchQuery?: any,
): DataClientFromSchema<S, A> => {
  DataNodeApply.implementation = (node) => (field, props) => (
    <Value
      node={node as any}
      props={(props as any) ?? {}}
      fieldName={field as any}
    />
  );

  const hooks: any = {};
  const asyncProvider = useAsyncProvider(context);

  const fetch = fetchGqlQuery(fetchQuery);
  const factory = nodeFactory(schema, {
    tz,
    handleLazyLoad(nodes) {
      fetchLazyNodes(fetchNodes, nodes);
    },
    async handleMutations(nodes) {
      const mutations: Mutations<S> = [];
      for (const node of nodes) {
        const mutation = getMutations<S>(node as any);
        if (mutation) mutations.push(mutation);
      }
      if (mutations.length) {
        await result.mutation.mutate(mutations);
      }
    },
    nodeToString(node) {
      return (
        schemaMeta?.[node.__typename]?.asString?.(node as any, schema as any) ??
        ''
      );
    },
  });
  const fetchNodes: FetchDataFunction = async (input) => {
    const result = await asyncProvider.register(fetch(input));
    const data = input.returnsAggregation ? result.data?.nodes : result.data;
    if (data) {
      const array = Array.isArray(data) ? data : [data];
      batch(() => {
        for (const item of array) {
          if ('__typename' in item) {
            factory.processNodeData(
              item,
              (input.options.source as string) ??
                getSourceFromSearch(input.options.search as any),
            );
          }
        }
        input.process?.(result);
      });
    } else if (input.process) {
      batch(() => input.process!(result));
    }
    return result;
  };

  const cache = createDataCache(schema, schemaMeta, factory, fetchNodes);

  const result = {
    ...factory,
    schema,

    useNodes<T extends NodeTypename<S>>(options: NodesOptions<S, T>) {
      const typename = options.typename;
      const args = store(options);

      return cache.useData({
        typename,
        resolverName: 'nodes',
        returnsMany: true,
        forUnion: typename,
        placeholderCount: args.placeholderCount,
        args: args as unknown as QueryVariables,
        addArgs: {
          typename: { __literal: typename },
        },
      });
    },

    mutation: {
      mutate: async (mutations: Mutations<S>) =>
        (
          await fetchGqlMutation(fetchQuery || fetchRawGql, 'mutate', {
            mutations,
          } as unknown as QueryVariables)
        ).errors || [],
    },

    query: {},

    serialize() {
      return {
        f: factory.serialize(),
        q: Object.fromEntries(
          Array.from(cache.requests.entries()).map(([key, status]) => [
            key,
            JSON.stringify(status),
          ]),
        ),
        a: Object.fromEntries(
          Array.from(cache.aggregations.entries()).map(([key, aggregation]) => [
            key,
            aggregation.serialize(),
          ]),
        ),
      };
    },

    deserialize(data: any) {
      factory.deserialize(data.f);
      for (const [key, value] of Object.entries(data.a) as [string, any]) {
        cache.aggregations.set(
          key,
          createDataListAggregation<S, NodeTypename<S>>(
            schema as unknown as S,
            schemaMeta,
            factory,
            value.t,
            value.f,
            value.s,
          ),
        );
      }
      for (const [key, value] of Object.entries(data.q) as [string, any]) {
        cache.requests.set(key, JSON.parse(value));
      }
    },

    get<Resolver extends keyof A['resolvers']>(
      resolver: Resolver,
      options: any,
      userRoles?: string[],
    ) {
      try {
        return hooks[resolver](options, userRoles);
      } catch (error) {
        return createDataList({
          factory,
          match: {},
          schema,
          typename: resolver.toString(),
          fieldStatus: { id: 'ready' },
          loading: false,
          errors: [new DataError('resolver', error)],
        });
      }
    },

    getNode(typename: NodeTypename<S>, id: string) {
      return factory.ensureNode(
        {
          __typename: typename,
          id,
        },
        { fieldStatus: { id: 'ready' } },
      );
    },

    createList<T extends NodeTypename<S>>({
      typename,
      match,
      sort,
    }: {
      typename: T;
      match: ResolverManyArgsFromSchema<S, T>;
      sort?: QuerySortOrder<S, S['nodes'][T]>;
    }) {
      return createDataList({
        factory,
        match,
        sort,
        schema,
        typename,
        fieldStatus: { id: 'ready' },
      });
    },
  };

  for (const typename in schema.nodes) {
    const nodeSchema = schema.nodes[typename];
    if (nodeSchema.resolvers) {
      for (const resolverName in nodeSchema.resolvers) {
        const resolverSchema = nodeSchema.resolvers[resolverName];

        hooks[resolverName] = (result as any)[
          `get${resolverName[0].toUpperCase()}${resolverName.slice(1)}`
        ] = (argsInput: any) => {
          const returnsMany = resolverSchema.return === 'many';
          const args = store(argsInput || {});

          return cache.useData({
            typename,
            resolverName,
            returnsMany,
            returnsAggregation: returnsMany,
            args,
          });
        };
      }
    }
  }

  if (schema.customResolvers?.queries)
    for (const name in schema.customResolvers.queries) {
      const query = schema.customResolvers.queries[name];
      (result as any).query[name] = async (
        args: any,
        fragment?: any,
        options?: any,
      ) => {
        const result = await fetchGqlCustomQuery(
          fetchQuery || fetchRawGql,
          name,
          args,
          fragment,
        );
        if (query.returns.split('?')[0] in schema.nodes && result.data) {
          const statusFragment: StatusFragment = { id: 'ready' };
          extendStatusFragment(
            'requested',
            statusFragment,
            fragment,
            schema,
            query.returns.split('?')[0],
          );
          return {
            data: factory.processNodeData(result.data, options?.source),
            errors: result.errors,
          };
        }
        return result;
      };
    }

  if (schema.customResolvers?.mutations)
    for (const name in schema.customResolvers.mutations) {
      const mutation = schema.customResolvers.mutations[name];
      (result as any).mutation[name] = async (
        args: any,
        fragment?: any,
        options?: any,
      ) => {
        const result = await fetchGqlMutation(
          fetchQuery || fetchRawGql,
          name,
          args,
          fragment,
        );
        if (mutation.returns.split('?')[0] in schema.nodes && result.data) {
          const statusFragment: StatusFragment = { id: 'ready' };
          extendStatusFragment(
            'requested',
            statusFragment,
            fragment,
            schema,
            mutation.returns.split('?')[0],
          );
          return {
            data: factory.processNodeData(result.data, options?.source),
            errors: result.errors,
          };
        }
        return result;
      };
    }

  return result as any;
};
