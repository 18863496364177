import { componentContext } from './mount/mount';

export const addGlobalEventListener: typeof window.addEventListener = (
  ...args: Parameters<typeof window.addEventListener>
) => {
  if (!componentContext.current)
    throw new Error('addGlobalEventListener can only be called in a component');

  return componentContext.current.dom.window.addEventListener(...args);
};

export const removeGlobalEventListener: typeof window.removeEventListener = (
  ...args: Parameters<typeof window.removeEventListener>
) => {
  if (!componentContext.current)
    throw new Error(
      'removeGlobalEventListener can only be called in a component',
    );

  return componentContext.current.dom.window.removeEventListener(...args);
};
