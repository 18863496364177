import { computed, signal } from '@donkeyjs/proxy';
import { withContext } from '../component';
import type { JSXVirtualNode } from '../dom';
import { componentContext } from '../mount/mount';
import { processBeforeMount } from '../mount/renderPlugin';
import { createJSXNode } from './createJSXNode';

export function createDomSignal(
  fn: () => JSX.Element,
  nextSsrCandidate?: Node | null,
): JSXVirtualNode {
  const context = componentContext.current;
  if (!context) throw new Error('Cannot render a JSX element without context');

  let rendered: JSXVirtualNode | undefined;
  let ssrCandidate = nextSsrCandidate;

  const current = signal(fn);
  const value = computed(() =>
    withContext(context, () => {
      const value = current.value();
      const next = processBeforeMount(value);
      const update = rendered?.update(next);
      if (update !== true) {
        rendered?.dispose();
        rendered = createJSXNode(next, ssrCandidate);
        ssrCandidate = undefined;
      }
      return rendered;
    }),
  );

  const result: JSXVirtualNode = {
    __type: 'node',
    currentValue: fn,
    testUpdate(fn: any) {
      if (typeof fn !== 'function') {
        return 'non-function passed a DOM signal';
      }
      return true;
    },
    update(fn: any) {
      const test = result.testUpdate(fn);
      if (test !== true) return test;
      result.currentValue = fn;
      current.value = fn;
      return true;
    },
    dispose() {
      rendered?.dispose();
      rendered = undefined;
    },
    get nodes() {
      return value?.value?.nodes || [];
    },
  };

  return result;
}
