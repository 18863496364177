import { baseSchema, blocksSort } from '@donkeyjs/core';
import { schemaEnGB } from '@donkeyjs/i18n-en-gb';
import { schemaNlNL } from '@donkeyjs/i18n-nl-nl';
import { mailingsSchemaExtension } from '@donkeyjs/module-mailings';
import {
  shoppingSchemaExtension,
  shoppingSchemaOrderable,
} from '@donkeyjs/module-shopping';
import { createSchema, extendSchema } from '@donkeyjs/proxy';

const { result } = extendSchema(baseSchema)
  .with({ i18n: { 'en-GB': schemaEnGB, nl: schemaNlNL } })
  .with(shoppingSchemaExtension)
  .with(mailingsSchemaExtension)
  .with({
    enums: {
      Language: ['DUTCH', 'ENGLISH'],
      CourseStatus: ['OPEN', 'CANCELED', 'EXPECTED', 'EXTERNAL'],
    },

    nodes: {
      Attendance: {
        day: ['CourseDay', { reverse: 'attendances' }],
        student: ['Student', { reverse: 'attendances' }],
        checkInAt: 'date',
        checkOutAt: 'date?',
      },

      Course: {
        name: ['string', { i18n: true }],
        partner: ['Partner?', { reverse: 'courses' }],
        linkToUrl: ['string?', { i18n: true }],
      },

      CourseOffering: {
        course: ['Course', { reverse: 'offerings' }],
        status: 'CourseStatus',
        isOnline: 'boolean?',
        starts: ['date?', { rangeToField: 'ends' }],
        ends: ['date?', { rangeFromField: 'starts' }],
        language: 'Language?',
        limitSeatsTo: 'int?',
        timeZone: 'string',
        recommended: 'boolean?',
        isTimeSchedulePublic: 'boolean?',
      },

      CourseOption: {
        ...shoppingSchemaOrderable,
        offering: [
          'CourseOffering',
          {
            reverse: 'options',
            embeddedList: true,
            cascadeDelete: true,
            sort: [{ name: 'ASC', order: ['sortIndex:auto'] }],
          },
        ],
        name: ['string', { i18n: true }],
        sortIndex: 'float?',
      },

      CourseDay: {
        offering: [
          'CourseOffering',
          {
            reverse: 'days',
            cascadeDelete: true,
            sort: [{ name: 'ASC', order: ['starts:asc'] }],
            embeddedList: true,
          },
        ],
        starts: 'date',
        ends: 'date',
        info: ['string?', { i18n: true }],
        place: ['Place?', { reverse: 'courseDays' }],
      },

      CourseMessage: {
        subject: 'string',
        sentAt: 'date?',
        course: [
          'CourseOffering',
          {
            reverse: 'messages',
            embeddedList: true,
            sort: [{ name: 'DESC', order: ['sentAt:desc'] }],
          },
        ],
      },

      Teacher: {
        title: ['string?', { size: 20 }],
        firstName: ['string?', { size: 80 }],
        lastName: ['string?', { size: 80 }],
        middleName: ['string?', { size: 80 }],
        user: ['User?', { reverse: 'teacher' as const }],
      },

      Testimonial: {
        teacher: [
          'Teacher',
          {
            reverse: 'testimonials',
            sort: [{ name: 'ASC', order: ['sortIndex:auto'] }],
          },
        ],
        course: ['Course?', { reverse: 'testimonials' }],
        author: 'string',
        capacity: ['string?', { i18n: true }],
        sortIndex: 'float?',
      },

      Student: {
        title: ['string?', { size: 20 }],
        firstName: ['string?', { size: 80 }],
        lastName: ['string?', { size: 80 }],
        middleName: ['string?', { size: 80 }],
        user: ['User', { reverse: 'student' as const }],
      },

      CourseMaterial: {
        name: ['string', { i18n: true }],
        file: ['File', { reverse: 'onCourseMaterial' }],
        course: [
          'CourseOffering',
          {
            reverse: 'materials',
            embeddedList: true,
            sort: [{ name: 'DESC', order: ['publishedAt:desc'] }],
          },
        ],
        publishedAt: 'date?',
      },

      TradeAssociation: {
        name: ['string', { i18n: true }],
        requestMembershipNumber: 'boolean?',
      },

      StudentTradeAssociation: {
        student: ['Student', { reverse: 'tradeAssociations' }],
        tradeAssociation: ['TradeAssociation', { reverse: 'students' }],
        membershipNumber: 'string?',
      },

      CourseTeacher: {
        course: ['Course', { reverse: 'teachers' }],
        teacher: ['Teacher', { reverse: 'courses' }],
        sortIndex: 'float?',
      },

      CourseOfferingStudent: {
        course: ['CourseOffering', { reverse: 'students' }],
        student: ['Student', { reverse: 'courses' }],
        order: ['Order', { reverse: 'order' }],
      },

      Partner: {
        name: ['string', { i18n: true }],
        logo: ['FileRef?', { reverse: 'partner' }],
      },
    },

    extensions: {
      Block: {
        add: {
          onTeacherBiography: [
            'Teacher?',
            { reverse: 'biography', sort: blocksSort, cascadeDelete: true },
          ],
          onTeacherLongBiography: [
            'Teacher?',
            { reverse: 'longBiography', sort: blocksSort, cascadeDelete: true },
          ],
          onCourseOfferingAccreditation: [
            'CourseOffering?',
            { reverse: 'accreditation', sort: blocksSort, cascadeDelete: true },
          ],
          onCourseOfferingPriorKnowledge: [
            'CourseOffering?',
            {
              reverse: 'priorKnowledge',
              sort: blocksSort,
              cascadeDelete: true,
            },
          ],
          onCourseOfferingDescription: [
            'CourseOffering?',
            { reverse: 'description', sort: blocksSort, cascadeDelete: true },
          ],
          onCourseOfferingMoreInfo: [
            'CourseOffering?',
            { reverse: 'moreInfo', sort: blocksSort, cascadeDelete: true },
          ],
          OnCourseOfferingPriceInfo: [
            'CourseOffering?',
            { reverse: 'priceInfo', sort: blocksSort, cascadeDelete: true },
          ],
          onCourseOfferingTargetAudience: [
            'CourseOffering?',
            {
              reverse: 'targetAudience',
              sort: blocksSort,
              cascadeDelete: true,
            },
          ],
          onCourseMessageBody: [
            'CourseMessage?',
            { reverse: 'body', sort: blocksSort },
          ],
          onTestimonialText: [
            'Testimonial?',
            { reverse: 'text', sort: blocksSort, cascadeDelete: true },
          ],
        },
      },

      Coupon: {
        add: {
          courseOption: [
            'CourseOption?',
            { reverse: 'coupons', embeddedList: true, cascadeDelete: true },
          ],
        },
      },

      Discount: {
        add: {
          courseOption: [
            'CourseOption?',
            { reverse: 'discounts', embeddedList: true, cascadeDelete: true },
          ],
        },
      },

      PaymentPlan: {
        add: {
          courseOption: [
            'CourseOption?',
            {
              reverse: 'paymentSchemes',
              embeddedList: true,
              cascadeDelete: true,
            },
          ],
        },
      },

      FileRef: {
        add: {
          course: ['Course?', { reverse: 'images', cascadeDelete: true }],
          courseOffering: [
            'CourseOffering?',
            { reverse: 'images', cascadeDelete: true },
          ],
          teacher: ['Teacher?', { reverse: 'images', cascadeDelete: true }],
        },
      },
      Order: {
        add: {
          numberOfTerms: 'int?',
          lastTerm: 'date?',
        },
      },
      OrderItem: {
        add: {
          course: ['CourseOption?', { reverse: 'orderItems' }],
        },
      },
      Message: {
        add: {
          course: ['CourseMessage?', { reverse: 'shipments' }],
        },
      },
    },

    resolvers: {
      CourseOffering: {
        courses: {
          return: 'many',
          sort: [{ name: 'STARTS_ASC', order: ['starts:asc'] }],
          where: {
            ends: ['ends', ['gte']],
            student: ['students.student', ['eq']],
          },
          // search: {
          //   name: 'A',
          // },
        },
      },
      Teacher: {
        teachers: {
          return: 'many',
          sort: [
            {
              name: 'LAST_NAME',
              order: ['lastName:asc', 'middleName:asc', 'firstName:asc'],
            },
            {
              name: 'FIRST_NAME',
              order: ['firstName:asc', 'middleName:asc', 'lastName:asc'],
            },
          ],
        },
      },
    },

    customResolvers: {
      mutations: {
        sendCourseMessage: {
          args: {
            id: 'string',
          },
          returns: 'boolean',
        },

        publishCourseMaterials: {
          args: {
            courseId: 'string',
            ids: 'string',
          },
          returns: 'CourseMaterial',
        },
      },
    },

    i18n: {
      'en-GB': () => import('./i18n/schema/enGB').then((m) => m.Schema),
      nl: () => import('./i18n/schema/nl').then((m) => m.Schema),
    },
  } as const);

export const schema = createSchema(result, { cultures: ['nl', 'en-GB'] });

export type AppSchema = typeof schema;
