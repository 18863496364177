import type { Schema } from '@donkeyjs/proxy';
import type { Views } from '.';

export const mergeViews = <S extends Schema>(
  views: Views<S> | undefined,
  extension: Views<S> | undefined,
) => {
  if (!views) return extension || {};
  if (!extension) return views || {};

  const merged: Views<S> = { ...views };
  for (const [key, value] of Object.entries(extension) as [
    keyof Views<S>,
    Record<string, any>,
  ][]) {
    if (merged[key]) {
      merged[key] = {
        ...merged[key],
        ...value,
      };
    } else {
      merged[key] = value;
    }
  }
  return merged;
};
