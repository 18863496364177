import {
  meta,
  store,
  watch,
  type Culture,
  type DataList,
  type DataNode,
  type Node,
  type NodeTypename,
} from '@donkeyjs/proxy';
import type { SchemaStore, SchemaStoreInput } from '../data/schemaStore';
import {
  createRouter,
  type RouteDefinition,
  type Router,
} from './createRouter';
import {
  createRouteDefinitionsFromDb,
  type RoutesFromDbCache,
} from './helpers/createRouteDefinitionsFromDb';
import { getNodeRouting, getRouteTypename } from './helpers/getNodeRouting';
import { getPath, type GetPathOptions } from './helpers/getPath';
import { getSchemaQuery } from './helpers/getSchemaQuery';
import type {
  NodeRouteMappings,
  RouteLink,
  RouterDataBlocks,
  RouterPlugin,
  UrlQuery,
} from './types';

export interface DatabaseRouter extends Router {
  schema: ApplicationSchema;
  app: DataNode<DataSchema, 'App'>;
  isLoading: boolean;
  getPath<Typename extends NodeTypename<DataSchema>>(
    to: RouteLink<Typename>,
    options?: GetPathOptions,
  ): string;
  getSchemaQuery<Input extends SchemaStoreInput>(
    input: Input,
  ): SchemaStore<Input>;
  getNodeRouting(
    node: Node,
  ): { route: RouteDefinition; query?: UrlQuery; anchor?: any } | undefined;
  getRouteTypename(
    route: RouteDefinition | DataNode<DataSchema, 'Route'>,
  ): string | undefined;
  dispose(): void;
}

export interface UseRouterOptions {
  app: DataList<DataSchema, 'App'>;
  schema: ApplicationSchema;
  plugins?: RouterPlugin<DatabaseRouter>[];
  singleCulture?: boolean;
  systemRoutes?: RouteDefinition[];
  nodeRouting?: NodeRouteMappings;
  getRouterDataBlocks?: (culture: Culture) => RouterDataBlocks;
}

type DatabaseRouterOptions = Pick<Router, 'pathname' | 'queryString'> &
  Pick<
    UseRouterOptions,
    | 'app'
    | 'schema'
    | 'singleCulture'
    | 'nodeRouting'
    | 'getRouterDataBlocks'
    | 'systemRoutes'
    | 'plugins'
  >;

export const createDatabaseRouter = (
  options: DatabaseRouterOptions,
): DatabaseRouter => {
  const { app, nodeRouting, plugins, schema, singleCulture } = options;

  const cultures = singleCulture
    ? [schema.defaultCulture as Culture]
    : schema.cultures;

  let router: DatabaseRouter;
  let routes: { [culture in Culture]?: RouteDefinition[] };
  const cache: RoutesFromDbCache = {};
  let hasCalledLoadedPlugins = false;
  const { dispose } = watch((first) => {
    if (first) requestAppFields(app[0]);

    const isLoading = meta(app[0])?.isLoading ?? false;
    const appRoutes = app[0]?.routes;

    if (!hasCalledLoadedPlugins && !isLoading) {
      hasCalledLoadedPlugins = true;
      if (plugins) {
        for (const plugin of plugins) {
          plugin.routesLoaded?.(router);
        }
      }
    }

    routes = createRouteDefinitionsFromDb(
      appRoutes || [],
      options.systemRoutes || [],
      cultures,
      cache,
    );

    if (router) {
      router.routes = routes;
    } else {
      router = createRouter<
        Omit<DatabaseRouter, Exclude<keyof Router, 'path'>>
      >({
        cultures,
        defaultCulture: schema.defaultCulture as Culture,
        get hostname() {
          return hostname(app[0]);
        },
        pathname: options.pathname,
        plugins: options.plugins as RouterPlugin<Router>[],
        queryString: options.queryString,
        routes: routes!,
        plugin: {
          get path(): string {
            return getPath(
              router,
              plugins,
              { route: router.route },
              { query: router.query },
            );
          },
          get app() {
            if (!app[0]) throw new Error('App not found');
            return meta(app[0]).getCulture(router.culture);
          },
          schema,
          get isLoading() {
            return meta(app[0])?.isLoading || false;
          },
          dispose: () => dispose(),
          getPath: (to, options) => getPath(router, plugins, to, options),
          getSchemaQuery: (input) =>
            getSchemaQuery(schema, router.query, input),
          getNodeRouting: (node) =>
            getNodeRouting(
              node,
              router.map,
              () => state.dataBlocks,
              router.culture,
              nodeRouting,
            ),
          getRouteTypename: (route) =>
            getRouteTypename(
              route,
              () => state.dataBlocks,
              options.nodeRouting,
            ),
        },
      }) as DatabaseRouter;
    }
  });

  const state = store({
    get dataBlocks() {
      return options.getRouterDataBlocks?.(router.culture);
    },
  });

  router!.navigate(options.pathname, {
    queryString: options.queryString,
    replace: true,
    forceRefresh: true,
  });

  return router!;
};

function hostname(app: DataNode<DataSchema, 'App'> | null) {
  if (typeof window !== 'undefined' && window.location.hostname === 'localhost')
    return `http://localhost:${window.location.port}`;
  return app?.hostname ? `https://${app.hostname}` : '';
}

function requestAppFields(app: DataNode<DataSchema, 'App'> | null) {
  meta(app)?.request({
    routes: {
      accessibleToRoles: true,
      blocks: DEFAULT_BLOCKS_REQUEST,
      hidden: true,
      key: true,
      name: true,
      parentRoute: { id: true },
      pathPrefix: true,
      redirect: true,
      segment: true,
      sortIndex: true,
      visibleToRoles: true,
    },
  });
}

export const DEFAULT_BLOCKS_REQUEST = {
  culture: true,
  files: {
    align: true,
    maxWidth: true,
    textWrap: true,
    file: {
      id: true,
      fileExtension: true,
      name: true,
      fileType: true,
      height: true,
      uploadStatus: true,
      dominant: true,
      width: true,
    },
  },
  parent: { id: true },
  segment: true,
  settings: true,
  sortIndex: true,
  type: true,
  locked: true,
} as const;
