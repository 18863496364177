import { passwordStrength } from '@donkeyjs/core';
import { mount, setState } from '@donkeyjs/jsx-runtime';
import { type DataError, bind, meta } from '@donkeyjs/proxy';
import { Dialog } from '../components/Dialog/Dialog';
import { getI18n } from '../i18n/getI18n';
import { text } from '../i18n/i18n';
import { Form } from '../layout';
import { setLayout } from '../layout/layoutContext';
import { session } from '../session';
import { getTheme } from '../styles';
import styles from './AuthenticationDialog.module.css';
import { LoginForm } from './LoginForm';
import { PasswordStrengthBar } from './PasswordStrengthBar';
import { getLoginForm } from './getLoginForm';
import {
  type RegistrationForm,
  getRegistrationForm,
} from './getRegistrationForm';
import type { UserLogin } from './getUserContext';

interface AuthenticationDialogProps {
  readonly onLogin: (login: UserLogin) => Promise<DataError[] | void>;
  readonly onRegister: (user: RegistrationForm) => Promise<DataError[] | void>;
  readonly onClose: (confirm?: boolean) => void;
}

export function AuthenticationDialog(props: AuthenticationDialogProps) {
  const theme = getTheme();
  const formData = getLoginForm();
  const registrationForm = getRegistrationForm();
  const i18n = getI18n();

  setLayout('html:flow');

  const state = setState({
    isRegistering: false,

    get strength() {
      return passwordStrength(registrationForm.password || '');
    },
  });

  async function submit() {
    if (state.isRegistering) {
      if (meta(registrationForm).validate().length) return;
      const errors = await props.onRegister({
        ...registrationForm,
        uiCulture: i18n.culture,
      });
      if (!errors?.length) props.onClose(true);
      meta(registrationForm).errors = errors || [];
      return errors;
    }

    if (meta(formData).validate().length) return;
    const userData = {
      email: formData.email,
      password: formData.password,
    };
    const errors = await props.onLogin(userData);
    if (!errors?.length) props.onClose(true);
    meta(formData).errors = errors || [];
    return errors;
  }

  return (
    <Dialog
      size="sm"
      buttons={
        <>
          <button
            type="submit"
            class={bind(() => [theme.class.button, 'default'])}
            onclick={submit}
            disabled={bind(() =>
              state.isRegistering ? !state.strength.isStrongEnough : false,
            )}
          >
            {() =>
              text(
                state.isRegistering
                  ? 'Account.ActionRegister'
                  : 'Account.ActionLogin',
              )
            }
          </button>
          <button
            type="button"
            class={bind(() => [theme.class.button])}
            onclick={() => props.onClose()}
          >
            {text('Common.Cancel')}
          </button>
        </>
      }
      onClose={async (confirm) => {
        if (confirm) await submit();
        else props.onClose();
      }}
    >
      <Form>
        {() =>
          !state.isRegistering ? (
            <LoginForm form={formData} />
          ) : (
            <>
              {(session.app.userCreation?.fields || ['email', 'password']).map(
                (field, index) => {
                  const Field = registrationForm.$[field];
                  return (
                    <Field
                      autofocus={index === 0}
                      password={field === 'password'}
                      autocomplete={
                        field === 'password' ? 'new-password' : undefined
                      }
                    />
                  );
                },
              )}
              <PasswordStrengthBar strength={bind(() => state.strength)} />
            </>
          )
        }
        {() =>
          session.app.userCreation && (
            <button
              type="button"
              class={bind(() => [theme.class.linkButton, styles.switch])}
              onclick={() => {
                state.isRegistering = !state.isRegistering;
              }}
            >
              {text(
                bind(() =>
                  state.isRegistering
                    ? 'Account.HasAccount'
                    : 'Account.NoAccount',
                ),
              )}
            </button>
          )
        }
      </Form>
    </Dialog>
  );
}

export const showAuthenticationDialog = (
  props: Omit<AuthenticationDialogProps, 'onClose'>,
): Promise<boolean> => {
  let close: () => void;

  const result = new Promise<boolean>((resolve) => {
    [close] = mount(
      session.dom,
      AuthenticationDialog,
      {
        ...props,
        onClose(confirm?: boolean) {
          close();
          resolve(!!confirm);
        },
      },
      [],
      document.body,
    );
  });

  return result;
};
