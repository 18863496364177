import type { UrlQuery } from '../types';

export function encodeQuery(query: UrlQuery): string {
  return Object.keys(query)
    .sort()
    .flatMap((key) => {
      const value = query[key];
      if (!value) return undefined;
      return value
        .map((value) =>
          !value
            ? undefined
            : `${key}=${
                Array.isArray(value)
                  ? value.map((s) => encodeURIComponent(s)).join(',')
                  : encodeURIComponent(value)
              }`,
        )
        .sort();
    })
    .filter(Boolean)
    .join('&');
}

export const decodeQueryString = (
  queryString: string | null | undefined,
): UrlQuery => {
  const values: UrlQuery = {};
  if (queryString) {
    const pairs = queryString.split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      if (key && value) (values[key] ??= []).push(decodeURIComponent(value));
    }
  }
  return values;
};
