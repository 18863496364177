import {
  EmailButton,
  FacebookButton,
  Heading,
  PinterestButton,
  SharingButtons,
  TwitterButton,
  WithLayout,
  createView,
  getAssetUrl,
  getI18n,
  session,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { PhMapPin } from '@donkeyjs/phosphor-icons';
import { map, meta, store, type DataNode } from '@donkeyjs/proxy';
import { fullName } from '../../helpers/fullName';
import { I18nIL } from '../../i18n';
import { ImageBox } from '../components/ImageBox';
import styles from './ViewCourseOffering.module.css';
import { CourseSidebar } from './components/CourseSidebar';
import { CourseTeacher } from './components/CourseTeacher';

const facebookAppId = '1788685141298012';

export const ViewCourseOffering = createView<DataSchema, 'CourseOffering'>(
  {
    name: () => 'Default course offering view',
    enableAsBlock: true,
    groupings: [
      {
        key: 'schedule',
        name: () => 'Scheduled / Self-paced',
        format(node, i18n) {
          return node.isOnline
            ? {
                name: i18n.get(I18nIL, 'Course.SelfPaced') || '',
                sortIndex: 0,
              }
            : {
                name: i18n.get(I18nIL, 'Course.Scheduled') || '',
                sortIndex: 1,
              };
        },
      },
    ],
  },

  function ViewCourseOffering(props) {
    const i18n = getI18n();

    const state = store({
      get imageUrl() {
        return props.node.images[0] && getAssetUrl(props.node.images[0].file);
      },
      get isOpenForRegistrations() {
        return props.node.status === 'OPEN';
      },
      get isSelected() {
        return !!props.selected;
      },
      get isPartner() {
        return !!props.node.course.partner && !meta(props.node).isLoading;
      },
      get venues() {
        const days = props.node.days;
        if (!days) return [];
        const venues = days.map((day) => day.place!).filter((v) => v);
        return Array.from(new Set(venues)).filter((v) => !!v);
      },
    });

    return () =>
      !props.node.course ? (
        <WithLayout set="html">
          <props.node.$.course mode="select" />
        </WithLayout>
      ) : (
        <ImageBox
          class={bind(() => ({
            selected: props.selected,
            [styles.online]: props.node.isOnline,
            [styles.partner]: state.isPartner,
          }))}
          item={bind(() => props.node)}
          linkTo={bind(() =>
            state.isSelected
              ? { route: 'current' }
              : { onRoute: 'current', node: props.node },
          )}
          smallImage={bind(() => !!props.node.isOnline && !props.selected)}
          tinted={bind(() => !!props.node.isOnline)}
          grayed={bind(() => !!state.isPartner)}
          showPartnerCorner={bind(() => !!state.isPartner)}
          onmount={props.onmount}
          header={
            <>
              <div class={styles.heading}>
                <Heading styleAs="subtitle">
                  <a
                    href={bind(() =>
                      session.router.getPath(
                        state.isSelected
                          ? { route: 'current' }
                          : { node: props.node },
                      ),
                    )}
                  >
                    {() =>
                      !!props.node.isOnline &&
                      !props.selected &&
                      !!props.node.course.teachers.length && (
                        <>
                          {props.node.course.teachers
                            .map((teacher) => fullName(teacher.teacher))
                            .join(', ')}
                          :{' '}
                        </>
                      )
                    }
                    <props.node.course.$.name readonly />
                  </a>
                </Heading>
                {() =>
                  state.isOpenForRegistrations ? (
                    props.node.recommended && (
                      <span class={[styles.tag, styles.gold]}>
                        {() =>
                          i18n.getFieldName('CourseOffering', 'recommended')
                        }
                      </span>
                    )
                  ) : (
                    <span class={styles.tag}>
                      {() =>
                        i18n.getEnumValue('CourseStatus', props.node.status)
                      }
                    </span>
                  )
                }
              </div>
              {() =>
                state.venues.map((venue) => (
                  <div class={styles.venue}>
                    {() => !props.node.isOnline && <PhMapPin weight="fill" />}
                    <span>
                      {[venue.name, venue.city].filter((s) => !!s).join(', ')}
                    </span>
                  </div>
                ))
              }
            </>
          }
          sidebar={
            <>
              {() =>
                (!props.node.isOnline || props.selected) && (
                  <div class={styles.teachers}>
                    {map(
                      () => props.node.course.teachers,
                      (rel: DataNode<DataSchema, 'CourseTeacher'>) => (
                        <CourseTeacher teacher={rel.teacher} />
                      ),
                    )}
                  </div>
                )
              }
              <CourseSidebar
                offering={bind(() => props.node)}
                canRegister={bind(() => state.isOpenForRegistrations)}
                isSelected={bind(() => state.isSelected)}
                venues={bind(() => state.venues)}
              />
            </>
          }
        >
          {() =>
            props.node.isOnline && !props.selected ? null : (
              <div class={styles.content}>
                <props.node.$.description />
                {() =>
                  props.selected && (
                    <div class={styles.detailContent}>
                      <props.node.$.moreInfo />
                    </div>
                  )
                }
                <div class={styles.sharing}>
                  <SharingButtons
                    message={bind(() => props.node.course.name)}
                    url={bind(() =>
                      session.router.prependPathWithHostname(
                        session.router.getPath({ node: props.node }),
                      ),
                    )}
                    options={bind(() =>
                      [
                        TwitterButton,
                        FacebookButton(facebookAppId),
                        PinterestButton(state.imageUrl),
                        EmailButton,
                      ].filter(Boolean),
                    )}
                  />
                </div>
              </div>
            )
          }
        </ImageBox>
      );
  },
);
