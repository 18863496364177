import { live } from '@donkeyjs/jsx-runtime';
import { setLayout } from './layoutContext';
import type { Layout as LayoutType } from './layouts';

export const withLayout = (
  value: LayoutType | `${LayoutType}:flow`,
  children?: JSX.Children,
) => <WithLayout set={value}>{children}</WithLayout>;

interface WithLayoutProps {
  set: LayoutType | `${LayoutType}:flow`;
  children?: JSX.Children;
}

export function WithLayout(props: WithLayoutProps) {
  live(() => {
    setLayout(props.set);
  });

  return props.children;
}
