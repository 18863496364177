import type { I18nLibrary } from '@donkeyjs/core';
import { bind } from '@donkeyjs/jsx-runtime';
import {
  CustomError,
  map,
  meta,
  store,
  type DataError,
  type Node,
  type ValidationError,
} from '@donkeyjs/proxy';
import { getI18n, type I18nContext } from '../../i18n/getI18n';
import { getTheme } from '../../styles';

export interface ErrorsProps<T extends Node> {
  readonly node?: T | null;
  readonly additionalErrors?: Error[];
  readonly useUserCulture?: boolean;
  readonly i18n?: I18nLibrary;
  readonly class?: JSX.ClassNames;
}

export function Errors<T extends Node>(props: ErrorsProps<T>) {
  const i18n = getI18n(props.useUserCulture);
  const user = { isSysAdmin: false }; // getUserContext();
  const theme = getTheme();

  const state = store({
    get errors() {
      return [
        ...(meta(props.node)?.errors || []),
        ...(props.additionalErrors || []),
      ];
    },
  });

  const errors = map(
    () => state.errors,
    (error) =>
      'field' in error &&
      !meta(props.node)?.getField(error.field as any)?.touched ? null : (
        <p>
          {errorToString(props.node as Node | null | undefined, error, {
            i18n,
            library: props.i18n,
            asAdmin: user.isSysAdmin,
          })}
        </p>
      ),
  );

  return () =>
    errors().filter(Boolean).length ? (
      <div class={bind(() => [theme.class.boxError, props.class])}>
        {errors()}
      </div>
    ) : null;
}

const errorToString = (
  node: Node | null | undefined,
  error: ValidationError | DataError | Error,
  {
    i18n,
    library,
    asAdmin,
  }: {
    asAdmin?: boolean;
    library?: I18nLibrary;
    i18n: I18nContext;
  },
): string => {
  let value: string | undefined;

  if ('code' in error) {
    const validation =
      node && 'field' in error ? i18n.getError(error.code) : undefined;
    if (validation) {
      value = validation;
    } else {
      value = library
        ? i18n.getError(library, error.code)
        : i18n.getError(error.code);
    }
  } else {
    value = error.toString();
  }

  return (
    value ||
    (error instanceof CustomError ? error.message : undefined) ||
    (asAdmin ? `(Sysadmin:) ${error.toString()}` : undefined) ||
    i18n.getError('unexpected') ||
    'An unexpected error occurred.'
  );
};
