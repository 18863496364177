export function isElementVisibleWithinAncestor(element: HTMLElement) {
  let parent = element.parentElement;
  while (parent && parent !== document.body) {
    // Check if the parent is a scroll container
    const overflowY = window.getComputedStyle(parent).overflowY;
    const isScrollContainer = overflowY !== 'visible' && overflowY !== 'hidden';

    if (isScrollContainer) {
      const parentRect = parent.getBoundingClientRect();
      const childRect = element.getBoundingClientRect();

      // Check if the element is within the vertical visible area of the parent
      const isVisible =
        childRect.top < parentRect.bottom && childRect.bottom > parentRect.top;

      if (!isVisible) {
        // Element is not visible within this scroll container
        return false;
      }
    }

    // Move to the next parent
    parent = parent.parentElement;
  }

  // The element is visible, or no scroll container is governing its visibility
  return true;
}
