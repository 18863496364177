import { bind } from '@donkeyjs/jsx-runtime';
import type { FieldRenderProps } from '../..';
import { File } from './File';

export function InlineFile(props: FieldRenderProps<'File'>) {
  return (
    <File
      value={bind(
        () => props.field.value,
        (value) => {
          props.field.value = value;
        },
      )}
      ratio={bind(() => props.ratio)}
      readonly={bind(() => props.readonly)}
      onmount={bind(() => props.onmount)}
    />
  );
}
