import { bind } from '@donkeyjs/jsx-runtime';
import type { DataNode } from '@donkeyjs/proxy';
import { Image } from '../../../components/Image';

interface FileProps {
  value: DataNode<DataSchema, 'File'> | null | undefined;
  onTest?: (
    file: DataNode<DataSchema, 'File'> | null | undefined,
  ) => (() => void) | void;
  ratio?: number | 'adaptive';
  readonly?: boolean;
  testing?: boolean;
  changeOnClick?: boolean | 'button';
  class?: JSX.ClassNames;
  style?: Record<string, any>;
  onmount?: JSX.OnMount<HTMLSpanElement>;
}

export function File(props: FileProps) {
  const handleSelect = (file: DataNode<DataSchema, 'File'>) => {
    props.value = file;
  };

  const result = (
    <Image
      file={bind(() => props.value)}
      class={bind(() => props.class)}
      style={bind(() => props.style)}
      onTest={props.onTest}
      onChange={bind(() => (!props.readonly ? handleSelect : undefined))}
      changeOnClick={bind(() => props.changeOnClick)}
      testing={bind(() => props.testing)}
      ratio={bind(() => props.ratio)}
    />
  );
  return props.onmount ? <span onmount={props.onmount}>{result}</span> : result;
}
