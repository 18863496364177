import { WithLayout, getTheme } from '@donkeyjs/client';
import { preventDefault } from '@donkeyjs/jsx-runtime';
import { type DataNode, meta } from '@donkeyjs/proxy';

interface AddressFormProps {
  address: DataNode<DataSchema, 'Address'>;
  enableCompanyFields?: boolean;
}

interface OrderAddressFormProps {
  order: DataNode<DataSchema, 'Order'>;
  address: 'shipping' | 'billing';
  includeEmail?: boolean;
  enableCompanyFields?: boolean;
}

export function validateAddressForm(
  props: AddressFormProps | OrderAddressFormProps,
) {
  const node = 'order' in props ? props.order : props.address;

  const keys =
    node.__typename === 'Order'
      ? [
          `${props.address}FirstName`,
          `${props.address}LastName`,
          `${props.address}AddressLine1`,
          `${props.address}City`,
          `${props.address}CountryCode`,
          `${props.address}PostalCode`,
        ]
      : [
          'firstName',
          'lastName',
          'addressLine1',
          'city',
          'countryCode',
          'postalCode',
        ];
  if ('includeEmail' in props && props.includeEmail) {
    keys.push('email');
  }
  return meta(node).validate(keys as (keyof typeof node)[]);
}

export function AddressForm(props: AddressFormProps | OrderAddressFormProps) {
  const theme = getTheme();

  const node = 'order' in props ? props.order : props.address;

  const key = (name: string) =>
    (node.__typename === 'Order'
      ? `${props.address}${name}`
      : name.charAt(0).toLowerCase() +
        name.slice(1)) as keyof (typeof node)['$'];

  const CompanyName = props.enableCompanyFields
    ? node.$[key('CompanyName')]
    : null;
  const FirstName = node.$[key('FirstName')];
  const MiddleName = node.$[key('MiddleName')];
  const LastName = node.$[key('LastName')];
  const AddressLine1 = node.$[key('AddressLine1')];
  const AddressLine2 = node.$[key('AddressLine2')];
  const City = node.$[key('City')];
  const CountryCode = node.$[key('CountryCode')];
  const PostalCode = node.$[key('PostalCode')];

  return (
    <WithLayout set="html:flow">
      <form onsubmit={preventDefault} class={theme.class.flow}>
        {CompanyName && <CompanyName class="width-full" />}
        <FirstName />
        <MiddleName class="width-xs" />
        <LastName />
        {() =>
          'includeEmail' in props &&
          props.includeEmail && <props.order.$.email class="width-full" />
        }
        <AddressLine1 class="width-full" />
        <AddressLine2 class="width-full" />
        <CountryCode />
        <PostalCode class="width-sm" />
        <City />
      </form>
    </WithLayout>
  );
}
