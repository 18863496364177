import { bind } from '@donkeyjs/jsx-runtime';
import styles from './Toolbar.module.css';

interface ToolbarProps {
  compact?: boolean;
  opaque?: boolean;
  alignRight?: boolean;
  class?: JSX.ClassNames;
  children?: JSX.Children;
}

export function Toolbar(props: ToolbarProps) {
  return (
    <div
      class={bind(() => [
        props.class,
        'toolbar',
        styles.toolbar,
        {
          [styles.compact]: props.compact,
          [styles.opaque]: props.opaque,
          [styles.alignRight]: props.alignRight,
        },
      ])}
    >
      {props.children}
    </div>
  );
}
