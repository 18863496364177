import { onUnmount } from '@donkeyjs/jsx-runtime';
import type { Culture } from '@donkeyjs/proxy';
import debounce from 'debounce';
import { allCommands } from './allCommands';
import { createShortcutsHandler } from './useShortcut';

export interface Commands {
  'common.close': {};
  'common.confirm': {};
  'nav.prevItem': {};
  'nav.nextItem': {};
  'inspect.toggle': {};
}

export type CommandHandler = (target: HTMLElement) => void;

export type Command =
  | {
      name?: string | ((culture: Culture) => string);
      icon?: JSX.Element;
      handler: CommandHandler;
    }
  | CommandHandler;

export type CommandsDefinitions = {
  [command in keyof Commands]?: Command;
};

export type CommandsList = (Omit<Command, 'name'> & {
  key: string;
  name: string;
})[];

const definitions: CommandsDefinitions[] = [];

const updateAllCommands = debounce(() => {
  allCommands.values = definitions.reduce((acc, def) => {
    return Object.assign(acc, def);
  }, {});
}, 0);

export const commands =
  (commands: CommandsDefinitions) => (node: HTMLElement) => {
    // definitions.push(commands);
    // updateAllCommands();

    const keyDownHandler = createShortcutsHandler(commands);
    node.addEventListener('keydown', keyDownHandler);

    return () => node.removeEventListener('keydown', keyDownHandler);
    // definitions.splice(definitions.indexOf(commands), 1);
    // updateAllCommands();
  };

export const useGlobalCommands = (commands: CommandsDefinitions) => {
  definitions.push(commands);
  updateAllCommands();

  onUnmount(() => {
    definitions.splice(definitions.indexOf(commands), 1);
    updateAllCommands();
  });
};
