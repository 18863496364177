import { session } from '@donkeyjs/client';
import { getGlobal, getRenderPlugin, onMount } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';

const contextKey = Symbol('taskbar');

export const getToolbars = () =>
  getGlobal<{ toolbars: JSX.Children[] }>(contextKey, () =>
    store({ toolbars: [] }),
  );

export const useToolbar = (children: JSX.Children) => {
  if (session.dom.ssr) return;
  if (getRenderPlugin()) return;

  const context = getToolbars();

  onMount(() => {
    context.toolbars = [...context.toolbars, children];

    return () => {
      context.toolbars = context.toolbars.filter((t) => t !== children);
    };
  });
};
