import type { CouponFields, OrderItemParser } from '@donkeyjs/module-shopping';
import {
  meta,
  type DataNode,
  type NodeFieldsFromFragment,
} from '@donkeyjs/proxy';

type CourseCouponFields = CouponFields &
  NodeFieldsFromFragment<
    DataSchema,
    'Coupon',
    {
      courseOption: {
        id: true;
      };
    }
  >;

export const CourseOrderItemParser: OrderItemParser<
  DataNode<DataSchema, 'OrderItem'>
> = {
  selection: {
    course: {
      selection: {
        id: true,
        name: true,
        earliestPaymentAt: true,
        discounts: {
          selection: {
            id: true,
            name: true,
            amount: true,
            validFrom: true,
            validUntil: true,
            isPercentage: true,
          },
        },
        offering: {
          selection: {
            id: true,
            course: {
              selection: {
                id: true,
                name: true,
              },
            },
            images: {
              selection: {
                id: true,
                file: {
                  selection: {
                    id: true,
                    fileType: true,
                    fileExtension: true,
                    name: true,
                  },
                },
              },
            },
            options: {
              selection: {
                name: true,
                id: true,
                earliestPaymentAt: true,
                paymentSchemes: {
                  selection: {
                    id: true,
                    name: true,
                    scheme: true,
                    numberOfInstallments: true,
                    frequency: true,
                    endDate: true,
                  },
                },
              },
            },
          },
        },
      },
    },
  },

  couponSelection: {
    courseOption: {
      selection: {
        id: true,
      },
    },
  },

  couponNeedsValidation(coupon: CourseCouponFields) {
    return !!coupon.courseOption;
  },

  parse(item) {
    return item.course
      ? {
          key: 'course',
          get orderable() {
            return item.course!;
          },
          set orderable(orderable) {
            item.course = orderable;
          },
          get orderables() {
            meta(item.course?.offering.options)?.request({ price: true });
            return (
              item.course?.offering.options?.map((item) => ({
                item,
                name: item.name,
              })) || []
            );
          },
          validateCoupon(coupon: CourseCouponFields) {
            return coupon.courseOption?.id === item.course?.id;
          },
          discounts: item.course.discounts,
          paymentSchemes: item.course.paymentSchemes,
          earliestPaymentAt: item.course.earliestPaymentAt,
          name: item.course?.offering.course.name || 'Unknown',
          option: item.course?.name,
          image: item.course?.offering.images[0],
          maxQuantity: item.course ? 1 : undefined,
        }
      : null;
  },
};
