import {
  getContext,
  setContext,
  type ComponentImplementation,
} from '../component';
import type { RenderContext } from './mount';

export const onError = (
  context: RenderContext,
  element: JSX.Element | ComponentImplementation<any>,
  error: unknown,
) => {
  const onError = context.context[key];
  (async () => {})().then(() => {
    if (onError) onError(error as Error);
    globalErrorHandler?.(error as Error);
  });
  console.warn('Could not mount', element);
  console.error(error);
};

const key = Symbol('error');

export const getOnError = () =>
  getContext<(error: Error) => void | undefined>(key);

export const setOnError = (fn: (error: Error) => void) => {
  setContext(key, fn);
};

let globalErrorHandler: ((error: Error) => void) | undefined;
export const registerGlobalErrorHandler = (fn: (error: Error) => void) => {
  globalErrorHandler = fn;
};
