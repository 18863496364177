import type { ShortcutDefinitions } from './useShortcut';

export const defaultShortcuts: ShortcutDefinitions = {
  ArrowDown: 'nav.nextItem',
  ArrowUp: 'nav.prevItem',
  Escape: 'common.close',
  Enter: 'common.confirm',
  'Ctrl+Shift+I': 'inspect.toggle',
  // Tab: 'nav.nextItem',
  // 'Shift+Tab': 'nav.prevItem',
};
