import { customAlphabet } from 'nanoid';

export const generateNodeId = customAlphabet(
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
  18,
);

if (typeof window !== 'undefined') {
  (window as any).generateNodeId = generateNodeId;
}
