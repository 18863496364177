import { createI18nLibrary } from '@donkeyjs/core';

export const I18nIL = createI18nLibrary({
  'en-GB': () => import('./library/enGB').then((m) => m.Library),
  nl: () => import('./library/nl').then((m) => m.Library),
});

export const I18nAdmin = createI18nLibrary({
  'en-GB': () => import('./library/enGB').then((m) => m.Admin),
  nl: () => import('./library/nl').then((m) => m.Admin),
});

export const I18nDashboard = createI18nLibrary({
  'en-GB': () => import('./library/enGB').then((m) => m.Dashboard),
  nl: () => import('./library/nl').then((m) => m.Dashboard),
});
