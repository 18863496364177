import { live } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import { addHours } from 'date-fns';
import type { FieldRenderProps } from '../..';
import { session } from '../../../session';
import { useCalendarPopup } from './useCalendarPopup';
import { useDateComponent } from './useDateComponent';

export type DateEditor = ReturnType<typeof useDateEditor>;

export const useDateEditor = (props: FieldRenderProps<'date'>) => {
  const base = useDateComponent(props);

  const calendar = useCalendarPopup(
    store({
      get date() {
        return props.field.value || new Date();
      },
      set date(value) {
        props.field.value = value;
      },
      get includeTime() {
        return !value.fullDay;
      },
      shadow: true,
    }),
  );

  const value = store({
    isRange: base.isRange,
    isRangeStart: base.isRangeStart,
    isRangeEnd: base.isRangeEnd,
    hasTimeZone: base.hasTimeZone,
    hasFullDayToggle: base.hasFullDayToggle,
    fullDayField: base.fullDayField,
    timeZoneField: base.timeZoneField,
    rangeToField: base.rangeToField,

    calendar,

    get node() {
      return props.field.parent?.node;
    },

    get fullDay() {
      return base.fullDay;
    },

    set fullDay(value) {
      if (base.fullDayField && props.field.parent) {
        (props.field.parent.node as any)[base.fullDayField] = value;
      }
    },

    get timeZone(): string | null | undefined {
      return base.timeZoneField
        ? ((props.field.parent?.node as any)[base.timeZoneField] as
            | string
            | null
            | undefined)
        : undefined;
    },

    set timeZone(value: string | null | undefined) {
      if (base.timeZoneField && props.field.parent) {
        (props.field.parent.node as any)[base.timeZoneField] = value;
      }
    },

    get rangeEnds(): Date | null | undefined {
      return base.rangeEnds;
    },

    set rangeEnds(value: Date | null | undefined) {
      if (base.rangeToField && props.field.parent) {
        (props.field.parent.node as any)[base.rangeToField] = value;
      }
    },

    get rangeEndsLoading() {
      return base.rangeEndsLoading;
    },

    get rangeIsCollapsed() {
      return base.rangeIsCollapsed;
    },

    addEnds() {
      value.rangeEnds = addHours(
        value.rangeEnds ?? props.field.value ?? new Date(),
        1,
      );
    },

    removeEnds() {
      value.rangeEnds = props.field.value;
    },
  });

  let last = props.field.value;
  live(() => {
    if (session.dom.ssr || !value.isRangeStart || props.field.loading) return;

    const current = props.field.value;
    if (
      current &&
      !props.field.loading &&
      !value.rangeEndsLoading &&
      (!value.rangeEnds ||
        value.rangeEnds.getTime() < current.getTime() ||
        current.getTime() !== last?.getTime())
    ) {
      last = current;
      value.rangeEnds = current;
    }
  });

  live(() => {
    if (
      !session.dom.ssr &&
      value.hasTimeZone &&
      !props.field.loading &&
      !value.timeZone
    ) {
      value.timeZone = session.app.tz?.defaultTimeZone ?? 'Europe/Amsterdam';
    }
  });

  return value;
};
