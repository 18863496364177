import { Each, getI18n, text } from '@donkeyjs/client';
import { formatMoney, type OrderableInfo } from '@donkeyjs/module-shopping';
import { I18nIL } from '../../../i18n';
import styles from './ShopOption.module.css';

interface ShopOptionProps {
  option: OrderableInfo;
}

export function ShopOption(props: ShopOptionProps) {
  const i18n = getI18n();

  return (
    <>
      <span class={styles.shopOption}>
        <span class={styles.name}>
          <props.option.node.$.name />
        </span>
        {() =>
          props.option.reducedPrice !== props.option.originalPrice && (
            <span class={styles.originalPrice}>
              {() =>
                formatMoney(
                  i18n,
                  props.option.originalPrice,
                  props.option.node.currency,
                )
              }
            </span>
          )
        }
        <span class={styles.price}>
          {() =>
            formatMoney(
              i18n,
              props.option.reducedPrice,
              props.option.node.currency,
            )
          }
        </span>
      </span>
      {() =>
        (props.option.discounts.length > 0 ||
          props.option.availableInstallments.length > 0) && (
          <div class={styles.details}>
            {() =>
              props.option.availableInstallments.length > 0 && (
                <div class={styles.detail}>
                  {() => text(I18nIL, 'Course.InstallmentsAvailable')}
                </div>
              )
            }
            <Each
              of={props.option.discounts}
              render={(discount) => (
                <div class={[styles.detail, styles.discount]}>
                  {() =>
                    discount.node.validUntil
                      ? text(
                          I18nIL,
                          'Course.DiscountExpires',
                          {},
                          {
                            name: discount.node.name,
                            expiresAt: i18n.formatDate(
                              discount.node.validUntil,
                              'PPP',
                            ),
                          },
                        )
                      : discount.node.name
                  }
                </div>
              )}
            />
          </div>
        )
      }
    </>
  );
}
