import type { IconProps } from '../types';


export function PhCaretLeft(props: IconProps): JSX.Element { 
  return <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 256 256"
    >
      {props.children}
    <rect width="256" height="256" fill="none" />
  {(props.weight || 'regular') === 'bold' && <><path d="M168.49,199.51a12,12,0,0,1-17,17l-80-80a12,12,0,0,1,0-17l80-80a12,12,0,0,1,17,17L97,128Z"/></>}{(props.weight || 'regular') === 'duotone' && <><path d="M160,48V208L80,128Z" opacity="0.2"/><path d="M163.06,40.61a8,8,0,0,0-8.72,1.73l-80,80a8,8,0,0,0,0,11.32l80,80A8,8,0,0,0,168,208V48A8,8,0,0,0,163.06,40.61ZM152,188.69,91.31,128,152,67.31Z"/></>}{(props.weight || 'regular') === 'fill' && <><path d="M168,48V208a8,8,0,0,1-13.66,5.66l-80-80a8,8,0,0,1,0-11.32l80-80A8,8,0,0,1,168,48Z"/></>}{(props.weight || 'regular') === 'light' && <><path d="M164.24,203.76a6,6,0,1,1-8.48,8.48l-80-80a6,6,0,0,1,0-8.48l80-80a6,6,0,0,1,8.48,8.48L88.49,128Z"/></>}{(props.weight || 'regular') === 'thin' && <><path d="M162.83,205.17a4,4,0,0,1-5.66,5.66l-80-80a4,4,0,0,1,0-5.66l80-80a4,4,0,1,1,5.66,5.66L85.66,128Z"/></>}{(props.weight || 'regular') === 'regular' && <><path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"/></>}
  </svg>
}