import { getContext, setContext } from '@donkeyjs/jsx-runtime';

interface UserZoneProps {
  children?: JSX.Children;
}

const key = Symbol('UserZone');

export function useUserZone() {
  return !!getContext(key);
}

export function UserZone(props: UserZoneProps) {
  setContext(key, true);

  return props.children;
}
