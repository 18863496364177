import { watch } from '@donkeyjs/proxy';
import { useChangelog } from '../../useChangelog';

export function attachChangelog(element: string) {
  const changelog = useChangelog();

  return (node: HTMLElement) => {
    return watch(() => {
      const items = changelog?.groupedByElement.get(element);
      if (items?.length) {
        changelog!.schedule(node, items || []);
      }
    }).dispose;
  };
}
