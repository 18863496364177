import { mount } from '@donkeyjs/jsx-runtime';
import { setLayout } from '../../layout';
import { session } from '../../session';
import { Dialog, type DialogProps } from './Dialog';

export const showDialog = (
  children: JSX.Children,
  options?: Omit<DialogProps, 'children'>,
): Promise<boolean> & { close: () => void } => {
  setLayout('html:flow');

  let close: () => void;

  const result = new Promise<boolean>((resolve) => {
    [close] = mount(
      session.dom,
      Dialog,
      {
        ...options,
        onClose(confirm?: boolean) {
          close();
          resolve(!!confirm);
        },
      },
      Array.isArray(children) ? children : [children],
      document.body,
    );
  }) as Promise<boolean> & { close: () => void };

  result.close = () => {
    close?.();
  };

  return result;
};
