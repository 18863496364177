import { jsxx } from '@donkeyjs/jsx-runtime';
import { isDataNode, store, type DataList } from '@donkeyjs/proxy';
import type { FieldRenderProps, LayoutRenderer } from '../..';
import { isNodeType, session } from '../../../session';

export function LayoutJson(layoutRenderer: () => LayoutRenderer<any>) {
  return function LayoutJson(props: FieldRenderProps<'json'>) {
    const layout = layoutRenderer();

    let ref: string | undefined;
    let nodes: DataList<DataSchema> | undefined;

    let _childProps: FieldRenderProps<'json'> | undefined;
    const childProps = (): FieldRenderProps<'json'> =>
      (_childProps ??= store.clone(props, {
        ...props.props,
        field: {
          ...props.field,
          schema: {
            ...props.field.schema,
            optional: !!props.optional,
            type: props.type,
          },
          get value() {
            const result = (props.field.value as any)?.[props.key!];
            if (result && isNodeType(props.type)) {
              const current = `${props.type}:${result}`;
              if (current !== ref) {
                nodes = undefined;
              }
              ref = current;
              return (nodes ??= session.data.useNodes({
                typename: props.type,
                drafts: false,
                ids: [result],
              }))[0];
            }
            return result;
          },
          set value(value: any) {
            props.field.value = {
              ...(props.field.value as any),
              [props.key!]: isDataNode(value) ? value.id : value || undefined,
            };
          },
        },
      }));

    return () => {
      const type = props.type || props.field.schema?.jsonType;
      if (!type) return null;

      const field =
        layout.fields[type] ||
        (isNodeType(props.type) ? layout.fields.node : undefined);

      return field && jsxx(field, props.key ? childProps() : props);
    };
  };
}
