import { createView } from '@donkeyjs/client';
import { updateComponent } from '@donkeyjs/jsx-runtime';
import styles from './ViewTestimonial.module.css';

export const ViewTestimonal = createView<DataSchema, 'Testimonial'>(
  {
    name: () => 'Testimonial',
    enableAsBlock: true,
    renderDataContainer(_, children) {
      return <Wrapper>{children}</Wrapper>;
    },
  },

  function ViewTestimonal(props) {
    return (
      <blockquote class={styles.testimonial} onmount={props.onmount}>
        <div class={styles.text}>
          <p>
            <props.node.$.text />
          </p>
          <div class={styles.stars}>
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </div>
        </div>
        <footer class={styles.footer}>
          <span class={styles.author}>
            <props.node.$.author />
          </span>
          <span class={styles.capacity}>
            <props.node.$.capacity />
          </span>
        </footer>
      </blockquote>
    );
  },
);

interface WrapperProps {
  children?: JSX.Children;
}

function Wrapper(props: WrapperProps) {
  return <div class={styles.wrapper}>{props.children}</div>;
}

function Star() {
  return (
    <svg
      class={styles.star}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      title="Star"
      aria-label="Star"
      role="img"
      fill="#e7ddc6"
    >
      <path d="M30.336 12.547l-10.172-1.074L16 2.133l-4.164 9.34-10.172 1.074 7.598 6.848L7.14 29.398 16 24.29l8.86 5.11-2.122-10.004z" />
    </svg>
  );
}

if (import.meta.hot) {
  import.meta.hot.accept((hot) => {
    updateComponent(Wrapper, hot?.Wrapper);
    updateComponent(
      ViewTestimonal.component as any,
      hot?.ViewTestimonal.component,
    );
  });
}
