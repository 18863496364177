import { jsxx } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { GoogleMap } from './GoogleMap';

export function InlinePlace(props: FieldRenderProps<'Place'>) {
  return jsxx(
    GoogleMap,
    store.clone(props, {
      get googlePlacesId() {
        return props.field.value?.googlePlacesId;
      },
    }),
  );
}
