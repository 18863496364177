import { jsxx } from '@donkeyjs/jsx-runtime';
import { bind, meta } from '@donkeyjs/proxy';
import type { BlockProps } from '..';
import { useDataContext } from '../data/DataContext';
import { getI18n } from '../i18n/getI18n';
import { getLayoutContext, setLayout } from '../layout/layoutContext';
import styles from './NodeFieldBlock.module.css';

export interface NodeFieldBlockProps {
  layout?: 'html' | 'inline';
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

export function NodeFieldBlock(props: BlockProps<NodeFieldBlockProps>) {
  return () => jsxx(NodeField, props);
}

function NodeField(props: BlockProps<NodeFieldBlockProps>) {
  const layoutContext = getLayoutContext();
  const context = useDataContext();
  const i18n = getI18n(true);

  if (props.layout) {
    setLayout(
      [props.layout, layoutContext.wrap].filter(Boolean).join(':') as any,
    );
  }

  return () => {
    const field = props.block?.type?.split(':')[1];
    const [typename, fieldName] = field?.split('.') ?? [];
    if (!typename || !fieldName) return null;

    const node = context?.findTypename(typename);
    const Field =
      props.readonly || !meta(node?.node)?.isLoading
        ? (node?.node?.$ as any)?.[fieldName]
        : undefined;

    if (Field)
      return (
        <div
          contenteditable="false"
          onmount={props.onmount}
          class={bind(() => props.class)}
        >
          <Field
            class={bind(() => (props.width ? `width-${props.width}` : ''))}
          />
        </div>
      );

    if (props.readonly) return null;

    return (
      <div
        onmount={props.onmount}
        class={bind(() => [props.class, styles.template])}
      >
        {i18n.getFieldName(typename as any, fieldName)}
      </div>
    );
  };
}
