import { PhDatabase, type Icon } from '@donkeyjs/phosphor-icons';
import type { Node } from '@donkeyjs/proxy';
import { session } from '../session';

export const getNodeIcon = (node: Node | string): Icon => {
  const meta =
    session.app.clientSchemaMeta?.[
      (typeof node === 'string'
        ? node
        : node.__typename) as keyof typeof session.app.clientSchemaMeta
    ];
  return meta?.icon?.(node as any) || PhDatabase;
};
