import {
  batch,
  computed,
  effect,
  signal,
  untracked,
  type ReadonlySignal,
  type Signal,
} from '@preact/signals-core';
import { isBinding, type Binding } from './bind';

export const watch = (
  fn: (initial: boolean) => ((final?: boolean) => void) | void,
): { dispose: () => void; clearInvalidations: () => void } => {
  let initial = true;
  return {
    dispose: effect(() => {
      const result = fn(initial);
      initial = false;
      return result;
    }),
    clearInvalidations: () => {},
  };
};

export function dontWatch<T>(fn: () => T): T {
  return untracked(fn);
}

// export function memo<T>(fn: () => T): T {
//   return computed(fn);
// }

type StoreFn = (<T extends object>(
  value: T,
  plugin?: StoreOptions<T>,
) => Store<T>) & {
  clone: <T extends object, S extends object>(
    store: T,
    add: S,
  ) => Omit<T, keyof S> & S;
  assign: <T extends object, S extends object>(
    store: T,
    add: S,
  ) => Omit<T, keyof S> & S;
  replace: <T extends object>(store: T, source: T) => void;
  debug: <T extends object>(value: T, plugin?: StoreOptions<T>) => T;
  merge: <T1 extends object, T2 extends object>(
    store1: Store<T1>,
    store2: Store<T2>,
  ) => Exclude<T1, keyof T2> & T2;
};

interface StoreFieldValue {
  type: 'value';
  signal: Signal;
}

interface StoreFieldAccessor {
  type: 'accessor';
  signal: ReadonlySignal;
  set?: (value: any) => void;
}

interface StoreFieldBinding {
  type: 'binding';
  binding: Binding;
}

type StoreField = StoreFieldValue | StoreFieldAccessor | StoreFieldBinding;

export interface StoreOptions<T extends object> {
  name?: string;
  afterGet?: <Key extends keyof T>(
    source: T,
    key: Key,
    value: unknown,
  ) => unknown;
  afterSet?: <Key extends keyof T>(
    source: T,
    key: Key,
    value: unknown,
  ) => unknown;
  beforeGet?: <Key extends keyof T>(source: T, key: Key) => unknown;
  beforeSet?: <Key extends keyof T>(
    source: T,
    key: Key,
    value: unknown,
  ) => unknown;
}

export type Store<T extends object> = T & {
  $: {
    peek<Key extends string | symbol | number>(
      key: Key,
    ): Key extends keyof T ? T[Key] : any;
    source: T;
  };
};

export function getWatchState() {}

export const store = (<T extends object>(
  source: T,
  options?: StoreOptions<T>,
): Store<T> => {
  if (isStore(source)) return source as Store<T>;

  const input: Record<string | symbol, any> = Object.defineProperties(
    {},
    Object.getOwnPropertyDescriptors(source),
  );

  const keys = signal<(string | symbol)[]>(Reflect.ownKeys(input));
  const fields = new Map<string | number | symbol, Signal<StoreField>>();

  function ensureField(
    target: T,
    key: string | number | symbol,
    override?: boolean,
  ): StoreField {
    const field = fields.get(key);
    let fieldValue = field?.value;

    if (!fieldValue || override) {
      const initial = Object.getOwnPropertyDescriptor(input, key);
      if (initial?.get) {
        fieldValue = {
          type: 'accessor',
          signal: computed(() => initial.get?.call(target)),
          set: initial.set,
        };
      } else if (isBinding(initial?.value)) {
        fieldValue = {
          type: 'binding',
          binding: initial.value,
        };
      } else {
        fieldValue = {
          type: 'value',
          signal: signal(initial?.value),
        };
      }
      if (override && field) {
        field.value = fieldValue;
      } else {
        fields.set(key, signal(fieldValue));
      }
    }

    return fieldValue;
  }

  function getField(target: T, key: string | number | symbol): any {
    const field = ensureField(target, key);
    return 'binding' in field ? field.binding.$value : field.signal.value;
  }

  const meta = {
    source: input,
    peek(key: keyof T) {
      return dontWatch(() => {
        const field = ensureField(input as T, key);

        return field.type === 'binding'
          ? field.binding.$value
          : field.signal.value;
      });
    },
  };

  function get(target: T, key: string | number | symbol, receiver: any) {
    if (key === 'constructor') return target.constructor;
    if (key === '__store') return true;
    if (key === '$') return meta;
    if (key === '__clone') {
      return (add: any) => {
        const source = {};
        const addValues = isStore(add) ? add.$.source : add;
        for (const key in input) {
          if (!(key in addValues)) {
            Object.defineProperty(source, key, {
              get() {
                return (result as any)[key];
              },
              set(value) {
                (result as any)[key] = value;
              },
              configurable: true,
              enumerable: true,
            });
          }
        }
        Object.defineProperties(source, {
          ...Object.getOwnPropertyDescriptors(addValues),
        });
        return store<any>(source, options);
      };
    }
    if (key === '__assign') {
      return (add: any) => {
        batch(() => {
          dontWatch(() => {
            const source = isStore(add) ? add.$.source : add;
            Object.defineProperties(
              input,
              Object.getOwnPropertyDescriptors(source),
            );
            for (const key in source) {
              ensureField(receiver, key as keyof T, true);
            }
          });
        });
      };
    }

    if (options?.beforeGet) {
      const beforeGetResult = options.beforeGet(target, key as keyof T);
      if (beforeGetResult !== undefined) return beforeGetResult;
    }

    let value = getField(receiver, key);

    if (options?.afterGet) {
      const afterGetResult = options.afterGet(target, key as keyof T, value);
      if (afterGetResult !== undefined) value = afterGetResult;
    }

    return value;
  }

  const set = (
    target: T,
    key: string | number | symbol,
    newValueInput: any,
    receiver: any,
    _isDescriptor?: boolean,
  ) => {
    return untracked(() => {
      let newValue = newValueInput;

      if (options?.beforeSet) {
        const beforeSetResult = options.beforeSet!(
          target,
          key as keyof T,
          newValue,
        );
        if (beforeSetResult !== undefined) newValue = beforeSetResult;
      }

      const field = ensureField(receiver, key);

      if (field.type !== 'value' || !equals(field.signal.peek(), newValue)) {
        if (field.type === 'accessor') {
          if (!field.set) return false;
          field.set!.call(receiver, newValue);
        } else if (field.type === 'binding') {
          field.binding.$value = newValue;
        } else if (field.type === 'value') {
          input[key] = newValue;
          field.signal.value = newValue;
        } else {
          input[key] = newValue;
          fields.get(key)!.value = {
            type: 'value',
            signal: signal(newValue),
          };
        }
        options?.afterSet?.(target, key as keyof T, newValue);
      }

      const newKey = typeof key === 'number' ? key.toString() : key;
      if (!keys.peek().includes(newKey)) {
        keys.value = [...keys.peek(), newKey];
      }

      return true;
    });
  };

  const result = new Proxy<Store<T>>(input as Store<T>, {
    get,
    set: (target, key, newValue, receiver) => {
      return set(target, key, newValue, receiver);
    },
    deleteProperty(_, p) {
      const newValue: StoreField = { type: 'value', signal: signal(undefined) };
      if (fields.has(p)) {
        fields.get(p)!.value = newValue;
      } else {
        fields.set(p, signal(newValue));
      }
      if (keys.peek().includes(p)) {
        keys.value = keys.peek().filter((key) => key !== p);
      }
      return true;
    },
    has(_, p) {
      return keys.value.includes(p);
    },
    getOwnPropertyDescriptor() {
      return {
        enumerable: true,
        configurable: true,
      };
    },
    ownKeys() {
      return keys.value;
    },
  });

  return result;
}) as StoreFn;

const equals = (a: any, b: any) => {
  if (a === b) return true;
  if (typeof a !== typeof b) return false;
  if (a === null || b === null) return false;
  if (typeof a !== 'object') return false;
  if (isStore<{}>(a) || isStore<{}>(b)) return false;
  if (a instanceof Date && b instanceof Date)
    return a.getTime() === b.getTime();
  if (a instanceof RegExp && b instanceof RegExp)
    return a.toString() === b.toString();
  if (a instanceof RegExp || b instanceof RegExp) return false;
  // if (Array.isArray(a) !== Array.isArray(b)) return false;
  // if (Array.isArray(a)) {
  //   if (a.length !== b.length) return false;
  //   for (let i = 0; i < a.length; i++) {
  //     if (!equals(a[i], b[i])) return false;
  //   }
  //   return true;
  // }
  return false;
};

store.assign = <T extends object, S extends object>(
  store: T,
  add: S,
): Omit<T, keyof S> & S => {
  return (store as any).__assign(add);
};

store.replace = <T extends object>(store: T, source: T): void => {
  batch(() => {
    for (const key in store) {
      if (!(key in source)) {
        delete store[key];
      }
    }
    for (const key in source) {
      store[key] = source[key];
    }
  });
};

store.clone = <T extends object, S extends object>(
  store: T,
  add: S,
): Omit<T, keyof S> & S => {
  return (store as any).__clone(add);
};

store.debug = <T extends object>(value: T, plugin?: StoreOptions<T>) =>
  store(value, {
    ...plugin,
    afterSet(target, key, value) {
      return plugin?.afterSet ? plugin.afterSet(target, key, value) : value;
    },
  });

export const isStore = <T extends object | null | undefined>(
  value: T,
): value is Store<NonNullable<T>> => {
  return !!value && typeof value === 'object' && !!(value as any).__store;
};

export const debugInvalidations = { active: false };

if (typeof window !== 'undefined') {
  (window as any).toggleDebugInvalidations = () => {
    debugInvalidations.active = !debugInvalidations.active;
  };
}
