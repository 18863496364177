export const getScrollParent = (
  node: HTMLElement | Document | null | undefined,
): HTMLElement | Window | null => {
  if (!node) return null;

  const overflowY =
    node instanceof HTMLElement ? window.getComputedStyle(node).overflowY : '';
  const isScrollable =
    !overflowY.includes('visible') && !overflowY.includes('hidden');

  if (isScrollable && node !== document && node !== document.body)
    return node as HTMLElement;

  return getScrollParent(node.parentElement) || window;
};
