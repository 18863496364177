import { bind } from '@donkeyjs/jsx-runtime';
import { store, type DataList } from '@donkeyjs/proxy';
import type { BlockProps } from '.';
import { SlideShow } from '../components/SlideShow';
import type { UseSlideShowSettings } from '../components/useSlideShow';
import { formulaContext, whereFromFilters } from '../data/filters';
import { session } from '../session';
import { View, type ViewType } from '../views';
import styles from './SlideShowBlock.module.css';

export interface SlideShowSettings extends UseSlideShowSettings {
  resolver?: keyof ApplicationSchema['resolvers'];
  where?: Record<string, string>;

  view?: string;
  viewMode?: string;

  showControls?: boolean;
}

export function SlideShowBlock(props: BlockProps<SlideShowSettings>) {
  const slides = session.data.get<any>(props.resolver, {
    get where() {
      return whereFromFilters(props.where, formulaContext(props.block));
    },
    get source() {
      return JSON.stringify({ where: props.where });
    },
  }) as DataList<DataSchema, any>;

  const state = store({
    get typename() {
      return props.resolver && session.app.schema.resolvers[props.resolver];
    },

    get selectedView() {
      return state.typename && props.view
        ? (session.app.views?.[state.typename]?.[
            props.view as keyof typeof session.app.views
          ] as ViewType)
        : undefined;
    },
  });

  return (
    <div
      class={bind(() => [props.class, styles.slideShow])}
      onmount={props.onmount}
    >
      <SlideShow
        {...props}
        autoHeight
        data={slides}
        render={(slide) => {
          const v = state.selectedView;
          return v ? (
            <View
              node={slide}
              view={v}
              mode={
                props.viewMode
                  ? v.modes?.find((m) => m.key === props.viewMode)
                  : undefined
              }
            />
          ) : (
            <div>No view selected</div>
          );
        }}
      />
    </div>
  );
}
