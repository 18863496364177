import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { Images } from '../../../components/Images';
import { Label } from '../../components/Label';
import { HtmlNodeList } from '../nodeList/HtmlNodeList';
import { FileRef } from './FileRef';
import styles from './HtmlFileRef.module.css';

// The max-width is a hack until gallery is implemented

export function HtmlFileRef(props: FieldRenderProps<'FileRef'>) {
  return (
    <Label
      class={'input align-start'}
      label={bind(() => props.label)}
      helper={bind(() => props.helper)}
      onmount={props.onmount}
    >
      <div>
        <div class={styles.file}>
          <FileRef
            value={bind(
              () => props.field.value,
              (value) => {
                props.field.value = value;
              },
            )}
            default={bind(() => props.default)}
            ratio={bind(() => props.ratio)}
            readonly={bind(() => props.readonly)}
            changeOnClick={bind(() => props.changeOnClick)}
            onmount={bind(() => props.onmount)}
          />
        </div>
      </div>
    </Label>
  );
}

export function HtmlFileRefs(props: FieldRenderProps<'FileRefList'>) {
  return () =>
    props.field.schema?.embeddedList ? (
      jsxx(HtmlNodeList, props as FieldRenderProps<'nodeList'>)
    ) : (
      <Label
        class="input align-start"
        label={bind(() => props.label)}
        helper={bind(() => props.helper)}
        onmount={props.onmount}
      >
        <div>
          <div style="max-width: 60px">
            {jsxx(
              Images,
              store.clone(props, {
                get files() {
                  return props.field.value;
                },
                get editable() {
                  return !props.readonly;
                },
                ratio: 1,
                noOutline: true,
              }),
            )}
          </div>
        </div>
      </Label>
    );
}
