import type { Component } from '@donkeyjs/jsx-runtime';
import type { BlockProps, BlocksType } from '.';
import { DataForm } from '../layout/components/DataForm';
import { DataFormStep } from '../layout/components/DataFormStep';
import { ButtonBlock } from './ButtonBlock';
import { CellBlock } from './CellBlock';
import { ColumnsBlock } from './ColumnsBlock';
import { ContactFormBlock } from './ContactFormBlock';
import { DataBlock } from './DataBlock';
import { DirectoryBlock } from './DirectoryBlock';
import { EmbedBlock } from './EmbedBlock';
import { FileBlock } from './FileBlock';
import { LayoutBlock } from './LayoutBlock';
import { LoginFormBlock } from './LoginFormBlock';
import { NodeFieldBlock } from './NodeFieldBlock';
import { QRCodeBlock } from './QRCodeBlock';
import { ReadMoreBlock } from './ReadMoreBlock';
import { SlideShowBlock } from './SlideShowBlock';

export const defaultBlocks: BlocksType = {
  button: { component: ButtonBlock },
  cell: { component: CellBlock },
  columns: { component: ColumnsBlock },
  'contact-form': { component: ContactFormBlock },
  data: { component: DataBlock },
  'data-form': { component: DataForm as Component<BlockProps> },
  'data-form-step': { component: DataFormStep },
  directory: { component: DirectoryBlock },
  embed: { component: EmbedBlock },
  file: { component: FileBlock },
  layout: { component: LayoutBlock },
  'qr-code': { component: QRCodeBlock },
  readmore: { component: ReadMoreBlock },
  'login-form': { component: LoginFormBlock },
  'slide-show': { component: SlideShowBlock },
  'node-field': { component: NodeFieldBlock },
};
