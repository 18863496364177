import { jsxx } from '@donkeyjs/jsx-runtime';
import {
  bind,
  store,
  type DataNode,
  type NodeFieldInfo,
  type NodeTypename,
} from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { getI18n } from '../../../i18n/getI18n';
import { getNumberValue } from '../float/numberHelpers';
import { HtmlStringField } from '../string/HtmlString';
import { Label } from '../../components/Label';
import { Slider } from '../float/Slider';

export function HtmlInt(props: FieldRenderProps<'int'>) {
  const i18n = getI18n();

  if (props.mode === 'slider') {
    return (
      <Label
        class={bind(() => ['input', props.class])}
        label={bind(() => props.label)}
        helper={bind(() => props.helper)}
        onmount={props.onmount}
      >
        <Slider
          value={bind(
            () => props.field.value ?? 0,
            (value) => {
              props.field.value = value;
            },
          )}
          min={props.min ?? 0}
          max={props.max ?? 100}
          step={props.step}
        />
      </Label>
    );
  }

  return jsxx(
    HtmlStringField,
    store.clone(props, {
      inputType: 'number',
      field: {
        ...props.field,
        get value() {
          return getNumberValue(
            props.field.value,
            props.formatter,
            i18n,
            props.readonly,
          );
        },
        set value(value) {
          props.field.value = value === '' ? undefined : Number.parseInt(value);
        },
      } as NodeFieldInfo<
        string,
        DataNode<DataSchema, NodeTypename<DataSchema>>
      >,
    }),
  );
}
