import { bind, stopPropagation } from '@donkeyjs/jsx-runtime';
import { Portal } from '../../helpers';
import { session } from '../../session';
import { getTheme, setTheme } from '../../styles';
import type { Theme } from '../../styles/Theme';
import { Buttons, isButtonsPreset, type ButtonsProps } from '../Buttons';
import styles from './Dialog.module.css';
import { DialogSidebars } from './DialogSidebars';

export interface DialogProps extends Partial<ButtonsProps> {
  size?: 'sm' | 'md' | 'lg' | 'full';
  height?: 'sm' | 'md' | 'lg';
  class?: string;
  leftSidebar?: JSX.Children;
  rightSidebar?: JSX.Children;
  children?: JSX.Children;
  theme?: Theme;
}

export function Dialog(
  props: DialogProps & { onClose: (confirm: boolean) => void },
) {
  const theme = props.theme ? setTheme(props.theme) : getTheme();

  return (
    <Portal parent={session.dom.body}>
      <div class={styles.clickaway} onmousedown={() => props.onClose(false)}>
        <section
          class={bind(() => [
            theme.themeClass,
            styles.dialog,
            'background-reset',
            props.class,
            styles[props.size || 'md'],
            props.height && styles[`height-${props.height}`],
          ])}
          onmousedown={stopPropagation}
          onkeydown={(ev: KeyboardEvent) => {
            if (ev.key === 'Enter') props.onClose(true);
            if (ev.key === 'Escape') props.onClose(false);
          }}
        >
          <DialogSidebars left={props.leftSidebar} right={props.rightSidebar}>
            <div class={styles.content}>{props.children}</div>
          </DialogSidebars>
          {() =>
            !!props.buttons && (
              <div>
                <Buttons
                  buttons={bind(() =>
                    isButtonsPreset(props.buttons)
                      ? {
                          ...props.buttons,
                          onClick: props.buttons.onClick || props.onClose,
                        }
                      : props.buttons,
                  )}
                  alignButtons={bind(() => props.alignButtons)}
                />
              </div>
            )
          }
        </section>
      </div>
    </Portal>
  );
}
