import { documentSelection } from '@donkeyjs/jsx-runtime';
import { allShortcuts, getShortcutFromEvent } from '../../helpers';
import type { MarkupProps } from './Markup';
import type { MarkupTreeProps } from './MarkupTree';

export const keyDownHandler =
  (props: MarkupProps<any>, treeProps: MarkupTreeProps) =>
  async (ev: KeyboardEvent) => {
    const selection =
      props.element && documentSelection.relateTo(props.element);
    if (!selection) {
      ev.preventDefault();
      ev.stopPropagation();
      return;
    }

    const from = selection.from;
    const to = selection.to;

    const updateSelection = <T>(action: () => T): T => {
      return treeProps.processor.updateSelection(selection, action);
    };

    if (!ev.ctrlKey && !ev.metaKey && !ev.altKey && ev.key.length === 1) {
      ev.preventDefault();
      ev.stopPropagation();

      updateSelection(() =>
        treeProps.interface.insertText(treeProps.processor, from, to, ev.key),
      );

      return;
    }

    const shortcut = getShortcutFromEvent(ev);
    if (AllowOwnKeys.includes(ev.key)) {
      ev.stopPropagation();
      return;
    }

    if (treeProps.interface?.passThroughKeys?.includes(ev.key)) {
      ev.preventDefault();
      return;
    }

    if (AllowKeys.includes(ev.key) || AllowShortcuts.includes(shortcut)) return;

    if (keys[shortcut] || !Object.keys(allShortcuts).includes(shortcut)) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    keys[shortcut]?.(
      {
        element: props.element!,
        tree: treeProps,
        from,
        to,
        updateSelection,
      },
      props,
    );
  };

const AllowOwnKeys = [
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'End',
  'Home',
];

const AllowKeys = [
  'Alt',
  'Ctrl',
  'Mod',
  'Shift',

  'Dead',
  'F5',
  'F12',
  'PageDown',
  'PageUp',
  'Tab',
];

const AllowShortcuts = ['Ctrl+A', 'Ctrl+R'];

interface KeyContext {
  tree: MarkupTreeProps;
  element: HTMLElement;
  from: number;
  to: number;
  updateSelection<T>(action: () => T): T;
}

const keys: Record<
  string,
  (context: KeyContext, props: MarkupProps<any>) => void | Promise<void>
> = {
  Backspace: ({ tree, from, to, updateSelection }, props) => {
    const merged = updateSelection(() =>
      tree.interface.removeTextBackward(tree.processor, from, to),
    );
    if (merged === null && !props.processor?.value.length)
      props.onEdgeDelete?.('backward');
    if (merged) {
      if (merged[0].element) {
        documentSelection.set({
          element: merged[0].element,
          from: merged[1],
          to: merged[1],
        });
      }
    }
  },
  Delete: ({ tree, from, to, updateSelection }, props) => {
    const merged = updateSelection(() =>
      tree.interface.removeTextForward(tree.processor, from, to),
    );
    if (merged === null && !props.processor?.value.length)
      props.onEdgeDelete?.('forward');
    if (merged) {
      if (merged[0].element) {
        documentSelection.set({
          element: merged[0].element,
          from: merged[1],
          to: merged[1],
        });
      }
    }
  },
  Enter: ({ tree, from, to }) => {
    if (
      ['UL', 'OL'].includes(tree.interface.getType() as string) &&
      !tree.processor.value.length
    ) {
      tree.interface.setType('P');
      return;
    }
    const next = tree.interface.split(tree.processor, from, to);
    if (next) {
      if (next.element) {
        documentSelection.set({ element: next.element, from: 0, to: 0 });
      }
    }
  },
  'Shift+Enter': ({ tree, from, to, updateSelection }) =>
    updateSelection(() =>
      tree.interface.insertText(tree.processor, from, to, '\n'),
    ),
  'Ctrl+B': ({ tree, from, to, updateSelection }) =>
    updateSelection(() =>
      tree.interface.toggleFormatting(tree.processor, from, to, 'b'),
    ),
  'Ctrl+I': ({ tree, from, to, updateSelection }) =>
    updateSelection(() =>
      tree.interface.toggleFormatting(tree.processor, from, to, 'i'),
    ),
  // 'Ctrl+C': (state) => state.copy(),
  // 'Ctrl+V': async (state) => state.paste(),
  // 'Ctrl+Shift+V': async (state) => state.paste(true),
  // 'Ctrl+X': (state) => state.cut(),
  // 'shift+Enter': (state) => state.splice(true),
  // 'Ctrl+Y': (setState) => setState((state) => state.redo()),
  // 'Ctrl+Z': (setState) => setState((state) => state.undo()),
  // 'Ctrl+Shift+Z': (setState) => setState((state) => state.redo()),

  'Ctrl+C': async ({ tree, from, to }) => {
    await tree.interface.copy(tree.processor, from, to);
  },
  'Ctrl+V': async (props) => {
    paste(props);
  },
  'Ctrl+Shift+V': async (props) => {
    paste(props);
  },
  'Ctrl+X': async ({ element, tree, from, to }) => {
    await tree.interface.cut(tree.processor, from, to);
    documentSelection.set({ element, from, to: from });
  },
  'Ctrl+Y': () => {},
  'Ctrl+Z': () => {},
  'Ctrl+Shift+Z': () => {},
};

const paste = async ({ tree, from, to }: KeyContext) => {
  const next = await tree.interface.paste(tree.processor, from, to);
  if (next) {
    if (next[0].element) {
      documentSelection.set({
        element: next[0].element,
        from: next[1],
        to: next[1],
      });
    }
  }
};
