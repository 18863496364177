import { bind } from '@donkeyjs/jsx-runtime';
import { PhCamera } from '@donkeyjs/phosphor-icons';
import { meta } from '@donkeyjs/proxy';
import { lazyComponent } from '../loaders';
import { getMailContext } from '../mail';
import styles from './Image.module.css';
import { useImage, type UseImageProps } from './useImage';

export interface ImageProps extends UseImageProps, ImageDisplayProps {
  readonly onmount?:
    | ((element: HTMLElement) => () => void)
    | (((element: HTMLElement) => () => void) | undefined)[];
}

export interface ImageDisplayProps {
  readonly alt?: string | undefined;
  readonly element?: keyof HTMLElementTagNameMap | undefined;
  readonly class?: JSX.ClassNames;
  readonly testing?: boolean;
  readonly style?: Record<string, any>;
  readonly changeOnClick?: boolean | 'button';
}

const Controls = lazyComponent(
  'donkey.filecontrols',
  () => import('./FileControls').then((c) => c.FileControls),
  'none',
);

export function Image(props: ImageProps) {
  const state = useImage(props);
  const mail = getMailContext();

  return () => {
    if (!state.file) {
      return props.onChange ? (
        <span
          class={bind(() => [
            'image',
            styles.image,
            styles.placeholder,
            props.class,
          ])}
          style={bind(() => state.style(props.style))}
          onclick={state.handleClick}
          onmount={bind(() => state.attach)}
        >
          <PhCamera weight="duotone" />
        </span>
      ) : undefined;
    }

    if (meta(state.file)?.isLoading) {
      return (
        <span
          class={bind(() => [
            'image',
            styles.image,
            styles.loading,
            props.class,
          ])}
          style={bind(() => state.style(props.style))}
          onsize={state.handleSize}
        />
      );
    }

    if (!state.url) {
      return (
        <span
          class={bind(() => ['image', styles.image, props.class])}
          style={bind(() => ({
            ...state.style(props.style),
            opacity: '0.5',
          }))}
          onsize={state.handleSize}
        />
      );
    }

    return (
      <>
        {() =>
          !!state.handleButtonClick && (
            <Controls
              onChange={state.handleButtonClick}
              element={bind(() => state.element)}
            />
          )
        }
        <img
          class={bind(() => [
            'image',
            styles.image,
            props.class,
            {
              [styles.testing]: props.testing,
              [styles.editable]:
                !!props.onChange &&
                (props.changeOnClick === undefined ||
                  props.changeOnClick === true),
            },
          ])}
          src={bind(() => state.url)}
          alt={bind(() => props.alt || state.file?.name)}
          style={bind(() => state.style(props.style))}
          width={mail?.target === 'mail' ? props.style?.width : undefined}
          align={
            mail?.target === 'mail'
              ? props.style?.align || props.style?.float
              : undefined
          }
          onmount={bind(() => [props.onmount, state.attach])}
          onload={state.handleLoad}
          onsize={state.handleSize}
          onclick={state.handleClick}
        />
      </>
    );
  };
}
