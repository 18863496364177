import type { NodeTypename } from '@donkeyjs/proxy';
import { session } from '../../../session';
import type { ViewContext, ViewType } from '../../../views';

export function getView(
  typename: string,
  view: string | undefined,
  viewContext: ViewContext | undefined,
): ViewType<any, any> | undefined {
  return (
    (session.app.views?.[typename as NodeTypename<DataSchema>]?.[
      (view as keyof typeof session.app.views) ??
        viewContext?.preferredListView ??
        'default'
    ] as ViewType | undefined) ||
    (session.app.views?.[typename as NodeTypename<DataSchema>]?.default as
      | ViewType
      | undefined)
  );
}
