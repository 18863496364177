import {
  bind,
  meta,
  store,
  type DataNode,
  type NodeTypename,
} from '@donkeyjs/proxy';
import { Portal, getAssetUrl } from './helpers';
import { getI18n } from './i18n/getI18n';
import { getMailContext } from './mail';
import { routerNode } from './routing';
import { session } from './session';
import { getTheme } from './styles';

export function SEO() {
  const theme = getTheme();
  const i18n = getI18n();
  const node = routerNode();
  const mail = getMailContext();

  const state = store({
    get isHome() {
      return session.router.pathname === '/';
    },
    get mainTitle() {
      return session.router.pathname === '/'
        ? session.app.data[0]?.name || session.router.route.name
        : session.router.route.name;
    },
    get appName() {
      return session.app.data[0]?.name;
    },
    get node() {
      return node['node-typename'] && node['node-id']
        ? (meta(
            session.data.getNode(
              node['node-typename'] as NodeTypename<DataSchema>,
              node['node-id'],
            ),
          ).getCulture(i18n.culture) as DataNode<DataSchema>)
        : undefined;
    },
    get nodeSeo() {
      return (
        state.node &&
        session.app.clientSchemaMeta?.[state.node.__typename]?.seo?.(
          state.node as any,
        )
      );
    },
    get title(): string | undefined {
      if (!state.node && session.router.route.node?.metaTitle)
        return session.router.route.node.metaTitle;

      if (state.node) {
        const title = state.nodeSeo?.title || state.node.toString();
        if (title && !title.startsWith('['))
          return [title, state.appName].filter((s) => !!s).join(' - ');
      }

      return state.mainTitle === state.appName
        ? state.appName
        : [state.mainTitle, state.appName].filter((s) => !!s).join(' - ');
    },
    get description(): string | null | undefined {
      return (
        state.nodeSeo?.description || session.router.route.node?.metaDescription
      );
    },
    get image(): string | null | undefined {
      let image: unknown = state.nodeSeo?.image;

      if (!image && state.node) {
        if ('image' in state.node) image = state.node.image;
        else if ('images' in state.node) image = state.node.images[0];
      }

      if (!image) image = session.router.route.node?.metaImage;

      if (
        image &&
        typeof image === 'object' &&
        'file' in image &&
        '__typename' in image &&
        image.__typename === 'FileRef'
      )
        image = image.file;

      if (
        image &&
        typeof image === 'object' &&
        '__typename' in image &&
        image.__typename === 'File'
      ) {
        return session.router.prependPathWithHostname(
          getAssetUrl(image as DataNode<DataSchema, 'File'>, {
            mailContext: mail,
            width: 1200,
          }),
        );
      }

      return null;
    },
    get canonicalUrl() {
      return session.router.prependPathWithHostname(session.router.path);
    },
  });

  return (
    <Portal parent={session.dom.head} bare>
      {() => {
        if (mail?.target === 'mail') return <meta charset="UTF-8" />;
        return (
          <>
            <title>{() => state.title}</title>
            <meta charset="UTF-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            <meta property="og:title" content={bind(() => state.title)} />
            {() =>
              !!state.description && (
                <meta
                  name="description"
                  content={bind(() => state.description!)}
                />
              )
            }
            {() =>
              !!state.description && (
                <meta
                  property="og:description"
                  content={bind(() => state.description!)}
                />
              )
            }
            <link rel="canonical" href={bind(() => state.canonicalUrl)} />
            <meta property="og:url" content={bind(() => state.canonicalUrl)} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={bind(() => state.appName)} />
            <meta
              property="og:locale"
              content={bind(() => i18n.culture.replace('-', '_'))}
            />
            <meta
              name="theme-color"
              content={bind(() => theme.colors.accent)}
            />
            {() =>
              state.image && (
                <meta property="og:image" content={bind(() => state.image!)} />
              )
            }
          </>
        );
      }}
    </Portal>
  );
}
