import { store, type Culture } from '@donkeyjs/proxy';
import type { RouteDefinition } from './createRouter';
import { isSameRoutesTree } from './helpers/isSameRoutesTree';

interface RoutingMapOptions {
  routes: { [culture in Culture]?: RouteDefinition[] };
  cultures: Culture[];
}

interface InternalRoutingMap {
  paths: Record<string, RouteDefinition>;
  routeIds: Record<string, RouteDefinition>;
  keys: Record<string, RouteDefinition>;
  rootLevel: RouteDefinition[];
}

export interface RoutingMap {
  routeById(culture: Culture, id: string): RouteDefinition | undefined;
  routeByPath(culture: Culture, path: string): RouteDefinition | undefined;
  routeByKey(culture: Culture, key: string): RouteDefinition | undefined;
  rootLevel(culture: Culture): RouteDefinition[];
  update(routes: { [culture in Culture]?: RouteDefinition[] }): void;
}

interface CultureValues {
  map: InternalRoutingMap;
  routes: RouteDefinition[];
}

export const createRoutingMap = (options: RoutingMapOptions): RoutingMap => {
  const cultures = store<{
    [culture in Culture]?: CultureValues;
  }>({});

  const lastSeenUrls = new WeakMap<RouteDefinition, string>();

  let updates: { [culture in Culture]?: RouteDefinition[] } = options.routes;

  function ensureCulture(culture: Culture) {
    if (!cultures[culture]) {
      const result: InternalRoutingMap = {
        routeIds: {},
        paths: {},
        keys: {},
        rootLevel: [],
      };
      const routes = updates[culture] || [];

      for (const route of routes) {
        lastSeenUrls.set(route, route.fullPathname || route.pathname);
        if (route.id) result.routeIds[route.id] = route;
        result.paths[route.pathname] = route;
        if (route.fullPathname) result.paths[route.fullPathname] = route;
        if (route.node?.key) result.keys[route.node.key] = route;
        if (!route.parent) result.rootLevel.push(route);
      }

      cultures[culture] = { map: result, routes };
    }

    return cultures[culture]!;
  }

  return {
    routeById(culture, id) {
      return ensureCulture(culture).map.routeIds[id];
    },

    routeByPath(culture, path) {
      return ensureCulture(culture).map.paths[path];
    },

    routeByKey(culture, key) {
      return ensureCulture(culture).map.keys[key];
    },

    rootLevel(culture) {
      return ensureCulture(culture).map.rootLevel;
    },

    update(newRoutes) {
      updates = newRoutes;
      for (const [culture, value] of Object.entries(cultures) as [
        Culture,
        CultureValues,
      ][]) {
        if (!isSameRoutesTree(value.routes, updates[culture]!, lastSeenUrls)) {
          delete cultures[culture];
        }
      }
    },
  };
};
