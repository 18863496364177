import type { SchemaStore, SchemaStoreInput } from '@donkeyjs/core';
import { bind, bindContext, live, onUnmount } from '@donkeyjs/jsx-runtime';
import {
  type Culture,
  type DataList,
  type DataNode,
  type NodeRef,
  type NodeTypename,
  type ResolverSchema,
  type Store,
  meta,
  store,
} from '@donkeyjs/proxy';
import { roundToNearestMinutes } from 'date-fns';
import type { BlockProps } from '.';
import { List } from '../components/List';
import {
  formulaContext,
  parseFilter,
  runFormula,
  valueFromFilter,
} from '../data/filters';
import { getNow } from '../helpers';
import { preferWorkspace } from '../helpers/preferWorkspace';
import {
  type InfiniteScrollState,
  useInfiniteScroll,
} from '../helpers/useInfiniteScroll';
import { getI18n } from '../i18n/getI18n';
import { getMailContext } from '../mail';
import { routerNode } from '../routing';
import { session } from '../session';
import type { ViewGrouping, ViewMode, ViewType } from '../views';
import { View } from '../views/View';
import { setDataFiltersContext } from './helpers/DataFilters';
import { parseMonthFilter } from './helpers/parseMonthFilter';

export interface DataSettings {
  ref?: NodeRef<DataSchema>;
  resolver?: keyof ApplicationSchema['resolvers'];
  view?: string | ViewType<any, any>;
  viewMode?: string;
  showModeButtons?: boolean;
  sort?: string;
  group?: string;
  where?: Record<string, string>;
  selection?: 'url';
  selectionMode?: 'isolate';
  paging?: 'infinite-scroll';
  pageSize?: number;
  limit?: number;
  offset?: number;
  filters?: DataSettingsFilter[];
  filterTagGroups?: string[];
  heading?: string;
  skipDrafts?: boolean;

  name?: string;
  workspace?: string;
  hideAddButton?: boolean;
  adding?: 'button' | 'top' | 'bottom';
}

export interface DataSettingsFilter {
  key: string;
  fieldName?: string;
  kind?: 'tag-group' | 'enum' | 'text' | 'date';
  value?: string;
  name?: string;

  input?: 'none' | 'eq' | 'in';
  inputSort?: 'count' | 'native';

  options?: {
    key: string;
    name: string;
    where: Record<string, string>;
    sort?: string;
  }[];
}

export type DataBlockState = ReturnType<typeof createDataBlockState>;

export function DataBlock(props: BlockProps<DataSettings>) {
  const state = createDataBlockState(props);

  if (props.workspace) preferWorkspace(props.workspace);

  const i18n = getI18n();
  const mail = getMailContext();

  if (state.data?.data) {
    const dispose = setDataFiltersContext({
      data: state.data.data,
      dataProps: props,
      where: state.data.args?.where,
    });

    onUnmount(() => {
      dispose();
    });
  }

  const containerClass = () =>
    state.view?.containerClass &&
    (typeof state.view.containerClass === 'function'
      ? state.view.containerClass({ mode: state.mode, grouping: state.group })
      : state.view.containerClass);

  const dataContainerClass = () =>
    state.view?.dataContainerClass &&
    (typeof state.view.dataContainerClass === 'function'
      ? state.view.dataContainerClass({
          get mode() {
            return state.mode;
          },
          get grouping() {
            return state.group;
          },
        })
      : state.view.dataContainerClass);

  return () => {
    const data = meta(state.data?.data)?.getCulture(i18n.culture);
    return props.ref || (props.resolver && props.view) ? (
      <div
        class={bind(() => [props.class, 'block-data'])}
        onmount={[props.onmount, state.pager?.bindContainer]}
        contenteditable="false"
      >
        {() =>
          !state.view ? (
            'View not found'
          ) : !!state.isolatedNode || (data && Array.isArray(data)) ? (
            <List<DataSchema, NodeTypename<DataSchema>>
              data={bind(() =>
                state.isolatedNode ? [state.isolatedNode] : data,
              )}
              controls={bind(() =>
                props.hideAddButton || mail ? 'hidden' : undefined,
              )}
              class={bind(() => containerClass())}
              onContainerMount={bind(() =>
                state.view?.onContainerMount?.(state.mode),
              )}
              modes={bind(() => state.view?.modes)}
              mode={bind(state, 'mode')}
              showModeButtons={bind(() => props.showModeButtons)}
              isInIsolation={bind(() => !!state.isolatedNode)}
              group={bind(() => state.group?.format)}
              overrideGroups={bind(() => state.group?.overrideGroups)}
              groupContainerClass={bind(() => state.group?.containerClass)}
              renderGroupContainer={bind(() => state.group?.renderContainer)}
              heading={bind(() => props.heading)}
              render={(node, index) => {
                return (
                  <View<DataSchema, NodeTypename<DataSchema>>
                    node={node}
                    context={data}
                    view={state.view!}
                    selected={bind(() =>
                      props.selection
                        ? state.selectedNode?.id === node.id
                        : null,
                    )}
                    readonly={!!props.ref || !!props.readonly}
                    mode={bind(state, 'mode')}
                    grouping={bind(() => state.group as any)}
                    setAsDefaultProperties={bind(
                      () => state.selectedNode?.id === node.id,
                    )}
                    onmount={(el) => {
                      if (index() % state.pageSize === 0) {
                        return state.pager?.bindPage(index() / state.pageSize)(
                          el,
                        );
                      }
                    }}
                  />
                );
              }}
              adding={bind(() => props.adding)}
              addAsDraft={bind(() => !props.skipDrafts)}
              onAdd={(node) => {
                if (props.selection === 'url') {
                  session.router.navigate(session.router.getPath({ node }));
                }
              }}
              noDrafts={bind(() => !!mail || !!props.hideAddButton)}
              dataContainerClass={bind(() => dataContainerClass())}
              renderAdd={bind(() => state.view?.renderAdd)}
              renderContainer={bind(() => state.view?.renderContainer)}
              renderDataContainer={bind(() => state.view?.renderDataContainer)}
              onDataContainerMount={bind(() =>
                state.view?.onDataContainerMount?.(state.mode),
              )}
            />
          ) : !props.readonly ? (
            'Empty list'
          ) : null
        }
      </div>
    ) : null;
  };
}

export const createDataBlockState = (props: BlockProps<DataSettings>) => {
  const i18n = getI18n();
  const query = routerNode();

  // const result = getSyncState(props.block ?? props, () => {
  let selectedLoader: any;

  const result = store({
    get typename(): NodeTypename<DataSchema> | undefined {
      return props.ref
        ? (props.ref.split(':')[0] as NodeTypename<DataSchema>)
        : props.resolver && session.app.schema.resolvers[props.resolver];
    },

    scrollState: store({
      get paused() {
        return !!result.isolatedNode;
      },
    }),

    pager: undefined as Store<InfiniteScrollState> | undefined,

    data: undefined as Partial<ReturnType<typeof useBlockData>> | undefined,

    get selectedNode(): DataNode<DataSchema> | undefined {
      return this.selectedNodeOrList && Array.isArray(this.selectedNodeOrList)
        ? this.selectedNodeOrList[0]
        : this.selectedNodeOrList;
    },

    get selectedNodeOrList():
      | DataNode<DataSchema>
      | DataList<DataSchema>
      | undefined {
      if (
        props.selection !== 'url' ||
        !this.typename ||
        query['node-typename'] !== this.typename
      )
        return undefined;

      const id = query['node-id'];
      return id
        ? (meta(session.data.getNode(this.typename, id))?.getCulture(
            (props.block?.culture as Culture) || i18n.culture,
          ) as DataNode<DataSchema>) ||
            (selectedLoader ??= session.data.useNodes({
              typename: this.typename,
              ids: [id],
              placeholderCount: 1,
            }))
        : undefined;
    },

    get isolatedNodeOrNodeList():
      | DataNode<DataSchema>
      | DataList<DataSchema>
      | undefined {
      if (props.ref) {
        const [typename, id] = props.ref.split(':') as [
          NodeTypename<DataSchema>,
          string,
        ];
        return (
          (session.data.getNode(typename, id) as DataNode<DataSchema>) ||
          session.data.useNodes({
            typename,
            ids: [id],
            placeholderCount: 1,
          })
        );
      }

      if (props.selectionMode === 'isolate') return this.selectedNode;

      return undefined;
    },

    get isolatedNode(): DataNode<DataSchema> | undefined {
      return Array.isArray(this.isolatedNodeOrNodeList)
        ? this.isolatedNodeOrNodeList[0]
        : this.isolatedNodeOrNodeList;
    },

    get skipExecution() {
      return !!this.isolatedNode;
    },

    get view(): ViewType | undefined {
      return this.typename && props.view
        ? !props.view || typeof props.view === 'string'
          ? (session.app.views?.[this.typename]?.[
              props.view as keyof typeof session.app.views
            ] as ViewType)
          : props.view
        : undefined;
    },

    get group():
      | ViewGrouping<DataSchema, NodeTypename<DataSchema>>
      | undefined {
      return props.limit || !props.group
        ? undefined
        : this.view?.groupings?.find((g) => g.key === props.group);
    },

    get pageSize() {
      return props.pageSize || 50;
    },

    _mode: undefined as ViewMode | undefined,

    get mode() {
      return (
        this._mode ||
        this.view?.modes?.find((m) => m.key === props.viewMode) ||
        this.view?.modes?.[0]
      );
    },

    set mode(mode: ViewMode | undefined) {
      this._mode = mode;
    },
  });
  //   return state;
  // });

  live(() => {
    if (props.paging === 'infinite-scroll') {
      result.pager = store(useInfiniteScroll());
    } else {
      result.pager = undefined;
    }
  });

  if (props.resolver && !result.data) {
    result.data = props.resolver
      ? useBlockData(props.resolver, props, result)
      : {};
  }

  return result;
};

const useBlockData = (
  resolver: keyof ApplicationSchema['resolvers'] | undefined,
  props: BlockProps<DataSettings>,
  blockState?: {
    skipExecution: boolean;
    isolatedNode: DataNode<DataSchema> | undefined;
    pager?: { page: number; pageProgress: number };
  },
) => {
  const i18n = getI18n();
  const search = session.router.getSchemaQuery({ search: 'string?' });

  const getFormulaContext = bindContext(() => formulaContext(props.block));

  const state = store({
    maxPage: 0,
    filters: undefined as DataSettingsFilter[] | undefined,
    optionsQuery: undefined as SchemaStore<any> | undefined,
    get optionsFromQuery(): OptionsFromQuery {
      return optionsFromQuery(
        state.filters,
        state.optionsQuery,
        getFormulaContext(),
      );
    },
  });

  const queryArgs = store({
    get skipExecution() {
      return blockState?.skipExecution;
    },

    get limit() {
      return props.limit || (state.maxPage + 1) * (props.pageSize ?? 50);
    },

    get offset() {
      return props.offset;
    },

    get drafts() {
      return props.hideAddButton ? false : undefined;
    },

    get search() {
      return search.search
        ? {
            text: `${search.search.trim().split(/\s+/g).join(' & ')}:*`,
            mode: { __literal: 'TOKENS' },
            culture: i18n.culture,
          }
        : undefined;
    },

    get where() {
      const { where } = state.optionsFromQuery;
      return Object.keys(where).length ? where : undefined;
    },

    get sort() {
      return state.optionsFromQuery.sort || props.sort;
    },

    get source() {
      return JSON.stringify({ search: this.search, where: this.where });
    },
  });

  live(() => {
    [state.filters, state.optionsQuery] = resolver
      ? optionsQuery(resolver, props)
      : [undefined, undefined];
  });

  live(() => {
    if (!blockState?.pager || blockState?.isolatedNode) return;
    if (
      blockState.pager.page === state.maxPage &&
      blockState.pager.pageProgress > 0.5
    ) {
      state.maxPage++;
    }
  });

  const data = session.data.get<any>(
    resolver,
    queryArgs as any,
  ) as DataList<DataSchema>;

  return {
    args: queryArgs,
    data,
  };
};

const optionsQuery = (
  resolver: keyof ApplicationSchema['resolvers'],
  settings: DataSettings,
): [DataSettingsFilter[] | undefined, SchemaStore<any> | undefined] => {
  const typename = session.app.schema.resolvers[resolver];
  const resolverSchema = (session.app.schema.nodes[typename] as any)?.resolvers[
    resolver
  ] as ResolverSchema;
  if (!resolverSchema) {
    console.error(`Resolver ${resolver} not found`);
    return [undefined, undefined];
  }

  const filters = settings.filters;
  if (!filters) return [undefined, undefined];

  const schema: SchemaStoreInput = {};
  for (const filter of filters) {
    if (filter.kind === 'text' || filter.kind === 'date') {
      schema[filter.key] = 'string?';
    } else if (
      filter.options?.length ||
      (filter.input && filter.input !== 'none')
    ) {
      schema[filter.key] = (
        filter.options?.length || filter.input === 'eq' ? 'string?' : 'string[]'
      ) as any;
    }
  }

  return [filters, session.router.getSchemaQuery(schema)];
};

interface OptionsFromQuery {
  where: Record<string, any>;
  sort?: string;
}

const optionsFromQuery = (
  filters: DataSettingsFilter[] | undefined,
  query: SchemaStore<any> | undefined,
  context: any,
): OptionsFromQuery => {
  if (!query || !filters) return { where: {} };

  const where: any = {};
  let sort: string | undefined;

  for (const filter of filters) {
    let value = (query as any)[filter.key];

    if (!value?.length && filter.value) {
      const { operator, value: filterValue } = valueFromFilter(filter, context);
      if (!filterValue) continue;

      if (filter.options?.length) {
        value = filterValue;
      } else if (filter.kind === 'tag-group') {
        value = filterValue;
      } else {
        where[filter.fieldName || filter.key] = {
          [operator]: filterValue,
        };
        continue;
      }
    }

    const transform =
      filter.kind === 'enum'
        ? (value: string) => ({ __literal: value })
        : (value: any) => value;

    if (value && filter.options?.length) {
      const option = filter.options.find((o) => o.key === value);
      if (option) {
        sort = option.sort;
        for (const [key, value] of Object.entries(option.where)) {
          const parsed = parseFilter(value);
          const calculated = runFormula(parsed.value, context);
          where[key] = { [parsed.operator]: calculated };
        }
      }
    } else if (filter.kind === 'tag-group' && value && (value as any).length) {
      where[filter.fieldName || filter.key] ??= { in: [] };
      where[filter.fieldName || filter.key].in.push(
        Array.isArray(value) ? value : [value],
      );
    } else if (filter.kind === 'date') {
      if (value === 'past') {
        where[filter.fieldName || filter.key] = {
          lt: roundToNearestMinutes(getNow(), { nearestTo: 5 }),
        };
        sort = 'DATE_DESC';
        continue;
      }
      const month = parseMonthFilter(value);
      if (month) {
        where[filter.fieldName || filter.key] = {
          gte: new Date(month.year, month.month - 1),
          lt: new Date(month.year, month.month),
        };
        continue;
      }
      where[filter.fieldName || filter.key] = {
        gte: roundToNearestMinutes(getNow(), { nearestTo: 5 }),
      };
    } else if (value && Array.isArray(value)) {
      if (value.length)
        where[filter.fieldName || filter.key] = { in: value.map(transform) };
    } else if (value) {
      where[filter.fieldName || filter.key] = { eq: transform(value) };
    }
  }
  return { where, sort };
};
