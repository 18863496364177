export function formatCurrencySign(value: string) {
  return signs[value] || value;
}

const signs: Record<string, string> = {
  EUR: '€',
  USD: '$',
  GBP: '£',
  JPY: '¥',
  BGN: 'лв',
  CZK: 'Kč',
  DKK: 'kr',
  HUF: 'Ft',
  PLN: 'zł',
  RON: 'lei',
  SEK: 'kr',
  CHF: 'Fr',
  ISK: 'kr',
  NOK: 'kr',
  HRK: 'kn',
  RUB: '₽',
  TRY: '₺',
  AUD: 'A$',
  BRL: 'R$',
  CAD: 'C$',
  CNY: '¥',
  HKD: 'HK$',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  KRW: '₩',
  MXN: 'Mex$',
  MYR: 'RM',
  NZD: 'NZ$',
  PHP: '₱',
  SGD: 'S$',
  THB: '฿',
  ZAR: 'R',
};
