import { computed, signal } from '@donkeyjs/proxy';
import { withContext } from '../component';
import type { DomNode, JSXVirtualNode } from '../dom';
import { componentContext } from '../mount/mount';
import { processBeforeMount } from '../mount/renderPlugin';
import { createJSXNode } from './createJSXNode';
import { walkSsrTree } from './walkSsrTree';

export function createDomArray(
  items: JSX.Element[],
  nextSsrCandidate?: Node | null,
): JSXVirtualNode {
  const current = signal(items);
  const nodes = new Map<object, JSXVirtualNode>();
  const texts: JSXVirtualNode[] = [];
  const nullNodes: JSXVirtualNode[] = [];
  let ssrCandidate = nextSsrCandidate;

  const context = componentContext.current;
  if (!context) throw new Error('Cannot render a JSX element without context');

  const value = computed(() =>
    withContext(context, () => {
      const result: DomNode[] = [];
      let textIndex = 0;
      let nullIndex = 0;
      const seen = new Set<object>();
      for (const itm of current.value) {
        const item = processBeforeMount(itm);
        if (typeof item === 'string' || typeof item === 'number') {
          const current = texts[textIndex];
          if (!current) {
            const text = createJSXNode(item, ssrCandidate);
            texts.push(text);
            result.push(text.nodes[0]);
            ssrCandidate = walkSsrTree(ssrCandidate, text.nodes);
          } else {
            current.update(item);
            result.push(current.nodes[0]);
          }
          textIndex++;
          continue;
        }
        if (item != null && typeof item === 'object') {
          seen.add(item);
          let jsx = nodes.get(item)!;
          if (jsx?.update(item) !== true) {
            jsx?.dispose();
            jsx = createJSXNode(item, ssrCandidate);
            nodes.set(item, jsx);
          }
          result.push(...jsx.nodes);
          ssrCandidate = walkSsrTree(ssrCandidate, jsx.nodes);
          continue;
        }
        const current = nullNodes[nullIndex];
        if (!current) {
          const value = createJSXNode(null);
          nullNodes.push(value);
        }
        nullIndex++;
      }
      for (const node of nodes.keys()) {
        if (!seen.has(node)) {
          nodes.get(node)!.dispose();
          nodes.delete(node);
        }
      }
      for (let i = textIndex; i < texts.length; i++) {
        texts[i].dispose();
      }
      for (let i = nullIndex; i < nullNodes.length; i++) {
        nullNodes[i].dispose();
      }
      texts.length = textIndex;
      nullNodes.length = nullIndex;
      return result;
    }),
  );

  const result: JSXVirtualNode = {
    __type: 'node',
    currentValue: items,
    testUpdate(items: JSX.Element[]) {
      if (typeof items !== 'object' || !Array.isArray(items)) {
        return 'non-array value passed to a DOM array';
      }
      return true;
    },
    update(fragment: JSX.Element[]) {
      const test = result.testUpdate(fragment);
      if (test !== true) return test;
      result.currentValue = fragment;
      current.value = fragment;
      return true;
    },
    dispose() {
      for (const node of nodes.values()) {
        node.dispose();
      }
      for (const node of texts) {
        node.dispose();
      }
      for (const node of nullNodes) {
        node.dispose();
      }
    },
    get nodes() {
      return value?.value || [];
    },
  };

  return result;
}
