import { useToolbar } from '@donkeyjs/backoffice';
import {
  admin,
  applyDonkeyTheme,
  confirmInput,
  enableMailMode,
  ensureMailContext,
  getUserContext,
  preferWorkspace,
  routerNode,
  session,
  setTheme,
  setTrackingContext,
  showDialog,
  text,
  usePromise,
} from '@donkeyjs/client';
import { type Component, onMount } from '@donkeyjs/jsx-runtime';
import { PhPaperPlaneTilt, PhUsersThree } from '@donkeyjs/phosphor-icons';
import { type DataNode, bind, store } from '@donkeyjs/proxy';
import { I18nMailingsAdmin } from '../..';
import { getMailingOptions } from '../options';
import { ManageSubscription } from './ManageSubscription';
import styles from './Newsletter.module.css';
import {
  SendNewsletterForm,
  type SendNewsletterFormProps,
} from './SendNewsletterForm';

export function Newsletter() {
  preferWorkspace('communication');
  enableMailMode();

  const options = getMailingOptions();
  const selection = admin.useSelection?.();
  const user = getUserContext();
  const context = ensureMailContext();
  const node = routerNode();
  const theme = applyDonkeyTheme(user);

  const state = store({
    get newsletter() {
      return newsletterNodes.find((n) => n.id === node['node-id']);
    },
    get template() {
      const template = state.newsletter?.template
        ? options.newsletterTemplates?.find(
            (t) => t.id === state.newsletter?.template,
          )
        : options.newsletterTemplates?.[0];
      return template?.component;
    },
    get manageId() {
      return session.router.query.manage?.[0];
    },
    get mode() {
      return this.manageId ? 'manage' : 'newsletter';
    },
    sending: undefined as 'sending' | 'sent' | 'error' | undefined,
  });

  const newsletterNodes = session.data.useNodes({
    typename: 'Newsletter',
    get ids() {
      const id =
        node['node-typename'] === 'Newsletter' ? node['node-id'] : undefined;
      return id ? [id] : [];
    },
  });

  onMount(() => {
    if (state.newsletter && selection) {
      return selection.useRoot({
        node: state.newsletter as unknown as DataNode<DataSchema>,
        blocks: state.newsletter.blocks,
        mode: 'mail',
      });
    }
  });

  const handleSendPreview = () => {
    confirmInput(user.user?.email || '', {
      confirmButtonText: text(I18nMailingsAdmin, 'Newsletter.SendPreview'),
      cancelButtonText: text('Common.Cancel'),
      prompt: text(I18nMailingsAdmin, 'Newsletter.SendPreviewToEmail'),
      user,
    }).then((email) => {
      if (email && node['node-id']) {
        session.data.mutation.sendNewsletterPreview({
          id: node['node-id'],
          toEmail: email,
        });
      }
    });
  };

  const handleSend = () => {
    let count = 0;

    const props = store<SendNewsletterFormProps>({
      lists: [],
    });

    const send = async () => {
      if (!node['node-id']) {
        dialog.close();
        return;
      }

      state.sending = 'sending';

      try {
        const result = await session.data.mutation.sendNewsletter({
          id: node['node-id'],
          listIds: props.lists.map((l) => l.id).join(','),
        });
        if (result.errors) {
          console.error(result.errors);
          state.sending = 'error';
          return;
        }
        count = Number.parseInt(result.data);
        state.sending = 'sent';
      } catch (err) {
        state.sending = 'error';
      }
    };

    const dialog = showDialog(
      () => {
        if (state.sending === 'sending') {
          return <div class="sending">Sending...</div>;
        }
        if (state.sending === 'sent') {
          return (
            <div class="sent">
              The newsletter has been sent to {count} recipients.
            </div>
          );
        }

        return SendNewsletterForm(props);
      },
      {
        buttons: () =>
          state.sending === 'sending' ? null : state.sending === 'sent' ||
            state.sending === 'error' ? (
            <button
              type="button"
              class={bind(() => theme().class.button)}
              onclick={() => dialog.close()}
            >
              {text('Common.Close')}
            </button>
          ) : (
            <>
              <button
                type="button"
                class={bind(() => [theme().class.button, 'default'])}
                onclick={send}
              >
                {text(I18nMailingsAdmin, 'Newsletter.Send')}
              </button>
              <button
                type="button"
                class={bind(() => theme().class.button)}
                onclick={() => dialog.close()}
              >
                {text('Common.Cancel')}
              </button>
            </>
          ),
        alignButtons: 'right',
        size: 'sm',
      },
    );
  };

  useToolbar(
    <>
      {() =>
        !!node['node-id'] && (
          <>
            <div role="group">
              <button type="button" onclick={handleSendPreview}>
                <PhPaperPlaneTilt weight="duotone" />
                <span>{text(I18nMailingsAdmin, 'Newsletter.SendPreview')}</span>
              </button>
            </div>
            <div role="group">
              <button type="button" onclick={handleSend}>
                <PhUsersThree weight="duotone" />
                <span>{text(I18nMailingsAdmin, 'Newsletter.Send')}</span>
              </button>
            </div>
          </>
        )
      }
    </>,
  );

  const wrap = (children: JSX.Children) => {
    if (context?.target) return children;
    return (
      <div class={bind(() => [styles.newsletter, theme().mailThemeClass])}>
        {children}
      </div>
    );
  };

  const template = usePromise(state.template?.());

  return () => {
    if (state.manageId) return <ManageSubscription manageId={state.manageId} />;
    template.update(state.template?.());
    return wrap(
      state.newsletter && template?.data ? (
        <Render newsletter={state.newsletter} template={template.data} />
      ) : null,
    );
  };
}

function Render(props: {
  template: Component<{ newsletter: DataNode<DataSchema, 'Newsletter'> }>;
  newsletter: DataNode<DataSchema, 'Newsletter'>;
}) {
  setTheme(session.app.theme);
  setTrackingContext({ sid: props.newsletter.id });

  return () => {
    const Template = props.template;
    return <Template newsletter={props.newsletter} />;
  };
}
