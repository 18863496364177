import styles from './IconList.module.css';

interface IconListItemProps {
  heading?: JSX.Children;
  children?: JSX.Children;
}

export function IconListItem(props: IconListItemProps) {
  return (
    <div class={styles.item}>
      {() =>
        !!props.heading && <div class={styles.heading}>{props.heading}</div>
      }
      <div class={styles.bullets}>{props.children}</div>
    </div>
  );
}

interface IconListBulletProps {
  icon?: JSX.Children;
  children?: JSX.Children;
}

export function IconListBullet(props: IconListBulletProps) {
  return (
    <div class={styles.content}>
      {() => !!props.icon && <span class={styles.icon}>{props.icon}</span>}
      {props.children}
    </div>
  );
}
