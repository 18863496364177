import { bind } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import { usePopper } from '../helpers';
import { getTheme } from '../styles';
import styles from './tooltip.module.css';

interface TooltipOptions {
  text?: JSX.Children;
  shortcut?: string;
  overflowOnly?: boolean;
}

export const tooltip =
  (tooltip: TooltipOptions | string | undefined | (() => string | undefined)) =>
  (node: HTMLElement) => {
    const theme = getTheme();

    const popper = usePopper(TooltipComponent);

    const state = store({
      get text() {
        return typeof tooltip === 'function'
          ? tooltip()
          : typeof tooltip === 'string'
            ? tooltip
            : tooltip?.text;
      },
      get shortcut() {
        return typeof tooltip === 'object' ? tooltip.shortcut : undefined;
      },
    });

    const handleMouseEnter = () => {
      if (typeof tooltip === 'object' && tooltip.overflowOnly) {
        const isOverflowing =
          node.clientWidth < node.scrollWidth ||
          node.clientHeight < node.scrollHeight;
        if (!isOverflowing) return;
      }

      if (state.text)
        popper.show(
          node,
          {
            get text() {
              return state.text;
            },
            get shortcut() {
              return state.shortcut;
            },
            class: theme.themeClass!,
            node,
          },
          { modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] },
        );
    };

    node.addEventListener('mouseenter', handleMouseEnter);
    node.addEventListener('mouseleave', popper.hide);

    return () => {
      popper.hide();
      node.removeEventListener('mouseenter', handleMouseEnter);
      node.removeEventListener('mouseleave', popper.hide);
    };
  };

function TooltipComponent(
  props: TooltipOptions & { class: string; node: HTMLElement },
) {
  return (
    <div class={bind(() => [props.class, styles.tooltip])}>
      {() => props.text}
      {() =>
        !!props.shortcut && <div class={styles.shortcut}>{props.shortcut}</div>
      }
    </div>
  );
}
