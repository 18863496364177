import { componentContext } from './mount/mount';

type Collection = 'ssr' | 'lazy';

const collections: Record<Collection, any[]> = {
  ssr: [],
  lazy: [],
};

export const registerInfo = (collection: Collection, ...message: any[]) => {
  collections[collection].push(message);
};

((typeof window === 'undefined' ? global : window) as any).info = {
  ssr() {
    for (const message of collections.ssr) console.log(...message);
    collections.ssr = [];
  },

  lazy() {
    for (const message of collections.lazy) console.log(...message);
    collections.lazy = [];
  },

  stack() {
    let context = componentContext.current;
    if (!context) return null;
    while (context) {
      if (Object.hasOwnProperty.call(context, 'component')) {
        console.log(context.component.name);
      }
      context = context.parent;
    }
  },
};
