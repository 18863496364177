export function bind<T, K extends keyof T>(obj: T, key: K): T[K];
export function bind<T>(get: () => T): T;
export function bind<T>(get: () => T, set: (value: T) => void): T;
export function bind(arg1: any, arg2?: any): any {
  if (typeof arg1 === 'object') {
    return {
      __bind: true,
      get $value() {
        return arg1[arg2];
      },
      set $value(value: any) {
        arg1[arg2] = value;
      },
    };
  }

  if (typeof arg2 === 'function') {
    return {
      __bind: true,
      get $value() {
        return arg1();
      },
      set $value(value: any) {
        arg2(value);
      },
    };
  }

  return {
    __bind: true,
    get $value() {
      return arg1();
    },
    set $value(_value: any) {
      console.warn('Cannot set value of a read-only binding.');
    },
  };
}

export interface Binding<T = any> {
  $value: T;
}

export function isBinding<T = any>(value: any): value is Binding<T> {
  return (
    value != null &&
    typeof value === 'object' &&
    '__bind' in value &&
    value?.__bind &&
    '$value' in value
  );
}

export function resolveBinding<T>(value: T | Binding<T>): T {
  return isBinding(value) ? value.$value : value;
}
