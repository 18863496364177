import type { ThemeFonts } from '../Theme';
import { fonts, requestFonts } from './fonts';

export const loadFonts = ({ google, typekit, custom }: ThemeFonts = {}) => {
  if (google?.families.length) {
    const display =
      google.display !== null ? `&display=${google.display || 'swap'}` : '';
    const subset = google.subsets?.length
      ? `&subset=${google.subsets.join(',')}`
      : '';
    const href = `https://fonts.googleapis.com/css2?${google.families
      .map((family) => `family=${family}`)
      .join('&')}${display}${subset}`;
    requestFonts(google.families.join('|'), href, true);
  }

  if (typekit?.id) {
    requestFonts(typekit.id, `https://use.typekit.net/${typekit.id}.css`);
  }

  if (custom) {
    for (const [family, { style, weight, ...files }] of Object.entries(
      custom,
    )) {
      fonts.css ??= {};
      fonts.css[family] = `@font-face {
        font-family: '${family}';
        src: ${Object.entries(files)
          .map(([format, url]) => `url('${url}') format('${format}')`)
          .join(', ')};
        font-weight: ${weight || 'normal'};
        font-style: ${style || 'normal'};
      };`;
    }
  }
};
