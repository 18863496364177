import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import { map, store, type Culture, type NodeFieldInfo } from '@donkeyjs/proxy';
import type { FieldRenderProps, StringFieldProps } from '../..';
import { Flag } from '../../../i18n/Flag';
import { session } from '../../../session';
import { getTheme } from '../../../styles';
import { Label } from '../../components/Label';
import styles from './HtmlString.module.css';
import { OptionButtons } from './OptionButtons';
import { useMask } from './useMask';
import { useStringEditor } from './useStringEditor';

export function HtmlStringField(props: FieldRenderProps<'string'>) {
  const state = useStringEditor(props);

  let element: HTMLElement | undefined = undefined;

  const valueProps = store.clone<StringFieldProps, HtmlStringProps>(props, {
    get value() {
      return state.value;
    },

    set value(value) {
      state.value = value;
      props.onchange?.(element);
    },

    cultures: props.field.cultures,

    oninput() {
      state.handleInput();
    },

    oninputmount(el) {
      element = el;
      return () => {
        element = undefined;
      };
    },

    get optional() {
      return !!props.field.schema?.optional;
    },

    get options() {
      return state.options;
    },
  });

  return jsxx(HtmlString, valueProps);
}

interface HtmlStringProps extends StringFieldProps {
  value: string;
  readonly cultures?: Record<Culture, NodeFieldInfo<any, any>>;

  readonly label?: JSX.Children;
  readonly helper?: JSX.Children;
  readonly optional?: boolean;
  readonly readonly?: boolean;
  readonly class?: JSX.ClassNames;
  readonly oninput?: () => void;
  readonly onmount?: JSX.OnMount;
  readonly oninputmount?: JSX.OnMount<
    HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement
  >;
}

export function HtmlString(props: HtmlStringProps) {
  return (
    <Label
      class={bind(() => ['input', props.class])}
      label={bind(() => props.label)}
      helper={bind(() => props.helper)}
      required={bind(() => !props.optional && !props.readonly)}
      onmount={props.onmount}
    >
      {() =>
        session.app.routerFollowsCultures && props.cultures ? (
          <div class={[styles.cultures, 'cultures']}>
            {Object.entries(props.cultures).map(([culture, field]) => (
              <div class={[styles.culture, 'culture']}>
                {jsxx(
                  Value,
                  store.clone(props, {
                    culture: culture as Culture,

                    get value() {
                      return field.value || '';
                    },

                    set value(value) {
                      field.value = value;
                    },
                  }),
                )}
              </div>
            ))}
          </div>
        ) : (
          jsxx(Value, props)
        )
      }
    </Label>
  );
}

function Value(props: HtmlStringProps & { culture?: Culture }) {
  const theme = getTheme();

  const { onchange, onkeydown, onvalueupdate, placeholder } = props.mask
    ? useMask({
        mask: props.mask,
        onkeydown: props.onkeydown,
        onchange: (value) => {
          props.value = value;
        },
      })
    : {
        onkeydown: props.onkeydown,
        placeholder: props.placeholder,
        onchange: props.oninput,
        onvalueupdate: undefined,
      };

  return (
    <>
      {props.prefix}
      {() =>
        !!props.culture && (
          <span class={[styles.flag, 'flag']}>
            <Flag culture={props.culture} />
          </span>
        )
      }
      {() =>
        props.options?.length && props.optionButtons ? (
          <OptionButtons
            value={bind(props, 'value')}
            options={bind(() => props.options!)}
            optionButtons={props.optionButtons}
          />
        ) : props.options ? (
          <select
            class={theme.class.select}
            value={bind(props, 'value')}
            onmount={props.oninputmount}
            onchange={props.oninput}
            onfocus={(ev) => props.onfocus?.(ev.target as HTMLElement)}
            onblur={(ev) => props.onblur?.(ev.target as HTMLElement)}
            onclick={props.onclick}
            onmousedown={props.onmousedown}
            onkeydown={props.onkeydown}
            disabled={bind(() => props.readonly)}
            placeholder={bind(() => props.placeholder)}
            name={bind(() => props.name)}
            autofocus={bind(() => props.autofocus)}
          >
            {() =>
              (!props.value || !!props.optional) &&
              !props.defaultOption && (
                <option value="">{props.placeholder || ''}</option>
              )
            }
            {map(
              () => props.options,
              (option) => (
                <option
                  value={option.value}
                  selected={bind(() =>
                    props.value === option.value ? true : undefined,
                  )}
                >
                  {option.label}
                </option>
              ),
            )}
          </select>
        ) : props.multiline ? (
          <textarea
            class={theme.class.textarea}
            value={bind(props, 'value')}
            onmount={props.oninputmount}
            oninput={props.oninput}
            onfocus={(ev) => props.onfocus?.(ev.target as HTMLElement)}
            onblur={(ev) => props.onblur?.(ev.target as HTMLElement)}
            onclick={props.onclick}
            onmousedown={props.onmousedown}
            onkeydown={props.onkeydown}
            disabled={bind(() => props.readonly)}
            placeholder={bind(() => props.placeholder)}
            name={bind(() => props.name)}
            autofocus={bind(() => props.autofocus)}
          />
        ) : (
          <input
            class={theme.class.input}
            value={bind(props, 'value')}
            onmount={props.oninputmount}
            type={bind(() =>
              props.password
                ? 'password'
                : props.email
                  ? 'email'
                  : props.inputType || 'text',
            )}
            autocomplete={bind(
              () => props.autocomplete || (props.email ? 'email' : undefined),
            )}
            oninput={onchange}
            onfocus={(ev) => props.onfocus?.(ev.target as HTMLElement)}
            onblur={(ev) => props.onblur?.(ev.target as HTMLElement)}
            onclick={props.onclick}
            onmousedown={props.onmousedown}
            onkeydown={onkeydown}
            onvalueupdate={onvalueupdate}
            disabled={bind(() => props.readonly)}
            placeholder={bind(() => placeholder)}
            name={bind(() => props.name)}
            autofocus={bind(() => props.autofocus)}
          />
        )
      }
      {props.suffix}
    </>
  );
}
