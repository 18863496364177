import type { RouteDefinition } from '@donkeyjs/core';
import { getGlobal, jsxx } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import { MessageRoute } from '../mail/MessageRoute';

const key = Symbol('system-routes');

interface SystemRoutes {
  routes: RouteDefinition[];
  register(...routes: RouteDefinition[]): void;
}

export function useSystemRoutes() {
  return getGlobal(key, () => {
    const value = store<SystemRoutes>({
      routes: [
        {
          name: 'Message',
          id: 'system.message',
          pathname: '/.message',
          isSystemRoute: true,
          render: () => jsxx(MessageRoute, {}),
        },
      ],
      register(...routes) {
        value.routes = [...value.routes, ...routes];
      },
    });
    return value;
  });
}
