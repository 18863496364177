import {
  Heading,
  IncreaseHeadingLevel,
  createView,
  getNow,
  session,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/proxy';
import { fullName } from '../../helpers/fullName';
import { I18nDashboard } from '../../i18n';
import { ImageBox } from '../components/ImageBox';

export const ViewCourseDashboard = createView<DataSchema, 'CourseOffering'>(
  {
    name: () => 'Student Dashboard',
    groupings: [
      {
        key: 'schedule',
        name: () => 'Running / Upcoming / Past',
        format(node, i18n) {
          return node.ends && node.ends < getNow()
            ? {
                name: i18n.get(I18nDashboard, 'Courses.Past') || '',
                sortIndex: 0,
              }
            : !node.ends || !node.starts || node.starts < getNow()
              ? {
                  name: i18n.get(I18nDashboard, 'Courses.Running') || '',
                  sortIndex: 0,
                }
              : {
                  name: i18n.get(I18nDashboard, 'Courses.Upcoming') || '',
                  sortIndex: 1,
                };
        },
      },
    ],
  },

  (props) => {
    return (
      <ImageBox
        item={bind(() => props.node)}
        smallImage
        tinted
        onmount={props.onmount}
        sidebar={<div class="sidebar">tbd</div>}
        header={
          <div class="heading">
            <Heading styleAs="subtitle">
              <a
                href={session.router.getPath(
                  props.selected ? { route: 'current' } : { node: props.node },
                )}
              >
                {() =>
                  !!props.node.isOnline &&
                  !props.selected &&
                  `${props.node.course.teachers
                    .map((teacher) => fullName(teacher.teacher))
                    .join(', ')}:${' '}`
                }
                <props.node.course.$.name readonly />
              </a>
            </Heading>
          </div>
        }
      >
        <div class="content">
          <IncreaseHeadingLevel>tbd</IncreaseHeadingLevel>
        </div>
      </ImageBox>
    );
  },
);
