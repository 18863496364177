import {
  Heading,
  IncreaseHeadingLevel,
  createView,
  getI18n,
  session,
  text,
} from '@donkeyjs/client';
import { formatName } from '@donkeyjs/core';
import { bind, updateComponent } from '@donkeyjs/jsx-runtime';
import { PhCaretRight } from '@donkeyjs/phosphor-icons';
import { formatCourseDates } from '../../helpers/formatCourseDates';
import { nextOffering } from '../../helpers/nextOffering';
import { I18nIL } from '../../i18n';
import { ImageBox } from '../components/ImageBox';
import { ReadMore } from '../components/ReadMore';
import styles from './ViewTeacher.module.css';

export const ViewTeacher = createView<DataSchema, 'Teacher'>(
  {
    name: () => 'Default teacher view',
    enableAsBlock: true,
    groupings: [
      {
        key: 'upcoming',
        name: () => 'Upcoming Courses',
        format(node, i18n) {
          const upcoming = node.courses.filter((rel) =>
            nextOffering(rel.course),
          );
          return upcoming.length
            ? {
                name: i18n.get(I18nIL, 'Teacher.Upcoming') || '',
                sortIndex: 0,
              }
            : { name: i18n.get(I18nIL, 'Teacher.Past') || '', sortIndex: 1 };
        },
      },
    ],
  },

  function ViewTeacher(props) {
    const i18n = getI18n();

    return (
      <ImageBox
        class={bind(() => [props.class, { selected: props.selected }])}
        onmount={props.onmount}
        item={bind(() => props.node)}
        linkTo={bind(() =>
          props.selected
            ? { route: 'current' }
            : { onRoute: 'current', node: props.node },
        )}
        header={
          <Heading styleAs="subtitle">{() => formatName(props.node)}</Heading>
        }
        sidebar={() => {
          const upcoming = props.node.courses
            .map((rel) => {
              const upcoming = nextOffering(rel.course);
              return (upcoming && {
                course: rel.course,
                upcoming,
              })!;
            })
            .filter(Boolean);

          return (
            !!upcoming.length && (
              <IncreaseHeadingLevel>
                <Heading styleAs={'heading1'}>
                  {text(I18nIL, 'Teacher.UpcomingCourses', {
                    plural: upcoming.length > 1,
                  })}
                </Heading>
                {() =>
                  upcoming.map(({ course, upcoming }) => (
                    <a href={session.router.getPath({ node: upcoming })}>
                      <div class={styles.event}>
                        <div>
                          <strong>{() => course.name}</strong>
                          <br />
                          {() => formatCourseDates(upcoming.days, i18n)}
                        </div>
                        <div>
                          <PhCaretRight weight="bold" />
                        </div>
                      </div>
                    </a>
                  ))
                }
              </IncreaseHeadingLevel>
            )
          );
        }}
        footer={<props.node.$.testimonials />}
      >
        <div>
          <IncreaseHeadingLevel>
            <props.node.$.biography />
            <div class={styles.details}>
              {() => props.selected && <props.node.$.longBiography />}
            </div>
            <ReadMore
              node={bind(() => props.node)}
              isActive={bind(() => props.selected)}
            />
          </IncreaseHeadingLevel>
        </div>
      </ImageBox>
    );
  },
);

if (import.meta.hot) {
  import.meta.hot.accept((hot) => {
    updateComponent(ViewTeacher.component as any, hot?.ViewTeacher.component);
  });
}
