import type { NodeTypename, Schema } from '@donkeyjs/proxy';

type Access = 'allow' | 'deny';
export type AccessType = 'read' | 'update' | 'insert' | 'delete';

export interface PermissionsInput<S extends Schema> {
  [role: string]: RolePermissions<S>;
}

export interface Permissions<S extends Schema> {
  roles: PermissionsInput<S>;
  can: <S extends Schema, Typename extends NodeTypename<S>>(
    roles: string[],
    access: AccessType,
    typename: Typename,
  ) => Can<keyof S['nodes'][Typename]['fields']>;
  with: (input: PermissionsInput<S>) => Permissions<S>;
}

export interface Can<Fields = string> {
  node: boolean;
  field: (field: Fields) => boolean;
}

type RolePermissions<S extends Schema> = {
  '*'?: NodePermissions<S, NodeTypename<S>>;
} & {
  [Typename in NodeTypename<S>]?: NodePermissions<S, Typename>;
};

interface NodePermissions<S extends Schema, Typename extends NodeTypename<S>> {
  '*'?: Access;
  read?: Access;
  update?: Access;
  insert?: Access;
  delete?: Access;
  fields?: {
    [Key in keyof S['nodes'][Typename]['fields']]?: FieldPermissions;
  };
}

interface FieldPermissions {
  '*'?: Access;
  read?: Access;
  update?: Access;
}

const can = <
  S extends Schema,
  P extends PermissionsInput<S>,
  Typename extends NodeTypename<S>,
>(
  permissions: P,
  roles: string[],
  access: 'read' | 'update' | 'insert' | 'delete',
  typename: Typename,
): Can<keyof S['nodes'][Typename]['fields']> => {
  const rules: NodePermissions<S, Typename>[] = [];

  for (const role of roles) {
    if (role in permissions) {
      if ('*' in permissions[role]) rules.push(permissions[role]['*']!);
      if (typename in permissions[role])
        rules.push(permissions[role][typename]!);
    }
  }

  const node = rules.reduce((prev, rule) => {
    const found = rule[access] || rule['*'];
    return found === 'deny' ? false : found === 'allow' ? true : prev;
  }, false);

  return {
    field: (name) =>
      rules.reduce((prev, rule) => {
        const found =
          rule.fields?.[name]?.[access === 'read' ? 'read' : 'update'] ||
          rule.fields?.[name]?.['*'];
        return found === 'deny' ? false : found === 'allow' ? true : prev;
      }, node),
    node,
  };
};

export const extendPermissions = <S extends Schema>(
  permissions: PermissionsInput<S>,
  extension: PermissionsInput<S>,
): Permissions<S> => {
  const result: any = { ...permissions };

  for (const role in extension) {
    const extensionRole = extension[role];
    const sourceRole = permissions[role];
    const resultRole: any = (result[role] = {
      ...permissions[role],
      ...extensionRole,
    });

    for (const typename in extensionRole) {
      if (typename === 'with') continue;

      const extensionNode = extensionRole[typename]!;
      const sourceNode = sourceRole?.[typename];
      const resultNode: any = (resultRole[typename] = {
        ...sourceNode,
        ...extensionNode,
      });

      if (sourceNode?.fields || extensionNode.fields)
        resultNode.fields = {
          ...sourceNode?.fields,
          ...extensionNode.fields,
        };

      if ('fields' in extensionNode) {
        for (const key in extensionNode.fields) {
          resultNode.fields[key] = {
            ...sourceNode?.fields?.[key],
            ...extensionNode.fields[key],
          };
        }
      }
    }
  }

  return {
    roles: result,
    can: (roles, access, typename) =>
      can(result, roles as any, access, typename),
    with: (input) => extendPermissions(result, input),
  };
};

export const createPermissions = <S extends Schema = DataSchema>(
  input: PermissionsInput<S>,
): Permissions<S> => extendPermissions({}, input);

export const defaultPermissions = () =>
  createPermissions<DataSchema>({
    sysadmin: {
      '*': { '*': 'allow' },
      User: { fields: { password: { read: 'deny' } } },
    },
    admin: {
      '*': { '*': 'allow' },
      User: { fields: { password: { read: 'deny' } } },
    },
    visitor: {
      App: { read: 'allow' },
      Block: { read: 'allow' },
      BlockLink: { read: 'allow' },
      Exception: { insert: 'allow' },
      File: { read: 'allow' },
      FileRef: { read: 'allow' },
      MessageTemplate: { read: 'allow' },
      Route: { read: 'allow' },
      Tag: { read: 'allow' },
      TagGroup: { read: 'allow' },
      TagFiles: { read: 'allow' },
      TagRoutes: { read: 'allow' },
      Trace: { insert: 'allow' },
      TraceEvent: { insert: 'allow' },
      User: { read: 'allow' },
    },
  });
