import { bind, meta, type DataError } from '@donkeyjs/proxy';
import { Dialog } from '../components/Dialog/Dialog';
import { applyDonkeyTheme } from '../donkey';
import { text } from '../i18n/i18n';
import { Form } from '../layout';
import { setLayout } from '../layout/layoutContext';
import { LoginForm } from './LoginForm';
import { getLoginForm } from './getLoginForm';
import { getUserContext, type UserLogin } from './getUserContext';

interface LoginDialogProps {
  readonly isConfirm?: boolean;
  readonly onLogin: (login: UserLogin) => Promise<DataError[] | void>;
  readonly onClose: () => void;
}

export function LoginDialog(props: LoginDialogProps) {
  const user = getUserContext();
  const formData = getLoginForm();

  setLayout('html:flow');
  const theme = applyDonkeyTheme(user, 'DARK');

  async function submit() {
    if (meta(formData).validate().length) return;
    const errors = await props.onLogin({
      email: formData.email,
      password: formData.password,
    });
    if (!errors?.length) props.onClose();
    meta(formData).errors = errors || [];
    return errors;
  }

  return (
    <Dialog
      size="sm"
      buttons={
        <>
          <button
            type="submit"
            class={bind(() => [theme().class.button, 'default'])}
            onclick={() => {
              submit();
            }}
          >
            {text(
              bind(() =>
                props.isConfirm ? 'Common.Continue' : 'Account.ActionLogin',
              ),
            )}
          </button>
          <button
            type="button"
            class={bind(() => [theme().class.button])}
            onclick={() => props.onClose()}
          >
            {text('Common.Cancel')}
          </button>
        </>
      }
      onClose={async (confirm) => {
        if (confirm) await submit();
        else props.onClose();
      }}
    >
      <Form>
        <LoginForm form={formData} isConfirm={bind(() => props.isConfirm)} />
      </Form>
    </Dialog>
  );
}
