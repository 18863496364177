import {
  componentContext,
  mount,
  onUnmount,
  type Component,
  type ComponentProps,
  type DomNode,
} from '@donkeyjs/jsx-runtime';
import {
  createPopper,
  type Instance,
  type Modifier,
  type OptionsGeneric,
  type VirtualElement,
} from '@popperjs/core';
import { session } from '../session';

export const usePopper = <Props extends object>(
  component: Component<Props>,
  parent?: HTMLElement | (() => HTMLElement),
) => {
  if (session.dom.ssr)
    return {
      show: () => {},
      hide: () => {},
      toggle: () => {},
      update: () => {},
      isVisible: () => false,
    };

  let dispose: (() => void) | undefined;
  let popperElement: DomNode | undefined;
  let popperInstance: Instance | undefined;
  const parentContext = componentContext.current;

  const show = <TModifier extends Partial<Modifier<any, any>>>(
    element: HTMLElement | VirtualElement,
    props: ComponentProps<Props> | undefined,
    {
      parent: optionsParent,
      ...options
    }: Partial<
      OptionsGeneric<TModifier> & { parent?: HTMLElement | null }
    > = {},
  ) => {
    if (popperElement) return;
    const onParent = optionsParent || parent;
    [dispose, popperElement] = mount(
      session.dom,
      component,
      props,
      [],
      (typeof onParent === 'function' ? onParent() : onParent) || document.body,
      parentContext,
    );
    if (popperElement)
      popperInstance = createPopper(
        element,
        popperElement as HTMLElement,
        options,
      );
  };

  const update = (newReference?: HTMLElement) => {
    if (newReference && popperInstance)
      popperInstance.state.elements.reference = newReference;

    popperInstance?.update();
  };

  const hide = () => {
    popperInstance?.destroy();
    dispose?.();

    popperElement = undefined;
    popperInstance = undefined;
    dispose = undefined;
  };

  const toggle = <TModifier extends Partial<Modifier<any, any>>>(
    element: HTMLElement,
    props: Props | undefined,
    options: Partial<
      OptionsGeneric<TModifier> & { parent?: HTMLElement | null }
    > = {},
  ) => {
    if (popperElement) {
      hide();
    } else {
      show(element, props, options);
    }
  };

  onUnmount(hide);

  return { show, hide, toggle, update, isVisible: () => !!popperElement };
};
