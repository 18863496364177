import { jsxx } from '@donkeyjs/jsx-runtime';
import {
  store,
  type DataNode,
  type NodeFieldInfo,
  type NodeTypename,
} from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { InlineString } from '../string/InlineString';
import { getNumberValue } from '../float/numberHelpers';
import { getI18n } from '../../../i18n/getI18n';

export function InlineInt(props: FieldRenderProps<'int'>) {
  const i18n = getI18n();

  return jsxx(
    InlineString,
    store.clone(props, {
      field: {
        ...props.field,
        get value() {
          return getNumberValue(
            props.field.value,
            props.formatter,
            i18n,
            props.readonly,
          );
        },
        set value(value) {
          props.field.value = value === '' ? undefined : Number.parseInt(value);
        },
      } as NodeFieldInfo<
        string,
        DataNode<DataSchema, NodeTypename<DataSchema>>
      >,
    }),
  );
}
