import { bind, preventDefault } from '@donkeyjs/jsx-runtime';
import { setTheme, type Theme } from '../../styles';
import styles from './NativeMenu.module.css';

export type NativeMenuFeature = 'checkboxes';

export interface NativeMenuProps {
  open?: boolean;
  div?: HTMLDivElement;
  class?: string;
  theme?: Theme;
  onClickAway?(ev: MouseEvent): void;
  children?: JSX.Children;
}

export function NativeMenu(props: NativeMenuProps) {
  if (props.theme) {
    setTheme(props.theme);
  }

  return (
    <>
      <div
        class={bind(() => [
          styles.nativeMenu,
          'background-reset',
          props.class,
          { [styles.open]: props.open },
        ])}
        data-show={bind(() => props.open || undefined)}
        onmousedown={preventDefault}
        onmount={(div) => {
          props.div = div;
          return () => {
            props.div === undefined;
          };
        }}
      >
        {props.children}
      </div>

      {() =>
        !!props.open &&
        !!props.onClickAway && (
          <div
            class={styles.clickaway}
            onmousedown={(ev: MouseEvent) => {
              ev.preventDefault();
              ev.stopPropagation();
            }}
            onclick={(ev: MouseEvent) => {
              ev.preventDefault();
              props.onClickAway!(ev);
            }}
            oncontextmenu={(ev: MouseEvent) => {
              ev.preventDefault();
              props.onClickAway!(ev);
            }}
          />
        )
      }
    </>
  );
}
