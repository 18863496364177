import type { OrderableItem, OrderableKey } from '../../../client';
import type {
  OrderDiscountFields,
  OrderTransactionFields,
} from '../getOrderOverview';

export interface CalculatedItemDiscounts {
  discounts: {
    node: OrderDiscountFields;
    amount: number;
  }[];
  discountsFromCoupons: {
    node: OrderTransactionFields;
    amount: number;
  }[];
  totalDiscount: number;
  originalPrice: number;
  reducedPrice: number;
}

export function calculateItemDiscounts(
  forPrice: number,
  forQuantity: number,
  candidateDiscounts: OrderDiscountFields[] | null,
  couponDiscounts: OrderTransactionFields[] | null,
): CalculatedItemDiscounts {
  const now = new Date();
  const discounts = (candidateDiscounts || [])
    .filter((discount) => isValidDiscount(now, discount))
    .map((discount) => ({
      node: discount,
      amount: discount.isPercentage
        ? forPrice * forQuantity * discount.amount
        : discount.amount * forQuantity,
    }))
    .filter((discount) => discount.amount > 0);

  const discountsFromCoupons = (couponDiscounts || []).map((discount) => ({
    node: discount,
    amount: discount.isPercentage
      ? forPrice * discount.amount
      : discount.amount,
  }));

  const totalDiscounts = discounts.reduce(
    (total, discount) => total + discount.amount,
    0,
  );
  const totalCouponDiscounts = discountsFromCoupons.reduce(
    (total, discount) => total + discount.amount,
    0,
  );
  return {
    discounts,
    discountsFromCoupons,
    totalDiscount: totalDiscounts + totalCouponDiscounts,
    originalPrice: forPrice * forQuantity,
    reducedPrice:
      forPrice * forQuantity - totalDiscounts - totalCouponDiscounts,
  };
}

export function isValidDiscount(
  date: Date,
  discount: OrderDiscountFields,
  orderable?: OrderableItem<OrderableKey>,
): boolean {
  if (
    (discount.validFrom && discount.validFrom > date) ||
    (discount.validUntil && discount.validUntil < date)
  )
    return false;
  if (orderable) {
    if (!orderable?.discounts?.length) return false;
    return orderable.discounts.some((match) => match.id === discount.id);
  }
  return true;
}
