import {
  meta,
  store,
  type DataList,
  type DataNode,
  type NodeTypename,
} from '@donkeyjs/proxy';
import { getUserContext } from '../authentication';
import type { DraggableOptions } from './useDraggable';
import type {
  AcceptedDrag,
  DraggedItem,
  DropPosition,
  UseDropZoneOptions,
} from './useDropZone';

export interface SortableOptions {
  listKey: unknown;
  item: DraggedItem | null;
  handle?: string;
  onHover?: (dragged: DraggedItem, position: DropPosition) => () => void;
  onDrop?: (dragged: DraggedItem, position: DropPosition) => void;
}

export interface SortableItem<
  T extends Exclude<AcceptedDrag, `Create${NodeTypename<DataSchema>}`>,
> {
  draggable: DraggableOptions<T>;
  accept: UseDropZoneOptions | undefined;
}

export const useSortable = <
  T extends Exclude<AcceptedDrag, `Create${NodeTypename<DataSchema>}`>,
>(
  options: SortableOptions,
): SortableItem<T> => {
  return store({
    draggable: options.item
      ? ({
          item: options.item,
          handle: options.handle,
          listKey: options.listKey,
        } as any)
      : { item: null },
    accept: options.item
      ? {
          key: options.item,
          onDrag(item) {
            if (item.listKey === options.listKey)
              return {
                positions: ['before', 'after'],
                hover: (positions) => options.onHover?.(item, positions),
                drop: (positions) => options.onDrop?.(item, positions),
              };
          },
        }
      : undefined,
  });
};

export interface SortableNodeOptions<T extends NodeTypename<DataSchema>> {
  listKey: unknown;
  item: DataNode<DataSchema, T> | null;
  handle?: string;
  onHover?: (dragged: DraggedItem<T>, position: DropPosition) => () => void;
  onDrop?: (dragged: DraggedItem<T>, position: DropPosition) => void;
}

export interface SortableNode<T extends NodeTypename<DataSchema>> {
  draggable: DraggableOptions<T>;
  accept: UseDropZoneOptions | undefined;
  readonly class: JSX.ClassNames;
}

export const useSortableNode = <T extends NodeTypename<DataSchema>>(
  options: SortableNodeOptions<T>,
): SortableNode<T> => {
  return {
    get class() {
      return { 'node-testing': meta(options.item)?.isTest };
    },
    draggable: options.item
      ? ({
          item: options.item,
          handle: options.handle,
          listKey: options.listKey,
        } as any)
      : { item: null },
    accept: options.item
      ? {
          key: options.item,
          onDrag(item) {
            if (
              item.listKey === options.listKey &&
              item.type === 'node' &&
              item.node.__typename === options.item?.__typename &&
              item.node.id !== options.item?.id
            ) {
              return {
                positions: ['before', 'after'],
                hover: (position) => options.onHover?.(item as any, position),
                drop: (position) => options.onDrop?.(item as any, position),
              };
            }
          },
        }
      : undefined,
  };
};

export type SortableListFunction<
  T extends NodeTypename<DataSchema> = NodeTypename<DataSchema>,
> = (
  item: DataNode<DataSchema, T>,
  options?: { handle?: string },
) => SortableNode<T>;

export const useSortableList = <T extends NodeTypename<DataSchema>>(
  list: DataList<DataSchema, T>,
  options?: { listKey?: string },
): SortableListFunction<T> => {
  const user = getUserContext();
  const listKey = options?.listKey || list;

  const moveFromTo = (
    item: DataNode<DataSchema, T>,
    dragged: DraggedItem<T>,
    position: DropPosition,
  ): [number, number] => {
    const from = list.indexOf((dragged as any).node);
    const target = list.indexOf(item as any);
    const to = position === 'after' ? target + 1 : target;
    return [from, to];
  };

  return (item, options) =>
    meta(list).isSortable && user.can('update', meta(list).typename as T)
      ? useSortableNode({
          listKey,
          item,
          handle: options?.handle,

          onHover(dragged, position) {
            return list.testMove(...moveFromTo(item, dragged, position));
          },
          onDrop(dragged, position) {
            list.move(...moveFromTo(item, dragged, position));
          },
        })
      : {
          class: [],
          draggable: { item: null as any },
          accept: undefined,
        };
};
