import { Image, getUserContext, session } from '@donkeyjs/client';
import type { RouteLink } from '@donkeyjs/core';
import { bind } from '@donkeyjs/jsx-runtime';
import type { DataNode, NodeTypename } from '@donkeyjs/proxy';
import { PartnerCorner } from '../views/components/PartnerCorner';
import styles from './ImageBox.module.css';

interface ImageBoxProps<Typename extends NodeTypename<DataSchema>> {
  class?: JSX.ClassNames;
  item: DataNode<DataSchema, Typename>;
  linkTo?: RouteLink<Typename>;
  onmount?: JSX.OnMount<HTMLDivElement>;
  sidebar?: JSX.Children;
  header?: JSX.Children;
  footer?: JSX.Children;
  smallImage?: boolean;
  tinted?: boolean;
  grayed?: boolean;
  showPartnerCorner?: boolean;
  children?: JSX.Children;
}

export function ImageBox<Typename extends NodeTypename<DataSchema>>(
  props: ImageBoxProps<Typename>,
) {
  const user = getUserContext();

  return (
    <div
      class={bind(() => [
        'box',
        styles.box,
        props.class,
        {
          [styles.smallImage]: props.smallImage,
          [styles.tinted]: props.tinted,
          [styles.grayed]: props.grayed,
        },
      ])}
      onmount={props.onmount}
    >
      <div class={styles.image}>
        <a
          disabled={bind(() => !props.linkTo || user.can('update', props.item))}
          href={bind(() =>
            props.linkTo ? session.router.getPath(props.linkTo) : undefined,
          )}
        >
          {() =>
            (props.item as any).images?.length > 0 && (
              <Image
                file={bind(() => (props.item as any).images[0])}
                ratio={1}
              />
            )
          }
        </a>
      </div>
      {props.header && <div class={styles.header}>{props.header}</div>}
      <div class={styles.children}>{props.children}</div>
      {props.sidebar && <div class={styles.sidebar}>{props.sidebar}</div>}
      {() => !!props.showPartnerCorner && <PartnerCorner />}
      {props.footer && <div class={styles.footer}>{props.footer}</div>}
    </div>
  );
}
