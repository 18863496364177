import { bind, jsxx, live } from '@donkeyjs/jsx-runtime';
import { store, type MarkupString } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { getI18n, type I18nContext } from '../../../i18n/getI18n';
import { LoadingSingleLine } from '../../../loaders';
import { Markup, type MarkupProps } from '../../markup';
import inlineStyles from '../../inlineLayout.module.css';
import { useCalendarPopup } from './useCalendarPopup';
import { useDateComponent } from './useDateComponent';
import { useDateEditor } from './useDateEditor';

export function InlineDate(props: FieldRenderProps<'date'>) {
  const i18n = getI18n();

  const state = useDateComponent(props);

  return () => {
    if (props.field.loading) return <LoadingSingleLine size={'small'} />;

    if (!props.readonly) return jsxx(InlineDateEditor, props);
    if (!props.field.value) return null;

    return format(state, props, i18n);
  };
}

function InlineDateEditor(props: FieldRenderProps<'date'>) {
  const state = useDateEditor(props);
  const i18n = getI18n();

  let hasFocus = false;
  let element: HTMLElement | undefined;

  const calendarProps = store({
    input: {
      field: props,
      state,
    },
    get date() {
      return props.field.value || new Date();
    },
    set date(value) {
      props.field.value = value;
    },
    includeTime: true,
    hasFocus: true,
    onClose() {
      calendar.hide();
      element?.focus();
    },
  });

  const calendar = useCalendarPopup(calendarProps);

  const markupProps = store<MarkupProps<MarkupString | string>>({
    get value() {
      return format(state, props, i18n);
    },
    placeholder: props.label,
    readonly: false,
  });

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    hasFocus = true;
    calendarProps.hasFocus = true;
    element = e.target as HTMLElement;
    calendar.toggle(element);
  };

  const handleBlur = () => {
    hasFocus = false;
    if (!calendarProps.hasFocus) {
      calendar.hide();
    }
  };

  live(() => {
    if (!calendarProps.hasFocus && !hasFocus) {
      calendar.hide();
    }
  });

  return (
    <span
      class={bind(() => [
        inlineStyles.editableLink,
        props.class,
        { [inlineStyles.active]: props.parentOutline?.active },
      ])}
      role="button"
      tabindex={0}
      onclick={handleClick}
      onkeydown={(ev: KeyboardEvent) => {
        if (ev.key === 'Enter') calendar.toggle(ev.target as HTMLElement);
      }}
      onblur={handleBlur}
    >
      {jsxx(Markup, markupProps)}
    </span>
  );
}

const format = (
  state: ReturnType<typeof useDateComponent>,
  p: FieldRenderProps<'date'>,
  i18n: I18nContext,
): string => {
  if (!p.field.value) return '';

  if (
    state.isRangeStart &&
    !state.rangeIsCollapsed &&
    p.formatRange !== null &&
    !p.noRange
  ) {
    return p.formatRange
      ? p.formatRange(p.field.value, state.rangeEnds)
      : [
          i18n.formatDate(
            p.field.value,
            p.formatter || (state.fullDay ? 'PPPP' : 'PPPPp'),
          ),
          !!state.rangeEnds &&
            i18n.formatDate(
              state.rangeEnds,
              p.formatter || (state.fullDay ? 'PPPP' : 'PPPPp'),
            ),
        ]
          .filter(Boolean)
          .join(' - ');
  }

  return i18n.formatDate(
    p.field.value,
    p.formatter || (state.fullDay ? 'PPPP' : 'PPPPp'),
  );
};
