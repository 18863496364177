import { generateNodeId } from '@donkeyjs/proxy';
import type { TrackingContext } from './trackingContext';

interface LinkClickBeacon {
  lid: string;
  context: TrackingContext;
}

interface PageViewBeacon {
  routeId: string;
  nodeId?: string;
}

export const sendBeacon = (data: LinkClickBeacon | PageViewBeacon) => {
  if (!navigator.sendBeacon) return;

  const query = `
    mutation Beacon($data: JSON) {
        beacon(data: $data)
      }
    `;

  const variables = {
    data: {} as any,
  };

  if ('lid' in data) {
    variables.data.linkClick = {
      lid: data.lid,
      sid: data.context.sid,
    };
  } else if ('routeId' in data) {
    let visitorHash = localStorage.getItem('vh');
    if (!visitorHash) {
      visitorHash = generateNodeId();
      localStorage.setItem('vh', visitorHash);
    }
    variables.data.pageView = {
      routeId: data.routeId,
      nodeId: data.nodeId,
      hash: visitorHash,
    };
  }

  const body = JSON.stringify({ query, variables });

  navigator.sendBeacon(
    '/graphql',
    new Blob([body], { type: 'application/json' }),
  );
};
