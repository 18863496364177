import type { SchemaMeta } from '@donkeyjs/proxy';

export const baseSchemaMeta: SchemaMeta<DataSchema> = {
  File: {
    searchVectors: {
      name: 'A',
      fileExtension: 'A',
      fileType: 'B',
      description: 'B',
      credits: 'C',
    },
  },
  Message: {
    searchVectors: {
      subject: 'A',
      body: 'B',
      fromName: 'C',
      toName: 'C',
      fromEmail: 'C',
      toEmail: 'C',
    },
  },
  Route: {
    searchVectors: {
      name: 'A',
      metaTitle: 'A',
      'blocks.text': 'B',
      metaDescription: 'B',
    },
  },
  TagFiles: {
    join: { file: 'tag', tag: 'file' },
  },
  User: {
    searchVectors: {
      firstName: 'A',
      lastName: 'A',
      middleName: 'B',
      email: 'B',
    },
  },
};
