import type {
  MarkupFormatting,
  MarkupString,
  MarkupStringProcessor,
} from '@donkeyjs/proxy';
import type { BlockEditorInfo } from '../../fields/blocks/BlocksEditor';
import type { TextBlockType } from '../../fields/blocks/helpers/getTextBlockElement';
import { clipboard } from './clipboard';

export interface MarkupInterface {
  multiline: boolean;
  passThroughKeys?: string[];
  splice(
    processor: MarkupStringProcessor,
    index: number,
    count: number,
    add?: string,
  ): void;
  insertText(
    processor: MarkupStringProcessor,
    fromIndex: number,
    toIndex: number,
    add: string,
  ): void;
  insertParagraphText(
    processor: MarkupStringProcessor,
    fromIndex: number,
    toIndex: number,
    add: (MarkupString | string)[],
  ): [textEndsAtBlock: BlockEditorInfo, textEndsAtIndex: number] | null;
  removeText(
    processor: MarkupStringProcessor,
    index: number,
    count: number,
  ): void;
  removeTextForward(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): [mergedWith: BlockEditorInfo, index: number] | null | undefined;
  removeTextBackward(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): [mergedWith: BlockEditorInfo, index: number] | null | undefined;
  toggleFormatting(
    processor: MarkupStringProcessor,
    index: number,
    toIndex: number,
    formatting: MarkupFormatting['f'],
  ): void;
  split(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): BlockEditorInfo | null;
  merge(
    processor: MarkupStringProcessor,
    direction: 'forward' | 'backward',
  ): [mergedWith: BlockEditorInfo, index: number] | null;
  getType(): TextBlockType | null | undefined;
  setType(
    type: TextBlockType | null | undefined,
  ): HTMLElement | null | undefined;
  getAlign(): 'left' | 'center' | 'right' | 'justify';
  setAlign(align: 'left' | 'center' | 'right' | 'justify'): void;

  copy(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): Promise<void>;
  cut(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): Promise<void>;
  paste(
    processor: MarkupStringProcessor,
    from: number,
    to: number,
  ): Promise<
    [pastedEndsAtBlock: BlockEditorInfo, pastedEndsAtIndex: number] | null
  >;
}

export const singleBlockMarkupInterface: MarkupInterface = {
  multiline: false,

  passThroughKeys: ['Enter', 'Escape'],

  splice(processor, index, count, add) {
    processor.splice(index, count, add);
  },

  insertText(processor, fromIndex, toIndex, add) {
    processor.replaceText(fromIndex, toIndex, add);
  },

  insertParagraphText(processor, fromIndex, toIndex, paragraphs) {
    let index = fromIndex;
    let isFirst = true;
    for (const paragraph of paragraphs) {
      this.insertText(processor, index, isFirst ? toIndex : index, paragraph);
      index += paragraph.length;
      isFirst = false;
    }
    return null;
  },

  removeText(processor, index, count) {
    processor.removeText(index, count);
  },

  removeTextForward(processor, from, to) {
    processor.removeTextForward(from, to);
    return null;
  },

  removeTextBackward(processor, from, to) {
    processor.removeTextBackward(from, to);
    return null;
  },

  toggleFormatting(processor, index, toIndex, formatting) {
    processor.toggleFormatting(index, toIndex, formatting);
  },

  split() {
    return null;
  },

  merge() {
    return null;
  },

  getType() {
    return undefined;
  },

  setType() {
    return undefined;
  },

  getAlign() {
    return 'left';
  },

  setAlign() {},

  async copy(processor, from, to) {
    await clipboard.copy(processor.extract(from, to));
  },

  async cut(processor, from, to) {
    await clipboard.copy(processor.extract(from, to));
    processor.removeText(from, to - from);
  },

  async paste(processor, from, to) {
    return clipboard.paste({
      onText: (text) =>
        this.insertParagraphText(
          processor,
          from,
          to,
          text.replace(/\r/g, '').split('\n'),
        ),
      onMarkup: (markup) =>
        this.insertParagraphText(
          processor,
          from,
          to,
          markup.map((block) => block.markup),
        ),
    });
  },
};
