import { bind, setState } from '@donkeyjs/jsx-runtime';
import { PhEye, PhEyeSlash, PhPlus } from '@donkeyjs/phosphor-icons';
import {
  isDataList,
  map,
  meta,
  type DataList,
  type DataNode,
  type InsertableNode,
  type NodeTypename,
  type Schema,
} from '@donkeyjs/proxy';
import { getUserContext } from '../authentication';
import { attachChangelog, tooltip } from '../donkey';
import { getI18n } from '../i18n/getI18n';
import { text } from '../i18n/i18n';
import { getTheme } from '../styles';
import type { ViewMode } from '../views';
import styles from './ListControls.module.css';

export function ListControls<
  S extends Schema,
  Typename extends NodeTypename<S>,
>(props: {
  readonly list: DataList<S, Typename> | null | undefined;
  readonly drafts: DataNode<S, Typename>[];
  draftsOpen?: boolean;
  readonly addButton?: boolean;
  readonly addButtonLabel?: JSX.Children;
  readonly addAsDraft?: boolean;
  readonly defaultValues?: InsertableNode<DataNode<S, Typename>>;
  readonly modeButtons?: boolean;
  readonly modes?: ViewMode[];
  mode?: ViewMode;
  readonly isInIsolation?: boolean;
  readonly onAdd?: (node: DataNode<S, Typename>) => void;
}) {
  const user = getUserContext();
  const i18n = getI18n();
  const theme = getTheme();
  const userI18n = getI18n(true);

  const state = setState({
    get showAddButton() {
      return (
        (!props.addButton == null || !!props.addButton) &&
        props.list &&
        isDataList(props.list) &&
        user.can('insert', meta(props.list).typename as any)
      );
    },
    get showCmsControls() {
      return this.showAddButton;
    },
    get showModeButtons() {
      return !!props.modeButtons && !!props.modes?.length;
    },
  });

  return () => {
    if (props.isInIsolation) return null;
    if (!state.showCmsControls && !state.showModeButtons) return null;

    return (
      <div
        class={bind(() => [
          'list-controls',
          styles.listControls,
          { isolated: props.isInIsolation },
        ])}
      >
        {() =>
          state.showCmsControls && (
            <div class={styles.cmsControls}>
              {() =>
                state.showAddButton && (
                  <button
                    type="button"
                    class={[theme.class.button, 'default']}
                    onclick={() => {
                      if (props.list && isDataList<S, Typename>(props.list)) {
                        const inserted = props.list?.insertAfter(
                          undefined,
                          props.addAsDraft
                            ? ({
                                draft: true,
                                ...props.defaultValues,
                              } as any)
                            : { ...props.defaultValues },
                        );
                        props.onAdd?.(inserted[0]);
                      }
                    }}
                  >
                    <PhPlus weight="bold" />
                    <span>
                      {props.addButtonLabel ??
                        text(
                          'Common.CreateNode',
                          {
                            capitalize: true,
                            forUser: true,
                          },
                          userI18n.getNodeName(
                            meta(props.list!)
                              .typename as NodeTypename<DataSchema>,
                          ),
                        )}
                    </span>
                  </button>
                )
              }
              {() =>
                props.drafts.length > 0 && (
                  <button
                    type="button"
                    class={theme.class.button}
                    onclick={() => {
                      props.draftsOpen = !props.draftsOpen;
                    }}
                    onmount={attachChangelog('list-drafts-button')}
                  >
                    {() =>
                      props.draftsOpen ? (
                        <PhEye weight="fill" />
                      ) : (
                        <PhEyeSlash weight="fill" />
                      )
                    }
                    <span>
                      {() =>
                        text(
                          'Common.DraftsCount',
                          { forUser: true },
                          props.drafts.length,
                          !!props.draftsOpen,
                        )
                      }
                    </span>
                  </button>
                )
              }
            </div>
          )
        }
        {() =>
          state.showModeButtons && (
            <div class={styles.group} role="group">
              {map(
                () => props.modes,
                (mode) => (
                  <button
                    type="button"
                    class={bind(() => [
                      theme.class.button,
                      { active: mode === props.mode },
                    ])}
                    onclick={() => {
                      props.mode = mode;
                    }}
                    onmount={tooltip({ text: mode.name(i18n) })}
                  >
                    {mode.icon}
                  </button>
                ),
              )}
            </div>
          )
        }
      </div>
    );
  };
}
