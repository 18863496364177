import { createView, getI18n } from '@donkeyjs/client';
import { PhTrash } from '@donkeyjs/phosphor-icons';
import { meta } from '@donkeyjs/proxy';
import styles from './ViewCourseOffering.module.css';

export const ViewCourseDayPropertiesList = createView<DataSchema, 'CourseDay'>(
  { name: () => 'Properties list' },

  function ViewCourseDayPropertiesList(props) {
    const i18n = getI18n(true);
    return (
      <div class="list-item">
        <div class={styles.propertyName}>
          <span>{i18n.getNodeName('CourseDay')}</span>
          <button type="button" onclick={() => meta(props.node).delete()}>
            <PhTrash weight="fill" />
          </button>
        </div>
        <props.node.$.starts />
        <props.node.$.ends />
        <props.node.$.place />
      </div>
    );
  },
);
