import { batch, computed, type ReadonlySignal } from '@preact/signals-core';
import type { Culture } from '../Culture';
import { changeMatch } from '../helpers/changeMatch';
import { extendStatusFragment } from '../helpers/extendStatusFragment';
import { generateNodeId } from '../helpers/generateNodeId';
import { map, type MappedList } from '../helpers/map';
import { meta } from '../helpers/meta';
import { sortNodes } from '../helpers/sortNodes';
import type { ValidationError } from '../helpers/validate';
import type {
  AggregationFromSchema,
  AppSchema,
  FieldsFragment,
  InsertableNode,
  NodeTypename,
  Schema,
  StatusFragment,
} from '../schema';
import { dontWatch, store } from '../store';
import type { DataError } from './DataError';
import { dataListHelpers } from './DataListHelpers';
import {
  isDataNode,
  type DataNode,
  type IntrinsicNodeFields,
  type Node,
} from './DataNode';
import { autoSortableField } from './autoSortableField';
import type { EnsureNodeFunction, NodeFactory } from './nodeFactory';
import { processListUpdates } from './processListUpdates';

export interface List<
  T extends { readonly __typename: string } = {
    readonly __typename: string;
  },
> extends Array<T> {
  push(...items: (T | InsertableNode<T>)[]): number;
  unshift(...items: (T | InsertableNode<T>)[]): number;
  insertAfter(
    after: T | null | undefined,
    ...items: (T | InsertableNode<T>)[]
  ): T[];
  insertBefore(
    before: T | null | undefined,
    ...items: (T | InsertableNode<T>)[]
  ): T[];
  testPush(...items: (T | InsertableNode<T>)[]): () => void;
  testInsertAfter(
    after: T | null | undefined,
    ...items: (T | InsertableNode<T>)[]
  ): [T[], () => void];
  testInsertBefore(
    before: T | null | undefined,
    ...items: (T | InsertableNode<T>)[]
  ): [T[], () => void];
  move(fromIndex: number, toIndex: number, count?: number): void;
  moveBefore(item: T, before: T | null | undefined): void;
  moveAfter(item: T, after: T | null | undefined): void;
  testMove(fromIndex: number, toIndex: number, count?: number): () => void;
  testMoveBefore(item: T, before: T | null | undefined): () => void;
  testMoveAfter(item: T, after: T | null | undefined): () => void;
  splice(start: number, deleteCount?: number): T[];
  splice(
    start: number,
    deleteCount: number,
    ...items: (T | InsertableNode<T>)[]
  ): T[];
  getSortIndex(where?: 'before' | 'after', item?: T): number;
  filter(
    predicate: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any,
  ): T[];
  filtered(predicate: (value: T, index: number, array: T[]) => unknown): this;
  totalLength: number;
  $<U>(
    fn: (node: T, index: () => number, array: List<T>) => U,
  ): () => MappedList<T, U>;
}

export interface ListMeta<
  T extends { readonly __typename: string },
  L extends List<T>,
> {
  typename: T extends { readonly __typename: infer Typename }
    ? Typename
    : string;
  isLoading: boolean;
  readonly isCreating: boolean;
  readonly isTest: boolean;
  readonly isSortable: boolean;
  readonly fieldStatus?: StatusFragment;
  errors: DataError[];
  match: any;
  sort: any; // QuerySortOrder
  request(fragment: FieldsFragment<T>): void;
  dispose(): void;

  getValue<Key extends keyof T>(key: Key, preventLazyLoad?: boolean): T[Key][];

  readonly culture: Culture;
  getCulture(culture: Culture): L;
}

export interface DataList<
  S extends Schema,
  Typename extends NodeTypename<S> = NodeTypename<S>,
> extends List<DataNode<S, Typename>> {
  aggregate: AggregationFromSchema<S, Typename> | undefined;
}

interface CreateDataListOptions {
  typename: string;
  match: any;
  sort?: any; // QuerySortOrder
  schema?: AppSchema;
  errors?: DataError[];
  fieldStatus?: StatusFragment;
  loading?: boolean;
  test?: boolean;
  creating?: boolean;
  placeholderCount?: number;
  aggregate?: AggregationFromSchema<any, any>;

  factory: NodeFactory;
}

export function createDataList<Fields extends { readonly __typename: string }>(
  options: CreateDataListOptions,
): List<Fields>;
export function createDataList<
  S extends Schema,
  Typename extends NodeTypename<S>,
>(options: CreateDataListOptions): DataList<S, Typename>;
export function createDataList<Fields extends { readonly __typename: string }>(
  options: CreateDataListOptions,
): List<Fields> {
  const { factory, schema: appSchema, test: isTest } = options;

  const state = store({
    loading: options.loading || false,
    match: options.match,
    errors: options.errors || [],
    sort: options.sort,
    aggregate: options.aggregate,
  });

  function disposeLoaders() {
    if (loaders) {
      for (const loader of loaders) meta(loader).delete();
      loaders = undefined;
    }
  }

  let matches = new WeakMap<Node, ReadonlySignal<boolean>>();

  let loaders: Node<Fields>[] | undefined;
  const nodes = computed(() => {
    const loading = state.loading;
    const match = state.match;
    const limit = match.limit || Number.POSITIVE_INFINITY;
    const sort = state.sort;

    if (loading) {
      return dontWatch(() => {
        const result = (loaders ??= Array.from({
          length: Math.min(limit, options.placeholderCount ?? 12),
        }).map(
          () =>
            factory.ensureNode(
              {
                __typename: options.typename,
                id: `loading-${generateNodeId()}`,
              },
              {
                fieldStatus: options.fieldStatus || { id: 'requested' },
                isTest,
                source: match.source,
              },
            ) as Node<Fields>,
        ));
        // Trigger field loading for matching and sorting fields
        meta(result[0])?.isMatch(match);
        sortNodes(result, sort);
        return result;
      });
    }

    disposeLoaders();

    const nodes = factory.nodesByTypename(options.typename).filter((node) => {
      if (matches.has(node)) return matches.get(node)!.value;
      const result = computed(() => !!meta(node).isMatch(match));
      matches.set(node, result);
      return result.value;
    }) as Node<Fields>[];

    if (limit !== Number.POSITIVE_INFINITY) {
      const length = limit + nodes.filter((n) => n.draft).length;
      if (length < nodes.length) nodes.length = length;
    }

    sortNodes(nodes, sort);
    return nodes;
  });

  const instances = new Map<string, List<Fields>>();

  const nodeFromInput = (item: any, test = false) =>
    nodeFromInputFn<Fields>({
      typename: options.typename,
      item,
      ensureNode: factory.ensureNode,
      match: state.match,
      errors: state.errors,
      test: test || isTest,
    });
  const helpers = dataListHelpers(nodeFromInput, state);
  const loadingHelpers = {
    find(this: any[], fn: (node: any) => any) {
      const result = this[0];
      // Trigger loading
      if (result) fn(result);
      return result;
    },

    findIndex(this: any[], fn: (node: any) => any) {
      const result = this[0];
      // Trigger loading
      if (result) fn(result);
      return result ? 0 : -1;
    },

    filter(this: any[], fn: (node: any) => any) {
      const first = this[0];
      // Trigger loading
      if (first) fn(first);
      return first ? [first] : [];
    },
  };

  function getInstance(
    culture: Culture,
    instanceFilter?: (
      node: Node<Fields>,
      index: number,
      array: Node<Fields>[],
    ) => unknown,
  ): List<Fields> {
    if (!instanceFilter && instances.has(culture))
      return instances.get(culture)!;

    const isDefaultCulture = culture === appSchema?.cultures[0];

    const list = computed(() => {
      const value = isDefaultCulture
        ? nodes.value
        : nodes.value.map((node) => meta(node).getCulture(culture));
      if (instanceFilter) return value.filter(instanceFilter);
      return value;
    });

    const listMeta: ListMeta<Fields, List<Fields>> = {
      typename: options.typename as any,

      get isLoading() {
        return state.loading;
      },

      set isLoading(value: boolean) {
        state.loading = value;
        disposeLoaders();
      },

      get isTest() {
        return !!isTest;
      },

      get isCreating() {
        return !!options.creating;
      },

      get isSortable() {
        return !!autoSortableField(state.sort);
      },

      fieldStatus: options.fieldStatus,

      get match() {
        return state.match;
      },

      set match(value: any) {
        matches = new WeakMap();
        state.match = value;
      },

      get sort() {
        return state.sort;
      },

      set sort(value: any) {
        state.sort = value;
      },

      get errors() {
        return state.errors;
      },

      set errors(value: DataError[]) {
        state.errors = value;
      },

      request(fields: FieldsFragment<Fields>) {
        if (!options.fieldStatus || !appSchema) return;

        extendStatusFragment(
          'requested',
          options.fieldStatus,
          fields,
          appSchema,
          options.typename,
        );

        if (!state.loading) {
          for (const node of nodes.value) {
            meta(node).request(fields as any);
          }
        }
      },

      dispose: disposeLoaders,

      getValue<Key extends keyof Fields>(key: Key, preventLazyLoad?: boolean) {
        return list.value.map((node) => {
          if (preventLazyLoad) {
            return node[key];
          }
          return meta(node).getValue(key as any);
        });
      },

      culture,
      getCulture(culture: Culture) {
        return getInstance(culture);
      },
    };

    function processChanges<T>(fn: (values: Node<Fields>[]) => T): T {
      const previous: Node<Fields>[] = [...list.peek()];
      const values = [...previous];
      const result = fn(values);
      if (!isSameArray(previous, values)) {
        batch(() => {
          const errors = processListUpdates<Fields>({
            target: values,
            previous,
            match: state.match,
            sort: state.sort,
            nodeFromInput,
          });
          if (errors.length) throw errors[0];
        });
      }
      return result;
    }

    const instance = new Proxy([] as unknown as List<Fields>, {
      get(_, prop) {
        if (prop === '__store') return 'list';
        if (prop === '__meta') return listMeta;
        if (prop === 'aggregate') return state.aggregate;
        if (prop === '$')
          return (
            callback: (node: Node, index: () => number, self: any[]) => any,
          ) => map(() => instance as unknown as Node[], callback);

        if (prop === 'filtered') {
          return (
            predicate: (
              value: Node<Fields>,
              index: number,
              array: Node<Fields>[],
            ) => unknown,
          ) => getInstance(culture, predicate); /*(node) => {
              if (
                instanceFilter &&
                !instanceFilter(node, list.value.indexOf(node), list.value)
              ) {
                return false;
              }
              return predicate(node, list.value.indexOf(node), list.value);
            });*/
        }

        const value =
          (state.loading ? (loadingHelpers as any)[prop] : undefined) ??
          (helpers as any)[prop] ??
          Reflect.get(list.value, prop);

        if (typeof value === 'function') {
          return (...args: any[]) =>
            processChanges((values) => value.apply(values, args));
        }

        return value;
      },

      set(_, prop, value) {
        if (prop === 'aggregate') {
          state.aggregate = value;
          return true;
        }
        return processChanges((values) => Reflect.set(values, prop, value));
      },

      has(_, p) {
        return Reflect.has(list.value, p);
      },
    });

    instances.set(culture, instance);
    return instance;
  }

  return getInstance((appSchema?.cultures[0] || 'global') as Culture);
}

export function isDataList<S extends Schema, Typename extends NodeTypename<S>>(
  list: unknown,
  typename?: Typename,
): list is DataList<S, Typename>;
export function isDataList<
  Fields extends { readonly __typename: string } = IntrinsicNodeFields,
>(list: unknown, typename?: string): list is List<Fields>;
export function isDataList<Fields extends { readonly __typename: string }>(
  list: unknown,
  typename?: string,
): list is List<Fields> {
  return (
    Array.isArray(list) &&
    (list as any).__store === 'list' &&
    (!typename || (list as any).__meta.typename === typename)
  );
}

function isSameArray(a: any[], b: any[]) {
  return a.length === b.length && a.every((item, index) => item === b[index]);
}

function nodeFromInputFn<Fields extends { readonly __typename: string }>({
  typename,
  item,
  ensureNode,
  match,
  errors,
  test,
}: {
  typename: string;
  item: any;
  ensureNode: EnsureNodeFunction;
  match: any;
  errors: ValidationError[];
  test?: boolean;
}): [Node<Fields>, (() => void) | void] {
  let dispose: Node | undefined;

  const result = isDataNode<Fields>(item)
    ? item
    : (dispose = ensureNode(
        {
          ...item,
          __typename: typename,
        },
        {
          isCreating: true,
          isTest: !!test,
        },
      ));

  if (
    match &&
    'source' in match &&
    match.source &&
    !meta(result).sources.includes(match.source)
  ) {
    meta(result).sources = [...meta(result).sources, match.source];
  }

  let revertMatch: (() => void) | void;
  if (match && 'where' in match && result) {
    const [matchErrors, revert] = changeMatch('apply', match, result, test);
    errors.push(...matchErrors);
    revertMatch = revert;
  }

  return [
    result as Node<Fields>,
    () => {
      if (dispose) meta(dispose).delete();
      else if (revertMatch) revertMatch();
    },
  ];
}
