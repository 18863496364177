import {
  Errors,
  getI18n,
  getTheme,
  session,
  setLayout,
  text,
  useForm,
} from '@donkeyjs/client';
import { setState } from '@donkeyjs/jsx-runtime';
import { I18nMailings } from '@donkeyjs/module-mailings';
import { PhSpinner } from '@donkeyjs/phosphor-icons';
import { meta, type DataNode } from '@donkeyjs/proxy';
import { I18nIL } from '../../../i18n';
import styles from './NotifyButtonForm.module.css';

interface NotifyButtonFormProps {
  course: DataNode<DataSchema, 'CourseOffering'>;
}

export function NotifyButtonForm(props: NotifyButtonFormProps) {
  setLayout('html');

  const i18n = getI18n();
  const theme = getTheme();

  const { reset, values: form } = useForm('notify', {
    email: 'string',
  });

  const state = setState({
    status: 'idle' as 'idle' | 'submitting' | 'submitted',
  });

  const submit = async (ev: Event) => {
    ev.preventDefault();
    state.status = 'submitting';
    const result = await session.data.mutation.subscribeToNotifications({
      email: form.email,
      culture: i18n.culture,
      id: props.course.id,
      typename: 'CourseOffering',
    });

    if (result.errors) {
      meta(form).errors = result.errors;
      state.status = 'idle';
    } else {
      reset();
      state.status = 'submitted';
    }
  };

  return (
    <form class={styles.form} onsubmit={submit}>
      <Errors node={form} i18n={I18nMailings} />
      {() =>
        state.status === 'submitted' ? (
          <p>{text(I18nIL, 'Course.NotifyThanks')}</p>
        ) : (
          <>
            <form.$.email
              label={text(I18nIL, 'Course.NotifyText')}
              autofocus
              email
            />
            <button
              class={[theme.class.button, 'default']}
              type="submit"
              disabled={state.status === 'submitting'}
            >
              {() => (state.status === 'submitting' ? <PhSpinner /> : null)}
              <span>{text('Common.Submit')}</span>
            </button>
          </>
        )
      }
    </form>
  );
}
