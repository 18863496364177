import type { JSXNode } from '@donkeyjs/jsx-runtime';

export const Fragment = Symbol('Fragment');
export const Comment = Symbol('Comment');

// const isServer = typeof document === 'undefined';

export const jsx = (tag: any, props: any, ...children: any): JSXNode => {
  return {
    __type: 'jsx-node',
    tag,
    props: props ?? {},
    children,
  };
};

export const jsxx = jsx as <P>(
  component: (props: P) => JSX.Element,
  props: P,
) => JSXNode;

// const createPropsStore = (props: any, key?: string, exclude?: string[]) => {
//   if (isStore(props)) return props;

//   const source: any = {};

//   const define = (key: string, value: any) => {
//     if (exclude?.includes(key)) return;
//     /*if (typeof value === 'function') {
//       source[key] = bindContext(value);
//     } else*/
//     if (key === 'children') {
//       if (Array.isArray(value)) {
//         source[key] = value.map((child) => {
//           if (typeof child === 'function') return bind(child);
//           return child;
//         });
//       } else if (typeof value === 'function') {
//         source[key] = bind(value);
//       } else {
//         source[key] = value;
//       }
//     } else {
//       source[key] = value;
//     }
//   };

//   if (key) define('key', key);
//   for (const key in props) define(key, props[key]);

//   return store(source);
// };

// const isGetSet = <T>(value: any): value is GetSet<T> =>
//   !!value &&
//   typeof value === 'object' &&
//   ((Object.keys(value).length === 1 && 'get' in value) ||
//     (Object.keys(value).length === 2 &&
//       'get' in value &&
//       'set' in value &&
//       typeof value.set === 'function')) &&
//   typeof value.get === 'function';
