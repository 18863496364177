import { bindContext } from '@donkeyjs/jsx-runtime';
import { resolveBinding } from '@donkeyjs/proxy';

export function getAttributeValue(
  virtual: boolean,
  tagName: string,
  key: string,
  value: any,
  typeAttr?: string,
): [name: string, value: any, attr?: string] {
  const result = resolveBinding(value);

  if (key === 'value') {
    return processValue(tagName, result, typeAttr);
  }

  if (key === 'class') {
    return [key, processClasses(result)];
  }

  if (key === 'style') {
    return [key, processStyles(result)];
  }

  if (typeof result === 'number') {
    return [key, result.toString()];
  }

  if (typeof result === 'function') {
    if (virtual) return [key, undefined];
    return [key, bindContext(result)];
  }

  return [key, result];
}

function processValue(
  tagName: string,
  value: any,
  type?: string,
): [string, any, string?] {
  if (
    tagName.toLowerCase() === 'input' &&
    (type === 'checkbox' || type === 'radio')
  )
    return ['checked', value, 'checked'];

  return ['value', value, 'value'];
}

function processClasses(
  classes:
    | string
    | (string | string[] | Record<string, any>)[]
    | Record<string, any>
    | undefined,
): string {
  if (!classes) return '';
  if (typeof classes === 'string') return classes;
  if (Array.isArray(classes))
    return classes.map(processClasses).filter(Boolean).join(' ');
  return Object.entries(classes)
    .map(([key, val]) => (val ? key : ''))
    .filter(Boolean)
    .join(' ');
}

export function processStyles(
  styles: string | string[] | JSX.CSSProperties,
): string {
  if (!styles) return '';
  if (Array.isArray(styles))
    return styles.map(processStyles).filter(Boolean).join('; ');
  if (typeof styles === 'object')
    return Object.entries(styles)
      .filter(([_, val]) => val !== undefined)
      .map(
        ([key, val]) => `${key.replace(/[A-Z]/g, '-$&').toLowerCase()}: ${val}`,
      )
      .join(';');
  return styles;
}
