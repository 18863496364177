import type { NewsletterTemplate } from '..';

let mailingOptions: MailingOptions = {};

export interface MailingOptions {
  newsletterTemplates?: NewsletterTemplate[];
}

export const setMailingOptions = (options: MailingOptions) => {
  mailingOptions = options;
};

export const getMailingOptions = (): MailingOptions => mailingOptions;
