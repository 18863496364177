import { getContext, setContext } from '@donkeyjs/jsx-runtime';

const key = Symbol('heading');

export const setHeadingLevel = (
  level: number | ((current: number) => number),
) => {
  setContext(
    key,
    typeof level === 'function' ? level(getContext(key) ?? 2) : level,
  );
};

export const getHeadingLevel = () => {
  return getContext<number>(key) ?? 2;
};
