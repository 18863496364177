import { allCommands } from './allCommands';
import { defaultShortcuts } from './defaultShortcuts';
import type { Commands, CommandsDefinitions } from './useCommands';

export interface ShortcutDefinitions {
  [key: string]: keyof Commands | (keyof Commands)[];
}

export interface Shortcut {
  key: string;
  ctrl?: boolean;
  shift?: boolean;
  alt?: boolean;
}

export const allShortcuts = { ...defaultShortcuts };

export const registerShortcuts = (shortcuts: ShortcutDefinitions) => {
  Object.assign(allShortcuts, shortcuts);
};

export const getShortcutFromEvent = (event: KeyboardEvent) => {
  const isMac =
    (navigator.platform || (navigator as any).userAgentData?.platform)
      ?.toLowerCase()
      .indexOf('mac') >= 0;

  const ctrl = (isMac ? event.metaKey : event.ctrlKey) ? 'Ctrl' : undefined;
  return [
    ctrl,
    event.altKey ? 'Alt' : undefined,
    (event.key.length > 1 || event.ctrlKey || event.altKey) && event.shiftKey
      ? 'Shift'
      : undefined,
    event.key.length === 1 ? event.key.toLocaleUpperCase() : event.key,
  ]
    .filter(Boolean)
    .join('+');
};

export const createShortcutsHandler =
  (commands?: CommandsDefinitions) => (event: KeyboardEvent) => {
    if (!event.key) return;

    const shortcut = getShortcutFromEvent(event);
    const binding = allShortcuts[shortcut.replace(' ', 'Space')];
    if (!binding) return;

    const target = event.target as HTMLInputElement;
    if (
      (target instanceof HTMLInputElement ||
        (target as HTMLElement)?.contentEditable === 'true') &&
      !target.readOnly &&
      !target.disabled &&
      shortcut.length === 1
    )
      return;

    const command =
      typeof binding === 'string'
        ? binding
        : binding.find((key) => (commands || allCommands.values)[key]);

    const found = command && (commands || allCommands.values)[command];
    if (found) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      if (typeof found === 'function') found(target);
      else found.handler(target);
    }
  };
