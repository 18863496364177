import type {
  DataNode,
  InsertableNodeFromSchema,
  NodeTypename,
} from '@donkeyjs/proxy';
import { useDragHandler } from './useDragHandler';

export type DropPosition = 'before' | 'after' | 'inside';

export interface UseDropZoneOptions {
  key: any;
  onDrag: (dragged: DraggedItem) => DropHandler | void;
}

export interface DropHandler {
  positions: DropPosition[];
  hover(position: DropPosition): (() => void) | void;
  drop(position: DropPosition): void;
}

export type AcceptedDrag =
  | NodeTypename<DataSchema>
  | `Create${NodeTypename<DataSchema>}`
  | 'native-files'
  | { custom: string };

export type DraggedItem<
  T extends NodeTypename<DataSchema> = NodeTypename<DataSchema>,
> =
  | DraggedNode<T>
  | DraggedCreateNode<T>
  | DraggedFiles
  | DraggedCustom<string>;

export interface DraggedNode<T extends NodeTypename<DataSchema>> {
  type: 'node';
  node: DataNode<DataSchema, T>;
  listKey?: unknown;
}

export interface DraggedCreateNode<T extends NodeTypename<DataSchema>> {
  type: 'node-create';
  typename: T;
  values: InsertableNodeFromSchema<DataSchema, T>;
  listKey?: never;
}

export interface DraggedFiles {
  type: 'files';
  files: FileList | undefined;
  listKey?: never;
}
export interface DraggedCustom<T extends string> {
  type: 'custom';
  custom: T;
  value: unknown;
  listKey?: unknown;
}
export interface DraggedTab {
  type: 'tab';
  key: string;
}

export const dropZone =
  (
    options: UseDropZoneOptions | null | undefined,
    disabled: () => boolean = () => false,
  ) =>
  (element: HTMLElement) => {
    if (!options) return undefined;
    return useDragHandler().registerZone({
      zone: options,
      disabled,
      element,
    });
  };
