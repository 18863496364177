import { live, onUnmount } from '@donkeyjs/jsx-runtime';
import {
  batch,
  generateNodeId,
  meta,
  store,
  type DataNode,
} from '@donkeyjs/proxy';
import { useSearchInput } from '../../../helpers';
import { session } from '../../../session';

export const usePlaceSearch = () => {
  const search = useSearchInput({});

  const state = store({
    get searchInput() {
      return search.input;
    },
    set searchInput(v) {
      search.input = v;
    },
    get searchText() {
      return search.value;
    },
    existing: [] as DataNode<DataSchema, 'Place'>[],
    fromMaps: [] as DataNode<DataSchema, 'Place'>[],
  });

  const dispose = () => {
    for (const place of state.fromMaps) {
      if (meta(place).isLocal) meta(place).delete();
    }
  };

  live(() => {
    const searchText = state.searchText;
    if (!searchText) {
      dispose();
      if (state.existing.length) state.existing = [];
      if (state.fromMaps.length) state.fromMaps = [];
    } else {
      session.data.query
        .searchGooglePlaces({ query: state.searchText })
        .then((d): void => {
          if (searchText !== state.searchText) return;

          batch(() => {
            dispose();

            const existing = [];
            const fromMaps = [];

            for (const place of d.data as any) {
              if (place.__typename) {
                existing.push(session.data.processNodeData(place));
              } else {
                fromMaps.push(
                  session.data.createLocalNode<'Place'>({
                    __typename: 'Place',
                    id: generateNodeId(),
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    ...place,
                  }),
                );
              }
            }

            state.existing = existing;
            state.fromMaps = fromMaps;
          });
        });
    }
  });

  onUnmount(dispose);

  return state;
};
