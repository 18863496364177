import { session } from '../session';

export const getNow = () => {
  const fromUrl = session.router.query['--now']?.[0];
  if (fromUrl) {
    const parsed = Date.parse(fromUrl);
    if (!Number.isNaN(parsed)) {
      return new Date(parsed);
    }
  }
  return new Date();
};

export const setNow = (date: Date | null) => {
  session.router.query['--now'] =
    date === null ? undefined : [date.toISOString()];
};
