import { jsxx } from '@donkeyjs/jsx-runtime';
import { store, type DataList, type DataNode } from '@donkeyjs/proxy';
import { Image, type ImageDisplayProps } from './Image';

interface ImagesProps extends ImagesDisplayProps {
  readonly files: DataList<DataSchema, 'FileRef'> | null | undefined;
  readonly editable?: boolean;
}

export interface ImagesDisplayProps extends ImageDisplayProps {
  readonly variant?: 'single-image' | 'image-pager' | 'image-gallery';
  readonly noOutline?: boolean;
  readonly ratio?: number | 'adaptive';
}

export function Images(props: ImagesProps) {
  const state = store({
    index: 0,
  });

  return () => {
    if (!props.files) return null;

    if (props.variant === 'image-gallery' || props.variant === 'image-pager') {
      return 'Not Supported';
    }

    return jsxx(
      Image,
      store.clone(props, {
        get file() {
          return props.files?.[state.index];
        },
        get onChange() {
          return !props.editable
            ? undefined
            : (value: DataNode<DataSchema, 'File'>) => {
                if (!props.files) return;
                if (value) {
                  if (props.files[state.index]) {
                    props.files[state.index].file = value;
                  } else {
                    props.files[state.index] = {
                      __typename: 'FileRef',
                      file: value as unknown as DataNode<DataSchema, 'File'>,
                    } as any;
                  }
                } else {
                  props.files.splice(state.index, 1);
                }
              };
        },
      }),
    );
  };
}
