import type { I18nContext } from '@donkeyjs/client';

export const formatTimesOfDay = (
  day: { starts: Date; ends: Date },
  options: {
    dateTimeSeperator?: string;
    formatDate: I18nContext['formatDate'];
  },
) => {
  return `${options.formatDate(day.starts, 'PPP')}${
    options.dateTimeSeperator || ' '
  }${options.formatDate(day.starts, 'p')}—${options.formatDate(day.ends, 'p')}`;
};
