import { defaultPermissions } from '@donkeyjs/core';

export const permissions = defaultPermissions().with({
  visitor: {
    Course: { read: 'allow' },
    CourseOffering: { read: 'allow' },
    CourseDay: { read: 'allow' },
    CourseOption: { read: 'allow' },
    CourseTeacher: { read: 'allow' },
    Partner: { read: 'allow' },
    Place: { read: 'allow' },
    Teacher: { read: 'allow' },
    Testimonial: { read: 'allow' },
  },
});
