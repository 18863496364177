import { createDatabaseRouter, type UseRouterOptions } from '@donkeyjs/core';
import { live } from '@donkeyjs/jsx-runtime';
import { extendedObject } from '@donkeyjs/proxy';
import { sendBeacon } from '../trace/sendBeacon';

export const useBrowserRouter = (options: UseRouterOptions) => {
  const router = createDatabaseRouter(
    extendedObject(options, {
      plugins: options.plugins || [],
      pathname: document.location.pathname,
      queryString: document.location.search?.replace('?', ''),
    }),
  );

  let lastRouteId = '';
  let lastNodeId = '' as string | undefined;

  window.addEventListener('popstate', () => {
    router.navigate(document.location.pathname, {
      queryString: document.location.search?.replace('?', ''),
    });
  });

  live(() => {
    const routeId = router.route.node?.id;
    const nodeId = router.query['node-id']?.[0];
    if (routeId && (routeId !== lastRouteId || nodeId !== lastNodeId)) {
      lastRouteId = routeId;
      lastNodeId = nodeId;
      sendBeacon({ routeId, nodeId });
    }
    if (
      !router.isLoading &&
      router.pathname !== '/404' &&
      (router.pathname !== document.location.pathname ||
        router.queryString !== document.location.search?.replace('?', ''))
    ) {
      const last = router.lastMutation;

      if (last.replace) history.replaceState({}, '', router.path);
      else history.pushState({}, '', router.path);

      if (!last.anchor && last.changedRoute) {
        document.scrollingElement?.scrollTo(0, 0);
      }
    }
  });

  return router;
};
