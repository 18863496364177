import type { DataNode } from '@donkeyjs/proxy';
import type { OrderableItem, OrderableKey } from '../useCart';
import { getShoppingSettings } from '../shoppingSettings';

export function addToOrder<Kind extends OrderableKey>(
  order: DataNode<DataSchema, 'Order'>,
  kind: Kind,
  item: OrderableItem<Kind>,
  quantity = 1,
) {
  const settings = getShoppingSettings();

  const orderItem = order.items.find(
    (orderItem) => orderItem[kind]?.id === item.id,
  );

  let newQuantity = orderItem ? orderItem.quantity + quantity : quantity;
  const maxQuantity =
    settings.maxItemQuantity?.(item) ?? Number.MAX_SAFE_INTEGER;
  if (newQuantity > maxQuantity) {
    newQuantity = maxQuantity;
  }

  if (orderItem) {
    orderItem.quantity = newQuantity;
  } else {
    order.items.push({
      __typename: 'OrderItem',
      [kind]: item,
      quantity: newQuantity,
      price: item.price,
    });
  }
}
