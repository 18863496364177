import {
  type AppSchema,
  type Culture,
  type I18nSchema,
  type NodeTypename,
  batch,
  store,
} from '@donkeyjs/proxy';
import { humanify } from './humanify';

export const getSchemaI18n = <S extends AppSchema>(
  schema: S,
  culture: Culture,
): SchemaI18n<S> => {
  schema.i18n.values ??= {};
  return (schema.i18n.values[culture] ??= new SchemaI18n(schema, culture));
};

export class SchemaI18n<S extends AppSchema> {
  private state: { loading: boolean; value?: I18nSchema<any, any> };
  public readonly schema: S;
  public readonly culture: Culture;
  private promise: Promise<any> | undefined;

  constructor(schema: S, culture: Culture) {
    this.schema = schema;
    this.culture = culture;
    const loaders: (() => Promise<I18nSchema<any, any>>)[] = [];
    const values: I18nSchema<any, any>[] = [];

    for (const loader of schema.i18n.loaders) {
      const value = loader[culture];
      if (typeof value === 'function') {
        loaders.push(value);
      } else if (value) {
        values.push(value);
      }
    }

    this.state = store({ loading: !!loaders.length });

    const apply = (resolved: I18nSchema<any, any>[]) => {
      this.state.value = [...values, ...resolved].reduce<
        Required<I18nSchema<any, any>>
      >(
        (acc, val) => {
          Object.assign(acc.enums, val.enums);
          Object.assign(acc.groups, val.groups);
          for (const [nodeKey, nodeValue] of Object.entries(val.nodes || {})) {
            acc.nodes[nodeKey] = { ...acc.nodes[nodeKey], ...nodeValue };
          }
          return acc;
        },
        {
          enums: {},
          groups: {},
          nodes: {},
        },
      );
    };

    if (loaders.length) {
      this.promise = Promise.all(loaders.map((l) => l())).then((values) => {
        batch(() => {
          this.state.loading = false;
          apply(values);
        });
      });
    } else {
      apply([]);
    }
  }

  async load() {
    return this.promise
      ? (await this.promise).then(() => this.state.value)
      : this.state.value;
  }

  public getNodeName(
    type: NodeTypename<S>,
    options?: { pluralize?: (value: string) => string },
  ): string {
    if (this.state.loading || !type) return '';

    const result =
      this.state.value?.nodes?.[type]?.__typename || humanify(type) || '';
    const [singular, plural] =
      typeof result === 'string' ? [result, undefined] : result;
    return options?.pluralize
      ? plural || options.pluralize(singular)
      : singular;
  }

  public getFieldName<Typename extends NodeTypename<S>>(
    type: Typename,
    field: Extract<keyof S['nodes'][Typename]['fields'], string>,
  ): string {
    if (this.state.loading) return '';

    const result =
      this.state.value?.nodes?.[type]?.[field] || humanify(field) || '';
    const [singular] = typeof result === 'string' ? [result] : result;
    return singular;
  }

  public getGroupName<Typename extends NodeTypename<S>>(
    type: Typename,
    group: string,
  ): string {
    if (this.state.loading) return '';

    return this.state.value?.groups?.[type]?.[group] || humanify(group) || '';
  }

  public getEnumValue<Enum extends keyof S['enums']>(
    name: Enum,
    key: Extract<
      S['enums'][Enum]['values'] extends readonly (infer U)[] ? U : never,
      string
    >,
  ): string {
    if (this.state.loading) return '';

    return this.state.value?.enums?.[name]?.[key] || humanify(key);
  }
}
