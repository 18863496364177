import { store } from '@donkeyjs/proxy';
import type { OrderableKey } from '../useCart';

import {
  getAvailableInstallments,
  type AvailableInstallments,
} from '../../shared/getAvailableInstallments';
import {
  calculateItemDiscounts,
  type CalculatedItemDiscounts,
} from '../../shared/order/helpers/calculateItemDiscounts';
import type { OrderableItem } from '../useCart';

export interface OrderableInfo extends CalculatedItemDiscounts {
  node: OrderableItem<OrderableKey>;
  availableInstallments: AvailableInstallments[];
}

export function useOrderableInfo(orderables: OrderableItem<OrderableKey>[]) {
  const state = store({
    get options(): OrderableInfo[] {
      return orderables.map((orderable) => {
        const discounts = calculateItemDiscounts(
          orderable.price,
          1,
          orderable.discounts,
          [],
        );

        const availableInstallments = getAvailableInstallments(
          orderable.paymentSchemes,
          discounts.reducedPrice,
          1,
        );

        return {
          node: orderable,
          ...discounts,
          availableInstallments,
        };
      });
    },
  });

  return state;
}
