import { bind } from '@donkeyjs/jsx-runtime';
import type { FieldRenderProps } from '../..';
import styles from './HtmlBoolean.module.css';

export function HtmlBooleanField(props: FieldRenderProps<'boolean'>) {
  return (
    <>
      <label class={[styles.label, 'label checkbox']}>
        <input
          type="checkbox"
          class={styles.input}
          value={bind(
            () => !!props.field.value,
            (value) => {
              props.field.value = !!value;
            },
          )}
        />
        <span>{() => props.label}</span>
      </label>
    </>
  );
}

export interface HtmlBooleanProps {
  value?: boolean | null;
  label: JSX.Children;
}

export function HtmlBoolean(props: HtmlBooleanProps) {
  return (
    <>
      <label class={[styles.label, 'label checkbox']}>
        <input
          type="checkbox"
          class={styles.input}
          value={bind(
            () => !!props.value,
            (value) => {
              props.value = !!value;
            },
          )}
        />
        <span>{() => props.label}</span>
      </label>
    </>
  );
}
