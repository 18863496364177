import { meta, store, type FieldSchema } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../../..';

export const useDateComponent = (props: FieldRenderProps<'date'>) => {
  const { rangeFromField, rangeToField, timeZoneField, fullDayField } =
    (props.field.schema as FieldSchema<'date'>) || {};

  if (rangeToField) {
    meta(props.field.parent?.node)?.request({
      [rangeToField]: true,
    });
  }
  if (fullDayField) {
    meta(props.field.parent?.node)?.request({
      [fullDayField]: true,
    });
  }

  const value = store({
    isRange: !!rangeFromField && !!rangeToField,
    isRangeStart: !!rangeToField,
    isRangeEnd: !!rangeFromField,
    hasTimeZone: !!timeZoneField,
    hasFullDayToggle: !!fullDayField,
    fullDayField,
    timeZoneField,
    rangeToField,

    get fullDay() {
      return fullDayField
        ? !!(props.field.parent?.node as any)[fullDayField]
        : undefined;
    },

    get rangeEnds(): Date | null | undefined {
      return rangeToField
        ? ((props.field.parent?.node as any)[rangeToField] as
            | Date
            | null
            | undefined)
        : undefined;
    },

    get rangeEndsLoading() {
      return (
        (rangeToField
          ? meta(props.field.parent?.node)?.getField(rangeToField as any)
              ?.loading
          : false) || false
      );
    },

    get rangeIsCollapsed() {
      return (
        !props.field.value ||
        !this.rangeEnds ||
        this.rangeEndsLoading ||
        this.rangeEnds <= props.field.value
      );
    },
  });

  return value;
};
