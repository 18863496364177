import {
  getHybridMountList,
  withContext,
  type Dom,
  type RenderContext,
} from '@donkeyjs/jsx-runtime';

export function withSsrResume<T>(
  dom: Dom,
  context: RenderContext,
  deserialize: (data: any) => void,
  fn: () => T,
): T {
  if (dom.ssr) return fn();

  const headStart = document.getElementById('ssr-head');
  const headEnd = document.getElementById('ssr-end-head');
  const bodyStart = document.getElementById('ssr-body');
  const bodyEnd = document.getElementById('ssr-end-body');
  const data = bodyEnd?.textContent;
  if (data) {
    const parsed = JSON.parse(data);
    deserialize(parsed);
  }
  if (headStart || bodyStart) {
    dom.ssrCursor ??= new Map();
    dom.ssrCursor.set(document.head, headStart?.nextSibling ?? null);
    dom.ssrCursor.set(document.body, bodyStart?.nextSibling ?? null);
    headStart?.parentNode?.removeChild(headStart);
    bodyStart?.parentNode?.removeChild(bodyStart);
  }

  const result = fn();

  if (data) {
    for (const onMount of getHybridMountList(context, true)) {
      const mountContext = onMount[1];
      if (mountContext.disposed) continue;
      const result = withContext(mountContext, onMount[0]);
      if (result) mountContext.onUnmount.push(result);
    }
    dom.removeExtraSsrNodes();
    dom.ssrCursor = undefined;
    headEnd?.parentNode?.removeChild(headEnd);
    bodyEnd?.parentNode?.removeChild(bodyEnd);
  }

  return result;
}
