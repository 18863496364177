import { bind, setState } from '@donkeyjs/jsx-runtime';
import type { FieldRenderProps } from '../..';
import { Label } from '../../components/Label';
import { EmbedEditor, type EmbedEditorProps } from './EmbedEditor';

export function HtmlEmbed(props: FieldRenderProps<'embed'>) {
  const state = setState<EmbedEditorProps>({
    get embed() {
      return props.field.value;
    },
    set embed(value) {
      props.field.value = value;
    },
  });

  return (
    <Label
      class={bind(() => ['input', props.class])}
      label={bind(() => props.label)}
      helper={bind(() => state.helper)}
      onmount={props.onmount}
    >
      {EmbedEditor(state)}
    </Label>
  );
}
