import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import { commands } from '../../helpers';
import { getTheme } from '../../styles';
import styles from './InlineSelect.module.css';
import { SelectChips } from './SelectChips';
import { attachPopup } from './usePopup';
import { useSelect, type UseSelectProps } from './useSelect';

export interface InlineSelectProps<T, Mapped = T>
  extends UseSelectProps<T, Mapped> {
  onClose?: () => void;
}

export function InlineSelect<T, Mapped = T>(
  props: InlineSelectProps<T, Mapped>,
) {
  const theme = getTheme();

  const select = useSelect(props, () => {
    if (!select.isArray) props.onClose?.();
  });

  return (
    <>
      <span class={[styles.select, theme.class.input]}>
        <SelectChips select={select} />
        <input
          type="text"
          class={styles.input}
          autofocus
          onfocus={(e) => {
            e.currentTarget.select();
          }}
          onblur={select.handleBlur}
          value={bind(select, 'searchInput')}
          onkeydown={(ev) => {
            if (ev.key === 'Tab') {
              props.onClose?.();
            }
          }}
          onmount={[
            commands({
              ...select.commands,
              'common.confirm': (el) => {
                select.commands['common.confirm'](el);
                props.onClose?.();
              },
              'common.close': () => {
                props.onClose?.();
              },
            }),
          ]}
        />
      </span>
      <div class={styles.items}>{select.renderOptions()}</div>
    </>
  );
}

export function attachInlineSelect<T, Mapped = T>(
  props: InlineSelectProps<T, Mapped>,
) {
  return attachPopup(() => jsxx(InlineSelect, props));
}
