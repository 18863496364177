import { bind } from '@donkeyjs/proxy';

interface LogoSvgProps {
  class?: string;
  withText?: boolean;
}

export function LogoSvg(props: LogoSvgProps) {
  return () =>
    props.withText ? (
      <svg
        class={bind(() => ['logo-svg', props.class])}
        clip-rule="evenodd"
        fill-rule="evenodd"
        stroke-line-join="round"
        stroke-miterlimit="1.4142"
        version="1.1"
        viewBox="0 0 897 227"
        xml-space="preserve"
      >
        <title>International Lectures</title>
        <g transform="translate(-2334 -22)">
          <g transform="matrix(2 0 0 2 -530 -23.152)">
            <g
              transform="matrix(.75982 0 0 .75982 261.08 -5.7007)"
              fill-rule="nonzero"
            >
              <path d="m1629 103.92l4.51 1.008v2.064h-13.82v-2.064l4.51-1.008v-28.416l-4.51-1.008v-2.064h13.82v2.064l-4.51 1.008v28.416z" />
              <path d="m1641 83.955l-3.69-0.912v-1.968h6.91l1.05 4.8h0.2c0.95-1.632 2.24-2.92 3.86-3.864 1.61-0.944 3.37-1.416 5.26-1.416 1.28 0 2.43 0.224 3.45 0.672 1.03 0.448 1.84 1.088 2.45 1.92 0.64 0.896 1.1 2.152 1.37 3.768s0.41 3.8 0.41 6.552v10.608l3.69 0.912v1.968h-11.57v-1.968l3.22-0.912v-10.704c0-2.048-0.06-3.592-0.17-4.632s-0.31-1.848-0.6-2.424c-0.8-1.568-2.21-2.352-4.22-2.352-0.9 0-1.76 0.16-2.59 0.48-0.84 0.32-1.57 0.752-2.21 1.296-0.71 0.64-1.23 1.384-1.56 2.232-0.34 0.848-0.54 1.944-0.6 3.288v12.816l3.21 0.912v1.968h-11.56v-1.968l3.69-0.912v-20.16z" />
              <path d="m1667.4 81.747l4.66-0.672 0.62-6.048 4.03-0.576v6.624h6.14v2.4h-6.14v17.136c0 1.088 0.02 1.832 0.05 2.232s0.09 0.728 0.19 0.984 0.27 0.416 0.53 0.48 0.67 0.096 1.25 0.096h3.36v1.392c-1.54 0.576-2.89 1-4.06 1.272s-2.1 0.408-2.81 0.408c-1.44 0-2.37-0.592-2.78-1.776-0.16-0.384-0.26-0.872-0.31-1.464s-0.07-1.496-0.07-2.712v-18.048h-4.66v-1.728z" />
              <path d="m1690.5 94.323c0 2.336 0.32 4.272 0.96 5.808 0.64 1.504 1.54 2.568 2.71 3.192s2.46 0.936 3.87 0.936c1.53 0 2.91-0.312 4.15-0.936 1.23-0.624 2.34-1.48 3.33-2.568l1.54 1.152c-0.58 0.768-1.19 1.488-1.85 2.16-0.65 0.672-1.4 1.264-2.23 1.776s-1.76 0.912-2.79 1.2c-1.02 0.288-2.15 0.432-3.4 0.432-2.21 0-4.16-0.472-5.86-1.416-1.69-0.944-2.99-2.328-3.89-4.152-0.48-0.992-0.83-2.088-1.05-3.288-0.23-1.2-0.34-2.504-0.34-3.912 0-4.512 1.02-7.992 3.07-10.44s4.87-3.672 8.45-3.672c3.3 0 5.83 1.016 7.59 3.048s2.63 4.84 2.63 8.424c0 0.32 0 0.616-0.02 0.888-0.01 0.272-0.05 0.728-0.12 1.368h-16.75zm6.38-11.136c-3.84 0-5.95 2.928-6.33 8.784h12v-1.584c0-2.304-0.49-4.08-1.47-5.328-0.97-1.248-2.37-1.872-4.2-1.872z" />
              <path d="m1725.7 83.859c-0.29-0.192-0.6-0.288-0.96-0.288-0.6 0-1.24 0.376-1.92 1.128-0.67 0.752-1.26 1.704-1.77 2.856-0.45 1.088-0.8 2.28-1.06 3.576s-0.38 2.632-0.38 4.008v8.976l5.18 0.912v1.968h-13.53v-1.968l3.69-0.912v-20.16l-3.69-0.912v-1.968h6.91l1.05 6.336h0.19c0.74-2.176 1.73-3.856 2.96-5.04s2.61-1.776 4.15-1.776c1.25 0 2.29 0.392 3.12 1.176s1.25 1.832 1.25 3.144c0 0.96-0.25 1.72-0.75 2.28-0.49 0.56-1.16 0.84-1.99 0.84s-1.49-0.264-1.97-0.792-0.72-1.224-0.72-2.088c0-0.192 0.02-0.368 0.05-0.528s0.1-0.416 0.19-0.768z" />
              <path d="m1736.9 83.955l-3.69-0.912v-1.968h6.91l1.06 4.8h0.19c0.96-1.632 2.25-2.92 3.86-3.864 1.62-0.944 3.37-1.416 5.26-1.416 1.28 0 2.43 0.224 3.45 0.672 1.03 0.448 1.85 1.088 2.45 1.92 0.64 0.896 1.1 2.152 1.37 3.768s0.41 3.8 0.41 6.552v10.608l3.7 0.912v1.968h-11.57v-1.968l3.21-0.912v-10.704c0-2.048-0.05-3.592-0.16-4.632-0.12-1.04-0.32-1.848-0.6-2.424-0.81-1.568-2.21-2.352-4.23-2.352-0.89 0-1.76 0.16-2.59 0.48s-1.57 0.752-2.21 1.296c-0.7 0.64-1.22 1.384-1.56 2.232-0.33 0.848-0.53 1.944-0.6 3.288v12.816l3.22 0.912v1.968h-11.57v-1.968l3.69-0.912v-20.16z" />
              <path d="m1764.8 101.38c0-1.248 0.28-2.352 0.84-3.312 0.55-0.96 1.44-1.816 2.66-2.568s2.78-1.4 4.7-1.944 4.23-1.024 6.92-1.44c-0.17-1.888-0.35-3.36-0.56-4.416-0.2-1.056-0.48-1.904-0.84-2.544-0.44-0.832-1.07-1.448-1.87-1.848s-1.66-0.6-2.59-0.6c-0.9 0-1.7 0.16-2.42 0.48s-1.21 0.752-1.47 1.296c0.45 0.352 0.78 0.776 0.99 1.272 0.2 0.496 0.31 0.968 0.31 1.416 0 0.8-0.27 1.432-0.79 1.896-0.53 0.464-1.18 0.696-1.95 0.696s-1.4-0.256-1.89-0.768c-0.5-0.512-0.75-1.232-0.75-2.16 0-0.992 0.27-1.88 0.82-2.664 0.54-0.784 1.25-1.44 2.13-1.968s1.88-0.928 2.98-1.2 2.2-0.408 3.29-0.408c2.72 0 4.86 0.784 6.43 2.352 1.98 1.984 2.97 5.504 2.97 10.56v6.96c0 1.6 0.11 2.696 0.32 3.288 0.2 0.592 0.66 0.888 1.37 0.888h2.16v1.44c-2.31 0.928-4.1 1.392-5.38 1.392-0.48 0-0.88-0.08-1.2-0.24s-0.58-0.44-0.77-0.84-0.33-0.936-0.41-1.608-0.12-1.52-0.12-2.544h-0.19c-0.99 1.632-2.3 2.912-3.93 3.84-1.64 0.928-3.4 1.392-5.28 1.392-2.02 0-3.6-0.536-4.76-1.608-1.15-1.072-1.72-2.568-1.72-4.488zm8.49 3.312c0.9 0 1.83-0.232 2.81-0.696s1.8-1.112 2.47-1.944c0.99-1.216 1.49-3.008 1.49-5.376 0-0.544-0.02-1.08-0.05-1.608s-0.08-1.032-0.14-1.512c-1.95 0.384-3.58 0.784-4.88 1.2-1.29 0.416-2.33 0.888-3.11 1.416-0.79 0.528-1.34 1.136-1.66 1.824s-0.48 1.48-0.48 2.376c0 1.376 0.31 2.44 0.94 3.192 0.62 0.752 1.49 1.128 2.61 1.128z" />
              <path d="m1789.1 81.747l4.66-0.672 0.62-6.048 4.03-0.576v6.624h6.15v2.4h-6.15v17.136c0 1.088 0.02 1.832 0.05 2.232s0.1 0.728 0.19 0.984c0.1 0.256 0.27 0.416 0.53 0.48s0.67 0.096 1.25 0.096h3.36v1.392c-1.54 0.576-2.89 1-4.06 1.272s-2.1 0.408-2.81 0.408c-1.44 0-2.36-0.592-2.78-1.776-0.16-0.384-0.27-0.872-0.31-1.464-0.05-0.592-0.07-1.496-0.07-2.712v-18.048h-4.66v-1.728z" />
              <path d="m1806.8 83.043v-1.968h8.35v23.04l3.7 0.912v1.968h-12.05v-1.968l3.7-0.912v-20.16l-3.7-0.912zm3.03-9.696c0-0.928 0.26-1.664 0.79-2.208s1.26-0.816 2.18-0.816c0.93 0 1.67 0.272 2.21 0.816 0.55 0.544 0.82 1.28 0.82 2.208s-0.27 1.656-0.82 2.184c-0.54 0.528-1.28 0.792-2.21 0.792-0.92 0-1.65-0.264-2.18-0.792s-0.79-1.256-0.79-2.184z" />
              <path d="m1845.8 93.795c0 2.112-0.31 4.096-0.94 5.952-0.62 1.856-1.49 3.392-2.61 4.608-1.89 2.08-4.67 3.12-8.35 3.12-3.59 0-6.32-1.008-8.21-3.024-1.09-1.184-1.93-2.648-2.52-4.392s-0.89-3.64-0.89-5.688c0-2.112 0.3-4.112 0.91-6s1.49-3.44 2.64-4.656c1.03-1.088 2.21-1.88 3.55-2.376 1.35-0.496 2.92-0.744 4.71-0.744 1.76 0 3.34 0.248 4.75 0.744s2.59 1.256 3.55 2.28c1.12 1.184 1.97 2.664 2.54 4.44 0.58 1.776 0.87 3.688 0.87 5.736zm-18.39 0.048c0 3.84 0.58 6.704 1.73 8.592 0.55 0.832 1.2 1.464 1.95 1.896s1.73 0.648 2.95 0.648c2.21 0 3.86-0.752 4.94-2.256 0.58-0.832 1-1.984 1.28-3.456 0.27-1.472 0.4-3.168 0.4-5.088 0-3.808-0.57-6.656-1.73-8.544-0.54-0.832-1.2-1.464-1.96-1.896-0.77-0.432-1.76-0.648-2.98-0.648-2.24 0-3.85 0.736-4.85 2.208-1.15 1.792-1.73 4.64-1.73 8.544z" />
              <path d="m1853.4 83.955l-3.7-0.912v-1.968h6.92l1.05 4.8h0.2c0.96-1.632 2.24-2.92 3.86-3.864s3.37-1.416 5.26-1.416c1.28 0 2.43 0.224 3.45 0.672 1.03 0.448 1.84 1.088 2.45 1.92 0.64 0.896 1.1 2.152 1.37 3.768s0.4 3.8 0.4 6.552v10.608l3.7 0.912v1.968h-11.57v-1.968l3.22-0.912v-10.704c0-2.048-0.06-3.592-0.17-4.632s-0.31-1.848-0.6-2.424c-0.8-1.568-2.21-2.352-4.22-2.352-0.9 0-1.76 0.16-2.6 0.48-0.83 0.32-1.56 0.752-2.2 1.296-0.71 0.64-1.23 1.384-1.56 2.232-0.34 0.848-0.54 1.944-0.6 3.288v12.816l3.21 0.912v1.968h-11.57v-1.968l3.7-0.912v-20.16z" />
              <path d="m1881.2 101.38c0-1.248 0.28-2.352 0.84-3.312s1.45-1.816 2.67-2.568c1.21-0.752 2.78-1.4 4.7-1.944s4.22-1.024 6.91-1.44c-0.16-1.888-0.34-3.36-0.55-4.416s-0.49-1.904-0.84-2.544c-0.45-0.832-1.07-1.448-1.87-1.848s-1.67-0.6-2.59-0.6c-0.9 0-1.71 0.16-2.43 0.48s-1.21 0.752-1.46 1.296c0.45 0.352 0.77 0.776 0.98 1.272s0.31 0.968 0.31 1.416c0 0.8-0.26 1.432-0.79 1.896s-1.18 0.696-1.94 0.696c-0.77 0-1.4-0.256-1.9-0.768-0.49-0.512-0.74-1.232-0.74-2.16 0-0.992 0.27-1.88 0.81-2.664 0.55-0.784 1.26-1.44 2.14-1.968s1.87-0.928 2.98-1.2c1.1-0.272 2.19-0.408 3.28-0.408 2.72 0 4.87 0.784 6.43 2.352 1.99 1.984 2.98 5.504 2.98 10.56v6.96c0 1.6 0.11 2.696 0.31 3.288 0.21 0.592 0.67 0.888 1.37 0.888h2.16v1.44c-2.3 0.928-4.1 1.392-5.37 1.392-0.49 0-0.88-0.08-1.21-0.24-0.31-0.16-0.57-0.44-0.76-0.84s-0.33-0.936-0.41-1.608-0.12-1.52-0.12-2.544h-0.19c-1 1.632-2.31 2.912-3.94 3.84s-3.39 1.392-5.28 1.392c-2.02 0-3.6-0.536-4.75-1.608s-1.73-2.568-1.73-4.488zm8.5 3.312c0.89 0 1.83-0.232 2.81-0.696 0.97-0.464 1.8-1.112 2.47-1.944 0.99-1.216 1.49-3.008 1.49-5.376 0-0.544-0.02-1.08-0.05-1.608-0.04-0.528-0.08-1.032-0.15-1.512-1.95 0.384-3.57 0.784-4.87 1.2s-2.33 0.888-3.12 1.416c-0.78 0.528-1.33 1.136-1.66 1.824-0.31 0.688-0.48 1.48-0.48 2.376 0 1.376 0.32 2.44 0.94 3.192 0.63 0.752 1.5 1.128 2.62 1.128z" />
              <path d="m1910.2 72.435l-3.69-0.912v-1.968h8.35v34.56l3.7 0.912v1.968h-12.05v-1.968l3.69-0.912v-31.68z" />
              <path d="m1943.6 104.12h13.05l1.63-8.304h2.64v11.184h-26.64v-2.064l4.52-1.008v-28.416l-4.52-1.008v-2.064h14.31v2.064l-4.99 1.008v28.608z" />
              <path d="m1969.5 94.323c0 2.336 0.32 4.272 0.95 5.808 0.65 1.504 1.55 2.568 2.72 3.192s2.45 0.936 3.86 0.936c1.54 0 2.92-0.312 4.15-0.936s2.35-1.48 3.34-2.568l1.54 1.152c-0.58 0.768-1.2 1.488-1.85 2.16-0.66 0.672-1.4 1.264-2.24 1.776-0.83 0.512-1.76 0.912-2.78 1.2s-2.16 0.432-3.41 0.432c-2.2 0-4.16-0.472-5.85-1.416-1.7-0.944-3-2.328-3.89-4.152-0.48-0.992-0.83-2.088-1.06-3.288-0.22-1.2-0.33-2.504-0.33-3.912 0-4.512 1.02-7.992 3.07-10.44s4.86-3.672 8.45-3.672c3.29 0 5.82 1.016 7.58 3.048s2.64 4.84 2.64 8.424c0 0.32-0.01 0.616-0.02 0.888-0.02 0.272-0.06 0.728-0.12 1.368h-16.75zm6.38-11.136c-3.84 0-5.95 2.928-6.34 8.784h12v-1.584c0-2.304-0.48-4.08-1.46-5.328s-2.38-1.872-4.2-1.872z" />
              <path d="m2003 80.595c2.78 0 5 0.68 6.65 2.04s2.47 3.176 2.47 5.448c0 2.464-0.99 3.696-2.98 3.696-0.93 0-1.64-0.256-2.16-0.768-0.51-0.512-0.77-1.2-0.77-2.064 0-0.8 0.22-1.488 0.65-2.064 0.44-0.576 1.02-0.976 1.76-1.2-0.93-1.76-2.71-2.64-5.33-2.64-2.63 0-4.54 0.848-5.74 2.544s-1.8 4.368-1.8 8.016c0 3.04 0.42 5.36 1.25 6.96 1.28 2.464 3.38 3.696 6.29 3.696 1.47 0 2.88-0.32 4.22-0.96 1.35-0.64 2.5-1.552 3.46-2.736l1.44 1.152c-1.28 1.984-2.75 3.44-4.42 4.368-1.66 0.928-3.61 1.392-5.85 1.392s-4.19-0.456-5.84-1.368c-1.64-0.912-2.93-2.248-3.86-4.008-0.54-1.024-0.97-2.2-1.27-3.528-0.31-1.328-0.46-2.744-0.46-4.248 0-2.08 0.29-3.96 0.87-5.64 0.57-1.68 1.39-3.12 2.47-4.32 1.07-1.2 2.36-2.128 3.88-2.784s3.21-0.984 5.07-0.984z" />
              <path d="m2014.6 81.747l4.66-0.672 0.62-6.048 4.03-0.576v6.624h6.15v2.4h-6.15v17.136c0 1.088 0.02 1.832 0.05 2.232 0.04 0.4 0.1 0.728 0.19 0.984 0.1 0.256 0.28 0.416 0.53 0.48 0.26 0.064 0.67 0.096 1.25 0.096h3.36v1.392c-1.53 0.576-2.89 1-4.05 1.272-1.17 0.272-2.11 0.408-2.81 0.408-1.44 0-2.37-0.592-2.79-1.776-0.16-0.384-0.26-0.872-0.31-1.464s-0.07-1.496-0.07-2.712v-18.048h-4.66v-1.728z" />
              <path d="m2039.8 94.659c0 1.024 0.02 1.92 0.05 2.688s0.07 1.424 0.12 1.968 0.12 1.008 0.22 1.392c0.09 0.384 0.22 0.72 0.38 1.008 0.74 1.568 2.08 2.352 4.03 2.352 0.87 0 1.7-0.16 2.5-0.48s1.5-0.752 2.11-1.296c0.71-0.64 1.23-1.384 1.56-2.232 0.34-0.848 0.54-1.944 0.6-3.288v-12.816l-4.17-0.912v-1.968h8.83v23.04l3.69 0.912v1.968l-7.44 0.48-0.53-5.28h-0.19c-0.93 1.632-2.19 2.92-3.79 3.864s-3.31 1.416-5.14 1.416c-1.21 0-2.32-0.224-3.33-0.672s-1.8-1.088-2.38-1.92c-0.64-0.896-1.09-2.152-1.36-3.768-0.28-1.616-0.41-3.8-0.41-6.552v-10.608l-3.22-0.912v-1.968h7.87v13.584z" />
              <path d="m2077.1 83.859c-0.29-0.192-0.61-0.288-0.96-0.288-0.61 0-1.25 0.376-1.92 1.128s-1.26 1.704-1.78 2.856c-0.44 1.088-0.8 2.28-1.05 3.576-0.26 1.296-0.39 2.632-0.39 4.008v8.976l5.19 0.912v1.968h-13.54v-1.968l3.7-0.912v-20.16l-3.7-0.912v-1.968h6.91l1.06 6.336h0.19c0.74-2.176 1.72-3.856 2.95-5.04 1.24-1.184 2.62-1.776 4.16-1.776 1.24 0 2.28 0.392 3.12 1.176 0.83 0.784 1.24 1.832 1.24 3.144 0 0.96-0.24 1.72-0.74 2.28s-1.16 0.84-1.99 0.84c-0.84 0-1.49-0.264-1.97-0.792s-0.72-1.224-0.72-2.088c0-0.192 0.02-0.368 0.05-0.528s0.09-0.416 0.19-0.768z" />
              <path d="m2089.8 94.323c0 2.336 0.32 4.272 0.96 5.808 0.64 1.504 1.54 2.568 2.71 3.192s2.46 0.936 3.87 0.936c1.53 0 2.92-0.312 4.15-0.936s2.34-1.48 3.33-2.568l1.54 1.152c-0.58 0.768-1.19 1.488-1.85 2.16-0.65 0.672-1.4 1.264-2.23 1.776s-1.76 0.912-2.78 1.2c-1.03 0.288-2.16 0.432-3.41 0.432-2.21 0-4.16-0.472-5.86-1.416-1.69-0.944-2.99-2.328-3.88-4.152-0.48-0.992-0.84-2.088-1.06-3.288s-0.34-2.504-0.34-3.912c0-4.512 1.03-7.992 3.08-10.44 2.04-2.448 4.86-3.672 8.44-3.672 3.3 0 5.83 1.016 7.59 3.048s2.64 4.84 2.64 8.424c0 0.32-0.01 0.616-0.03 0.888-0.01 0.272-0.05 0.728-0.12 1.368h-16.75zm6.38-11.136c-3.83 0-5.95 2.928-6.33 8.784h12v-1.584c0-2.304-0.49-4.08-1.46-5.328-0.98-1.248-2.38-1.872-4.21-1.872z" />
              <path d="m2120.5 105.27c1.83 0 3.3-0.36 4.42-1.08s1.68-1.752 1.68-3.096c0-0.8-0.21-1.472-0.63-2.016-0.41-0.544-0.95-1.008-1.6-1.392-0.66-0.384-1.4-0.712-2.21-0.984-0.82-0.272-1.61-0.52-2.38-0.744-0.99-0.288-1.96-0.624-2.9-1.008-0.95-0.384-1.79-0.872-2.55-1.464-0.75-0.592-1.35-1.312-1.8-2.16-0.44-0.848-0.67-1.88-0.67-3.096 0-2.304 0.84-4.152 2.52-5.544s4.03-2.088 7.03-2.088c2.56 0 4.59 0.536 6.08 1.608 1.48 1.072 2.23 2.536 2.23 4.392 0 1.024-0.23 1.824-0.67 2.4-0.45 0.576-1.09 0.864-1.93 0.864-0.73 0-1.34-0.224-1.82-0.672s-0.72-1.024-0.72-1.728c0-0.608 0.16-1.16 0.48-1.656s0.74-0.856 1.25-1.08c-0.48-0.672-1.1-1.16-1.85-1.464s-1.72-0.456-2.9-0.456c-3.84 0-5.76 1.36-5.76 4.08 0 0.736 0.13 1.344 0.38 1.824 0.26 0.48 0.64 0.896 1.15 1.248 0.52 0.352 1.15 0.664 1.9 0.936s1.62 0.568 2.62 0.888c1.08 0.352 2.16 0.72 3.21 1.104 1.06 0.384 2.01 0.864 2.86 1.44 0.84 0.576 1.53 1.296 2.06 2.16s0.79 1.936 0.79 3.216c0 1.248-0.25 2.352-0.76 3.312-0.52 0.96-1.24 1.768-2.16 2.424-0.93 0.656-2.04 1.16-3.32 1.512s-2.68 0.528-4.22 0.528c-2.85 0-5.13-0.568-6.84-1.704s-2.57-2.696-2.57-4.68c0-1.024 0.23-1.832 0.7-2.424 0.46-0.592 1.12-0.888 1.99-0.888 0.77 0 1.42 0.224 1.94 0.672 0.53 0.448 0.8 1.04 0.8 1.776 0 0.608-0.17 1.184-0.51 1.728-0.33 0.544-0.74 0.944-1.22 1.2 0.41 0.64 1.16 1.152 2.23 1.536s2.29 0.576 3.67 0.576z" />
            </g>
            <g transform="matrix(2.9381 0 0 2.9381 -3312.2 -182.31)">
              <path
                d="m1624.1 107.24l-0.05 0.336c-0.19 0.192-0.54 0.368-1.03 0.528-0.5 0.16-0.89 0.24-1.16 0.24s-0.4-0.064-0.4-0.192c0.76-4.608 1.15-9.976 1.15-16.104s-0.35-11.688-1.06-16.68l1.25-0.864 4.27 2.208c-0.54 11.808-1.38 21.152-2.49 28.032h1.29c4.8 0 8.77-0.816 11.91-2.448l0.81 1.248-1.58 4.128c-1.7 0.16-3.64 0.24-5.81 0.24-2.18 0-4.55-0.224-7.1-0.672z"
                fill="#A82B1C"
                fill-rule="nonzero"
              />
            </g>
            <g transform="matrix(-2.5532 .090531 -.090531 -2.5532 5593.1 152.93)">
              <path
                d="m1624.5 107.81c-0.19 0.192-0.54 0.368-1.03 0.528-0.5 0.16-0.88 0.24-1.16 0.24-0.27 0-0.41-0.064-0.41-0.192 0.61-4.128 0.92-8.352 0.92-12.672 0-7.68-0.47-14.464-1.39-20.352l1.24-0.864 4.32 2.208c-0.16 5.152-0.46 10.872-0.91 17.16s-0.98 10.936-1.58 13.944z"
                fill="#A82B1C"
                fill-rule="nonzero"
              />
            </g>
            <g
              transform="matrix(.32739 0 0 .32739 961.44 65.576)"
              fill-rule="nonzero"
            >
              <path d="m1624.4 94.899c0 2.23 0.3 4.078 0.91 5.544 0.61 1.436 1.48 2.451 2.59 3.047 1.12 0.596 2.35 0.893 3.69 0.893 1.47 0 2.79-0.297 3.96-0.893 1.18-0.596 2.24-1.413 3.19-2.451l1.46 1.099c-0.55 0.734-1.13 1.421-1.76 2.062-0.63 0.642-1.34 1.207-2.13 1.695-0.8 0.489-1.68 0.871-2.66 1.146s-2.06 0.412-3.25 0.412c-2.11 0-3.97-0.45-5.59-1.351s-2.86-2.223-3.71-3.964c-0.46-0.946-0.8-1.993-1.01-3.138-0.22-1.145-0.32-2.39-0.32-3.734 0-4.307 0.98-7.628 2.93-9.965s4.64-3.505 8.06-3.505c3.15 0 5.56 0.97 7.24 2.909 1.68 1.94 2.52 4.62 2.52 8.041 0 0.306 0 0.588-0.02 0.848-0.01 0.259-0.05 0.695-0.11 1.305h-15.99zm6.09-10.629c-3.67 0-5.68 2.795-6.05 8.384h11.46v-1.512c0-2.199-0.47-3.894-1.4-5.085s-2.27-1.787-4.01-1.787z" />
              <path d="m1644.3 101.63c0-1.191 0.27-2.245 0.8-3.161 0.54-0.916 1.38-1.733 2.54-2.451 1.17-0.718 2.66-1.336 4.5-1.856 1.83-0.519 4.03-0.977 6.59-1.374-0.15-1.802-0.33-3.207-0.52-4.215-0.2-1.008-0.47-1.818-0.81-2.429-0.42-0.794-1.02-1.382-1.78-1.763-0.77-0.382-1.59-0.573-2.48-0.573-0.85 0-1.62 0.153-2.31 0.458s-1.15 0.718-1.4 1.237c0.43 0.336 0.74 0.741 0.94 1.214 0.2 0.474 0.3 0.924 0.3 1.352 0 0.763-0.25 1.367-0.76 1.81-0.5 0.442-1.12 0.664-1.85 0.664-0.74 0-1.34-0.244-1.81-0.733-0.48-0.489-0.71-1.176-0.71-2.062 0-0.947 0.26-1.794 0.78-2.543 0.52-0.748 1.2-1.374 2.04-1.878 0.83-0.504 1.78-0.886 2.84-1.146 1.05-0.259 2.1-0.389 3.13-0.389 2.6 0 4.65 0.748 6.14 2.245 1.9 1.894 2.84 5.254 2.84 10.08v6.643c0 1.527 0.1 2.573 0.3 3.138s0.64 0.848 1.31 0.848h2.06v1.375c-2.2 0.885-3.91 1.328-5.13 1.328-0.46 0-0.84-0.076-1.15-0.229-0.3-0.153-0.55-0.42-0.73-0.802-0.18-0.381-0.31-0.893-0.39-1.535-0.08-0.641-0.11-1.45-0.11-2.428h-0.19c-0.94 1.558-2.2 2.78-3.75 3.666-1.56 0.885-3.24 1.328-5.04 1.328-1.93 0-3.44-0.511-4.54-1.535-1.1-1.023-1.65-2.451-1.65-4.284zm8.11 3.162c0.86 0 1.75-0.222 2.68-0.665 0.93-0.442 1.72-1.061 2.36-1.855 0.95-1.161 1.42-2.871 1.42-5.132 0-0.519-0.01-1.03-0.05-1.534-0.03-0.504-0.07-0.985-0.13-1.444-1.87 0.367-3.42 0.749-4.65 1.146-1.24 0.397-2.23 0.847-2.98 1.351s-1.28 1.085-1.58 1.741c-0.31 0.657-0.46 1.413-0.46 2.268 0 1.314 0.3 2.329 0.89 3.047 0.6 0.718 1.43 1.077 2.5 1.077z" />
              <path d="m1678.3 105.35c1.74 0 3.15-0.344 4.22-1.031 1.07-0.688 1.6-1.673 1.6-2.955 0-0.764-0.2-1.405-0.59-1.925-0.4-0.519-0.91-0.962-1.54-1.328-0.63-0.367-1.33-0.68-2.11-0.94-0.78-0.259-1.53-0.496-2.27-0.71-0.94-0.275-1.87-0.595-2.77-0.962-0.9-0.366-1.71-0.832-2.43-1.397-0.71-0.565-1.29-1.253-1.71-2.062-0.43-0.81-0.64-1.795-0.64-2.955 0-2.199 0.8-3.963 2.4-5.292s3.84-1.993 6.71-1.993c2.45 0 4.38 0.512 5.8 1.535s2.13 2.42 2.13 4.192c0 0.977-0.22 1.741-0.64 2.291-0.43 0.55-1.04 0.824-1.84 0.824-0.7 0-1.28-0.213-1.74-0.641s-0.68-0.977-0.68-1.649c0-0.581 0.15-1.108 0.45-1.581 0.31-0.473 0.71-0.817 1.2-1.031-0.46-0.641-1.05-1.107-1.77-1.397s-1.64-0.436-2.77-0.436c-3.67 0-5.5 1.299-5.5 3.895 0 0.702 0.12 1.283 0.37 1.741 0.24 0.458 0.61 0.855 1.1 1.191s1.09 0.634 1.81 0.894c0.72 0.259 1.55 0.542 2.5 0.847 1.03 0.336 2.06 0.687 3.06 1.054 1.01 0.366 1.92 0.825 2.73 1.374 0.81 0.55 1.47 1.237 1.97 2.062s0.76 1.848 0.76 3.07c0 1.191-0.25 2.245-0.74 3.161-0.49 0.917-1.17 1.688-2.06 2.314-0.88 0.626-1.94 1.107-3.16 1.443s-2.56 0.504-4.03 0.504c-2.72 0-4.9-0.542-6.53-1.626-1.63-1.085-2.45-2.574-2.45-4.467 0-0.978 0.22-1.749 0.66-2.314 0.45-0.565 1.08-0.848 1.9-0.848 0.74 0 1.36 0.214 1.86 0.642 0.5 0.427 0.76 0.992 0.76 1.695 0 0.58-0.16 1.13-0.48 1.649-0.33 0.52-0.72 0.901-1.17 1.146 0.39 0.611 1.1 1.099 2.13 1.466 1.02 0.366 2.19 0.55 3.5 0.55z" />
              <path d="m1690.6 82.895l4.45-0.641 0.59-5.773 3.85-0.55v6.323h5.86v2.291h-5.86v16.356c0 1.039 0.01 1.749 0.05 2.131 0.03 0.382 0.09 0.695 0.18 0.939s0.26 0.397 0.5 0.458c0.25 0.061 0.64 0.092 1.19 0.092h3.21v1.329c-1.47 0.549-2.76 0.954-3.87 1.214-1.12 0.259-2.01 0.389-2.68 0.389-1.38 0-2.26-0.565-2.66-1.695-0.15-0.367-0.25-0.832-0.3-1.397-0.04-0.566-0.06-1.428-0.06-2.589v-17.227h-4.45v-1.65z" />
              <rect x="1720.3" y="92.929" width="22.633" height="2.657" />
              <path d="m1768.6 101.5l0.55 2.703h0.22l0.56-2.703 5.36-19.701h3.29l5.55 19.701 0.55 2.703h0.18l0.55-2.703 4.58-16.494-2.97-0.87v-1.879h8.7v1.879l-2.47 0.87-6.83 22.45h-4.72l-5.45-17.868-5.32 17.868h-4.72l-7.01-22.45-2.42-0.87v-1.879h10.26v1.879l-3.16 0.87 4.72 16.494z" />
              <path d="m1802.5 94.899c0 2.23 0.3 4.078 0.91 5.544 0.61 1.436 1.47 2.451 2.59 3.047s2.34 0.893 3.69 0.893c1.46 0 2.79-0.297 3.96-0.893 1.18-0.596 2.24-1.413 3.19-2.451l1.46 1.099c-0.55 0.734-1.14 1.421-1.76 2.062-0.63 0.642-1.34 1.207-2.13 1.695-0.8 0.489-1.68 0.871-2.66 1.146s-2.06 0.412-3.25 0.412c-2.11 0-3.97-0.45-5.59-1.351s-2.86-2.223-3.71-3.964c-0.46-0.946-0.8-1.993-1.01-3.138-0.22-1.145-0.32-2.39-0.32-3.734 0-4.307 0.98-7.628 2.93-9.965 1.96-2.337 4.64-3.505 8.06-3.505 3.15 0 5.56 0.97 7.24 2.909 1.68 1.94 2.52 4.62 2.52 8.041 0 0.306 0 0.588-0.02 0.848-0.02 0.259-0.05 0.695-0.11 1.305h-15.99zm6.09-10.629c-3.67 0-5.68 2.795-6.05 8.384h11.46v-1.512c0-2.199-0.47-3.894-1.4-5.085s-2.27-1.787-4.01-1.787z" />
              <path d="m1831.8 105.35c1.74 0 3.14-0.344 4.21-1.031 1.07-0.688 1.61-1.673 1.61-2.955 0-0.764-0.2-1.405-0.6-1.925-0.4-0.519-0.91-0.962-1.53-1.328-0.63-0.367-1.33-0.68-2.11-0.94-0.78-0.259-1.54-0.496-2.27-0.71-0.95-0.275-1.87-0.595-2.77-0.962-0.9-0.366-1.71-0.832-2.43-1.397s-1.29-1.253-1.72-2.062c-0.43-0.81-0.64-1.795-0.64-2.955 0-2.199 0.8-3.963 2.41-5.292 1.6-1.329 3.84-1.993 6.71-1.993 2.44 0 4.37 0.512 5.79 1.535s2.13 2.42 2.13 4.192c0 0.977-0.21 1.741-0.64 2.291-0.42 0.55-1.04 0.824-1.83 0.824-0.7 0-1.28-0.213-1.74-0.641s-0.69-0.977-0.69-1.649c0-0.581 0.16-1.108 0.46-1.581 0.31-0.473 0.7-0.817 1.19-1.031-0.46-0.641-1.04-1.107-1.76-1.397s-1.64-0.436-2.77-0.436c-3.67 0-5.5 1.299-5.5 3.895 0 0.702 0.12 1.283 0.36 1.741 0.25 0.458 0.62 0.855 1.1 1.191 0.49 0.336 1.1 0.634 1.81 0.894 0.72 0.259 1.55 0.542 2.5 0.847 1.04 0.336 2.06 0.687 3.07 1.054 1.01 0.366 1.92 0.825 2.73 1.374 0.81 0.55 1.46 1.237 1.97 2.062 0.5 0.825 0.75 1.848 0.75 3.07 0 1.191-0.24 2.245-0.73 3.161-0.49 0.917-1.18 1.688-2.06 2.314-0.89 0.626-1.94 1.107-3.16 1.443-1.23 0.336-2.57 0.504-4.04 0.504-2.71 0-4.89-0.542-6.52-1.626-1.64-1.085-2.46-2.574-2.46-4.467 0-0.978 0.23-1.749 0.67-2.314s1.07-0.848 1.9-0.848c0.73 0 1.35 0.214 1.86 0.642 0.5 0.427 0.75 0.992 0.75 1.695 0 0.58-0.16 1.13-0.48 1.649-0.32 0.52-0.71 0.901-1.17 1.146 0.4 0.611 1.11 1.099 2.13 1.466 1.03 0.366 2.19 0.55 3.51 0.55z" />
              <path d="m1844.1 82.895l4.44-0.641 0.6-5.773 3.85-0.55v6.323h5.86v2.291h-5.86v16.356c0 1.039 0.01 1.749 0.04 2.131s0.09 0.695 0.18 0.939c0.1 0.244 0.26 0.397 0.51 0.458 0.24 0.061 0.64 0.092 1.19 0.092h3.21v1.329c-1.47 0.549-2.76 0.954-3.87 1.214-1.12 0.259-2.01 0.389-2.69 0.389-1.37 0-2.26-0.565-2.65-1.695-0.16-0.367-0.26-0.832-0.3-1.397-0.05-0.566-0.07-1.428-0.07-2.589v-17.227h-4.44v-1.65z" />
            </g>
            <g
              transform="matrix(.29757 0 0 .29757 1323.5 68.617)"
              fill-rule="nonzero"
            >
              <path d="m947.8 82.799l-3.882-0.958v-2.066h7.259l1.109 5.04h0.202c0.974-1.713 2.277-3.066 3.906-4.057 1.63-0.992 3.369-1.488 5.218-1.488 1.411 0 2.646 0.261 3.705 0.782 1.058 0.521 1.84 1.168 2.344 1.941 0.537 0.84 0.974 1.949 1.31 3.326 1.076-1.915 2.47-3.402 4.184-4.461 1.714-1.058 3.546-1.588 5.495-1.588 1.344 0 2.554 0.261 3.629 0.782s1.899 1.235 2.47 2.142c0.303 0.471 0.555 1.042 0.756 1.714 0.202 0.672 0.37 1.504 0.504 2.495 0.135 0.992 0.227 2.159 0.278 3.504 0.05 1.344 0.075 2.923 0.075 4.738v9.326l3.882 0.957v2.067h-12.149v-2.067l3.378-0.957v-11.242c0-2.15-0.059-3.772-0.177-4.864-0.117-1.092-0.328-1.941-0.63-2.546-0.84-1.646-2.252-2.47-4.234-2.47-0.874 0-1.706 0.169-2.495 0.505-0.79 0.336-1.504 0.789-2.143 1.361-0.739 0.672-1.285 1.453-1.638 2.344-0.353 0.89-0.563 2.041-0.63 3.453v13.459l3.377 0.957v2.067h-11.644v-2.067l3.377-0.957v-11.242c0-2.15-0.059-3.772-0.176-4.864-0.118-1.092-0.328-1.941-0.63-2.546-0.84-1.646-2.252-2.47-4.235-2.47-0.873 0-1.705 0.169-2.495 0.505s-1.504 0.789-2.142 1.361c-0.739 0.672-1.286 1.453-1.638 2.344-0.353 0.89-0.563 2.041-0.631 3.453v13.459l3.378 0.957v2.067h-12.149v-2.067l3.882-0.957v-21.172z" />
              <path d="m998.71 93.687c0 2.453 0.336 4.487 1.008 6.1 0.67 1.579 1.62 2.696 2.85 3.352 1.23 0.655 2.58 0.983 4.06 0.983 1.61 0 3.06-0.328 4.36-0.983 1.29-0.656 2.46-1.554 3.5-2.697l1.61 1.21c-0.6 0.806-1.25 1.562-1.94 2.268s-1.47 1.328-2.34 1.865c-0.87 0.538-1.85 0.958-2.93 1.26-1.07 0.303-2.26 0.454-3.57 0.454-2.32 0-4.37-0.496-6.154-1.487-1.781-0.991-3.142-2.445-4.083-4.36-0.504-1.042-0.874-2.193-1.109-3.453s-0.353-2.63-0.353-4.108c0-4.739 1.075-8.393 3.226-10.964s5.113-3.857 8.873-3.857c3.46 0 6.12 1.067 7.96 3.201 1.85 2.134 2.78 5.083 2.78 8.847 0 0.336-0.01 0.647-0.03 0.933-0.02 0.285-0.06 0.764-0.12 1.436h-17.598zm6.708-11.694c-4.04 0-6.254 3.074-6.657 9.224h12.607v-1.663c0-2.42-0.52-4.285-1.54-5.596-1.03-1.31-2.5-1.965-4.41-1.965z" />
              <path d="m1021 93.637c0-2.218 0.29-4.302 0.86-6.251s1.43-3.562 2.57-4.839c0.97-1.075 2.13-1.89 3.45-2.445 1.33-0.554 2.89-0.832 4.67-0.832 1.51 0 2.87 0.286 4.08 0.857 1.21 0.572 2.3 1.597 3.28 3.075v-12.501l-3.89-0.958v-2.066h8.78v36.294l3.88 0.957v2.067l-7.82 0.504-0.4-4.133c-1.04 1.579-2.22 2.663-3.53 3.251s-2.77 0.882-4.38 0.882c-1.72 0-3.26-0.252-4.64-0.756s-2.52-1.311-3.43-2.42c-1.08-1.31-1.92-2.881-2.54-4.713-0.63-1.831-0.94-3.822-0.94-5.973zm18.91-0.051c0-4.133-0.59-7.141-1.77-9.023-0.54-0.873-1.19-1.537-1.96-1.991-0.78-0.453-1.8-0.68-3.08-0.68-2.28 0-3.98 0.789-5.09 2.369-0.61 0.874-1.04 2.083-1.31 3.629s-0.4 3.327-0.4 5.344c0 4.032 0.58 7.023 1.76 8.972 0.57 0.874 1.24 1.538 2.02 1.991 0.77 0.454 1.79 0.681 3.07 0.681 1.18 0 2.19-0.193 3.03-0.58 0.84-0.386 1.49-0.966 1.96-1.739 0.57-0.941 1.01-2.159 1.31-3.655 0.3-1.495 0.46-3.268 0.46-5.318z" />
              <path d="m1051.7 81.841v-2.066h8.77v24.196l3.88 0.957v2.067h-12.65v-2.067l3.88-0.957v-21.172l-3.88-0.958zm3.18-10.182c0-0.975 0.27-1.748 0.83-2.319 0.55-0.571 1.32-0.857 2.29-0.857 0.98 0 1.75 0.286 2.32 0.857s0.86 1.344 0.86 2.319c0 0.974-0.29 1.739-0.86 2.293-0.57 0.555-1.34 0.832-2.32 0.832-0.97 0-1.74-0.277-2.29-0.832-0.56-0.554-0.83-1.319-0.83-2.293z" />
              <path d="m1080.9 79.27c2.92 0 5.25 0.715 6.98 2.143s2.59 3.335 2.59 5.721c0 2.588-1.04 3.882-3.12 3.882-0.97 0-1.73-0.269-2.27-0.807s-0.8-1.26-0.8-2.167c0-0.841 0.22-1.563 0.67-2.168 0.46-0.605 1.07-1.025 1.85-1.26-0.98-1.849-2.84-2.773-5.6-2.773s-4.76 0.891-6.02 2.672-1.89 4.587-1.89 8.418c0 3.193 0.43 5.629 1.31 7.309 1.34 2.588 3.54 3.882 6.6 3.882 1.55 0 3.02-0.336 4.44-1.008 1.41-0.673 2.62-1.63 3.63-2.874l1.51 1.21c-1.35 2.084-2.89 3.613-4.64 4.587-1.75 0.975-3.8 1.462-6.15 1.462s-4.39-0.479-6.12-1.437c-1.73-0.957-3.09-2.36-4.06-4.209-0.57-1.075-1.02-2.31-1.34-3.705-0.32-1.394-0.48-2.881-0.48-4.461 0-2.184 0.31-4.158 0.91-5.923 0.61-1.764 1.47-3.276 2.6-4.536 1.12-1.261 2.48-2.235 4.08-2.924s3.37-1.034 5.32-1.034z" />
              <path d="m1094.5 81.841v-2.066h8.77v24.196l3.88 0.957v2.067h-12.65v-2.067l3.88-0.957v-21.172l-3.88-0.958zm3.17-10.182c0-0.975 0.28-1.748 0.84-2.319 0.55-0.571 1.31-0.857 2.29-0.857 0.97 0 1.75 0.286 2.32 0.857s0.85 1.344 0.85 2.319c0 0.974-0.28 1.739-0.85 2.293-0.57 0.555-1.35 0.832-2.32 0.832-0.98 0-1.74-0.277-2.29-0.832-0.56-0.554-0.84-1.319-0.84-2.293z" />
              <path d="m1114.1 82.799l-3.89-0.958v-2.066h7.26l1.11 5.04h0.21c1-1.713 2.35-3.066 4.05-4.057 1.7-0.992 3.54-1.488 5.52-1.488 1.35 0 2.56 0.236 3.63 0.706 1.08 0.471 1.93 1.143 2.57 2.017 0.67 0.94 1.15 2.26 1.44 3.957s0.43 3.99 0.43 6.88v11.141l3.88 0.957v2.067h-12.15v-2.067l3.38-0.957v-11.242c0-2.15-0.06-3.772-0.18-4.864s-0.33-1.941-0.63-2.546c-0.84-1.646-2.32-2.47-4.43-2.47-0.95 0-1.85 0.169-2.73 0.505-0.87 0.336-1.64 0.789-2.31 1.361-0.74 0.672-1.29 1.453-1.64 2.344-0.36 0.89-0.57 2.041-0.63 3.453v13.459l3.37 0.957v2.067h-12.15v-2.067l3.89-0.957v-21.172z" />
              <path d="m1148.9 93.687c0 2.453 0.33 4.487 1.01 6.1 0.67 1.579 1.62 2.696 2.85 3.352 1.22 0.655 2.57 0.983 4.05 0.983 1.61 0 3.07-0.328 4.36-0.983 1.3-0.656 2.46-1.554 3.51-2.697l1.61 1.21c-0.61 0.806-1.25 1.562-1.94 2.268s-1.47 1.328-2.35 1.865c-0.87 0.538-1.84 0.958-2.92 1.26-1.07 0.303-2.27 0.454-3.58 0.454-2.32 0-4.37-0.496-6.15-1.487s-3.14-2.445-4.08-4.36c-0.5-1.042-0.87-2.193-1.11-3.453s-0.35-2.63-0.35-4.108c0-4.739 1.07-8.393 3.22-10.964s5.11-3.857 8.87-3.857c3.47 0 6.12 1.067 7.97 3.201s2.77 5.083 2.77 8.847c0 0.336-0.01 0.647-0.02 0.933-0.02 0.285-0.06 0.764-0.13 1.436h-17.59zm6.7-11.694c-4.03 0-6.25 3.074-6.65 9.224h12.6v-1.663c0-2.42-0.51-4.285-1.54-5.596-1.02-1.31-2.49-1.965-4.41-1.965z" />
              <rect x="1184.8" y="91.52" width="24.902" height="2.924" />
              <path d="m1240.5 114v2.066h-13.66v-2.066l3.88-0.958v-30.245l-3.88-0.958v-2.066h7.26l0.76 3.881c1.07-1.68 2.29-2.831 3.65-3.453s2.9-0.933 4.61-0.933c1.72 0 3.27 0.253 4.66 0.757 1.4 0.504 2.53 1.31 3.41 2.419 1 1.277 1.8 2.806 2.39 4.587s0.88 3.731 0.88 5.848c0 2.15-0.32 4.175-0.98 6.074-0.65 1.899-1.54 3.486-2.65 4.763-1.88 2.185-4.58 3.277-8.11 3.277-1.45 0-2.75-0.269-3.91-0.807-1.16-0.537-2.22-1.512-3.2-2.923v9.779l4.89 0.958zm-4.94-21.071c0 3.999 0.59 6.923 1.77 8.771 0.53 0.874 1.19 1.538 1.96 1.991 0.77 0.454 1.8 0.681 3.08 0.681 2.28 0 3.98-0.79 5.09-2.369 0.6-0.841 1.04-2.008 1.31-3.504 0.27-1.495 0.4-3.234 0.4-5.217 0-3.932-0.59-6.839-1.76-8.721-0.57-0.873-1.25-1.537-2.02-1.991-0.77-0.453-1.8-0.68-3.07-0.68-1.18 0-2.19 0.193-3.03 0.579-0.84 0.387-1.49 0.967-1.96 1.739-0.58 0.908-1.01 2.084-1.32 3.529-0.3 1.445-0.45 3.176-0.45 5.192z" />
              <path d="m1279.4 92.729c0-2.15-0.05-3.772-0.17-4.864s-0.33-1.941-0.63-2.546c-0.84-1.646-2.32-2.47-4.44-2.47-0.94 0-1.85 0.169-2.72 0.505s-1.65 0.789-2.32 1.361c-0.74 0.672-1.28 1.445-1.64 2.318-0.35 0.874-0.56 1.966-0.63 3.277v13.661l3.38 0.957v2.067h-12.15v-2.067l3.88-0.957v-33.27l-3.88-0.958v-2.066h8.77v16.836c1.01-1.613 2.35-2.89 4.01-3.831s3.45-1.412 5.37-1.412c1.34 0 2.55 0.236 3.63 0.706 1.07 0.471 1.93 1.143 2.57 2.017 0.67 0.94 1.15 2.26 1.44 3.957 0.28 1.697 0.43 3.99 0.43 6.88v11.141l3.88 0.957v2.067h-12.15v-2.067l3.37-0.957v-11.242z" />
              <path d="m1291.1 81.841v-2.066h8.77v24.196l3.88 0.957v2.067h-12.65v-2.067l3.88-0.957v-21.172l-3.88-0.958zm3.18-10.182c0-0.975 0.27-1.748 0.83-2.319 0.55-0.571 1.32-0.857 2.29-0.857 0.98 0 1.75 0.286 2.32 0.857s0.86 1.344 0.86 2.319c0 0.974-0.29 1.739-0.86 2.293-0.57 0.555-1.34 0.832-2.32 0.832-0.97 0-1.74-0.277-2.29-0.832-0.56-0.554-0.83-1.319-0.83-2.293z" />
              <path d="m1310.3 70.701l-3.89-0.958v-2.066h8.77v36.294l3.89 0.957v2.067h-12.66v-2.067l3.89-0.957v-33.27z" />
              <path d="m1347.5 93.133c0 2.218-0.33 4.301-0.99 6.25-0.65 1.949-1.57 3.563-2.74 4.84-1.99 2.184-4.91 3.276-8.77 3.276-3.77 0-6.64-1.058-8.62-3.176-1.15-1.243-2.03-2.78-2.65-4.612-0.62-1.831-0.93-3.823-0.93-5.973 0-2.218 0.32-4.319 0.96-6.301 0.63-1.983 1.56-3.613 2.77-4.89 1.07-1.143 2.32-1.974 3.73-2.495s3.06-0.782 4.94-0.782c1.85 0 3.51 0.261 4.99 0.782s2.72 1.319 3.73 2.394c1.17 1.244 2.07 2.798 2.67 4.663 0.61 1.865 0.91 3.873 0.91 6.024zm-19.31 0.05c0 4.033 0.61 7.041 1.82 9.023 0.57 0.874 1.25 1.538 2.04 1.991 0.79 0.454 1.82 0.681 3.1 0.681 2.32 0 4.05-0.79 5.19-2.369 0.6-0.874 1.05-2.084 1.33-3.63 0.29-1.546 0.43-3.327 0.43-5.343 0-3.999-0.6-6.99-1.81-8.973-0.57-0.873-1.26-1.537-2.07-1.991-0.81-0.453-1.85-0.68-3.12-0.68-2.36 0-4.05 0.773-5.09 2.318-1.21 1.882-1.82 4.873-1.82 8.973z" />
              <path d="m1362.2 105.18c1.91 0 3.46-0.378 4.63-1.134 1.18-0.756 1.77-1.84 1.77-3.251 0-0.84-0.22-1.546-0.66-2.117-0.44-0.572-1-1.059-1.69-1.462s-1.46-0.748-2.32-1.034c-0.85-0.285-1.68-0.546-2.49-0.781-1.04-0.302-2.06-0.655-3.05-1.058-0.99-0.404-1.88-0.916-2.67-1.538s-1.42-1.378-1.89-2.268c-0.47-0.891-0.71-1.975-0.71-3.252 0-2.419 0.88-4.36 2.65-5.822 1.76-1.462 4.22-2.193 7.38-2.193 2.69 0 4.82 0.563 6.38 1.689s2.34 2.663 2.34 4.613c0 1.075-0.23 1.915-0.7 2.52s-1.14 0.907-2.02 0.907c-0.77 0-1.41-0.235-1.91-0.705-0.51-0.471-0.76-1.076-0.76-1.815 0-0.639 0.17-1.218 0.5-1.739 0.34-0.521 0.78-0.899 1.32-1.134-0.51-0.706-1.16-1.219-1.95-1.538s-1.8-0.479-3.04-0.479c-4.04 0-6.05 1.429-6.05 4.285 0 0.773 0.13 1.411 0.4 1.915 0.27 0.505 0.67 0.941 1.21 1.311s1.2 0.697 1.99 0.983 1.71 0.597 2.75 0.933c1.14 0.369 2.27 0.756 3.37 1.159 1.11 0.403 2.11 0.907 3 1.512s1.62 1.361 2.17 2.269c0.56 0.907 0.83 2.033 0.83 3.377 0 1.311-0.27 2.47-0.8 3.478-0.54 1.008-1.3 1.857-2.27 2.546-0.98 0.689-2.14 1.218-3.48 1.588-1.34 0.369-2.82 0.554-4.44 0.554-2.99 0-5.38-0.596-7.18-1.789s-2.7-2.832-2.7-4.915c0-1.076 0.25-1.924 0.74-2.546 0.48-0.622 1.18-0.932 2.09-0.932 0.8 0 1.48 0.235 2.04 0.705 0.55 0.471 0.83 1.093 0.83 1.865 0 0.639-0.18 1.244-0.53 1.815s-0.78 0.992-1.29 1.26c0.44 0.672 1.22 1.21 2.35 1.613 1.12 0.404 2.41 0.605 3.86 0.605z" />
              <path d="m1402.2 93.133c0 2.218-0.33 4.301-0.98 6.25-0.66 1.949-1.57 3.563-2.75 4.84-1.98 2.184-4.91 3.276-8.77 3.276-3.77 0-6.64-1.058-8.62-3.176-1.14-1.243-2.03-2.78-2.65-4.612-0.62-1.831-0.93-3.823-0.93-5.973 0-2.218 0.32-4.319 0.96-6.301 0.64-1.983 1.56-3.613 2.77-4.89 1.08-1.143 2.32-1.974 3.73-2.495s3.06-0.782 4.94-0.782c1.85 0 3.51 0.261 4.99 0.782s2.72 1.319 3.73 2.394c1.18 1.244 2.07 2.798 2.67 4.663 0.61 1.865 0.91 3.873 0.91 6.024zm-19.31 0.05c0 4.033 0.61 7.041 1.82 9.023 0.57 0.874 1.25 1.538 2.04 1.991 0.79 0.454 1.82 0.681 3.1 0.681 2.32 0 4.05-0.79 5.19-2.369 0.61-0.874 1.05-2.084 1.34-3.63 0.28-1.546 0.43-3.327 0.43-5.343 0-3.999-0.61-6.99-1.82-8.973-0.57-0.873-1.26-1.537-2.07-1.991-0.8-0.453-1.84-0.68-3.12-0.68-2.35 0-4.05 0.773-5.09 2.318-1.21 1.882-1.82 4.873-1.82 8.973z" />
              <path d="m1419.5 114v2.066h-13.66v-2.066l3.88-0.958v-30.245l-3.88-0.958v-2.066h7.26l0.75 3.881c1.08-1.68 2.3-2.831 3.66-3.453s2.9-0.933 4.61-0.933c1.72 0 3.27 0.253 4.66 0.757 1.4 0.504 2.53 1.31 3.41 2.419 1 1.277 1.8 2.806 2.39 4.587s0.88 3.731 0.88 5.848c0 2.15-0.32 4.175-0.98 6.074s-1.54 3.486-2.65 4.763c-1.88 2.185-4.58 3.277-8.11 3.277-1.45 0-2.75-0.269-3.91-0.807-1.16-0.537-2.22-1.512-3.2-2.923v9.779l4.89 0.958zm-4.94-21.071c0 3.999 0.59 6.923 1.76 8.771 0.54 0.874 1.2 1.538 1.97 1.991 0.77 0.454 1.8 0.681 3.08 0.681 2.28 0 3.98-0.79 5.09-2.369 0.6-0.841 1.04-2.008 1.31-3.504 0.27-1.495 0.4-3.234 0.4-5.217 0-3.932-0.59-6.839-1.76-8.721-0.58-0.873-1.25-1.537-2.02-1.991-0.77-0.453-1.8-0.68-3.08-0.68-1.17 0-2.18 0.193-3.02 0.579-0.84 0.387-1.5 0.967-1.97 1.739-0.57 0.908-1 2.084-1.31 3.529-0.3 1.445-0.45 3.176-0.45 5.192z" />
              <path d="m1458.3 92.729c0-2.15-0.05-3.772-0.17-4.864s-0.33-1.941-0.63-2.546c-0.84-1.646-2.32-2.47-4.44-2.47-0.94 0-1.85 0.169-2.72 0.505s-1.65 0.789-2.32 1.361c-0.74 0.672-1.28 1.445-1.64 2.318-0.35 0.874-0.56 1.966-0.63 3.277v13.661l3.38 0.957v2.067h-12.15v-2.067l3.88-0.957v-33.27l-3.88-0.958v-2.066h8.77v16.836c1.01-1.613 2.35-2.89 4.01-3.831s3.45-1.412 5.37-1.412c1.34 0 2.55 0.236 3.63 0.706 1.07 0.471 1.93 1.143 2.57 2.017 0.67 0.94 1.15 2.26 1.44 3.957 0.28 1.697 0.42 3.99 0.42 6.88v11.141l3.89 0.957v2.067h-12.15v-2.067l3.37-0.957v-11.242z" />
              <path d="m1484.6 105.78c-0.71 1.949-1.36 3.613-1.94 4.991-0.59 1.377-1.21 2.495-1.84 3.352-0.64 0.857-1.36 1.478-2.15 1.865-0.79 0.386-1.74 0.58-2.84 0.58-0.81 0-1.56-0.11-2.25-0.328-0.69-0.219-1.29-0.529-1.81-0.933-0.52-0.403-0.93-0.873-1.24-1.411-0.3-0.538-0.45-1.126-0.45-1.764 0-0.941 0.27-1.723 0.81-2.344 0.53-0.622 1.24-0.933 2.11-0.933 0.78 0 1.39 0.21 1.84 0.63 0.46 0.42 0.68 1 0.68 1.739 0 0.471-0.09 0.924-0.27 1.361-0.19 0.437-0.43 0.824-0.74 1.16 0.07 0.369 0.27 0.605 0.59 0.705 0.31 0.101 0.61 0.152 0.88 0.152 0.33 0 0.66-0.051 0.98-0.152 0.32-0.1 0.64-0.344 0.96-0.73 0.32-0.387 0.65-0.933 1.01-1.639 0.35-0.705 0.73-1.663 1.13-2.873l0.61-1.865-10.44-24.549-3.02-0.958v-2.066h11.29l0.4 2.066-3.58 0.958 7.51 19.76h0.2l6.36-19.76-2.93-0.958v-2.066h9.58v2.066l-3.08 0.958-8.36 22.986z" />
              <rect x="1511.3" y="91.52" width="24.902" height="2.924" />
              <path d="m1557.8 82.799l-3.88-0.958v-2.066h7.26l1.11 5.04h0.2c0.97-1.713 2.28-3.066 3.9-4.057 1.63-0.992 3.37-1.488 5.22-1.488 1.41 0 2.65 0.261 3.71 0.782s1.84 1.168 2.34 1.941c0.54 0.84 0.98 1.949 1.31 3.326 1.08-1.915 2.47-3.402 4.18-4.461 1.72-1.058 3.55-1.588 5.5-1.588 1.34 0 2.55 0.261 3.63 0.782 1.07 0.521 1.9 1.235 2.47 2.142 0.3 0.471 0.55 1.042 0.76 1.714 0.2 0.672 0.37 1.504 0.5 2.495 0.13 0.992 0.23 2.159 0.28 3.504 0.05 1.344 0.07 2.923 0.07 4.738v9.326l3.88 0.957v2.067h-12.14v-2.067l3.37-0.957v-11.242c0-2.15-0.06-3.772-0.17-4.864-0.12-1.092-0.33-1.941-0.63-2.546-0.84-1.646-2.26-2.47-4.24-2.47-0.87 0-1.7 0.169-2.49 0.505s-1.51 0.789-2.15 1.361c-0.73 0.672-1.28 1.453-1.63 2.344-0.36 0.89-0.57 2.041-0.63 3.453v13.459l3.37 0.957v2.067h-11.64v-2.067l3.38-0.957v-11.242c0-2.15-0.06-3.772-0.18-4.864s-0.33-1.941-0.63-2.546c-0.84-1.646-2.25-2.47-4.23-2.47-0.88 0-1.71 0.169-2.5 0.505s-1.5 0.789-2.14 1.361c-0.74 0.672-1.29 1.453-1.64 2.344-0.35 0.89-0.56 2.041-0.63 3.453v13.459l3.38 0.957v2.067h-12.15v-2.067l3.88-0.957v-21.172z" />
              <path d="m1603.2 101.1c0-1.31 0.29-2.47 0.88-3.478s1.52-1.907 2.8-2.697c1.27-0.789 2.92-1.47 4.94-2.041 2.01-0.572 4.43-1.076 7.26-1.513-0.17-1.982-0.37-3.528-0.58-4.637-0.22-1.109-0.52-2-0.89-2.672-0.47-0.873-1.12-1.52-1.96-1.94-0.84-0.421-1.75-0.631-2.72-0.631-0.95 0-1.79 0.168-2.55 0.505-0.76 0.336-1.27 0.789-1.54 1.361 0.47 0.369 0.82 0.814 1.04 1.335s0.32 1.017 0.32 1.487c0 0.841-0.27 1.504-0.83 1.992-0.55 0.487-1.23 0.73-2.04 0.73s-1.47-0.268-1.99-0.806-0.78-1.294-0.78-2.268c0-1.042 0.28-1.975 0.86-2.798 0.57-0.823 1.31-1.512 2.24-2.067 0.92-0.554 1.96-0.974 3.12-1.26s2.31-0.429 3.46-0.429c2.85 0 5.11 0.824 6.75 2.47 2.09 2.084 3.13 5.781 3.13 11.09v7.309c0 1.681 0.11 2.832 0.32 3.453 0.22 0.622 0.7 0.933 1.44 0.933h2.27v1.512c-2.42 0.975-4.3 1.462-5.65 1.462-0.5 0-0.92-0.084-1.26-0.252-0.33-0.168-0.6-0.462-0.8-0.882-0.21-0.42-0.35-0.983-0.43-1.689-0.09-0.705-0.13-1.596-0.13-2.671h-0.2c-1.04 1.713-2.42 3.058-4.13 4.032-1.72 0.975-3.57 1.462-5.55 1.462-2.11 0-3.78-0.563-4.99-1.689-1.21-1.125-1.81-2.696-1.81-4.713zm8.92 3.478c0.94 0 1.92-0.243 2.95-0.73 1.02-0.488 1.89-1.168 2.59-2.042 1.05-1.277 1.57-3.159 1.57-5.646 0-0.571-0.02-1.134-0.05-1.688-0.04-0.555-0.09-1.084-0.15-1.588-2.05 0.403-3.76 0.823-5.12 1.26s-2.45 0.932-3.28 1.487c-0.82 0.554-1.4 1.193-1.74 1.915-0.33 0.723-0.5 1.555-0.5 2.496 0 1.445 0.33 2.562 0.98 3.352 0.66 0.79 1.57 1.184 2.75 1.184z" />
              <path d="m1634 82.799l-3.88-0.958v-2.066h7.25l1.11 5.04h0.2c1.01-1.713 2.37-3.066 4.06-4.057 1.7-0.992 3.54-1.488 5.52-1.488 1.35 0 2.56 0.236 3.63 0.706 1.08 0.471 1.93 1.143 2.57 2.017 0.67 0.94 1.15 2.26 1.44 3.957 0.28 1.697 0.43 3.99 0.43 6.88v11.141l3.88 0.957v2.067h-12.15v-2.067l3.38-0.957v-11.242c0-2.15-0.06-3.772-0.18-4.864s-0.33-1.941-0.63-2.546c-0.84-1.646-2.32-2.47-4.43-2.47-0.94 0-1.85 0.169-2.73 0.505-0.87 0.336-1.64 0.789-2.32 1.361-0.73 0.672-1.28 1.453-1.63 2.344-0.36 0.89-0.57 2.041-0.63 3.453v13.459l3.37 0.957v2.067h-12.14v-2.067l3.88-0.957v-21.172z" />
              <path d="m1663.2 101.1c0-1.31 0.3-2.47 0.89-3.478 0.58-1.008 1.52-1.907 2.79-2.697 1.28-0.789 2.93-1.47 4.94-2.041 2.02-0.572 4.44-1.076 7.26-1.513-0.17-1.982-0.36-3.528-0.58-4.637s-0.51-2-0.88-2.672c-0.47-0.873-1.12-1.52-1.97-1.94-0.83-0.421-1.74-0.631-2.72-0.631-0.94 0-1.79 0.168-2.54 0.505-0.76 0.336-1.27 0.789-1.54 1.361 0.47 0.369 0.81 0.814 1.03 1.335s0.33 1.017 0.33 1.487c0 0.841-0.28 1.504-0.83 1.992-0.56 0.487-1.24 0.73-2.04 0.73-0.81 0-1.47-0.268-1.99-0.806s-0.78-1.294-0.78-2.268c0-1.042 0.28-1.975 0.85-2.798s1.32-1.512 2.24-2.067c0.93-0.554 1.97-0.974 3.13-1.26s2.31-0.429 3.45-0.429c2.86 0 5.11 0.824 6.76 2.47 2.08 2.084 3.12 5.781 3.12 11.09v7.309c0 1.681 0.11 2.832 0.33 3.453 0.22 0.622 0.7 0.933 1.44 0.933h2.27v1.512c-2.42 0.975-4.31 1.462-5.65 1.462-0.5 0-0.92-0.084-1.26-0.252s-0.61-0.462-0.81-0.882-0.34-0.983-0.42-1.689c-0.09-0.705-0.13-1.596-0.13-2.671h-0.2c-1.04 1.713-2.42 3.058-4.14 4.032-1.71 0.975-3.56 1.462-5.54 1.462-2.12 0-3.78-0.563-4.99-1.689-1.21-1.125-1.82-2.696-1.82-4.713zm8.93 3.478c0.94 0 1.92-0.243 2.95-0.73 1.02-0.488 1.89-1.168 2.59-2.042 1.04-1.277 1.56-3.159 1.56-5.646 0-0.571-0.01-1.134-0.05-1.688-0.03-0.555-0.08-1.084-0.15-1.588-2.05 0.403-3.75 0.823-5.11 1.26s-2.46 0.932-3.28 1.487c-0.82 0.554-1.4 1.193-1.74 1.915-0.34 0.723-0.5 1.555-0.5 2.496 0 1.445 0.32 2.562 0.98 3.352 0.65 0.79 1.57 1.184 2.75 1.184z" />
              <path d="m1714 107.5c0 1.277-0.27 2.453-0.83 3.529-0.55 1.075-1.36 1.999-2.42 2.772s-2.35 1.378-3.88 1.815-3.25 0.655-5.17 0.655c-3.86 0-6.78-0.546-8.77-1.638-1.98-1.092-2.97-2.647-2.97-4.663 0-1.579 0.47-2.789 1.41-3.629s2.24-1.395 3.88-1.664v-0.201c-2.21-0.639-3.32-1.95-3.32-3.932 0-1.042 0.37-2.008 1.13-2.899 0.76-0.89 1.94-1.621 3.55-2.192-1.68-0.605-2.96-1.546-3.85-2.823s-1.34-2.84-1.34-4.688c0-1.345 0.25-2.546 0.73-3.604 0.49-1.059 1.18-1.966 2.07-2.723 0.89-0.756 1.96-1.335 3.22-1.739 1.27-0.403 2.69-0.605 4.26-0.605 3.13 0 5.6 0.773 7.42 2.319l1.71-0.605 4.64-2.218h0.85v4.436h-0.85l-3.53-0.403h-1.72c0.98 1.344 1.47 2.957 1.47 4.839 0 2.655-0.91 4.747-2.73 6.276-1.81 1.529-4.35 2.294-7.61 2.294-0.37 0-0.71-9e-3 -1.03-0.026-0.32-0.016-0.65-0.042-0.98-0.075-2.66 0.403-3.99 1.394-3.99 2.974 0 0.773 0.31 1.302 0.91 1.588 0.61 0.285 1.78 0.428 3.53 0.428h5.85c2.75 0 4.84 0.513 6.25 1.538s2.11 2.646 2.11 4.864zm-13.86-2.52c-2.25 0-3.88 0.386-4.89 1.159s-1.51 1.781-1.51 3.025c0 3.058 2.62 4.587 7.86 4.587 2.86 0 5.04-0.429 6.53-1.286 1.5-0.857 2.25-2.142 2.25-3.856 0-1.344-0.52-2.285-1.54-2.823-1.03-0.537-2.83-0.806-5.42-0.806h-3.28zm-4.28-17.24c0 2.016 0.45 3.545 1.36 4.587s2.35 1.563 4.33 1.563c1.99 0 3.43-0.521 4.34-1.563s1.36-2.571 1.36-4.587-0.45-3.545-1.36-4.587-2.35-1.563-4.34-1.563c-1.98 0-3.42 0.521-4.33 1.563s-1.36 2.571-1.36 4.587z" />
              <path d="m1724.1 93.687c0 2.453 0.33 4.487 1.01 6.1 0.67 1.579 1.62 2.696 2.84 3.352 1.23 0.655 2.58 0.983 4.06 0.983 1.61 0 3.07-0.328 4.36-0.983 1.29-0.656 2.46-1.554 3.5-2.697l1.62 1.21c-0.61 0.806-1.25 1.562-1.94 2.268s-1.47 1.328-2.35 1.865c-0.87 0.538-1.85 0.958-2.92 1.26-1.08 0.303-2.27 0.454-3.58 0.454-2.32 0-4.37-0.496-6.15-1.487s-3.14-2.445-4.08-4.36c-0.51-1.042-0.88-2.193-1.11-3.453-0.24-1.26-0.35-2.63-0.35-4.108 0-4.739 1.07-8.393 3.22-10.964s5.11-3.857 8.87-3.857c3.47 0 6.12 1.067 7.97 3.201s2.77 5.083 2.77 8.847c0 0.336-0.01 0.647-0.02 0.933-0.02 0.285-0.06 0.764-0.13 1.436h-17.59zm6.7-11.694c-4.03 0-6.25 3.074-6.65 9.224h12.6v-1.663c0-2.42-0.51-4.285-1.54-5.596-1.02-1.31-2.49-1.965-4.41-1.965z" />
              <path d="m1749.7 82.799l-3.88-0.958v-2.066h7.26l1.11 5.04h0.2c0.98-1.713 2.28-3.066 3.91-4.057 1.63-0.992 3.37-1.488 5.22-1.488 1.41 0 2.64 0.261 3.7 0.782s1.84 1.168 2.35 1.941c0.53 0.84 0.97 1.949 1.31 3.326 1.07-1.915 2.47-3.402 4.18-4.461 1.71-1.058 3.54-1.588 5.49-1.588 1.35 0 2.56 0.261 3.63 0.782 1.08 0.521 1.9 1.235 2.47 2.142 0.31 0.471 0.56 1.042 0.76 1.714s0.37 1.504 0.5 2.495c0.14 0.992 0.23 2.159 0.28 3.504 0.05 1.344 0.08 2.923 0.08 4.738v9.326l3.88 0.957v2.067h-12.15v-2.067l3.38-0.957v-11.242c0-2.15-0.06-3.772-0.18-4.864s-0.33-1.941-0.63-2.546c-0.84-1.646-2.25-2.47-4.23-2.47-0.88 0-1.71 0.169-2.5 0.505s-1.5 0.789-2.14 1.361c-0.74 0.672-1.29 1.453-1.64 2.344-0.35 0.89-0.56 2.041-0.63 3.453v13.459l3.38 0.957v2.067h-11.65v-2.067l3.38-0.957v-11.242c0-2.15-0.06-3.772-0.18-4.864-0.11-1.092-0.32-1.941-0.63-2.546-0.84-1.646-2.25-2.47-4.23-2.47-0.87 0-1.71 0.169-2.49 0.505-0.79 0.336-1.51 0.789-2.15 1.361-0.74 0.672-1.28 1.453-1.64 2.344-0.35 0.89-0.56 2.041-0.63 3.453v13.459l3.38 0.957v2.067h-12.15v-2.067l3.88-0.957v-21.172z" />
              <path d="m1800.7 93.687c0 2.453 0.33 4.487 1 6.1 0.68 1.579 1.63 2.696 2.85 3.352 1.23 0.655 2.58 0.983 4.06 0.983 1.61 0 3.07-0.328 4.36-0.983 1.3-0.656 2.46-1.554 3.5-2.697l1.62 1.21c-0.61 0.806-1.25 1.562-1.94 2.268s-1.47 1.328-2.35 1.865c-0.87 0.538-1.85 0.958-2.92 1.26-1.08 0.303-2.27 0.454-3.58 0.454-2.32 0-4.37-0.496-6.15-1.487s-3.14-2.445-4.08-4.36c-0.51-1.042-0.88-2.193-1.11-3.453-0.24-1.26-0.35-2.63-0.35-4.108 0-4.739 1.07-8.393 3.22-10.964s5.11-3.857 8.87-3.857c3.47 0 6.12 1.067 7.97 3.201s2.77 5.083 2.77 8.847c0 0.336-0.01 0.647-0.02 0.933-0.02 0.285-0.06 0.764-0.13 1.436h-17.59zm6.7-11.694c-4.03 0-6.25 3.074-6.65 9.224h12.6v-1.663c0-2.42-0.51-4.285-1.54-5.596-1.02-1.31-2.49-1.965-4.41-1.965z" />
              <path d="m1826.3 82.799l-3.89-0.958v-2.066h7.26l1.11 5.04h0.2c1.01-1.713 2.36-3.066 4.06-4.057 1.7-0.992 3.54-1.488 5.52-1.488 1.34 0 2.55 0.236 3.63 0.706 1.08 0.471 1.93 1.143 2.57 2.017 0.67 0.94 1.15 2.26 1.44 3.957 0.28 1.697 0.42 3.99 0.42 6.88v11.141l3.89 0.957v2.067h-12.15v-2.067l3.38-0.957v-11.242c0-2.15-0.06-3.772-0.18-4.864s-0.33-1.941-0.63-2.546c-0.84-1.646-2.32-2.47-4.44-2.47-0.94 0-1.85 0.169-2.72 0.505s-1.65 0.789-2.32 1.361c-0.74 0.672-1.28 1.453-1.64 2.344-0.35 0.89-0.56 2.041-0.63 3.453v13.459l3.38 0.957v2.067h-12.15v-2.067l3.89-0.957v-21.172z" />
              <path d="m1854.1 80.48l4.89-0.705 0.65-6.352 4.24-0.605v6.957h6.45v2.52h-6.45v17.996c0 1.142 0.01 1.924 0.05 2.344 0.03 0.42 0.1 0.764 0.2 1.033s0.29 0.437 0.55 0.504c0.27 0.067 0.71 0.101 1.32 0.101h3.52v1.462c-1.61 0.605-3.03 1.05-4.25 1.336-1.23 0.285-2.21 0.428-2.95 0.428-1.52 0-2.49-0.622-2.93-1.865-0.17-0.403-0.27-0.916-0.32-1.537-0.06-0.622-0.08-1.572-0.08-2.849v-18.953h-4.89v-1.815z" />
            </g>
          </g>
        </g>
      </svg>
    ) : (
      <svg
        class={props.class}
        clip-rule="evenodd"
        fill-rule="evenodd"
        stroke-line-join="round"
        stroke-miterlimit="1.4142"
        version="1.1"
        viewBox="0 0 71 114"
      >
        <title>International Lectures</title>
        <g transform="translate(-1167 -11)">
          <g transform="translate(-265 -11.576)">
            <g transform="matrix(2.9381 0 0 2.9381 -3312.2 -182.31)">
              <path
                d="m1624.1 107.24l-0.05 0.336c-0.19 0.192-0.54 0.368-1.03 0.528-0.5 0.16-0.89 0.24-1.16 0.24s-0.4-0.064-0.4-0.192c0.76-4.608 1.15-9.976 1.15-16.104s-0.35-11.688-1.06-16.68l1.25-0.864 4.27 2.208c-0.54 11.808-1.38 21.152-2.49 28.032h1.29c4.8 0 8.77-0.816 11.91-2.448l0.81 1.248-1.58 4.128c-1.7 0.16-3.64 0.24-5.81 0.24-2.18 0-4.55-0.224-7.1-0.672z"
                fill="#A82B1C"
                fill-rule="nonzero"
              />
            </g>
          </g>
          <g transform="translate(-265 -11.576)">
            <g transform="matrix(-2.5532 .090531 -.090531 -2.5532 5593.1 152.93)">
              <path
                d="m1624.5 107.81c-0.19 0.192-0.54 0.368-1.03 0.528-0.5 0.16-0.88 0.24-1.16 0.24-0.27 0-0.41-0.064-0.41-0.192 0.61-4.128 0.92-8.352 0.92-12.672 0-7.68-0.47-14.464-1.39-20.352l1.24-0.864 4.32 2.208c-0.16 5.152-0.46 10.872-0.91 17.16s-0.98 10.936-1.58 13.944z"
                fill="#A82B1C"
                fill-rule="nonzero"
              />
            </g>
          </g>
        </g>
      </svg>
    );
}
