import { live, setState } from '@donkeyjs/jsx-runtime';
import type { NodeTypename } from '@donkeyjs/proxy';
import { setDataContext } from '../data/DataContext';
import { session } from '../session';
import { enableMailMode } from '../helpers/enableMailMode';
import { setMailContext } from './mailContext';

export function MessageRoute() {
  enableMailMode();
  setMailContext({ target: 'mail', theme: session.app.theme });

  const query = session.router.getSchemaQuery({
    t: 'string?',
    c: 'string?',
  });

  const state = setState({
    get template() {
      const id = query.t;
      return id ? session.data.getNode('MessageTemplate', id) : undefined;
    },

    get context() {
      const context = query.c;
      const [typename, id] = context?.split(':') ?? [];
      return typename && id
        ? session.data.getNode(typename as NodeTypename<DataSchema>, id)
        : undefined;
    },
  });

  live(() => {
    if (state.context) {
      setDataContext(state.context);
    }
  });

  return () => (state.template ? <state.template.$.body readonly /> : null);
}
