import {
  Each,
  Flag,
  GoogleMap,
  getI18n,
  getTheme,
  session,
  text,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { useAddToCart, useOrderableInfo } from '@donkeyjs/module-shopping';
import {
  PhArrowCircleRight,
  PhBookmarks,
  PhCalendar,
  PhCheckCircle,
  PhClock,
  PhLightbulb,
  PhUsers,
  PhXCircle,
} from '@donkeyjs/phosphor-icons';
import { type DataNode, meta, store } from '@donkeyjs/proxy';
import { I18nIL } from '../../../i18n';
import { CultureFromLanguage } from '../../helpers/CultureFromLanguage';
import { formatTimesOfDay } from '../../helpers/formatTimesOfDay';
import styles from './CourseSidebar.module.css';
import { IconListBullet, IconListItem } from './IconList';
import { NotifyButton } from './NotifyButton';
import { ShopOption } from './ShopOption';

interface CourseSidebarProps {
  offering: DataNode<DataSchema, 'CourseOffering'>;
  canRegister: boolean;
  isSelected: boolean;
  venues: DataNode<DataSchema, 'Place'>[];
}

export function CourseSidebar(props: CourseSidebarProps) {
  const i18n = getI18n();
  const theme = getTheme();
  const orderable = useOrderableInfo(props.offering.options);

  const orderViaLink =
    !!props.offering.course.linkToUrl &&
    session.router.query.next?.[0] !== 'true';

  meta(props.offering).request({
    options: {
      id: true,
      sortIndex: true,
      name: true,
      price: true,
    },
    language: true,
  });

  const state = store({
    get firstOption() {
      const result = props.offering.options[0];
      return result;
    },

    get isCompact() {
      return props.offering.isOnline || !!props.offering.course.partner;
    },
  });

  const cart =
    !orderViaLink && props.offering.options[0]
      ? useAddToCart({
          kind: 'course',
          item: props.offering.options[0],
        })
      : undefined;

  return (
    <div class={styles.wrapper}>
      <div class={styles.buttons}>
        {() => {
          if (props.offering.status !== 'OPEN') return null;
          if (orderViaLink)
            return (
              <a
                class={[theme.class.button, 'default']}
                href={props.offering.course.linkToUrl!}
                target="_blank"
                rel="noreferrer"
              >
                <span>{text(I18nIL, 'RegistrationForms.Register')}</span>
                <PhCheckCircle weight="fill" />
              </a>
            );
          if (!cart) return null;
          return cart.inCart ? (
            <a
              class={[theme.class.button, 'default']}
              href={session.router.getPath({ routeKey: 'cart' })}
            >
              <span>{() => text(I18nIL, 'Course.GoToCart')}</span>
              <PhArrowCircleRight weight="fill" />
            </a>
          ) : (
            <button
              class={[theme.class.button, 'default']}
              onclick={cart.onClick}
              type="button"
            >
              <span>{() => cart.label}</span>
              {() => cart.icon}
            </button>
          );
        }}
        <NotifyButton course={props.offering} />
        <a
          class={[theme.class.button, 'default', styles.moreInfo]}
          href={bind(() =>
            props.isSelected
              ? session.router.getPath({ route: 'current' })
              : session.router.getPath({ node: props.offering }),
          )}
        >
          <span>
            {() =>
              props.isSelected ? text('Common.Close') : text('Common.MoreInfo')
            }
          </span>
          {() =>
            props.isSelected ? (
              <PhXCircle weight="fill" />
            ) : (
              <PhArrowCircleRight weight="fill" />
            )
          }
        </a>

        {() =>
          !props.offering.isOnline && (
            <>
              <IconListItem
                heading={() => i18n.getFieldName('CourseOffering', 'starts')}
              >
                <IconListBullet icon={<PhClock weight="fill" />}>
                  <props.offering.$.starts />
                </IconListBullet>
              </IconListItem>
              <IconListItem
                heading={() => i18n.getFieldName('CourseOffering', 'ends')}
              >
                <IconListBullet icon={<PhClock weight="fill" />}>
                  <props.offering.$.ends />
                </IconListBullet>
              </IconListItem>
            </>
          )
        }

        {() =>
          state.isCompact && !props.isSelected ? null : (
            <>
              {() =>
                props.offering.status === 'OPEN' &&
                (!!state.firstOption ||
                  (props.isSelected && !!props.offering.options.length)) && (
                  <IconListItem
                    heading={() => i18n.getFieldName('OrderItem', 'price')}
                  >
                    {() => (
                      <>
                        <Each
                          of={orderable.options}
                          render={(option) => (
                            <IconListBullet
                              icon={
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  viewBox="0 0 256 256"
                                >
                                  <title>Euro</title>
                                  <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,80a8,8,0,0,1,0,16H88v16h32a8,8,0,0,1,0,16H89.89a44.25,44.25,0,0,0,72.6,18.74,8,8,0,1,1,11,11.6A60.22,60.22,0,0,1,73.36,152H64a8,8,0,0,1,0-16h8V120H64a8,8,0,0,1,0-16h9.12A60.21,60.21,0,0,1,173.51,72.52a8,8,0,1,1-11,11.6A44.24,44.24,0,0,0,89.56,104Z" />
                                </svg>
                              }
                            >
                              <ShopOption option={option} />
                            </IconListBullet>
                          )}
                        />
                        <IconListBullet>
                          <props.offering.$.priceInfo
                            class={styles.priceInfo}
                          />
                        </IconListBullet>
                      </>
                    )}
                  </IconListItem>
                )
              }
              {() =>
                props.offering.language ? (
                  <IconListItem
                    heading={() =>
                      i18n.getFieldName('CourseOffering', 'language')
                    }
                  >
                    <IconListBullet
                      icon={
                        <Flag
                          culture={CultureFromLanguage[props.offering.language]}
                        />
                      }
                    >
                      {i18n.getEnumValue('Language', props.offering.language)}
                    </IconListBullet>
                  </IconListItem>
                ) : null
              }
            </>
          )
        }

        {() =>
          props.isSelected ? (
            <>
              {() =>
                !!props.offering.days &&
                props.offering.days.length > 0 && (
                  <IconListItem
                    heading={() => i18n.getFieldName('CourseOffering', 'days')}
                  >
                    <Each
                      of={props.offering.days}
                      render={(day) => (
                        <IconListBullet icon={<PhCalendar weight="fill" />}>
                          <div>
                            {() =>
                              formatTimesOfDay(day, {
                                formatDate: (...args) =>
                                  i18n.formatDate(...args),
                                dateTimeSeperator: ' | ',
                              })
                            }
                          </div>
                        </IconListBullet>
                      )}
                    />
                  </IconListItem>
                )
              }
              {() =>
                !!props.offering.targetAudience?.length && (
                  <IconListItem
                    heading={() =>
                      i18n.getFieldName('CourseOffering', 'targetAudience')
                    }
                  >
                    <IconListBullet icon={<PhUsers weight="fill" />}>
                      <props.offering.$.targetAudience />
                    </IconListBullet>
                  </IconListItem>
                )
              }
              {() =>
                !!props.offering.accreditation?.length && (
                  <IconListItem
                    heading={() =>
                      i18n.getFieldName('CourseOffering', 'accreditation')
                    }
                  >
                    <IconListBullet icon={<PhBookmarks weight="fill" />}>
                      <props.offering.$.accreditation />
                    </IconListBullet>
                  </IconListItem>
                )
              }
              {() =>
                !!props.offering.priorKnowledge?.length && (
                  <IconListItem
                    heading={() =>
                      i18n.getFieldName('CourseOffering', 'priorKnowledge')
                    }
                  >
                    <IconListBullet icon={<PhLightbulb weight="fill" />}>
                      <props.offering.$.priorKnowledge />
                    </IconListBullet>
                  </IconListItem>
                )
              }
            </>
          ) : null
        }
        {() =>
          props.isSelected &&
          props.venues.map((venue) => (
            <GoogleMap googlePlacesId={venue.googlePlacesId} />
          ))
        }
      </div>
    </div>
  );
}
