import { createTheme } from '@donkeyjs/client';
import cart from './theme.cart.module.css';
import styles from './theme.module.css';

export const theme = () =>
  createTheme({
    colors: {
      accent: '#A82B1C',
      background: '#fff',
      color: '#000',
      error: '#A82B1C',
      'text-background': '#fff5d04d',
      'text-on-error': '#ffffff',
      'text-on-accent': '#ffffff',
      'viewport-background': '#ffffff',
    },

    font: "10pt/1.3 'Asap', sans-serif",

    webFonts: {
      google: {
        families: ['Asap:ital,wght@0,400;0,700;1,400;1,700', 'Domine'],
      },
    },

    email: {
      font: "10pt/1.3 'Asap', Helvetica, Arial, sans-serif",
      colors: {
        background: '#fffcf4',
      },
      styles: {},
    },

    styles,

    components: {
      shopping_cart: cart,
    },
  });
