import { watch } from '@donkeyjs/proxy';
import debounce from 'debounce';
import {
  onMount,
  type Component,
  type ComponentImplementation,
  type ComponentProps,
} from '../component';
import type { Dom, DomElement, DomNode, JSXVirtualNode } from '../dom';
import { createFragment } from '../dom-utils/createFragment';

export interface RenderContext {
  dom: Dom;
  component: ComponentImplementation<any>;
  props: any;
  state?: any;
  namespace?: string;
  parent: RenderContext | undefined;
  context: Record<string | symbol, any>;
  global: Record<string | symbol, any>;
  onMount: [fn: () => (() => void) | void, context: RenderContext][];
  onUnmount: (() => void)[];
  disposed?: boolean;
}

export const componentContext = {
  current: undefined as RenderContext | undefined,
};
if (typeof window !== 'undefined') {
  (window as any).componentContext = componentContext;
}

export const createRenderContext = (
  dom: Dom,
  global?: Record<string | symbol, any>,
): RenderContext => {
  return {
    component: undefined as any,
    dom,
    parent: undefined,
    props: undefined as any,
    context: {},
    global: global || {},
    onMount: [],
    onUnmount: [],
  };
};

export function mount<Props extends object>(
  dom: Dom,
  component: Component<Props>,
  props: ComponentProps<Props> | undefined,
  children: JSX.Element[],
  parent?: DomElement | HTMLElement,
  parentContext?: RenderContext,
): [
  dispose: () => void,
  firstDomNode: DomNode | undefined,
  fragment: JSXVirtualNode,
  context: RenderContext,
] {
  const previousComponentContext = componentContext.current;
  if (parentContext) componentContext.current = parentContext;
  else componentContext.current ??= createRenderContext(dom);

  const fragment = createFragment(
    [
      {
        __type: 'jsx-node',
        tag: component,
        props,
        children,
      },
    ],
    parent as DomElement,
  );

  const context = componentContext.current;

  const { dispose } = watch(() => {
    const trigger = fragment.nodes;
    return (trigger || !trigger) && undefined;
  });

  componentContext.current = previousComponentContext;

  let i = 0;
  let first = fragment.nodes[0];
  while (first?.nodeType === 8) {
    i++;
    first = fragment.nodes[i];
  }

  return [
    () => {
      dispose();
      fragment.dispose();
    },
    first,
    fragment,
    context,
  ];
}

// export const getElementIntrospection = (element: HTMLElement) =>
//   introspectElements.get(element);

export const debugMounts = { active: false };
export const logDomUpdates = { active: false };

let logCount = 0;
const resetLogCount = debounce(() => {
  logCount = 0;
}, 1000);
export const logDomUpdate = (...args: any[]) => {
  if (logDomUpdates.active) {
    ((console as any).logOriginal || console.log)(
      ...args,
      // ...args.map((arg) => (typeof arg === 'function' ? 'fn' : arg)),
    );
    logCount += 1;
    resetLogCount();

    if (logCount > 1000) {
      console.log('Too many updates, logging stopped');
      logDomUpdates.active = false;
    }
  }
};

if (typeof window !== 'undefined') {
  (window as any).toggleMountDebug = () => {
    debugMounts.active = !debugMounts.active;
  };
  (window as any).toggleDomUpdateLogging = () => {
    logDomUpdates.active = !logDomUpdates.active;
  };
}

export const HybridMountKey = Symbol('hybrid-mount');

export const onHybridMount = (fn: () => void | (() => void)) => {
  if (!componentContext.current) {
    console.error("Can't call onHybridMount outside of a mount tree.");
    return;
  }

  if (
    componentContext.current.dom.ssr ||
    componentContext.current.dom.ssrCursor
  )
    (componentContext.current.global[HybridMountKey] ??= []).push([
      fn,
      componentContext.current,
    ]);
  else onMount(fn);
};

export const getHybridMountList = (
  context: RenderContext,
  clear?: boolean,
): [() => void | (() => void), RenderContext][] => {
  const result = context.global[HybridMountKey] || [];
  if (clear) context.global[HybridMountKey] = [];
  return result;
};
