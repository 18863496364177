import { getContext, setContext } from '@donkeyjs/jsx-runtime';
import {
  type DataNode,
  type NodeTypename,
  type Schema,
  dontWatch,
} from '@donkeyjs/proxy';
import type { OutlineEntry } from '../admin';

const key = Symbol('dataContext');

export interface DataContext<
  S extends Schema = any,
  Typename extends NodeTypename<S> = any,
> {
  readonly node: DataNode<S, Typename>;
  readonly outline?: OutlineEntry<S, Typename>;
  readonly parent?: DataContext;
  findTypename<S extends Schema, Typename extends NodeTypename<S>>(
    typename: Typename,
  ): DataContext<S, Typename> | undefined;
}

export function useDataContext() {
  return getContext<DataContext | undefined>(key);
}

export function setDataContext<
  S extends Schema,
  Typename extends NodeTypename<S>,
>(node: DataNode<S, Typename>, outline?: OutlineEntry<S, Typename>) {
  const result: DataContext = setContext<DataContext>(key, {
    node: node as any,
    outline: outline as any,
    parent: useDataContext(),
    findTypename(typename) {
      return dontWatch(() => {
        let context: DataContext | undefined = result;
        while (context) {
          if (context.node.__typename === typename) return context as any;
          context = context.parent;
        }
        return undefined;
      });
    },
  });
  return result;
}
