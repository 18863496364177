import { bind, preventDefault } from '@donkeyjs/jsx-runtime';
import type { BlockProps } from '.';
import { getUserContext } from '../authentication';
import { LoginForm } from '../authentication/LoginForm';
import { getLoginForm } from '../authentication/getLoginForm';
import { text } from '../i18n/i18n';
import { setLayout } from '../layout/layoutContext';
import { getTheme } from '../styles';

export function LoginFormBlock(props: BlockProps) {
  setLayout('html');

  const user = getUserContext();

  const theme = getTheme();
  const form = getLoginForm();

  const submit = async () => {
    return await user.login({
      email: form.email,
      password: form.password,
    });
  };

  return () =>
    user.isLoggedIn ? (
      user.isSysAdmin ? (
        <div class={bind(() => props.class)} onmount={props.onmount}>
          Logged in as system admin ({() => user.user!.email}).
        </div>
      ) : null
    ) : (
      <form
        class={bind(() => [props.class, 'login-form'])}
        onmount={props.onmount}
      >
        <LoginForm form={form} />
        <div class={theme.class.buttons}>
          <button
            type="submit"
            class={[theme.class.button, 'default']}
            onclick={preventDefault(() => submit())}
          >
            {text('Account.ActionLogin')}
          </button>
        </div>
      </form>
    );
}
