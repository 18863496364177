import { jsxx, onMount, type ComponentProps } from '@donkeyjs/jsx-runtime';
import { batch, meta, store } from '@donkeyjs/proxy';
import type { CalendarProps, FieldRenderProps } from '../..';
import { getUserContext } from '../../../authentication';
import { getDonkeyTheme } from '../../../donkey/applyDonkeyTheme';
import { usePopper } from '../../../helpers';
import { getI18n } from '../../../i18n/getI18n';
import { withLayout } from '../../withLayout';
import { Calendar } from './Calendar';
import { DateInput, type DateInputProps } from './DateInput';
import styles from './useCalendarPopup.module.css';
import type { DateEditor } from './useDateEditor';

export interface CalendarPopupProps extends CalendarProps {
  input?: {
    field: FieldRenderProps<'date'>;
    state: DateEditor;
  };
  hasFocus?: boolean;
  onClose?: () => void;
}

export type CalendarPopup = ReturnType<typeof useCalendarPopup>;

export const useCalendarPopup = (props: CalendarPopupProps) => {
  const popper = usePopper(CalendarPopup);

  return {
    show: (target: HTMLElement) => {
      popper.show(target, props, { placement: 'bottom-start' });
    },
    hide: () => {
      popper.hide();
    },
    toggle: (target: HTMLElement) => {
      popper.toggle(target, props, { placement: 'bottom-start' });
    },
    get isVisible() {
      return popper.isVisible();
    },
  };
};

function CalendarPopup(props: CalendarPopupProps) {
  const i18n = getI18n();
  const user = getUserContext();

  const editor = props.input?.state;

  const state = store({
    focus: 'start' as 'start' | 'end',
    startEl: undefined as HTMLElement | undefined,
    get showEnds() {
      return !!editor?.isRangeStart && !editor?.rangeIsCollapsed;
    },
    endsWasAdded: false,
  });

  const calendarProps = store.clone(props, {
    get date() {
      return state.focus === 'start'
        ? props.date
        : editor?.rangeEnds ?? props.date;
    },
    set date(value) {
      if (state.focus === 'start') {
        props.date = value;
      } else {
        editor!.rangeEnds = value;
      }
    },
  });

  onMount(() => {
    const focusin = (e: FocusEvent) => {
      if (e.target instanceof HTMLElement) {
        if (e.target.closest('.calendar-popup, .popup') === null) {
          props.hasFocus = false;
        }
      }
    };

    document.addEventListener('focusin', focusin);

    return () => {
      document.removeEventListener('focusin', focusin);
    };
  });

  return (
    <div
      class={[
        styles.calendar,
        'calendar-popup',
        getDonkeyTheme(user).themeClass,
        { [styles.hasInput]: !!props.input },
      ]}
    >
      {props.input && (
        <div class={styles.input}>
          {jsxx(
            DateInput,
            store.clone<
              FieldRenderProps<'date'>,
              Pick<
                FieldRenderProps<'date'>,
                'autofocus' | 'formatter' | 'onmount' | 'onfocus' | 'onkeydown'
              >
            >(props.input.field, {
              autofocus: props.hasFocus,

              get formatter() {
                return editor?.fullDay ? 'P' : 'Pp';
              },

              get onmount() {
                return [
                  (el: HTMLElement) => {
                    state.startEl = el;
                  },
                  props.input?.field.onmount,
                ];
              },

              onfocus() {
                state.focus = 'start';
              },

              onkeydown(e: KeyboardEvent) {
                if (e.key === 'Escape' || e.key === 'Enter') {
                  e.preventDefault();
                  props.onClose?.();
                }
                if (e.key === 'Tab' && e.shiftKey) props.onClose?.();
              },
            }),
          )}
          {() =>
            !!(editor?.isRangeStart && !editor.rangeIsCollapsed) &&
            jsxx(
              DateInput,
              store.clone<
                FieldRenderProps<'date'>,
                ComponentProps<DateInputProps>
              >(props.input!.field, {
                field: meta(editor.node!).getField(
                  editor.rangeToField as any,
                ) as any,
                label: i18n.getFieldName(
                  editor.node!.__typename as any,
                  editor.rangeToField!,
                ),

                get autofocus() {
                  return !!state.endsWasAdded;
                },

                get formatter() {
                  return editor.fullDay ? 'P' : 'Pp';
                },

                onfocus() {
                  state.focus = 'end';
                },

                onblur() {
                  state.focus = 'start';
                },

                onremove() {
                  state.startEl?.focus();
                  editor.removeEnds();
                },

                onkeydown(e: KeyboardEvent) {
                  if (e.key === 'Escape' || e.key === 'Enter') {
                    e.preventDefault();
                    props.onClose?.();
                  }
                },
              }),
            )
          }
        </div>
      )}
      {() =>
        !!(
          (editor?.isRangeStart && editor.rangeIsCollapsed) ||
          editor?.timeZoneField ||
          editor?.hasFullDayToggle
        ) && (
          <div class={styles.more}>
            <div class={styles.options} role="group">
              {() =>
                !!(editor.isRangeStart && editor.rangeIsCollapsed) && (
                  <button
                    type="button"
                    class={styles.ends}
                    onclick={() => {
                      batch(() => {
                        state.endsWasAdded = true;
                        editor.addEnds();
                      });
                    }}
                  >
                    Ends
                  </button>
                )
              }
              {() => {
                if (!editor.node || !editor.timeZoneField) return null;
                const Field = (editor.node.$ as any)[editor.timeZoneField];
                withLayout('inline', <Field />);
              }}
            </div>
            {() => {
              if (
                !editor.node ||
                !editor.fullDayField ||
                !editor.hasFullDayToggle
              )
                return null;
              const Field = (editor.node.$ as any)[editor.fullDayField];
              return <div role="group">{withLayout('html', <Field />)}</div>;
            }}
          </div>
        )
      }
      {Calendar(calendarProps)}
    </div>
  );
}
