// Most sharing logic inspired by avdeev/vanilla-sharing

import {
  PhEnvelope,
  PhFacebookLogo,
  PhPinterestLogo,
  type IconProps,
} from '@donkeyjs/phosphor-icons';
import { map } from '@donkeyjs/proxy';
import { getTheme } from '../styles';
import styles from './SharingButtons.module.css';

export interface SharingButton {
  key: string;
  icon: () => JSX.Element;
  action: (url: string, message: string) => void;
}

export function XLogo(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      <title>Twitter</title>
      {props.children}
      <path d="M 27.161 33 L 105.41 137.796 L 26.667 223 L 44.388 223 L 113.328 148.403 L 169.026 223 L 229.334 223 L 146.684 112.31 L 219.976 33 L 202.255 33 L 138.766 101.703 L 87.468 33 L 27.161 33 Z M 53.221 46.074 L 80.928 46.074 L 203.27 209.924 L 175.565 209.924 L 53.221 46.074 Z" />
    </svg>
  );
}

export const FacebookButton = (fbAppId: string): SharingButton => ({
  action: (url, message) => {
    location.href = `https://www.facebook.com/dialog/share?${query({
      app_id: fbAppId,
      display: 'page',
      href: url,
      quote: message,
      redirect_uri: location.href,
    })}`;
  },
  icon: () => <PhFacebookLogo weight="fill" />,
  key: 'facebook',
});

export const TwitterButton: SharingButton = {
  action: (url, message) => {
    openPopup(
      `https://twitter.com/intent/tweet?${query({
        text: message,
        url,
      })}`,
    );
  },
  icon: () => XLogo({ weight: 'fill' }),
  key: 'twitter',
};

export const PinterestButton = (media?: string): SharingButton => ({
  action: (url, message) => {
    openPopup(
      `https://pinterest.com/pin/create/button/?${query({
        description: message,
        media,
        url,
      })}`,
    );
  },
  icon: () => <PhPinterestLogo weight="fill" />,
  key: 'pinterest',
});

export const EmailButton: SharingButton = {
  action: (url, message) => {
    location.href = `mailto:?${query({ body: `${message}\n${url}` })}`;
  },
  icon: () => <PhEnvelope weight="fill" />,
  key: 'email',
};

interface SharingButtonsProps {
  url: string;
  message: string;
  options: SharingButton[];
}

export function SharingButtons(props: SharingButtonsProps) {
  const theme = getTheme();

  return map(
    () => props.options,
    (option) => (
      <button
        class={[styles.button, theme.class.linkButton]}
        type="button"
        onclick={() => option.action(props.url, props.message)}
      >
        {option.icon}
      </button>
    ),
  );
}

const query = (args: Record<string, string | undefined>) =>
  Object.entries(args)
    .map(([key, value]) =>
      value ? `${key}=${encodeURIComponent(value)}` : undefined,
    )
    .filter(Boolean)
    .join('&');

const openPopup = (url: string) =>
  window.open(
    url,
    '_blank',
    'scrollbars=0, resizable=1, menubar=0, left=100, top=100, width=550, height=440, toolbar=0, status=0',
  );
