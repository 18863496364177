import { getTheme, text } from '@donkeyjs/client';
import { bind, setState } from '@donkeyjs/jsx-runtime';
import { PhCaretRight } from '@donkeyjs/phosphor-icons';
import type { DataNode } from '@donkeyjs/proxy';
import { I18nIL } from '../../../i18n';
import styles from './NotifyButton.module.css';
import { NotifyButtonForm } from './NotifyButtonForm';

interface NotifyButtonProps {
  course: DataNode<DataSchema, 'CourseOffering'>;
}

export function NotifyButton(props: NotifyButtonProps) {
  const theme = getTheme();

  const state = setState({
    open: false,
  });

  return () =>
    props.course.status === 'EXPECTED' ? (
      <>
        <button
          type="button"
          class={bind(() => [
            styles.notifyButton,
            theme.class.button,
            'default',
            { open: state.open },
          ])}
          onclick={() => {
            state.open = !state.open;
          }}
        >
          <span>{text(I18nIL, 'Course.NotifyButton')}</span>
          <PhCaretRight weight="fill" />
        </button>
        {() => state.open && <NotifyButtonForm course={props.course} />}
      </>
    ) : null;
}
