import type { I18nContext } from '@donkeyjs/client';
import type { DataList } from '@donkeyjs/proxy';
import { differenceInDays, startOfDay } from 'date-fns';

export const formatCourseDates = (
  days: DataList<DataSchema, 'CourseDay'>,
  I18n: I18nContext,
) => {
  const dates = days
    .map((day) => day.starts)
    .sort((a, b) => a.getTime() - b.getTime());

  const groups: { from: Date; to: Date }[] = [];
  let currentGroup: { from: Date; to: Date } | undefined;
  for (const dateInList of dates) {
    const date = startOfDay(dateInList);
    if (!currentGroup) {
      currentGroup = { from: date, to: date };
      groups.push(currentGroup);
    } else {
      if (differenceInDays(date, currentGroup.to) > 1) {
        currentGroup = { from: date, to: date };
        groups.push(currentGroup);
      } else currentGroup.to = date;
    }
  }

  const { long, from, to, connector } = language[I18n.culture];

  return groups
    .map((group) => {
      if (group.to.getTime() === group.from.getTime())
        return I18n.formatDate(group.from, long);

      const sameMonth = group.from.getMonth() === group.to.getMonth();
      return `${I18n.formatDate(
        group.from,
        sameMonth ? from[1] : from[0],
      )} ${connector} ${I18n.formatDate(group.to, sameMonth ? to[1] : to[0])}`;
    })
    .join(' / ');
};

const language = {
  'en-GB': {
    long: 'LLLL d, yyyy',
    from: ['LLLL d', 'LLLL d'],
    to: ['LLLL d, yyyy', 'd, yyyy'],
    connector: 'to',
  },
  nl: {
    long: 'd LLLL yyyy',
    from: ['d LLLL', 'd'],
    to: ['d LLLL yyyy', ' LLLL yyyy'],
    connector: 't/m',
  },
};
