import { session, text } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { PhCaretRight } from '@donkeyjs/phosphor-icons';
import type { DataNode } from '@donkeyjs/proxy';
import styles from './ReadMore.module.css';

interface ReadMoreProps {
  isActive?: boolean;
  node: DataNode<DataSchema, 'Teacher'>;
}

export function ReadMore(props: ReadMoreProps) {
  return (
    <a
      href={bind(() =>
        props.isActive
          ? session.router.getPath({ route: 'current' })
          : session.router.getPath({ route: 'current', node: props.node }),
      )}
      class={bind(() => [styles.readmore, { [styles.active]: props.isActive }])}
    >
      <strong>
        {() =>
          props.isActive ? text('Common.Close') : text('Common.ReadMore')
        }
      </strong>
      <PhCaretRight weight="bold" />
    </a>
  );
}
