import type { ManyFragment } from '..';
import type { Fragment, NodeTypename, Schema } from '../schema';

export const printFragment = <
  S extends Schema,
  Typename extends NodeTypename<S>,
>(
  fragment: Fragment<S, Typename> | ManyFragment<S, Typename>,
  {
    returnsAggregation,
    forUnion,
    includeDrafts: includeDraftsInput,
    includeIds: includeIdsInput,
    includeTypenames: includeTypenamesInput,
    indent = 0,
    pretty,
  }: {
    includeIds?: boolean;
    includeDrafts?: boolean;
    includeTypenames?: boolean;
    forUnion?: string;
    returnsAggregation?: boolean;
    pretty?: boolean;
    indent?: number;
  } = {},
): string => {
  const includeDrafts = includeDraftsInput && !returnsAggregation;
  const includeIds = includeIdsInput && !returnsAggregation;
  const includeTypenames = includeTypenamesInput && !returnsAggregation;
  const spaces = pretty ? new Array(indent * 2).fill(' ').join('') : '';
  const result = `${includeIds ? `${spaces}id${pretty ? '\n' : ' '}` : ''}${
    includeDrafts ? `${spaces}draft${pretty ? '\n' : ' '}` : ''
  }${Object.entries(fragment)
    .map(([key, value]) =>
      (includeIds && key === 'id') || (includeDrafts && key === 'draft')
        ? null
        : value === true
          ? `${spaces}${withCultureArgs(key)}`
          : typeof value === 'object'
            ? `${spaces}${withGroupArgs(withCultureArgs(key))} {${
                pretty ? '\n' : ' '
              }${printFragment<S, NodeTypename<S>>(
                value as Fragment<S, NodeTypename<S>>,
                {
                  returnsAggregation:
                    !(returnsAggregation && key === 'nodes') &&
                    !(returnsAggregation && key === 'value') &&
                    (returnsAggregation || key === 'aggregate'),
                  includeDrafts,
                  includeIds: includeIdsInput,
                  includeTypenames:
                    includeTypenamesInput ||
                    (returnsAggregation && key === 'value'),
                  indent: indent + 1,
                  pretty,
                },
              )}${pretty ? `\n${spaces}` : ' '}}`
            : null,
    )
    .filter((v) => !!v)
    .join(pretty ? '\n' : ' ')}`;

  return `${
    includeTypenames ? `${spaces}__typename${pretty ? '\n' : ' '}` : ''
  }${forUnion ? `... on ${forUnion} {${result}}` : result}`;
};

const withCultureArgs = (key: string) => {
  const [name, culture] = key.split('__');
  return culture ? `${key}: ${name}(culture: ${culture})` : key;
};

const withGroupArgs = (key: string) => {
  const [name, group] = key.split('_group_');
  return group ? `${key}: ${name}(groupId: "${group}")` : key;
};

// const generateFieldArgs = ({
//   $orderBy: orderBy,
//   $where: where,
// }: {
//   $orderBy: any;
//   $where: (() => Record<string, any>) | undefined;
// }) =>
//   where == null && orderBy == null
//     ? ''
//     : `(${[
//         where == null ? undefined : `where: ${nestWhere(where())}`,
//         orderBy == null ? undefined : `orderBy: "${orderBy}"`,
//       ]
//         .filter((s) => !!s)
//         .join(', ')})`;
