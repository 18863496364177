import type { Views } from '@donkeyjs/client';
import { ViewCourseDashboard } from './ViewCourseDashboard';
import { ViewCourseDayPropertiesList } from './ViewCourseDayPropertiesList';
import { ViewCourseOffering } from './ViewCourseOffering';
import { ViewCourseOptionPropertiesList } from './ViewCourseOptionPropertiesList';
import { ViewOrderItem } from './ViewOrderItem';
import { ViewTeacher } from './ViewTeacher';
import { ViewTestimonal } from './ViewTestimonial';

export const views: Views = {
  CourseDay: { 'properties-list': ViewCourseDayPropertiesList },
  CourseOption: { 'properties-list': ViewCourseOptionPropertiesList },
  CourseOffering: {
    default: ViewCourseOffering,
    dashboard: ViewCourseDashboard,
  },
  OrderItem: { default: ViewOrderItem },
  Teacher: { default: ViewTeacher },
  Testimonial: { default: ViewTestimonal },
};
