import { NodeRoutingPlugin, createClientApp } from '@donkeyjs/client';
import {
  clientModuleMailings,
  setMailingOptions,
} from '@donkeyjs/module-mailings';
import {
  clientModuleShopping,
  setShoppingSettings,
} from '@donkeyjs/module-shopping';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { clientSchemaMeta } from '../clientSchemaMeta';
import { CourseOrderItemParser } from '../CourseOrderItemParser';
import { settings } from '../settings';
import { Main } from './Main';
import { theme } from './theme';
import { views } from './views';

setShoppingSettings({
  stripe: {
    publicKey:
      'pk_test_51Em0v0HYIle0CnBsqbnB7sv5dxpvjy1Gmsy3n1BK2b8TeVfeZ0lwoUZJqu9xwAW1Ph9f1YKXuz232W2FpvviamZ200KKxuZqrs',
    appearance: {
      theme: 'flat',
    },
  },
  maxItemQuantity: () => 1,
  parsers: [CourseOrderItemParser],
  auth: {
    orderNeedsRole: 'student',
  },
  cart: {
    itemView: 'option-selection',
    enableCompanyFields: true,
  },
});

setMailingOptions({
  newsletterTemplates: [
    {
      id: 'nl',
      name: 'Dutch',
      component: () =>
        import('../newsletter/Newsletter').then((m) => m.NewsletterDutch),
    },
    {
      id: 'en',
      name: 'English',
      component: () =>
        import('../newsletter/Newsletter').then((m) => m.NewsletterEnglish),
    },
  ],
});

export const app = createClientApp({
  ...settings,

  clientSchemaMeta,

  theme,
  views,

  root: Main,
  backOffice: () => import('./backoffice').then((m) => m.backOffice),
  routerPlugins: [NodeRoutingPlugin],
  tz: {
    utcToZonedTime,
    zonedTimeToUtc,
    defaultTimeZone: 'Europe/Amsterdam',
  },

  install: () => {},

  modules: [clientModuleShopping, clientModuleMailings],
});
