import { lazyComponent } from '../loaders/lazy';
import { createLayout } from './createLayout';
import { HtmlBlock, HtmlBlocks } from './fields/blocks/HtmlBlock';
import { HtmlBooleanField } from './fields/boolean/HtmlBoolean';
import { HtmlEmbed } from './fields/embed/HtmlEmbed';
import { HtmlFileRef, HtmlFileRefs } from './fields/fileRefs/HtmlFileRef';
import { HtmlFile } from './fields/files/HtmlFile';
import { HtmlFloat } from './fields/float/HtmlFloat';
import { HtmlInt } from './fields/int/HtmlInt';
import { LayoutJson } from './fields/json/LayoutJson';
import { HtmlNodeField } from './fields/node/HtmlNode';
import { HtmlNodeList } from './fields/nodeList/HtmlNodeList';
import { HtmlPlace } from './fields/places/HtmlPlace';
import { HtmlStringField } from './fields/string/HtmlString';

export const htmlLayout = createLayout({
  fields: {
    float: HtmlFloat,
    int: HtmlInt,
    string: HtmlStringField,
    date: lazyComponent('donkey.fields.htmldate', () =>
      import('./fields/date/HtmlDate').then((m) => m.HtmlDate),
    ),
    boolean: HtmlBooleanField,
    json: LayoutJson((() => htmlLayout) as any),
    embed: HtmlEmbed,
    node: HtmlNodeField,
    nodeList: HtmlNodeList,
    Block: HtmlBlock,
    BlockList: HtmlBlocks,
    File: HtmlFile,
    FileRef: HtmlFileRef,
    FileRefList: HtmlFileRefs,
    Place: HtmlPlace,
  },
});
