import {
  generateNodeId,
  store,
  type DataNode,
  type Insertable,
} from '@donkeyjs/proxy';

interface ExceptionInput {
  col: number;
  filename: string;
  line: number;
  message: string;
  stack?: string;
}

const events: Insertable<DataNode<DataSchema, 'TraceEvent'>>[] = [];

export const trace = store({
  events: [] as Insertable<DataNode<DataSchema, 'TraceEvent'>>[],
});

const addEvent = (
  event: Partial<Insertable<DataNode<DataSchema, 'TraceEvent'>>>,
) => {
  events.push({
    __typename: 'TraceEvent',
    id: generateNodeId(),
    at: new Date(),
    ...event,
  });
  trace.events = [...events];
};

export const traceClick = (target: string) => {
  addEvent({
    type: 'CLICK',
    target,
  });
};

export const traceInput = (target: string) => {
  // Log only once per input
  const lastEvent = events[events.length - 1];
  if (lastEvent?.type === 'INPUT' && lastEvent.target === target) return;

  addEvent({
    type: 'INPUT',
    target,
  });
};

export const traceRouting = (target: string, routeName?: string) => {
  addEvent({
    type: 'ROUTING',
    target,
    data: { routeName },
  });
};

export const traceConsole = (type: keyof typeof console, ...args: any[]) => {
  addEvent({
    type: 'CONSOLE',
    data: {
      type,
      args: args.map((arg) => JSON.stringify(arg, getCircularReplacer())),
    },
  });
};

export const traceException = (values: ExceptionInput) => {
  exceptionHandler(values, events);
};

export const getTraceLog = () => events;

let exceptionHandler = async (
  exception: ExceptionInput,
  _events: Insertable<DataNode<DataSchema, 'TraceEvent'>>[],
) => {
  addEvent({
    type: 'EXCEPTION',
    data: { ...exception },
  });
};

export const setExceptionHandler = (handler: typeof exceptionHandler) => {
  exceptionHandler = async (...args) => {
    try {
      await handler(...args);
    } catch (err) {
      report(err);
    }
  };
};

if (typeof window !== 'undefined')
  window.addEventListener('error', (err) => {
    console.error(err);
    traceException({
      col: err.colno,
      filename: err.filename,
      line: err.lineno,
      message: err.message,
      stack: (err.error as Error)?.stack,
    });
    return false;
  });

const report = console.error;
(console as any).logOriginal = console.log;

// Extend the console type globally to include the logOriginal method
declare global {
  interface Console {
    logOriginal: typeof console.log;
  }
}

// ['log', 'info', 'warn'].forEach((key) => {
//   const fn = (console as any)[key];
//   (console as any)[key] = (...args: any[]) => {
//     traceConsole(key as keyof typeof console, ...args);
//     fn.apply(console, args);
//   };
// });

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (_key: string | number | symbol, value: unknown) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
