import { getGlobal, onMount } from '@donkeyjs/jsx-runtime';

export const enableMailMode = () => {
  const context = getGlobal<any>('workspace');

  onMount(() => {
    if (!context || context.mode === 'mail') return;

    const prev = context.mode;
    context.mode = 'mail';

    return () => {
      if (context.mode === 'mail') context.mode = prev;
    };
  });
};
