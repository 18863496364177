import {
  ChildBlocks,
  Errors,
  getTheme,
  session,
  setLayout,
  text,
  useForm,
  type BlockProps,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { meta, store } from '@donkeyjs/proxy';
import { I18nMailings } from '../../../i18n';
import styles from './SubscribeFormBlock.module.css';

export interface SubscribeFormSettings {
  listId?: string;
  disableName?: boolean;
  sentMessage?: string;
}

const key = Symbol();

export function SubscribeFormBlock(props: BlockProps<SubscribeFormSettings>) {
  if (!props.block) throw new Error('Block is required in subscribe form');

  const theme = getTheme();

  setLayout('html:flow');

  const form = useForm(key, {
    firstName: 'string?',
    lastName: 'string?',
    email: 'string',
  });

  const state = store({
    status: 'idle' as 'idle' | 'submitting' | 'submitted',
  });

  const submit = async (ev: Event) => {
    ev.preventDefault();
    state.status = 'submitting';

    const result = await session.data.mutation.subscribe({
      id: props.block!.id,
      email: form.values.email,
      firstName: form.values.firstName || undefined,
      lastName: form.values.lastName || undefined,
    });

    if (result.errors) {
      meta(form.values).errors = result.errors;
      state.status = 'idle';
    } else {
      form.reset();
      state.status = 'submitted';
    }
  };

  return (
    <form
      class={bind(() => [styles.subscribe, props.class, theme.class.block])}
      onsubmit={submit}
      onmount={props.onmount}
    >
      {() => {
        if (state.status === 'submitted') {
          return (
            props.sentMessage || text(I18nMailings, 'Subscription.Subscribed')
          );
        }

        return (
          <>
            <ChildBlocks parentProps={props} />
            <Errors node={form.values} i18n={I18nMailings} />
            <form.values.$.email
              label={text(I18nMailings, 'Subscription.Email')}
            />
            {() =>
              !props.disableName && (
                <form.values.$.firstName
                  label={text(I18nMailings, 'Subscription.FirstName')}
                />
              )
            }
            {() =>
              !props.disableName && (
                <form.values.$.lastName
                  label={text(I18nMailings, 'Subscription.LastName')}
                />
              )
            }
            <div class={theme.class.buttons}>
              <button
                type="submit"
                class={[theme.class.button, 'default']}
                disabled={bind(() => state.status === 'submitting')}
                action="submit"
              >
                {text(I18nMailings, 'Subscription.Subscribe')}
              </button>
            </div>
          </>
        );
      }}
    </form>
  );
}
