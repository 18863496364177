import type { DomText, JSXVirtualNode } from '../dom';
import { componentContext } from '../mount/mount';

export function createTextNode(
  value: string | number | String,
  nextSsrCandidate?: Node | null,
) {
  const context = componentContext.current;
  if (!context) throw new Error('Cannot render a JSX element without context');

  const element = fromSsr(value, nextSsrCandidate);

  const result: JSXVirtualNode = {
    __type: 'node',
    currentValue: value,
    nodes: [element],
    dispose() {},
    testUpdate(value) {
      if (
        typeof value === 'string' ||
        typeof value === 'number' ||
        value instanceof String
      )
        return true;
      return `invalid value '${value?.toString()}' passed to text node with value '${
        element.textContent
      }'`;
    },
    update(value) {
      const test = result.testUpdate(value);
      if (test !== true) return test;
      result.currentValue = value;
      (element as Text).textContent = value.toString();
      // if (!context.dom.ssr) {
      //   textNodeChanged(element);
      // }
      return true;
    },
  };

  return result;
}

function fromSsr(
  value: string | number | String,
  nextSsrCandidate?: Node | null,
) {
  const text = value.toString();
  if (nextSsrCandidate?.nodeType === 3) {
    // nextSsrCandidate.textContent = text;
    return nextSsrCandidate as DomText;
  }
  const element = componentContext.current!.dom.createTextNode(text);
  return element;
}
