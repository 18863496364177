import { baseSchema, blocksSort, type BaseSchemaInput } from '@donkeyjs/core';
import {
  createSchemaExtension,
  extendSchemaInput,
  type SchemaFromInput,
} from '@donkeyjs/proxy';

export const mailingsSchemaExtension = createSchemaExtension<BaseSchemaInput>()(
  {
    nodes: {
      Newsletter: {
        subject: 'string',
        template: 'string?',
      },

      NewsletterShipment: {
        sentAt: 'date',
        newsletter: ['Newsletter', { reverse: 'shipments' }],
        renderedHtml: 'string',
      },

      NewsletterShipmentList: {
        list: ['MailingList', { reverse: 'mailingLists' }],
        shipment: ['NewsletterShipment', { reverse: 'lists' }],
      },

      NewsletterShipmentRecipient: {
        newsletterShipment: ['NewsletterShipment', { reverse: 'recipients' }],
        subscription: ['Subscription?', { reverse: 'newsletterShipments' }],
        batchNumber: 'int?',
        opened: 'boolean?',
      },

      MailingList: {
        name: 'string',
        internalKey: 'string?',
      },

      Subscription: {
        email: 'string',
        firstName: 'string?',
        lastName: 'string?',
      },

      MailingListSubscription: {
        list: [
          'MailingList',
          { reverse: 'subscriptions', cascadeDelete: true },
        ],
        subscription: [
          'Subscription',
          { reverse: 'lists', cascadeDelete: true },
        ],
      },
    },

    extensions: {
      App: {
        add: {
          sendNewsletterFromName: 'string?',
          sendNewsletterFromEmail: 'string?',
        },
      },

      Block: {
        add: {
          onNewsletterBlocks: [
            'Newsletter?',
            {
              cascadeDelete: true,
              embeddedList: true,
              reverse: 'blocks',
              sort: blocksSort,
            },
          ],
        },
      },
    },

    resolvers: {
      Newsletter: {
        newsletters: {
          return: 'many',
          sort: [{ name: 'DESC', order: ['createdAt:desc'] }],
        },
      },

      MailingList: {
        mailingLists: {
          return: 'many',
          sort: [{ name: 'NAME', order: ['name:asc'] }],
        },
      },

      Subscription: {
        subscriptions: {
          return: 'many',
          sort: [
            {
              name: 'NAME',
              order: ['lastName:asc', 'firstName:asc', 'email:asc'],
            },
            { name: 'DESC', order: ['updatedAt:desc'] },
          ],
          where: {
            mail: ['email', ['eq']],
            lists: ['lists.list', ['eq', 'in']],
          },
        },
      },
    },

    customResolvers: {
      mutations: {
        subscribe: {
          returns: 'boolean',
          args: {
            id: 'string',
            email: 'string',
            firstName: 'string?',
            lastName: 'string?',
          },
        },

        unsubscribe: {
          returns: 'boolean',
          args: {
            manageId: 'string',
          },
        },

        subscribeToNotifications: {
          returns: 'boolean',
          args: {
            email: 'string',
            typename: 'string',
            id: 'string',
            culture: 'string?',
          },
        },

        updateSubscription: {
          returns: 'boolean',
          args: {
            manageId: 'string',
            email: 'string',
          },
        },

        sendNewsletterPreview: {
          returns: 'boolean',
          args: {
            id: 'string',
            toEmail: 'string',
          },
        },

        sendNewsletter: {
          returns: 'string',
          args: {
            id: 'string',
            listIds: 'string',
          },
        },
      },
    },

    i18n: {
      nl: () => import('./i18n/nlNL').then((m) => m.SchemaNl),
    },
  } as const,
);

const mailingsSchema = extendSchemaInput(baseSchema, mailingsSchemaExtension);

export type MailingsSchema = SchemaFromInput<typeof mailingsSchema>;
