import type { SchemaMeta } from '@donkeyjs/proxy';

export const schemaMeta: SchemaMeta<DataSchema> = {
  Course: {
    searchVectors: {
      name: 'A',
    },
  },
  CourseOffering: {
    searchVectors: {
      'course.name': 'A',
      'description.text': 'B',
    },
  },
  CourseTeacher: {
    join: {
      course: 'teacher',
      teacher: 'course',
    },
  },
  Teacher: {
    searchVectors: {
      lastName: 'A',
      firstName: 'A',
      middleName: 'C',
      'biography.text': 'B',
      'longBiography.text': 'C',
    },
  },
  Testimonial: {
    searchVectors: {
      author: 'A',
      capacity: 'B',
      'text.text': 'C',
      'teacher.firstName': 'A',
      'teacher.lastName': 'A',
    },
  },
};
