import type { AppClientModule } from '@donkeyjs/client';
import { permissions } from '../permissions';
import { schemaMeta } from '../schemaMeta';
import { blocks } from './blocks';
import { clientSchemaMeta } from './clientSchemaMeta';
import { Newsletter } from './components/Newsletter';
import { getMailingOptions } from './options';

export * from './mail/addTrackingToAssetUrl';
export * from './mail/MangeSubscriptionLink';
export * from './options';

export const clientModuleMailings: AppClientModule = {
  blocks,
  permissions,
  schemaMeta,
  clientSchemaMeta,
  get localRoutes() {
    return getMailingOptions().newsletterTemplates
      ? [
          {
            name: 'Newsletter',
            id: 'mailings.newsletter',
            pathname: '/.newsletter',
            isSystemRoute: true,
            render: () => <Newsletter />,
          },
        ]
      : undefined;
  },
};
