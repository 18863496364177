import { session } from '@donkeyjs/client';
import type { LocaleInput } from '@donkeyjs/core';
import { formatCurrencySign } from './formatCurrencySign';

export const formatMoney = (
  locale: Pick<LocaleInput, 'formatNumber'>,
  amount: number,
  currency?: string,
) => {
  const sign = formatCurrencySign(
    currency || session.app.data[0]?.defaultCurrency || '',
  );
  return [sign, locale.formatNumber(amount, '.2f')].filter(Boolean).join(' ');
};
