import { bind, setState } from '@donkeyjs/jsx-runtime';
import { meta, type DataList } from '@donkeyjs/proxy';
import { Flag } from '../../i18n/Flag';
import { getI18n } from '../../i18n/getI18n';
import { session } from '../../session';
import { getTheme } from '../../styles';
import styles from './Cultures.module.css';

interface CulturesProps {
  blocks: DataList<DataSchema, 'Block'>;
  segment: string | null | undefined;
}

export function Cultures(props: CulturesProps) {
  if (session.app.routerFollowsCultures) return null;

  const i18n = getI18n();
  const theme = getTheme();

  const state = setState({
    get cultures() {
      if (meta(props.blocks).isLoading) return [];
      const cultures = new Set<string>();
      for (const block of props.blocks) {
        const segment = block.segment;
        const culture = block.culture;
        if (meta(block).isLoading) continue;
        if (segment !== props.segment) continue;
        cultures.add(culture);
      }
      session.app.schema.cultures.filter(
        (culture) =>
          meta(props.blocks)
            .getCulture(culture)
            .filter(
              (block) =>
                !meta(block).isLoading &&
                (block.segment || undefined) === (props.segment || undefined),
            ).length > 0,
      );
      return session.app.schema.cultures.filter((culture) =>
        cultures.has(culture),
      );
    },
  });

  return () =>
    state.cultures.length < 2 ? null : (
      <div class={styles.cultures}>
        {() =>
          session.app.schema.cultures.map(
            (culture) =>
              state.cultures.includes(culture) && (
                <a
                  class={bind(() => [
                    styles.culture,
                    theme.class.linkButton,
                    { [styles.active]: meta(props.blocks).culture === culture },
                  ])}
                  href={bind(() =>
                    session.router.getPath(
                      { route: 'current' },
                      {
                        query:
                          i18n.culture === culture
                            ? undefined
                            : { culture: [culture] },
                      },
                    ),
                  )}
                >
                  <Flag culture={culture} showTextIfNotFound />
                </a>
              ),
          )
        }
      </div>
    );
}
