import type { RouteDefinition } from '@donkeyjs/core';
import type { UserContext } from '../authentication';
import { session } from '../session';

export interface GetMenuOptions {
  readonly parentRouteKey?: string;
  readonly segment?: string;
  readonly level?: number;
  readonly sublevels?: number;
  readonly expand?: 'all' | 'active';
  readonly skipActiveRoutes?: boolean;
  readonly user: UserContext;
  readonly activeRoute: RouteDefinition;
  filter?(route: RouteDefinition): boolean;
  routeIcon?(route: RouteDefinition): JSX.Element | undefined;
  forceExpanded?(
    route: RouteDefinition,
    info: { activeRoutes: RouteDefinition[] },
  ): boolean;
}

export interface MenuRoute {
  route: RouteDefinition;
  isActive: boolean;
  icon?: JSX.Element;
  submenu: MenuRoute[];
}

export const getMenu = ({
  expand,
  filter,
  forceExpanded,
  level: startLevel = 0,
  parentRouteKey,
  routeIcon,
  segment,
  skipActiveRoutes,
  sublevels = Number.MAX_SAFE_INTEGER,
  user,
  activeRoute,
}: GetMenuOptions): MenuRoute[] => {
  const activeRoutes = session.router.getBranch(activeRoute);

  const outOfRange = startLevel >= activeRoutes.length;

  const selectedParent = outOfRange
    ? null
    : (parentRouteKey && session.router.getRouteByKey(parentRouteKey)) ||
      (startLevel === 0 ? undefined : activeRoutes[startLevel - 1]);

  return selectedParent === null
    ? []
    : getRoutesForParent(selectedParent, {
        activeRoutes,
        expand,
        filter,
        forceExpanded,
        currentLevel: 0,
        matchRoles: (roles) => user.matchRoles(roles),
        routeIcon,
        segment,
        skipActiveRoutes,
        sublevels,
      });
};

const getRoutesForParent = (
  parent: RouteDefinition | undefined,
  {
    activeRoutes,
    currentLevel,
    expand,
    filter,
    forceExpanded,
    matchRoles,
    routeIcon,
    segment,
    skipActiveRoutes,
    sublevels,
  }: {
    activeRoutes: RouteDefinition[];
    expand: 'all' | 'active' | undefined;
    forceExpanded?: (
      route: RouteDefinition,
      info: {
        activeRoutes: RouteDefinition[];
        // siblings: DataNode<DataSchema, 'Route'>[];
      },
    ) => boolean;
    filter?: (route: RouteDefinition) => boolean;
    segment?: string;
    currentLevel: number;
    matchRoles: (roles: string[]) => boolean;
    routeIcon?: (route: RouteDefinition) => JSX.Element | undefined;
    skipActiveRoutes?: boolean;
    sublevels: number;
  },
): MenuRoute[] => {
  if (currentLevel > sublevels) return [];

  return session.router
    .getRoutesForParent(parent)
    .map((route) => {
      const isVisible =
        !route.node?.hidden &&
        ((segment && route.node?.segment === segment) ||
          (!segment && !route.node?.segment)) &&
        (!route.node?.visibleToRoles ||
          matchRoles(route.node.visibleToRoles.split(','))) &&
        !!route.node?.name &&
        (!filter || filter(route));
      if (!isVisible) return undefined;

      const icon = routeIcon?.(route);

      const isActive = !!activeRoutes?.find((r) => r.id === route.id);
      return isActive && skipActiveRoutes
        ? undefined
        : {
            icon,
            isActive,
            route,
            submenu:
              expand === 'all' ||
              (isActive && expand === 'active') ||
              (!isActive &&
                forceExpanded?.(route, {
                  activeRoutes,
                  // siblings: selectedRoutes,
                }))
                ? getRoutesForParent(route, {
                    activeRoutes,
                    currentLevel: currentLevel + 1,
                    expand,
                    filter,
                    forceExpanded,
                    matchRoles,
                    // routeIcon,
                    segment,
                    skipActiveRoutes,
                    sublevels,
                  })
                : [],
          };
    })
    .filter(Boolean) as MenuRoute[];
};
