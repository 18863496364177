import {
  isNode,
  type NodeTypename,
  type QueryWhereValue,
  type ResolverManyArgsFromSchema,
  type ResolverManySearch,
  type Schema,
} from '../schema';
import { meta } from './meta';

export const getSourceFromSearch = <
  S extends Schema,
  Typename extends NodeTypename<S>,
>(
  search?: ResolverManySearch<S, S['nodes'][Typename]>,
): string | undefined =>
  search &&
  `search:${search.culture ? `${search.culture}:` : ''}:${search.text}`;

export const isMatch = <S extends Schema, Typename extends NodeTypename<S>>(
  args: ResolverManyArgsFromSchema<S, Typename>,
  obj: Record<string, any>,
  sources: string[],
  omitLazyLoad?: boolean,
): boolean => {
  const source = args.source ?? getSourceFromSearch(args.search);

  if (source && !sources.includes(source)) return false;

  let result = true;
  if (args.where)
    for (const key in args.where) {
      const checks = args.where[key];
      if (checks) {
        const value = getNestedValue(obj, key.split('.'), omitLazyLoad);
        if (!check(checks, value)) {
          result = false;
          // Continue only if this is not a lazy load
          // in order to trigger field loading
          if (omitLazyLoad) return false;
        }
      }
    }

  return result;
};

const check = (checks: QueryWhereValue<any>, inputValue: any) => {
  if (checks.in != null) {
    if (inputValue == null) return false;
    if (!checkInMatch(inputValue, checks.in)) return false;
  } else if (Array.isArray(inputValue)) {
    for (const item of inputValue) {
      if (check(checks, item)) return true;
    }
    return false;
  }

  const value = isNode(inputValue) ? inputValue.id : inputValue;

  if (checks.eq != null && checks.eq !== value) return false;
  if (checks.notEq != null && checks.notEq === value) return false;
  if (checks.gt != null && (value == null || (value as any) <= checks.gt))
    return false;
  if (checks.gte != null && (value == null || (value as any) < checks.gte))
    return false;
  if (checks.lt != null && (value == null || (value as any) >= checks.lt))
    return false;
  if (checks.lte != null && (value == null || (value as any) > checks.lte))
    return false;
  if (checks.notIn?.includes(value as any)) return false;

  return true;
};

export const checkInMatch = (value: any, match: any[] | any[][]): boolean => {
  if (value == null) return match.length === 0;

  if (Array.isArray(match[0])) {
    for (const group of match) {
      if (!checkInMatch(value, group)) return false;
    }
    return true;
  }

  const values = Array.isArray(value) ? value : [value];
  for (const test of match) {
    if (values.some((item) => (isNode(item) ? item.id : item) === test))
      return true;
  }
  return false;
};

function getNestedValue(obj: any, keys: string[], omitLazyLoad?: boolean) {
  const [field, ...rest] = keys;
  const value: any = Array.isArray(obj)
    ? obj.map((v) => getNestedValue(v, [field], omitLazyLoad))
    : omitLazyLoad
      ? meta(obj)?.peek(field, { allowTracked: true })
      : obj[field];
  if (!rest.length) return value;

  return getNestedValue(value, rest, omitLazyLoad);
}
