import {
  getLocale,
  getSchemaI18n,
  type I18nLibrary,
  type I18nLibraryCulture,
  type NestedTranslations,
} from '@donkeyjs/core';
import { getContext, setContext } from '@donkeyjs/jsx-runtime';
import {
  store,
  type AppSchema,
  type FieldsFromNodeSchema,
  type NodeTypename,
  type Schema,
  type SupportedCulture,
} from '@donkeyjs/proxy';
import { session } from '../session';
import { I18nSystem } from './I18nSystem';

export type Culture = ApplicationSchema extends { cultures: (infer U)[] }
  ? U
  : SupportedCulture;

const key = Symbol('i18n');

interface I18nContextCultures {
  culture: Culture;
  userCulture: Culture;
}

interface I18nContextValues {
  culture: I18nContext;
  userCulture: I18nContext;
}

export class I18nContext {
  constructor(private readonly _culture: { culture: Culture }) {}

  public get culture() {
    return this._culture.culture;
  }

  public isLoading<Library extends I18nLibraryCulture<any, any>>(
    library: I18nLibrary<Library>,
  ) {
    return !!library[this.culture]?.loading;
  }

  public get<
    K extends Extract<
      keyof NestedTranslations<
        typeof I18nSystem extends I18nLibrary<infer Set>
          ? NonNullable<Set['values']>
          : never
      >,
      string
    >,
  >(
    key: K,
    ...args: NestedTranslations<
      typeof I18nSystem extends I18nLibrary<infer Library>
        ? NonNullable<Library['values']>
        : never
    >[K] extends (...props: infer Props) => string
      ? Props
      : []
  ): string;
  public get<
    Library extends I18nLibraryCulture<any, any>,
    K extends Extract<keyof Nodes, string>,
    Nodes extends NestedTranslations<
      Library extends I18nLibraryCulture<infer Nodes, any> ? Nodes : never
    >,
  >(
    library: I18nLibrary<Library>,
    key: K,
    ...args: Nodes[K] extends (...props: infer Props) => string ? Props : []
  ): string;
  public get<
    Library extends I18nLibraryCulture<any, any>,
    K extends Extract<
      keyof NestedTranslations<
        Library extends I18nLibraryCulture<infer Nodes, any> ? Nodes : never
      >,
      string
    >,
  >(arg0: I18nLibrary<Library> | K, ...rest: any[]): string {
    const [library, key, ...args] =
      typeof arg0 === 'string'
        ? [I18nSystem, arg0, ...rest]
        : [arg0 as typeof I18nSystem, ...rest];
    const value = library[this.culture]?.get(key);
    return (typeof value === 'function' ? value(...args) : value) || '';
  }

  public getError(key: string): string | undefined;
  public getError<Library extends I18nLibraryCulture<any, any>>(
    library: I18nLibrary<Library>,
    key: string,
  ): string | undefined;
  public getError<Library extends I18nLibraryCulture<any, any>>(
    arg0: I18nLibrary<Library> | string,
    key = arg0 as string,
  ): string | undefined {
    return typeof arg0 === 'string'
      ? I18nSystem[this.culture]?.getError(key)
      : arg0[this.culture]?.getError(key);
  }

  public getNodeName<Typename extends NodeTypename<DataSchema>>(
    typename: Typename,
    options?: { pluralize?: (value: string) => string },
  ): string;
  public getNodeName<S extends AppSchema, Typename extends NodeTypename<S>>(
    schema: S,
    typename: Typename,
    options?: { pluralize?: (value: string) => string },
  ): string;
  public getNodeName<S extends AppSchema, Typename extends NodeTypename<S>>(
    arg0: S | Typename,
    arg1?: Typename | { pluralize?: (value: string) => string },
    arg2?: { pluralize?: (value: string) => string },
  ) {
    const [schema, typename, options] =
      typeof arg0 === 'string'
        ? [
            session.app.schema,
            arg0 as Typename,
            arg1 as { pluralize?: (value: string) => string },
          ]
        : [arg0, arg1 as Typename, arg2];
    const i18n = getSchemaI18n(schema, this.culture);
    return i18n.getNodeName(typename, options) || '';
  }

  public getFieldName<
    Typename extends NodeTypename<DataSchema>,
    F extends Exclude<
      keyof FieldsFromNodeSchema<DataSchema, Typename>,
      '__typename' | 'id'
    >,
  >(typename: Typename, fieldName: F): string;
  public getFieldName<
    S extends AppSchema,
    Typename extends NodeTypename<S>,
    F extends Extract<keyof S['nodes'][NodeTypename<S>]['fields'], string>,
  >(schema: S, typename: Typename, fieldName: F): string;
  public getFieldName<
    S extends AppSchema,
    Typename extends NodeTypename<S>,
    F extends Extract<keyof S['nodes'][NodeTypename<S>]['fields'], string>,
  >(arg0: S | Typename, arg1: Typename | F, arg2?: F) {
    const [schema, typename, fieldname] =
      typeof arg0 === 'string'
        ? [session.app.schema, arg0 as Typename, arg1 as F]
        : [arg0, arg1 as Typename, arg2 as F];
    const i18n = getSchemaI18n(schema, this.culture);
    return i18n.getFieldName(typename, fieldname);
  }

  public getGroupName<Typename extends NodeTypename<DataSchema>>(
    typename: Typename,
    groupName: string,
  ): string;
  public getGroupName<S extends AppSchema, Typename extends NodeTypename<S>>(
    schema: S,
    typename: Typename,
    groupName: string,
  ): string;
  public getGroupName<S extends AppSchema, Typename extends NodeTypename<S>>(
    arg0: S | Typename,
    arg1: Typename | string,
    arg2?: string,
  ) {
    const [schema, typename, fieldname] =
      typeof arg0 === 'string'
        ? [session.app.schema, arg0 as Typename, arg1 as string]
        : [arg0, arg1 as Typename, arg2 as string];
    const i18n = getSchemaI18n(schema, this.culture);
    return i18n.getGroupName(typename, fieldname);
  }

  public getEnumValue<
    Enum extends keyof DataSchema['enums'],
    Key extends Extract<
      DataSchema['enums'][Enum]['values'] extends readonly (infer U)[]
        ? U
        : never,
      string
    >,
  >(name: Enum, key: Key): string;
  public getEnumValue<
    S extends Schema,
    Enum extends keyof S['enums'],
    Key extends Extract<
      S['enums'][Enum]['values'] extends readonly (infer U)[] ? U : never,
      string
    >,
  >(schema: S, name: Enum, key: Key): string;
  public getEnumValue<
    S extends AppSchema,
    Enum extends keyof S['enums'],
    Key extends Extract<
      S['enums'][Enum]['values'] extends readonly (infer U)[] ? U : never,
      string
    >,
  >(arg0: S | Enum, arg1: Enum | Key, arg2?: Key) {
    const [schema, name, key] =
      typeof arg0 === 'object'
        ? [arg0, arg1 as Enum, arg2 as Key]
        : [session.app.schema, arg0 as Enum, arg1 as Key];
    const i18n = getSchemaI18n(schema, this.culture);
    return (i18n.getEnumValue as any)(name, key) || '';
  }

  formatNumber(number: number, specifier: string) {
    const locale = getLocale(this.culture);
    return locale.formatNumber(number, specifier) || '';
  }

  formatDate(date: Date, specifier: string) {
    const locale = getLocale(this.culture);
    return locale.formatDate(date, specifier) || '';
  }

  get dateLocale() {
    return getLocale(this.culture).dateLocale;
  }
}

export const setI18n = (options: I18nContextCultures) => {
  const source = store(options);

  setContext<I18nContextValues>(key, {
    culture: new I18nContext({
      get culture() {
        return source.culture;
      },
    }),
    userCulture: new I18nContext({
      get culture() {
        return source.userCulture;
      },
    }),
  });
};

export const getI18n = (user?: boolean) =>
  getContext<I18nContextValues>(key)[user ? 'userCulture' : 'culture'];

export const getI18nCultures = () => {
  const context = getContext<I18nContextValues>(key);
  return {
    get culture() {
      return context.culture.culture;
    },
    get userCulture() {
      return context.userCulture.culture;
    },
  };
};
