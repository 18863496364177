import { createClientSchemaMeta } from '@donkeyjs/client';
import { fullName } from './helpers/fullName';
import { schemaMeta } from './schemaMeta';

export const clientSchemaMeta = createClientSchemaMeta(schemaMeta, {
  CourseOffering: {
    asString: (node) => node.course?.name,
    grouping: {
      basicInfo: {
        fields: [
          'course',
          'images',
          'language',
          'status',
          'isOnline',
          'recommended',
        ],
      },
      planning: { fields: ['starts', 'ends', 'days', 'isTimeSchedulePublic'] },
      details: {
        fields: [
          'description',
          'moreInfo',
          'accreditation',
          'priorKnowledge',
          'targetAudience',
        ],
      },
      sales: { fields: ['options', 'priceInfo', 'limitSeatsTo'] },
    },
  },
  Student: {
    asString: (node) => fullName(node.user),
  },
  Teacher: {
    // icon: () => ({ icon: GraduationCap, weight: 'duotone' }),
    asString: (node) => fullName(node),
  },
  Testimonial: {
    asString: (node) =>
      [node.author, `${node.text[0]?.text?.slice(0, 20)}...`]
        .filter(Boolean)
        .join(': '),
  },
});
