import { bind } from '@donkeyjs/jsx-runtime';
import { PhCheck } from '@donkeyjs/phosphor-icons';
import styles from './MenuItem.module.css';

export interface MenuItemProps {
  readonly shortcut?: string;
  readonly checked?: boolean;
  readonly label: JSX.Children;
  readonly icon?: JSX.Children;
  readonly onclick: (ev: MouseEvent | undefined, target: HTMLElement) => void;
  readonly onmousedown?: (ev: MouseEvent) => void;
  readonly focused?: boolean;
  readonly hidden?: boolean;
}

export function MenuItem(props: MenuItemProps) {
  return () =>
    props.hidden ? null : (
      <button
        type="button"
        onclick={(ev) => props.onclick(ev, ev.target as HTMLElement)}
        onmousedown={props.onmousedown}
        class={bind(() => [styles.button, { [styles.focused]: props.focused }])}
      >
        <span class={styles.icon}>
          {() => (props.checked ? <PhCheck weight="bold" /> : props.icon)}
        </span>
        <span class={styles.label}>{() => props.label}</span>
        {() =>
          !!props.shortcut && (
            <span class={styles.shortcut}>{props.shortcut}</span>
          )
        }
      </button>
    );
}
