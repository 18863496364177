import { bind } from '@donkeyjs/jsx-runtime';
import type { BlockProps } from '.';
import { Embed, type EmbedType } from '../components/Embed';
import { getTheme } from '../styles';

export function EmbedBlock(props: BlockProps<EmbedType>) {
  const theme = getTheme();

  return (
    <div
      class={bind(() => [props.class, theme.class.block])}
      onmount={props.onmount}
      style={bind(() =>
        props.maxWidth ? { width: `${props.maxWidth}px` } : {},
      )}
    >
      {() =>
        !props.originalUrl ? (
          'Embed'
        ) : (
          <Embed embed={props} editable={!props.readonly} />
        )
      }
    </div>
  );
}
