import { bind } from '@donkeyjs/jsx-runtime';
import type { BlockProps } from '.';
import { getTheme } from '../styles';
import styles from './ColumnsBlock.module.css';
import { ChildBlocks } from './helpers/ChildBlocks';

export interface ColumnsProps {
  columnWidth?: number;
}

export function ColumnsBlock(props: BlockProps<ColumnsProps>) {
  const theme = getTheme();

  return (
    <ChildBlocks
      class={bind(() => [
        'text-columns',
        props.class,
        theme.class.block,
        styles.columns,
      ])}
      parentProps={props}
      onmount={props.onmount}
    />
  );
}
