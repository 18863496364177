import type { Fragment, NodeTypename, Schema, StatusFragment } from '../schema';

export const fragmentFromFieldStatus = <
  S extends Schema,
  Typename extends NodeTypename<S>,
>(
  fragment: StatusFragment,
  includeAllStatuses?: boolean,
): Fragment<S, Typename> | undefined => {
  let result: Fragment<S, Typename> | undefined = undefined;

  for (const key in fragment) {
    const value = fragment[key];
    if (typeof value === 'string') {
      if (value === 'requested') fragment[key] = 'loading';
      if (value === 'requested' || includeAllStatuses) {
        if (!result) result = {} as any;
        (result as any)[key] = true;
      }
    } else if (value) {
      if (value.id === 'requested' || includeAllStatuses) {
        // Go into other nodes only if they haven't been loaded yet.
        // Otherwise, they will have scheduled their own request
        if (!result) result = {} as any;
        (result as any)[key] = fragmentFromFieldStatus(
          value as any,
          includeAllStatuses,
        );
      }
    }
  }

  return result;
};
