import type { FieldStatus, StatusFragment } from '../schema';
import type { StoreOptions } from '../store';

export const statusCollector = (options: {
  status: StatusFragment;
  initializeField: (
    key: string,
  ) => { status: FieldStatus | StatusFragment; value?: any } | undefined;
}): StoreOptions<any> => {
  return {
    beforeGet(source, key) {
      if (
        typeof key !== 'string' ||
        Object.prototype.hasOwnProperty.call(source, key)
      )
        return;

      const field = options.initializeField(key);
      if (!field) return;

      options.status[key] = field.status;
      if (field.value !== undefined) source[key] = field.value;
    },
  };
};
