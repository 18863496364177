import type { DataNode, Node } from '../cache/DataNode';
import type { NodeTypename, QuerySortOrder, Schema } from '../schema';
import { meta } from './meta';

export function sortNodes<S extends Schema, Typename extends NodeTypename<S>>(
  nodes: Node<{ __typename: Typename }>[] | DataNode<S, Typename>[],
  sort: QuerySortOrder<S, S['nodes'][Typename]> | null | undefined,
): void {
  if (!sort) return;

  const sortKeys = sort.map((key) => key.split(':'));
  const collator =
    typeof Intl === 'undefined' || typeof Intl.Collator === 'undefined'
      ? undefined
      : new Intl.Collator(undefined, {
          sensitivity: 'base',
          ignorePunctuation: true,
        });

  nodes.sort((a, b) => {
    for (const [fieldName, direction] of sortKeys) {
      let aValue: any = meta(a).getValue(fieldName as any) || '';
      let bValue: any = meta(b).getValue(fieldName as any) || '';
      if (typeof aValue === 'string') aValue = aValue.toLowerCase();
      if (typeof bValue === 'string') bValue = bValue.toLowerCase();
      if (aValue instanceof Date) aValue = aValue.getTime();
      if (bValue instanceof Date) bValue = bValue.getTime();
      if (aValue !== bValue) {
        const result =
          typeof aValue === 'string' && typeof bValue === 'string' && collator
            ? collator.compare(aValue, bValue)
            : aValue < bValue
              ? -1
              : 1;
        return direction === 'asc' || direction === 'auto'
          ? result
          : result * -1;
      }
    }
    return 0;
  });
}
