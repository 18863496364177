import { Blocks, CultureMenu, Menu, MobileMenu } from '@donkeyjs/client';
import { updateComponent } from '@donkeyjs/jsx-runtime';
import { CartLink } from '@donkeyjs/module-shopping';
import styles from './Main.module.css';
import { BackgroundSvg } from './components/BackgroundSvg';
import { Logo } from './components/Logo';
import { LogoSvg } from './components/LogoSvg';

export function Main() {
  return (
    <>
      <div class={styles.wrapper}>
        <div class={styles.main}>
          <div class={styles.mobileMenu}>
            <MobileMenu
              appName={<LogoSvg class={styles.mobileLogo} />}
              includeCultures
            />
          </div>
          <div class={styles.inner}>
            <header class={styles.header}>
              <Logo />
              <nav class={styles.menu}>
                <Menu />
                <CartLink class={styles.cart} />
                <div class={styles.cultures}>
                  <CultureMenu includeActive />
                </div>
              </nav>
            </header>
            <div class={styles.content}>
              <main>
                <Blocks segment="main" />
              </main>
              <nav class={styles.submenu}>
                <Menu level={1} />
                <Blocks segment="sidebar" />
              </nav>
            </div>
            <footer class={styles.footer}>
              <div class={styles.subscribeCell}>
                <Blocks on="app" segment="subscribe" />
              </div>
              <div class={styles.footerContent}>
                <div class={styles.address}>
                  <Blocks on="app" segment="footer" />
                </div>
                <div class={styles.navigation}>
                  <Menu parentRouteKey="footer" />
                </div>
              </div>
            </footer>
          </div>
          <BackgroundSvg class={styles.background} />
        </div>
        <div class={styles.topBar} />
      </div>
      <div class={[styles.sideImage, styles.left]} />
      <div class={[styles.sideImage, styles.right]} />
    </>
  );
}

if (import.meta.hot) {
  import.meta.hot.accept((hot) => {
    updateComponent(Main, hot?.Main);
  });
}
