import { bind, getSyncState, live } from '@donkeyjs/jsx-runtime';
import { PhCaretRight, PhList } from '@donkeyjs/phosphor-icons';
import { SlideOut } from '../layout';
import { session } from '../session';
import { CompactMenu } from './CompactMenu';
import styles from './MobileMenu.module.css';

export interface MobileMenuProps {
  class?: JSX.ClassNames;
  appName?: JSX.Children | false;
  includeCultures?: boolean;
}

export function MobileMenu(props: MobileMenuProps) {
  const state = getSyncState(MobileMenu, () => ({
    open: false,
  }));

  let route = session.router.route;
  live(() => {
    if (session.router.route !== route) {
      route = session.router.route;
      state.open = false;
    }
  });

  return (
    <div
      class={bind(() => [
        props.class,
        styles.mobileMenu,
        { [styles.open]: state.open },
      ])}
    >
      <button
        type="button"
        class={styles.button}
        onclick={() => {
          state.open = !state.open;
        }}
      >
        {() =>
          props.appName === false ? null : (
            <span class={styles.title}>
              {() =>
                props.appName === undefined
                  ? session.router.app.name
                  : props.appName
              }
            </span>
          )
        }
        <span class={styles.pageTitle}>{() => session.router.route.name}</span>
        <span class={styles.icon}>
          {' '}
          <PhList weight="regular" />
        </span>
      </button>
      <SlideOut class={styles.slideOut} open={bind(() => state.open)}>
        <CompactMenu
          includeCultures={props.includeCultures}
          renderExpandIcon={() => <PhCaretRight weight="bold" />}
        />
      </SlideOut>
    </div>
  );
}
