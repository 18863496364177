import { getShoppingSettings, type ParsedOrderItem } from '../client';
import type {
  OrderItemFields,
  OrderTransactionFields,
} from './order/getOrderOverview';

export interface CartOrderItem extends ParsedOrderItem {
  origin: OrderItemFields;
  coupons: OrderTransactionFields[];
}

export function parseOrderItem(
  item: OrderItemFields,
  transactions?: OrderTransactionFields[],
): CartOrderItem | null | undefined {
  const settings = getShoppingSettings();

  const parsers = settings.parsers;
  if (!parsers) throw new Error('No parsers in shoppingClient options');

  let parsed: ParsedOrderItem | null | undefined;
  for (const parser of parsers) {
    parsed = parser.parse(item);
    if (parsed) break;
  }

  return (
    parsed && {
      ...parsed,
      origin: item,
      coupons:
        transactions?.filter(
          (t) =>
            t.type === 'COUPON' && t.orderItem && t.orderItem.id === item.id,
        ) || [],
    }
  );
}

export function parseOrderItems(
  items: OrderItemFields[],
  transactions?: OrderTransactionFields[],
) {
  return items
    .map((item) => parseOrderItem(item, transactions))
    .filter((n) => !!n);
}
