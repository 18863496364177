export function cloneObject<T>(obj: T, override?: Partial<T>): T {
  const clonedObj = Object.defineProperties(
    {},
    Object.getOwnPropertyDescriptors(obj),
  ) as T;

  if (override) {
    Object.defineProperties(
      clonedObj,
      Object.getOwnPropertyDescriptors(override),
    );
  }

  return clonedObj;
}

export function extendedObject<T, S>(obj: T, extension: S): T & S {
  return cloneObject(obj, extension as any) as T & S;
}
