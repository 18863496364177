import { getI18n, getTheme, session, text } from '@donkeyjs/client';
import { getGlobal } from '@donkeyjs/jsx-runtime';
import { batch, store } from '@donkeyjs/proxy';
import {
  type DefaultValuesOption,
  type Stripe,
  type StripeElements,
  type StripePaymentElement,
  loadStripe,
} from '@stripe/stripe-js';
import { I18nShopping } from '../../i18n';
import { getShoppingSettings } from '../shoppingSettings';

const StateKey = Symbol('payment-state');

function getPaymentState() {
  return getGlobal(StateKey, () =>
    store({
      initialized: false,
      clientSecret: null as string | null,
      elements: null as StripeElements | null,
      element: null as StripePaymentElement | null,
      stripe: null as Stripe | null,
    }),
  );
}

export function isPaymentRequest() {
  return session.dom.ssr
    ? false
    : new URLSearchParams(window.location.search).get('action') === 'payment';
}

export function isPaymentReturnPage() {
  return session.dom.ssr
    ? false
    : !!new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret',
      ) ||
        !!new URLSearchParams(window.location.search).get(
          'setup_intent_client_secret',
        );
}

export function usePayment() {
  const options = getShoppingSettings();
  const state = getPaymentState();
  const theme = getTheme();
  const i18n = getI18n();

  let statusCheckStarted = false;
  let kind: 'payment_intent' | 'setup_intent' | null = null;

  return store({
    async stripe() {
      if (session.dom.ssr) return undefined;
      if (state.stripe) return state.stripe;

      const publicKey = session.isDev
        ? options.stripe?.testKey || options.stripe?.publicKey
        : options.stripe?.publicKey;
      if (!publicKey) throw new Error('Stripe public key not found.');

      const stripe = await loadStripe(publicKey);
      if (!stripe) throw new Error('Stripe not loaded.');

      state.stripe = stripe;
      return stripe;
    },

    async ensurePayment(
      target: string,
      { defaultValues }: { defaultValues?: DefaultValuesOption } = {},
    ) {
      if (!state.initialized) {
        state.initialized = true;

        await this.checkStatus();
        if (this.success) return;
      } else {
        state.element = null;
      }

      const stripe = await this.stripe();
      if (!stripe) return;

      const response = await session.data.mutation.getPaymentIntent({
        target,
      });
      if (response.errors) throw new Error(response.errors[0].message);
      const data = response.data as {
        client_secret: string;
        kind: 'payment_intent' | 'setup_intent';
      };
      kind = data.kind;

      const elements = stripe.elements({
        locale: i18n.culture as any,
        appearance: {
          ...options.stripe?.appearance,
          variables: {
            colorPrimary: theme.colors.accent,
            ...options.stripe?.appearance?.variables,
          },
        },
        clientSecret: data.client_secret,
      });
      const paymentElement = elements.create('payment', {
        ...options.stripe?.elementOptions,
        defaultValues,
      });

      batch(() => {
        state.clientSecret = data.client_secret;
        state.element = paymentElement;
        state.elements = elements;
        state.stripe = stripe;
      });
    },

    get element() {
      return state.element;
    },

    get isSetup() {
      return kind === 'setup_intent';
    },

    loading: false,
    success: false,
    message: null as JSX.Children,

    async submit(e: Event) {
      e.preventDefault();
      this.loading = true;

      const stripe = await this.stripe();
      if (!stripe) return;

      const action =
        kind === 'setup_intent' ? stripe.confirmSetup : stripe.confirmPayment;
      const { error } = await action({
        elements: state.elements!,
        confirmParams: {
          return_url: window.location.href.split('?')[0],
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        this.message = error.message;
      } else {
        this.message = 'An unexpected error occurred.';
      }

      this.loading = false;
    },

    async checkStatus() {
      if (session.dom.ssr || statusCheckStarted) return;
      statusCheckStarted = true;

      const paymentIntentClientSecret = new URLSearchParams(
        window.location.search,
      ).get('payment_intent_client_secret');
      const setupIntentClientSecret = new URLSearchParams(
        window.location.search,
      ).get('setup_intent_client_secret');
      const clientSecret = paymentIntentClientSecret || setupIntentClientSecret;
      kind = paymentIntentClientSecret
        ? 'payment_intent'
        : setupIntentClientSecret
          ? 'setup_intent'
          : null;

      if (!clientSecret) return;

      const stripe = await this.stripe();
      if (!stripe) return;

      const isPayment = kind === 'payment_intent';

      const intent = isPayment
        ? (await stripe.retrievePaymentIntent(clientSecret)).paymentIntent
        : (await stripe.retrieveSetupIntent(clientSecret)).setupIntent;

      switch (intent?.status) {
        case 'succeeded':
          this.message = text(
            I18nShopping,
            isPayment ? 'Payment.Success' : 'Setup.Success',
          );
          this.success = true;
          break;
        case 'processing':
          this.message = text(
            I18nShopping,
            isPayment ? 'Payment.Processing' : 'Setup.Processing',
          );
          this.success = true;
          break;
        default:
          this.message = text(
            I18nShopping,
            isPayment ? 'Payment.Failed' : 'Setup.Failed',
          );
          break;
      }
    },
  });
}
