import { WithLayout, createView, getI18n } from '@donkeyjs/client';
import { bind, setState } from '@donkeyjs/jsx-runtime';
import { PhCheck, PhPencil } from '@donkeyjs/phosphor-icons';
import { formatAddress } from '../../shared/format/formatAddress';
import { AddressForm } from '../ui/AddressForm';
import styles from './ViewAddress.module.css';

export const ViewAddress = createView<DataSchema, 'Address'>(
  {
    name: () => 'Default address view',
  },

  function ViewAddress(props) {
    const i18n = getI18n(true);

    const state = setState({
      editing: !props.node.addressLine1,
    });

    return (
      <div
        class={bind(() => [
          styles.addressContainer,
          { [styles.readonly]: !state.editing },
        ])}
      >
        {() =>
          state.editing ? (
            <>
              <div class={styles.address}>
                <WithLayout set="html:flow">
                  <AddressForm address={props.node} />
                </WithLayout>
              </div>
              <button
                type="button"
                class={styles.icon}
                onclick={() => {
                  state.editing = false;
                }}
              >
                <PhCheck weight="bold" />
              </button>
            </>
          ) : (
            <>
              <div class={styles.address}>
                {() =>
                  formatAddress(props.node, (code) =>
                    i18n.getEnumValue('CountryCode', code),
                  )
                }
              </div>
              <button
                type="button"
                class={styles.icon}
                onclick={() => {
                  state.editing = true;
                }}
              >
                <PhPencil weight="bold" />
              </button>
            </>
          )
        }
      </div>
    );
  },
);
