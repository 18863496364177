import { bind, preventDefault } from '@donkeyjs/jsx-runtime';
import { map } from '@donkeyjs/proxy';
import styles from './Month.module.css';
import type { CalendarState } from './useCalendar';

export function Month(props: Pick<CalendarState, 'visibleWeeks' | 'select'>) {
  return map(
    () => props.visibleWeeks,
    (week) => (
      <div class={styles.week}>
        {week.map((day) => (
          <button
            type="button"
            class={bind(() => [
              styles.day,
              day.marks,
              { otherMonth: day.otherMonth },
            ])}
            onclick={preventDefault(() => {
              props.select(day.date, { keepTime: true });
            })}
          >
            <span>{day.date.getDate()}</span>
          </button>
        ))}
      </div>
    ),
  );
}
